import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectQuery,
  selectReportingFields,
  setQuery,
  setShouldCleanQuery,
  setShouldExecute,
} from "../../../../store/slices/reportsSlice";
import { getTranslation } from "../../../../util/utils";
import ConfirmAlert from "../../../../store/confirm/ConfirmAlert";
import {
  Box,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Transition } from "../MobileCreateReportDialog";
import AddConditionDialogHeader from "./AddConditionDialogHeader";
import RuleFieldSelector from "./RuleFieldSelector";
import RuleOperatorSelector from "./RuleOperatorSelector";
import RuleValueEditor from "./RuleValueEditor";
import {
  editRuleById,
  OPERATORS,
  REPORT_FIELDS,
} from "../../../../util/reports-utils";
import { findPath } from "react-querybuilder";
import { getSvgIcon } from "../../../../util/icons";
import { selectGlobalFontSize } from "../../../../store/slices/appSlice";

const CustomRemoveRuleButton = ({ handleOnClick, ruleOrGroup, path }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const query = useSelector(selectQuery);
  const reportingFields = useSelector(selectReportingFields);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ruleFieldValue, setRuleFieldValue] = useState(ruleOrGroup?.field);
  const [ruleOperatorValue, setRuleOperatorValue] = useState(
    ruleOrGroup?.operator
  );
  const [ruleEditorValue, setRuleEditorValue] = useState(ruleOrGroup?.value);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openConfirmDiscardChanges, setOpenConfirmDiscardChanges] =
    useState(false);

  // Other variables
  const openMenu = Boolean(anchorEl);

  const fields = reportingFields.filter(
    (field) =>
      !(
        field.name === REPORT_FIELDS["ID"] ||
        field.name === REPORT_FIELDS["TAG_ID"] ||
        field.name === REPORT_FIELDS["P_ID"] ||
        field.name === REPORT_FIELDS["P_TAG_ID"] ||
        field.name === REPORT_FIELDS["CREATED_BY"] ||
        field.name === REPORT_FIELDS["RESOURCE_PARENT_CREATED_BY"]
      )
  );

  const alert = {
    content: getTranslation("REMOVE_CONDITION_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("REMOVE_CONDITION_ALERT_TITLE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const haveChanges =
    ruleFieldValue !== ruleOrGroup?.field ||
    ruleOperatorValue !== ruleOrGroup?.operator ||
    ruleEditorValue !== ruleOrGroup?.value;

  const alertDiscardChanges = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleClick = () => {
    handleOnClick();
    dispatch(setShouldCleanQuery(true));
    dispatch(setShouldExecute(false));
  };

  const handleOpenConfirm = () => {
    handleCloseMenu();
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    handleClick();
    setOpenConfirm(false);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditDialog = () => {
    handleCloseMenu();
    setOpenEditDialog(true);
  };

  const handleReset = () => {
    setRuleFieldValue(ruleOrGroup?.field);
    setRuleOperatorValue(ruleOrGroup?.operator);
    setRuleEditorValue(ruleOrGroup?.value);
  };

  const handleOpenConfirmDiscardChanges = () => {
    setOpenConfirmDiscardChanges(true);
  };

  const handleConfirmDiscardChanges = () => {
    handleReset();
    setOpenEditDialog(false);
    setOpenConfirmDiscardChanges(false);
  };

  const handleCloseEditDialog = () => {
    if (haveChanges) {
      handleOpenConfirmDiscardChanges();
    } else {
      setOpenEditDialog(false);
    }
  };

  const handleDoneEdit = () => {
    setOpenEditDialog(false);

    const target = findPath(path, query);

    const newRule = {
      field: ruleFieldValue,
      operator: ruleOperatorValue,
      value: ruleEditorValue,
    };

    const newQuery = editRuleById(query, target?.id, newRule);
    dispatch(setQuery(newQuery));

    dispatch(setShouldExecute(false));
  };

  const handleOnChangeRuleFieldValue = (ruleFieldValue) => {
    setRuleFieldValue(ruleFieldValue);
  };

  const handleOnChangeRuleOperatorValue = (ruleOperatorValue) => {
    setRuleOperatorValue(ruleOperatorValue);
  };

  const handleOnChangeRuleEditorValue = (ruleEditorValue) => {
    setRuleEditorValue(ruleEditorValue);
  };

  return (
    <>
      <IconButton id="more-menu-button" onClick={handleOpenMenu}>
        {getSvgIcon(
          "MORE",
          iconSize,
          iconSize,
          theme.palette.secondary.contrastText
        )}
      </IconButton>

      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{ color: theme.palette.secondary.contrastText }}
          onClick={handleOpenEditDialog}
        >
          <div style={{ marginTop: "5px", marginRight: "10px" }}>
            {getSvgIcon(
              "EDIT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </div>
          Edit
        </MenuItem>
        <MenuItem sx={{ color: "#EE0000" }} onClick={handleOpenConfirm}>
          <div style={{ marginRight: "10px" }}>
            {getSvgIcon("DELETE", iconSize, iconSize, theme.palette.error.main)}
          </div>
          Delete
        </MenuItem>
      </Menu>

      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="delete-rule"
        handleConfirm={handleConfirm}
      />

      <Dialog fullScreen open={openEditDialog} TransitionComponent={Transition}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            paddingBlock: "10px",
          }}
        >
          <Box>
            <Box sx={{ paddingInline: "10px" }}>
              <AddConditionDialogHeader
                handleClose={handleCloseEditDialog}
                handleDone={handleDoneEdit}
                title="Edit condition"
                disabled={
                  ruleOperatorValue !== "IN" && !ruleEditorValue ? 1 : 0
                }
              />
            </Box>

            <Box sx={{ marginTop: "20px", paddingInline: "10px" }}>
              <Box>
                <RuleFieldSelector
                  options={fields}
                  value={ruleFieldValue}
                  handleOnChange={handleOnChangeRuleFieldValue}
                />
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <RuleOperatorSelector
                  options={OPERATORS}
                  value={ruleOperatorValue}
                  field={ruleFieldValue}
                  handleOnChange={handleOnChangeRuleOperatorValue}
                />
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <RuleValueEditor
                  field={ruleFieldValue}
                  operator={ruleOperatorValue}
                  value={ruleEditorValue}
                  handleOnChange={handleOnChangeRuleEditorValue}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <ConfirmAlert
          isOpen={openConfirmDiscardChanges}
          setIsOpen={setOpenConfirmDiscardChanges}
          alert={alertDiscardChanges}
          label="close-mobile-reports-wizard"
          handleConfirm={handleConfirmDiscardChanges}
        />
      </Dialog>
    </>
  );
};

export default CustomRemoveRuleButton;
