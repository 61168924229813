import { Box, MenuItem, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTabs = styled(Tabs)(({ theme, fitScreen }) => ({
  ".MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.contrastText,
  },
  position: fitScreen ? "relative" : "static",
  zIndex: fitScreen ? 100 : 0,
}));

export const MobileTabs = styled(Tabs)(({ theme, fitScreen }) => ({
  ".MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.contrastText,
  },
  position: fitScreen ? "relative" : "static",
  zIndex: fitScreen ? 100 : 0,
  minHeight: "32px",
}));

export const StyledTab = styled(Tab)(({ theme, active }) => ({
  "&.MuiTab-textColorPrimary": {
    color: active ? theme.palette.secondary.contrastText : "",
    maxWidth: "150px",
  },
}));

export const MobileTab = styled(Tab)(({ theme, active }) => ({
  "&.MuiTab-textColorPrimary": {
    color: active ? theme.palette.secondary.contrastText : "",
  },
  minHeight: "32px",
  paddingBlock: "8px",
}));

export const GraphicalRackViewTab = styled(StyledTab)({
  "&.MuiTab-textColorPrimary": {
    maxWidth: "200px",
  },
  minHeight: 0,
});

export const AssetListTabsFlexContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const TabListContentContainer = styled(Box)(({ itemsLength }) => ({
  marginTop: "16px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: itemsLength > 0 ? "space-between" : "start",
}));

export const TabContentContainer = styled(Box)(({ drawerwidth }) => ({
  overflowX: "hidden",
  maxWidth: `${window.outerWidth - drawerwidth - 20}px`,
}));

export const HalfWidthContainer = styled(Box)({
  margin: "auto",
  width: `${window.innerWidth * 0.5}px`,
  position: "relative",
});

export const GraphicalViewMenuItem = styled(MenuItem)(
  ({ theme, graphicalViewAssetDisplay, targetGraphicalViewAssetDisplay }) => ({
    backgroundColor:
      graphicalViewAssetDisplay === targetGraphicalViewAssetDisplay
        ? theme.palette.mode === "light"
          ? "#E6EFF5"
          : "#383838"
        : theme.palette.primary.contrastText,
  })
);
