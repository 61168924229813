import {
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
  alpha,
} from "@mui/material";
import { styled } from "@mui/system";
import { ItemName } from "../ListInlineView.styles";
import { THEME } from "../../../../util/utils";

export const ListTable = styled(Table)({
  width: "100%",
  maxWidth: "650px",
});

export const EmptyCell = styled(TableCell)({
  width: "48px",
});

export const SecondaryContrastColorCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  paddingLeft: "10px",
}));

export const Row = styled(TableRow)({
  cursor: "pointer",
});

export const CategoryText = styled(ItemName)({
  marginLeft: 0,
});

export const FlexContainer = styled(Box)({
  display: "flex",
  justifyContent: "end",
});

export const AssetListTableFlexContainer = styled(FlexContainer)({
  alignItems: "center",
  columnGap: "5px",
});

export const FlexBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

export const NameTableCell = styled(TableCell)({
  width: "150px",
});

export const CountTableCell = styled(TableCell)({
  width: "90px",
});

export const CategoryTableCell = styled(TableCell)({
  width: "150px",
});

export const TableCellText = styled(Typography)(({ theme, isSelected }) => ({
  color: theme.palette.primary.main,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "inherit",
  fontWeight: isSelected ? "bold" : "normal",
}));

export const ChildrenCountCellText = styled(TableCellText)({
  marginBottom: "2px",
});

export const SelectedTableRow = styled(Row)(
  ({ isselected, currentTheme, longPressed }) => ({
    backgroundColor: longPressed
      ? currentTheme === THEME.LIGHT
        ? "#FFF"
        : "#222222"
      : isselected
      ? alpha("#0076bc", 0.1)
      : "",
    ":hover": {
      backgroundColor: isselected ? alpha("#0076bc", 0.2) : alpha("#000", 0.04),
    },
    zIndex: longPressed ? 1200 : "auto",
    position: longPressed ? "relative" : "static",
  })
);

export const TableIconImg = styled("img")({
  objectFit: "contain",
});
