import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { DarkListItem } from "./AssetDetailBasicInfo.styles";

export const ListItemContainer = styled(DarkListItem)(({ istablet }) => ({
  display: istablet === 1 ? "flex" : "block",
  marginTop: "2px",
  flexDirection: istablet === 1 ? "row" : "",
  justifyContent: istablet === 1 ? "space-between" : "",
  gap: "8px",
}));

export const OverflowContainer = styled(Box)({
  overflow: "hidden",
});
