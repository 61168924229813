import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const AssetDetailPage = () => {
  // General hooks
  const { resourceid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/resources/${resourceid}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppAccess>
      <Layer2Access>
        <></>
      </Layer2Access>
    </AppAccess>
  );
};

export default AssetDetailPage;
