import { useTranslation } from "react-i18next";
import {
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import { useDeleteReportDefinitionMutation } from "../../store/slices/api/reportsApiSlice";
import { resetWizard } from "../../store/slices/reportsSlice";
import { messageError, messageSuccess } from "../../util/notification";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import { getSvgIcon } from "../../util/icons";
import UpdateReportDialog from "./update-report/UpdateReportDialog";
import MobileUpdateReportDialog from "./mobile-view/MobileUpdateReportDialog";

const LongPressReportsItemMenu = ({ anchorEl, handleClose, reportId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEditMobile, setOpenEditMobile] = useState(false);
  const [openEditDesktop, setOpenEditDesktop] = useState(false);

  // Other variables
  const userId = user?.id;
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const open = Boolean(anchorEl);
  const iconSize = globalFontSize * 1.5;

  // Queries
  const { data: userRoles } = useUserRolePermissionsQuery({
    organizationId,
    userId,
  });

  // Mutations
  const [deleteReportDefinition] = useDeleteReportDefinitionMutation();

  // Other variables
  const alert = {
    content: getTranslation("DELETE_REPORT_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DELETE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Handlers
  const handleEdit = (e) => {
    e.stopPropagation();
    handleClose();

    if (mobileMatches) {
      setOpenEditMobile(true);
    } else {
      setOpenEditDesktop(true);
    }
  };

  const handleOpenDeleteConfirm = () => {
    handleClose();
    setOpenConfirm(true);
  };

  const handleCloseEditDialogMobile = () => {
    setOpenEditMobile(false);
    dispatch(resetWizard());
    handleClose();
  };

  const deleteConfirm = async () => {
    try {
      await deleteReportDefinition({
        reportDefinitionId: reportId,
        organizationId,
      }).unwrap();

      messageSuccess(getTranslation("successfulDeleteReport", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedDeleteReport", t, i18n));
    }
  };

  return (
    <>
      {openConfirm && (
        <ConfirmAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={alert}
          handleConfirm={deleteConfirm}
          label="delete"
        />
      )}

      {open && (
        <Menu
          sx={{
            "& .MuiMenu-paper": {
              width: "250px",
              maxWidth: "100%",
              borderRadius: "5px",
              marginTop: "4px",
              marginRight: "4px",
              left: mobileMatches ? "10px !important" : "16px",
            },
          }}
          id="longpress-reports-item-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={2}
        >
          <MenuList sx={{ padding: 0 }}>
            {hasAccess(
              "all",
              [permissions["REPORTING_USER_DEFINED_EDIT"]],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                  {getSvgIcon(
                    "EDIT",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </ListItemIcon>

                <ListItemText
                  sx={{ color: theme.palette.secondary.contrastText }}
                >
                  {getTranslation("EDIT", t, i18n)}
                </ListItemText>
              </MenuItem>
            )}

            {hasAccess(
              "all",
              [permissions["REPORTING_USER_DEFINED_DELETE"]],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <MenuItem onClick={handleOpenDeleteConfirm}>
                <ListItemIcon>
                  {getSvgIcon(
                    "DELETE",
                    iconSize,
                    iconSize,
                    theme.palette.error.main
                  )}
                </ListItemIcon>

                <ListItemText sx={{ color: "#EB452C" }}>
                  {getTranslation("DELETE", t, i18n)}
                </ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      {openEditMobile && (
        <MobileUpdateReportDialog
          open={openEditMobile}
          handleClose={handleCloseEditDialogMobile}
          reportId={reportId}
        />
      )}

      {openEditDesktop && (
        <UpdateReportDialog
          reportId={reportId}
          open={openEditDesktop}
          setOpen={setOpenEditDesktop}
        />
      )}
    </>
  );
};

export default LongPressReportsItemMenu;
