import { useDispatch } from "react-redux";
import {
  setShouldAddDefaultConditions,
  setShouldExecute,
} from "../../../../store/slices/reportsSlice";
import { AddGroupButton } from "../../../styles/reports/Reports.styles";
import { getSvgIcon } from "../../../../util/icons";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../../store/slices/appSlice";

const CustomAddGroupButton = ({ handleOnClick, level }) => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleClick = () => {
    handleOnClick();
    dispatch(setShouldAddDefaultConditions(true));
    dispatch(setShouldExecute(false));
  };

  const handleReturnLabel = () => {
    let labelText = "Add group";
    const nestingLevel = level + 1;

    if (nestingLevel > 3 && nestingLevel <= 9) {
      labelText = "Group";
    } else if (nestingLevel > 9) {
      labelText = "G";
    }

    return labelText;
  };

  return (
    <AddGroupButton id="add-filter-group" variant="text" onClick={handleClick}>
      <div style={{ marginTop: "5px", marginRight: "5px" }}>
        {getSvgIcon(
          "CREATE_NEW",
          iconSize,
          iconSize,
          theme.palette.secondary.contrastText
        )}
      </div>

      {handleReturnLabel()}
    </AddGroupButton>
  );
};

export default CustomAddGroupButton;
