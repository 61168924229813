import React, { useCallback } from "react";
import {
  ItemName,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getTranslation } from "../../../util/utils";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import {
  useCreateGraphicalObjectMutation,
  useDeleteGraphicalObjectMutation,
  usePatchGraphicalObjectMutation,
} from "../../../store/slices/api/graphicalObjectsSlice";
import {
  getCalculatedHeight,
  getObjectsPlacements,
} from "../../../util/graphical-rack-view-utils";
import { messageSuccess } from "../../../util/notification";
import { ListItemIcon, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { SecondaryContrastButton } from "../../styles/general/General.styles";
import {
  CloseButton,
  GraphicalItem,
  GraphicalListContainer,
  GraphicalListDialog,
  GraphicalListDialogContent,
  NotPlacedObjectsContainer,
  SectionText,
  StyledDialogTitle,
} from "../../styles/assets/graphical-rack-view/GraphicalRackObjects.styles";
import { updatePresent } from "../../../store/slices/undoRedoGraphicalViewSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";

const GraphicalRackObjects = ({
  resourceChildren,
  graphicalObjects,
  rows,
  characteristicDefinitions,
  open,
  setOpen,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const iconSize = globalFontSize * 1.5;

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [createGraphicalObject] = useCreateGraphicalObjectMutation();
  const [patchGraphicalObject] = usePatchGraphicalObjectMutation();
  const [deleteGraphicalObject] = useDeleteGraphicalObjectMutation();

  // Handlers
  const handleClose = () => {
    setOpen(false);
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const getCharacteristicValue = (characteristicName, resource) => {
    const characteristicDefinitionId = characteristicDefinitions?.find(
      (c) => c.name === characteristicName
    )?.id;
    const resourceCharacteristic = resource?.characteristics?.find(
      (c) => c.id === characteristicDefinitionId
    );
    const typeCharacteristic = resource?.type?.characteristics.find(
      (c) => c.id === characteristicDefinitionId
    );

    return resourceCharacteristic?.value || typeCharacteristic?.value;
  };

  const handleGenerateAll = useCallback(
    async (placedObjects, notPlacedObjects) => {
      const toPlaceObjects = placedObjects.map((po) => {
        const assetHeight = getCharacteristicValue("HEIGHT", po);
        const assetCalculatedHeight = getCalculatedHeight(assetHeight);

        const graphicalObject = graphicalObjects?.find(
          (go) => go.resourceId === po.id
        );

        return {
          height: assetCalculatedHeight,
          start: graphicalObject?.yCoordinate,
        };
      });

      const toBePlaceObjects = notPlacedObjects.map((po) => {
        const resourceHeight = getCharacteristicValue("HEIGHT", po);
        const resourceCalculatedHight = getCalculatedHeight(resourceHeight);

        return {
          resourceId: po.id,
          height: resourceCalculatedHight,
          start: 1,
        };
      });

      const objectPlacements = getObjectsPlacements(
        rows,
        toPlaceObjects,
        toBePlaceObjects
      );

      try {
        handleClose();
        const promises = objectPlacements.map(async (item) => {
          const graphicalObjectDto = {
            resourceId: item.resourceId,
            parentResourceId: null,
            type: "ASSET",
            shape: "RECTANGLE",
            xCoordinate: 0,
            yCoordinate: item.start ?? 1,
            zCoordinate: 0,
            angleOfRotation: 0,
            characteristics: [],
          };

          const createdGraphicalObject = await createGraphicalObject({
            organizationId,
            graphicalObjectDto,
          }).unwrap();

          return createdGraphicalObject;
        });
        let graphicalObjects = await Promise.all(promises);

        messageSuccess(getTranslation("OBJECTS_CREATED", t, i18n));
        dispatch(
          updatePresent({
            action: "CREATE",
            payload: graphicalObjects,
          })
        );
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphicalObjects, characteristicDefinitions]
  );

  const handleGenerateGraphicalObject = async (id) => {
    const resource = resourceChildren?.find((r) => r.id === id);
    const resourceHeight = getCharacteristicValue("HEIGHT", resource);
    const resourceCalculatedHight = getCalculatedHeight(resourceHeight);

    const placedObjects = resourceChildren
      ?.filter((r) => {
        return graphicalObjects?.some((go) => go.resourceId === r.id);
      })
      .map((po) => {
        const assetHeight = getCharacteristicValue("HEIGHT", po);
        const assetCalculatedHeight = getCalculatedHeight(assetHeight);

        const graphicalObject = graphicalObjects?.find(
          (go) => go.resourceId === po.id
        );

        return {
          height: assetCalculatedHeight,
          start: graphicalObject?.yCoordinate,
        };
      });

    const objectPlacement = getObjectsPlacements(rows, placedObjects, [
      { resourceId: resource?.id, height: resourceCalculatedHight },
    ])[0];

    try {
      const graphicalObjectDto = {
        resourceId: id,
        parentResourceId: null,
        type: "ASSET",
        shape: "RECTANGLE",
        xCoordinate: 0,
        yCoordinate: objectPlacement.start ?? 1,
        zCoordinate: 0,
        angleOfRotation: 0,
        characteristics: [],
      };

      const createdGraphicalObject = await createGraphicalObject({
        organizationId,
        graphicalObjectDto,
      }).unwrap();

      messageSuccess(getTranslation("OBJECT_CREATED", t, i18n));

      dispatch(
        updatePresent({
          action: "CREATE",
          payload: [createdGraphicalObject],
        })
      );

      handleClose();
    } catch (error) {}
  };

  const handleRotate = async (id) => {
    try {
      const graphicalObject = graphicalObjects?.find(
        (go) => go.resourceId === id
      );

      const graphicalObjectDto = {
        angleOfRotation:
          graphicalObject.angleOfRotation + 180 === 360 ? 0 : 180,
      };

      await patchGraphicalObject({
        organizationId,
        graphicalObjectId: graphicalObject.id,
        graphicalObjectDto,
      }).unwrap();

      messageSuccess(getTranslation("OBJECT_UPDATED", t, i18n));

      dispatch(
        updatePresent({
          action: "UPDATE",
          payload: [graphicalObject],
        })
      );
      handleClose();
    } catch (error) {}
  };

  const deleteObject = async (id) => {
    try {
      const graphicalObject = graphicalObjects?.find(
        (go) => go.resourceId === id
      );

      await deleteGraphicalObject({
        organizationId,
        graphicalObjectId: graphicalObject.id,
      }).unwrap();
      messageSuccess(getTranslation("OBJECT_DELETED", t, i18n));

      dispatch(
        updatePresent({
          action: "DELETE",
          payload: [graphicalObject],
        })
      );
    } catch (error) {}
  };

  const sortObjects = (objects) => {
    return objects?.sort(function (a, b) {
      const { name, displayId } = a;
      const firstCombinationName = displayId || name;

      const { name: secondName, displayId: secondDisplayId } = b;
      const secondCombinationName = secondName || secondDisplayId;

      if (firstCombinationName < secondCombinationName) {
        return -1;
      }

      if (firstCombinationName > secondCombinationName) {
        return 1;
      }

      return 0;
    });
  };

  const checkHaveNecessaryDimensions = (characteristics) => {
    const characteristicDefinitionWidthId = characteristicDefinitions?.find(
      (cd) => cd.name === "WIDTH"
    ).id;
    const characteristicDefinitionHeightId = characteristicDefinitions?.find(
      (cd) => cd.name === "HEIGHT"
    ).id;

    const hardwareAssetWidth = characteristics?.find(
      (characteristic) => characteristic?.id === characteristicDefinitionWidthId
    );

    const hardwareAssetHeight = characteristics?.find(
      (characteristic) =>
        characteristic?.id === characteristicDefinitionHeightId
    );

    return (
      Boolean(hardwareAssetWidth) &&
      hardwareAssetWidth.value > 0 &&
      Boolean(hardwareAssetHeight) &&
      hardwareAssetHeight.value > 0
    );
  };

  // Other variables
  const dimensionalResources = resourceChildren?.filter((r) => {
    return (
      checkHaveNecessaryDimensions(r.characteristics) ||
      checkHaveNecessaryDimensions(r.type.characteristics)
    );
  });

  const nonDimensionalResources = sortObjects(
    resourceChildren?.filter((r) => {
      return (
        !checkHaveNecessaryDimensions(r.characteristics) &&
        !checkHaveNecessaryDimensions(r.type.characteristics)
      );
    })
  );

  const notPlacedObjects = sortObjects(
    dimensionalResources?.filter((r) => {
      return !graphicalObjects?.some((go) => go.resourceId === r.id);
    })
  );

  const placedObjects = sortObjects(
    resourceChildren?.filter((r) => {
      return graphicalObjects?.some((go) => go.resourceId === r.id);
    })
  );

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <>
        <GraphicalListDialog
          fullScreen={fullScreen}
          onClose={handleClose}
          open={open}
        >
          <StyledDialogTitle id="customized-dialog-title">
            {getTranslation("GRAPHICAL_VIEW_OBJECTS", t, i18n)}
          </StyledDialogTitle>
          <CloseButton aria-label="close" onClick={handleClose}>
            {getSvgIcon(
              "CLEAR",
              iconSize,
              iconSize,
              theme.palette.secondary.light
            )}
          </CloseButton>
          <GraphicalListDialogContent>
            {nonDimensionalResources?.length > 0 && (
              <>
                <Tooltip
                  title={getTranslation("NECESSARY_OBJECT_DIMENSIONS", t, i18n)}
                >
                  <SectionText>
                    {getTranslation("DEVICE_WITHOUT_DIMENSIONS", t, i18n)}
                  </SectionText>
                </Tooltip>

                <GraphicalListContainer id="unnecessary-resources">
                  {nonDimensionalResources?.map((resource) => {
                    const { name, displayId, functionId } = resource;
                    const combinationDisplayIdAndName = displayId || name;
                    const resourceFunction = getFunction(functionId);

                    return (
                      <GraphicalItem>
                        <ListItemIcon>
                          {getSvgIcon(
                            resourceFunction?.name?.toUpperCase(),
                            iconSize,
                            iconSize,
                            theme.palette.secondary.light
                          )}
                        </ListItemIcon>
                        {/* If display id is empty, display name instead */}
                        <ItemName>{combinationDisplayIdAndName}</ItemName>
                      </GraphicalItem>
                    );
                  })}
                </GraphicalListContainer>
              </>
            )}
            {notPlacedObjects?.length > 0 && (
              <>
                <NotPlacedObjectsContainer>
                  <SecondaryText>
                    {getTranslation("NOT_PLACED", t, i18n)}
                  </SecondaryText>
                  <SecondaryContrastButton
                    variant="contained"
                    onClick={() =>
                      handleGenerateAll(placedObjects, notPlacedObjects)
                    }
                  >
                    {getTranslation("GENERATE_OBJECTS", t, i18n)}
                  </SecondaryContrastButton>
                </NotPlacedObjectsContainer>
                <GraphicalListContainer id="not-placed">
                  {notPlacedObjects?.map((resource) => {
                    const { id, name, displayId, functionId } = resource;
                    const combinationDisplayIdAndName = displayId || name;
                    const resourceFunction = getFunction(functionId);

                    return (
                      <GraphicalItem
                        onClick={() => handleGenerateGraphicalObject(id)}
                      >
                        <ListItemIcon>
                          {getSvgIcon(
                            resourceFunction?.name?.toUpperCase(),
                            iconSize,
                            iconSize,
                            theme.palette.secondary.light
                          )}
                        </ListItemIcon>
                        {/* If display id is empty, display name instead */}
                        <ItemName>{combinationDisplayIdAndName}</ItemName>
                      </GraphicalItem>
                    );
                  })}
                </GraphicalListContainer>
              </>
            )}
            {placedObjects?.length > 0 && (
              <>
                <SectionText>
                  {getTranslation("PLACED_OBJECTS", t, i18n)}
                </SectionText>
                <GraphicalListContainer id="placed">
                  {placedObjects?.map((resource) => {
                    const { id, name, displayId, functionId } = resource;
                    const combinationDisplayIdAndName = displayId || name;
                    const resourceFunction = getFunction(functionId);

                    return (
                      <GraphicalItem>
                        <ListItemIcon>
                          {getSvgIcon(
                            resourceFunction?.name?.toUpperCase(),
                            iconSize,
                            iconSize,
                            theme.palette.secondary.light
                          )}
                        </ListItemIcon>
                        {/* If display id is empty, display name instead */}
                        <ItemName>{combinationDisplayIdAndName}</ItemName>
                        <ListItemIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteObject(id);
                          }}
                        >
                          {getSvgIcon(
                            "CLEAR",
                            iconSize,
                            iconSize,
                            theme.palette.secondary.light
                          )}
                        </ListItemIcon>
                        <ListItemIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRotate(id);
                          }}
                        >
                          {getSvgIcon(
                            "ROTATE",
                            iconSize,
                            iconSize,
                            theme.palette.secondary.light
                          )}
                        </ListItemIcon>
                      </GraphicalItem>
                    );
                  })}
                </GraphicalListContainer>
              </>
            )}
          </GraphicalListDialogContent>
        </GraphicalListDialog>
      </>
    </ErrorHandling>
  );
};

export default GraphicalRackObjects;
