import { styled } from "@mui/system";
import { CardMedia, Box } from "@mui/material";
import { PrimaryText } from "./AssetDetailBasicInfo.styles";
import { ListItemContainer } from "./AssetDetail.styles";

export const AssetDetailCardMedia = styled(CardMedia)(({ height }) => ({
  height: height ? height : "200px",
  width: "100%",
  objectFit: "contain",
}));

export const AssetImage = styled(CardMedia)(({ height }) => ({
  height: height ? height : "200px",
  width: "100px",
  objectFit: "contain",
}));

export const SpacedAssetDetailCardMedia = styled(AssetDetailCardMedia)({
  padding: "40px 0",
});

export const AssetHeaderText = styled(PrimaryText)(({ istablet }) => ({
  margin: istablet === 1 ? "25px 15px" : "50px 0",
  marginBottom: 0,
  textAlign: istablet === 1 ? "left" : "center",
}));

export const AssetDetailCardMediaHeaderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const AssetDetailCardMediaHeaderTextContainer = styled(Box)({
  width: "100%",
});

export const ListItemContainerColumn = styled(ListItemContainer)(
  ({ index }) => ({
    display: `${index > 0 ? "flex" : "block"}`,
    justifyContent: `${index > 0 ? "space-between" : ""}`,
  })
);

export const TextWrapper = styled(Box)({
  marginTop: "24px",
});
