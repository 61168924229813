import React from "react";
import { useSelector } from "react-redux";
import {
  WIDGET_COLORS_DARK_THEME,
  WIDGET_COLORS_LIGHT_THEME,
  getReasonablePercentageDistribution,
} from "../../util/reports-utils";
import { THEME } from "../../util/utils";
import { selectTheme } from "../../store/slices/appSlice";
import {
  ReportSecondaryText,
  DarkBackgroundWrapper,
  StyledDoughnut,
} from "../styles/home/ChartPage.styles";
import LoadingSpinner from "../common/LoadingSpinner";

const ReportAssetChart = ({ onClick, title, data = [], isLoading }) => {
  // Selectors
  const theme = useSelector(selectTheme);

  // Other variables
  const reasonableGroup = getReasonablePercentageDistribution(data);
  const colors =
    theme === THEME.LIGHT
      ? WIDGET_COLORS_LIGHT_THEME
      : WIDGET_COLORS_DARK_THEME;
  const pieChartData = {
    labels: reasonableGroup.map((d) => d.label),
    datasets: [
      {
        fill: true,
        data: reasonableGroup.map((d) => d.count),
        backgroundColor: colors.slice(0, reasonableGroup.length),
      },
    ],
    updateMode: "normal",
  };

  return (
    <DarkBackgroundWrapper onClick={onClick}>
      {isLoading && <LoadingSpinner />}

      <ReportSecondaryText textAlign="center" gutterBottom>
        {title}
      </ReportSecondaryText>

      {!isLoading && (
        <>
          {reasonableGroup.length > 0 ? (
            <StyledDoughnut
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                borderWidth: 0,
              }}
              data={pieChartData}
            />
          ) : (
            <ReportSecondaryText fontWeight="bold" margin="auto">
              No Data
            </ReportSecondaryText>
          )}
        </>
      )}
    </DarkBackgroundWrapper>
  );
};

export default ReportAssetChart;
