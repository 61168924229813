import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { ProfileAvatar } from "../../profile/ProfileCardItm.styles";

export const AddResourceAttachmentPageContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

export const AttachFileButton = styled(ProfileAvatar)(({ theme }) => ({
  minWidth: "150px",
  height: "75px",
  backgroundColor: theme.palette.secondary.dark,
}));

export const DescriptionTextFieldContainer = styled(Box)({
  marginTop: "30px",
});
