import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TypeForm from "../../components/types/type-form/TypeForm";
import PageTransition from "../../components/PageTransition";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import {
  setIsActionButtonDisabled,
  setIsLoadingAction,
} from "../../store/slices/appSlice";
import {
  resetForm,
  selectTypeInput,
  selectIsFirstSubmitted,
  setTypeInput,
  setIsFirstSubmitted,
  setOriginalTypeInput,
  selectOriginalTypeInput,
} from "../../store/slices/typeInputSlice";
import {
  getTranslation,
  transitionDirections,
  permissions,
  hasAccess,
  getPermissionsFromUserRoles,
} from "../../util/utils";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../store/slices/authSlice";
import { messageError, messageSuccess } from "../../util/notification";
import {
  selectResourceInput,
  setResourceInput,
} from "../../store/slices/resourceInputSlice";
import { setFunction, setCategory } from "../../store/slices/typeSearchSlice";
import EditTypeHeader from "../../navigation/header/library/EditTypeHeader";
import {
  useDeleteTypeMutation,
  useGetTypeDetailsQuery,
  useUpdateTypeMutation,
} from "../../store/slices/api/typesApiSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  checkTypeChanged,
  transformTypeInputRequest,
  transformTypeResponse,
  validateTypeForm,
} from "../../util/type-utils";
import TypeDelete from "../../components/types/type-form/TypeDelete";
import EventBus, { EVENT_DISCARD_CHANGES } from "../../util/EventBus";
import { useGetAllFunctionsQuery } from "../../store/slices/api/assetManagementSlice";
import Access from "../../components/common/Access";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";

const EditTypePage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { typeId } = useParams();
  const direction = searchParams.get("direction");
  const previousPage = searchParams.get("previousPage");
  const resourceid = searchParams.get("resourceid");

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const typeInput = useSelector(selectTypeInput);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const resourceInput = useSelector(selectResourceInput);
  const originalTypeInput = useSelector(selectOriginalTypeInput);

  // States
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState("");

  // Mutations
  const [
    deleteType,
    { isSuccess: isSuccessDelete, isLoading: isLoadingDelete },
  ] = useDeleteTypeMutation();
  const [updateType, { isLoading }] = useUpdateTypeMutation();

  // Queries
  const {
    data: typeData,
    isLoading: isLoadingTypes,
    isError: isErrorTypes,
    error: typeDataError,
  } = useGetTypeDetailsQuery(
    {
      typeId,
      organizationId,
    },
    { skip: isSuccessDelete || isLoadingDelete }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const { data: userRoles } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  // Other variables
  const typeFunction = allFunctionsData?.find(
    (f) => f.id === typeData?.functionId
  );

  // Handlers
  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));
    try {
      const { error: evaluatedError, firstError } = validateTypeForm(typeInput);
      const isValid = Object.keys(evaluatedError).length === 0;
      if (!isValid) {
        messageError(getTranslation(firstError, t, i18n));
        dispatch(setIsActionButtonDisabled(true));
        return;
      }

      await updateType({
        typeInput: transformTypeInputRequest(typeInput, user?.region),
        organizationId: user?.organizations?.find((o) => o.default)?.id,
        typeId: typeInput.id,
      }).unwrap();

      dispatch(
        setResourceInput({
          ...resourceInput,
          category: typeInput.category,
          functionId: typeInput.functionId,
        })
      );

      dispatch(setCategory(typeInput.category));
      dispatch(setFunction(typeInput.functionId));

      cancelHandler(getReturnUrl());
      messageSuccess(getTranslation("successfulUpdateType", t, i18n));

      dispatch(setIsLoadingAction(false));
    } catch (error) {
      dispatch(setIsLoadingAction(false));
      messageError(getTranslation("failedUpdateType", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getReturnUrl = () => {
    let returnUrl;

    if (previousPage === "type-details") {
      returnUrl = `/library/${typeId}/details?direction=${transitionDirections.TOP_TO_BOTTOM}`;
    } else {
      // Only resourceid will exists if we come from edit.
      if (resourceid) {
        returnUrl = `/resources/${resourceid}/edit?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=edit-type`;
      } else {
        returnUrl = `/resources/add-new?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=edit-type`;
      }
    }

    return returnUrl;
  };

  const cancelHandler = (redirectUrl) => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(redirectRoute);
    }
  };

  // Effects
  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleNavigation = (data) => {
      if (checkTypeChanged(originalTypeInput, typeInput, user?.region)) {
        setOpenConfirmCancel(true);
        setRedirectRoute(data.route);
      } else {
        navigate(data.route);
      }
    };

    EventBus.on(EVENT_DISCARD_CHANGES, handleNavigation);

    return () => EventBus.remove(EVENT_DISCARD_CHANGES, handleNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeInput, originalTypeInput]);

  // Validation Form control
  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateTypeForm(typeInput);
    const isValid = Object.keys(evaluatedError).length === 0;

    dispatch(setIsActionButtonDisabled(!isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeInput]);

  // Set loaded type
  useEffect(() => {
    if (typeData) {
      // We convert the characteristic values in user measurement unit

      const type = transformTypeResponse(typeData);

      dispatch(
        setTypeInput({
          ...type,
          functionId: typeData.functionId,
          category: typeFunction?.category,
        })
      );

      dispatch(
        setOriginalTypeInput({
          ...type,
          functionId: typeData.functionId,
          category: typeFunction?.category,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeData]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setIsLoadingAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (typeDataError?.status === 404) {
    return <Navigate to="*" />;
  }

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoading || isLoadingDelete || isLoadingFunctions || isLoadingTypes
        }
        isError={isErrorFunctions || isErrorTypes}
      >
        <Access oneOf={[permissions.ASSET_MANAGEMENT_TYPE_EDIT]}>
          <Layer2Access>
            <PageTransition direction={direction}>
              <ConfirmAlert
                isOpen={openConfirmCancel}
                setIsOpen={setOpenConfirmCancel}
                alert={{
                  content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
                  confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
                  closeTitle: getTranslation("CANCEL", t, i18n),
                  showConfirm: true,
                }}
                label="discard-changes"
                handleConfirm={cancelHandler}
              />
              <EditTypeHeader
                handleAction={handleSubmit}
                handleCancelAction={() => {
                  let resultRoute = getReturnUrl();

                  if (
                    checkTypeChanged(originalTypeInput, typeInput, user?.region)
                  ) {
                    setRedirectRoute(resultRoute);
                    setOpenConfirmCancel(true);
                    return;
                  }

                  cancelHandler(resultRoute);
                }}
              />
              <HomePagePadding>
                <TypeForm mode="edit" />
                {typeData &&
                  hasAccess(
                    "all",
                    [permissions.ASSET_MANAGEMENT_TYPE_DELETE],
                    getPermissionsFromUserRoles(userRoles)
                  ) && <TypeDelete typeId={typeId} deleteType={deleteType} />}
              </HomePagePadding>
            </PageTransition>
          </Layer2Access>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default EditTypePage;
