import React from "react";
import { List, useTheme } from "@mui/material";
import { getTranslation } from "../../../util/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import { useTranslation } from "react-i18next";
import {
  Indicator,
  IndicatorsContainer,
  IndicatorText,
  PagingText,
} from "../../styles/types/type-list/TypeListInlineView.styles";
import TypeItem from "./TypeItem";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useSelector } from "react-redux";
import { NoDataTextContainer } from "../../styles/general/General.styles";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { getSvgIcon } from "../../../util/icons";

const TypeListInlineView = ({
  list,
  index,
  pageSize,
  fetchMoreData,
  totalRecord,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selector
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.5;

  return (
    <List id="type-list">
      <InfiniteScroll
        dataLength={list?.length}
        next={fetchMoreData}
        hasMore={index < Math.ceil(totalRecord / pageSize)}
        loader={
          list?.length > 0 ? (
            <InfiniteScrollLoader />
          ) : (
            <NoDataTextContainer>
              <SecondaryText align="center">
                {getTranslation("NO_RESULTS_FOUND", t, i18n)}
              </SecondaryText>
            </NoDataTextContainer>
          )
        }
      >
        <IndicatorsContainer rowGap={1}>
          <Indicator>
            {getSvgIcon(
              "PROFILE",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
            {getSvgIcon(
              "HORIZONTAL_RULE",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
            <IndicatorText>
              {getTranslation("USER_CREATED_TYPE", t, i18n)}
            </IndicatorText>
          </Indicator>
          <Indicator>
            {getSvgIcon(
              "MODEL_LIBRARY",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
            {getSvgIcon(
              "HORIZONTAL_RULE",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}

            <IndicatorText>
              {getTranslation("SYSTEM_CREATED_TYPE", t, i18n)}
            </IndicatorText>
          </Indicator>
        </IndicatorsContainer>
        <PagingText variant="h6">
          {getTranslation("ITEMS", t, i18n)
            .replace("{0}", list?.length)
            .replace("{1}", totalRecord)}
        </PagingText>
        {list?.map((row, i) => (
          <TypeItem type={row} />
        ))}
        <div id="end"></div>
      </InfiniteScroll>
    </List>
  );
};

export default TypeListInlineView;
