import ResourceImageForm from "../../components/assets/asset-form/ResourceImageForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import { transitionDirections, getTranslation } from "../../util/utils";
import ChooseAssetImageHeader from "../../navigation/header/assets/ChooseAssetImageHeader";
import { useDispatch } from "react-redux";
import {
  editResourceImage,
  selectResourceImages,
} from "../../store/slices/resourceImageSlice";
import ErrorHandling from "../../components/common/ErrorHandling";
import { selectUser } from "../../store/slices/authSlice";
import { useGetResourceImageQuery } from "../../store/slices/api/resourceImagesApiSlice";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const DuplicateEditLocalResourceImagePage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const localResourceImages = useSelector(selectResourceImages);

  // Other variables
  const direction = searchParams.get("direction");
  const index = Number(searchParams.get("index"));
  const singleResourceImage = localResourceImages[index];

  // Queries
  const {
    data: resourceImageUriData,
    isLoading,
    isError,
    error,
  } = useGetResourceImageQuery(
    {
      imageUri: singleResourceImage ? singleResourceImage?.selectedFile : "",
      organizationId,
    },
    {
      skip: !singleResourceImage || !singleResourceImage?.isFromDb,
    }
  );

  const transformedImage = {
    imageCategory: singleResourceImage?.imageCategory,
    selectedFile: resourceImageUriData || singleResourceImage?.selectedFile,
    resourceid: "",
    id: singleResourceImage?.id,
    name: singleResourceImage?.name,
    mimeType: singleResourceImage?.name?.substring(
      singleResourceImage?.name?.lastIndexOf(".")
    ),
    description: singleResourceImage?.description,
    file: singleResourceImage?.file,
    isFromDb: singleResourceImage?.isFromDb,
  };

  // State
  const [resourceImageInput, setResourceImageInput] =
    useState(transformedImage);

  const [name, setName] = useState(
    resourceImageInput?.name?.substring(
      0,
      resourceImageInput?.name?.lastIndexOf(".")
    )
  );

  const [extension, setExtension] = useState(
    resourceImageInput?.name?.substring(
      resourceImageInput?.name?.lastIndexOf(".")
    )
  );

  // Handlers
  const cancelHandler = () => {
    navigate(
      `/resources/${resourceId}/duplicate?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=edit-local-image`
    );
  };

  // Other variables
  const isValid =
    (resourceImageInput.file || resourceImageInput.selectedFile) && name;

  const isDisabled = !isValid;

  const handleSubmit = useCallback(async () => {
    if (!resourceImageInput.selectedFile) {
      messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
      return;
    }

    const resourceImage = {
      ...resourceImageInput,
      name: name + extension,
    };

    messageSuccess(
      getTranslation("RESOURCE_IMAGE_UPDATED_SUCCESSFULLY", t, i18n)
    );
    dispatch(editResourceImage({ index, resourceImage }));
    cancelHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImageInput, name]);

  useEffect(() => {
    if (resourceImageUriData) {
      const newResourceImageInput = {
        ...resourceImageInput,
        selectedFile: resourceImageUriData,
      };

      setResourceImageInput(newResourceImageInput);
    }
  }, [resourceImageUriData]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={isLoading}
          isError={error?.status !== 404 && isError}
        >
          <PageTransition direction={direction}>
            <ChooseAssetImageHeader
              handleAction={handleSubmit}
              handleCancelAction={cancelHandler}
              isDisabled={isDisabled}
            />

            <HomePagePadding>
              <ResourceImageForm
                resourceImageInput={resourceImageInput}
                setResourceImageInput={setResourceImageInput}
                name={name}
                setName={setName}
                setExtension={setExtension}
              />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default DuplicateEditLocalResourceImagePage;
