import { IconButton, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setShouldExecute } from "../../../store/slices/reportsSlice";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useState } from "react";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";

const CustomRemoveGroupButton = ({ handleOnClick }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const handleClick = () => {
    handleOnClick();
    dispatch(setShouldExecute(false));
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    handleClick();
    setOpenConfirm(false);
  };

  // Other variables
  const alert = {
    content: getTranslation("REMOVE_FILTER_GROUP_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("REMOVE_FILTER_GROUP_ALERT_TITLE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };
  const iconSize = globalFontSize * 1.2;

  return (
    <>
      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="delete-filter-group"
        handleConfirm={handleConfirm}
      />

      <IconButton id="remove-group-button" onClick={handleOpenConfirm}>
        {getSvgIcon(
          "DELETE",
          iconSize,
          iconSize,
          theme.palette.secondary.contrastText
        )}
      </IconButton>
    </>
  );
};

export default CustomRemoveGroupButton;
