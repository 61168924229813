import {
  Box,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import AddConditionDialogHeader from "./query-builder/AddConditionDialogHeader";
import RuleFieldSelector from "./query-builder/RuleFieldSelector";
import {
  CustomValueEditorAutocomplete,
  CustomValueEditorTextField,
  ReportPreviewTypography,
  ReportPreviewWrapper,
  ReportRefreshButton,
  ReportsSecondaryText,
  ResultCheckboxMobile,
} from "../../styles/reports/Reports.styles";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { Transition } from "./MobileCreateReportDialog";
import { useSelector } from "react-redux";
import {
  selectExecutionResultFields,
  selectQuery,
  selectReportingFields,
  selectResultFields,
  selectShouldRefreshDialogTable,
  selectTypeId,
  setExecutionResultFields,
  setShouldRefreshDialogTable,
} from "../../../store/slices/reportsSlice";
import {
  AGGREGATION_TYPES,
  constructAggregations,
  parseQueryExecute,
  REPORT_FIELDS,
} from "../../../util/reports-utils";
import { selectUser } from "../../../store/slices/authSlice";
import { useReportExecutionBaseQuery } from "../../../store/slices/api/reportsApiSlice";
import ResultFieldDialogPreviewTable from "./ResultFieldDialogPreviewTable";
import { useDispatch } from "react-redux";
import BounceLetterSpinner from "../../common/BounceLetterSpinner";
import { useState } from "react";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";

const options = AGGREGATION_TYPES.map((item) => item.name);

const ResultFieldsDialog = ({
  open,
  handleClose,
  handleDone,
  fieldValue,
  columnTitle,
  aggregationTypes,
  orderIndex,
  sortMethod,
  handleOnChangeFieldValue,
  handleOnChangeColumnTitle,
  handleOnChangeAggregationType,
  handleOnChangeOrderIndex,
  handleOnChangeSortMethod,
  handleReturnCountForMenuItems,
  isGroup,
  isEdit,
  haveChanges,
  setFieldValue,
  setColumnTitle,
  setAggregationTypes,
  setOrderIndex,
  setSortMethod,
  resultField,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const reportingFields = useSelector(selectReportingFields);
  const typeId = useSelector(selectTypeId);
  const executionResultFields = useSelector(selectExecutionResultFields);
  const query = useSelector(selectQuery);
  const resultFields = useSelector(selectResultFields);
  const shouldRefreshDialogTable = useSelector(selectShouldRefreshDialogTable);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Other variables
  const fields = reportingFields.filter(
    (field) =>
      !(
        field.name === REPORT_FIELDS["ID"] ||
        field.name === REPORT_FIELDS["TAG_ID"] ||
        field.name === REPORT_FIELDS["P_ID"] ||
        field.name === REPORT_FIELDS["P_TAG_ID"] ||
        field.name === REPORT_FIELDS["CREATED_BY"] ||
        field.name === REPORT_FIELDS["RESOURCE_PARENT_CREATED_BY"]
      )
  );

  const headerTitle = isEdit
    ? isGroup
      ? "Edit group"
      : "Edit column"
    : isGroup
    ? "Add group"
    : "Add column";

  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  const fieldsPayload = [
    ...executionResultFields
      .filter((field) => field.usedForGrouping)
      .map((field, i) => {
        return {
          field: {
            name: field.field,
          },
          displayName: field.displayName,
          index: i,
          orderIndex: field.orderIndex,
          sortMethod: field.sortMethod,
          usedForGrouping: field.usedForGrouping,
        };
      }),
    {
      field: {
        name: fieldValue,
      },
      displayName: columnTitle,
      index: executionResultFields.filter((field) => field.usedForGrouping)
        ?.length,
      orderIndex,
      sortMethod,
      usedForGrouping: isGroup,
    },
  ];

  const finalAggregations = constructAggregations([
    ...executionResultFields,
    {
      field: fieldValue,
      usedForGrouping: isGroup,
      aggregationTypes,
    },
  ]);

  const filters = [parseQueryExecute(query)];

  const alert = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const iconSize = globalFontSize;

  // Queries
  const {
    data: reportExecutionData,
    isLoading,
    isFetching,
  } = useReportExecutionBaseQuery(
    {
      organizationId,
      typeId,
      fields: fieldsPayload,
      filters,
      aggregations: finalAggregations,
    },
    { skip: !shouldRefreshDialogTable }
  );

  // Handlers
  const handleLoadPreview = () => {
    dispatch(setShouldRefreshDialogTable(true));
    dispatch(setExecutionResultFields(resultFields));
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    if (resultField) {
      setFieldValue(resultField.field);
      setColumnTitle(resultField.displayName);
      setAggregationTypes(resultField.aggregationTypes);
      setOrderIndex(resultField.orderIndex);
      setSortMethod(resultField.sortMethod);
    } else {
      setFieldValue(REPORT_FIELDS["NAME"]);
      setColumnTitle(getTranslation(REPORT_FIELDS["NAME"], t, i18n));
      setAggregationTypes([]);
      setOrderIndex(null);
      setSortMethod(null);
    }

    handleClose();
    setOpenConfirm(false);
  };

  return (
    <Dialog
      id={fieldValue}
      fullScreen
      open={open}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          paddingBlock: "10px",
        }}
      >
        <Box>
          <Box sx={{ paddingInline: "10px" }}>
            <AddConditionDialogHeader
              handleClose={() => {
                if (haveChanges) {
                  handleOpenConfirm();
                } else {
                  handleClose();
                }
              }}
              handleDone={handleDone}
              title={headerTitle}
              disabled={!columnTitle ? 1 : 0}
            />
          </Box>

          <Box sx={{ marginTop: "20px", paddingInline: "10px" }}>
            <Box>
              <RuleFieldSelector
                options={fields}
                value={fieldValue}
                handleOnChange={handleOnChangeFieldValue}
              />
            </Box>

            <Box sx={{ marginTop: "20px" }}>
              <CustomValueEditorTextField
                id="column-title"
                name="column-title"
                label={getTranslation("COLUMN_TITLE", t, i18n)}
                placeholder={getTranslation("COLUMN_TITLE", t, i18n)}
                type="text"
                variant="outlined"
                fullWidth
                value={columnTitle}
                onChange={handleOnChangeColumnTitle}
                error={!columnTitle}
              />
            </Box>

            {!isGroup && (
              <Box sx={{ marginTop: "20px" }}>
                <CustomValueEditorAutocomplete
                  fullWidth
                  multiple
                  id="function"
                  options={options}
                  disableCloseOnSelect
                  getOptionLabel={(option) => getTranslation(option, t, i18n)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <ResultCheckboxMobile
                        icon={getSvgIcon(
                          "CHECKBOX",
                          iconSize,
                          iconSize,
                          theme.palette.primary.main
                        )}
                        checkedIcon={getSvgIcon(
                          "CHECKBOX_DONE",
                          iconSize,
                          iconSize,
                          theme.palette.primary.main
                        )}
                        checked={selected}
                      />

                      {getTranslation(option, t, i18n)}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={getTranslation("FUNCTION_FOR_GROUP", t, i18n)}
                    />
                  )}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) =>
                      index >= tagValue?.length - 1
                        ? getTranslation(option, t, i18n)
                        : getTranslation(option, t, i18n) + ", "
                    );
                  }}
                  value={aggregationTypes}
                  onChange={handleOnChangeAggregationType}
                />
              </Box>
            )}

            <Box sx={{ marginTop: "30px" }}>
              <ReportsSecondaryText variant="caption">
                {isGroup
                  ? getTranslation("SORTING", t, i18n)
                  : getTranslation("SORTING_INSIDE_GROUP", t, i18n)}
              </ReportsSecondaryText>

              <Grid sx={{ marginTop: "10px" }} container columnSpacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="sort-index-select-label" shrink={true}>
                      Order
                    </InputLabel>

                    <Select
                      labelId="sort-index-select-label"
                      label="Order"
                      id="sort-index"
                      variant="outlined"
                      value={orderIndex}
                      onChange={handleOnChangeOrderIndex}
                      displayEmpty={true}
                    >
                      <MenuItem disabled value={null}>
                        -
                      </MenuItem>

                      {handleReturnCountForMenuItems().map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="sort-type-select-label" shrink={true}>
                      Asc / Desc
                    </InputLabel>

                    <Select
                      labelId="sort-type-select-label"
                      label="Asc / Desc"
                      id="sort-type-select"
                      variant="outlined"
                      value={sortMethod}
                      onChange={handleOnChangeSortMethod}
                      disabled={!orderIndex}
                      error={Boolean(orderIndex) && !sortMethod}
                      displayEmpty={true}
                    >
                      <MenuItem disabled value={null}>
                        -
                      </MenuItem>
                      <MenuItem value={"asc"}>A to Z</MenuItem>
                      <MenuItem value={"desc"}>Z to A</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box sx={{ paddingInline: "10px" }}>
          {!(isLoading || isFetching) && (
            <ReportPreviewWrapper>
              <ReportPreviewTypography variant="body">
                {getTranslation("REPORT_PREVIEW", t, i18n)}
              </ReportPreviewTypography>

              <ReportRefreshButton
                id="refresh-preview"
                disabled={!columnTitle || shouldRefreshDialogTable}
                onClick={handleLoadPreview}
                variant="text"
              >
                {getSvgIcon(
                  "REFRESH",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText,
                  { marginRight: "5px" }
                )}
                {getTranslation("REPORT_REFRESH", t, i18n)}
              </ReportRefreshButton>
            </ReportPreviewWrapper>
          )}

          {isLoading || isFetching ? (
            <BounceLetterSpinner />
          ) : (
            reportExecutionData?.data &&
            reportExecutionData.data.length > 0 && (
              <ResultFieldDialogPreviewTable
                rows={reportExecutionData.data}
                shouldExecute={shouldRefreshDialogTable}
                field={{
                  field: fieldValue,
                  displayName: columnTitle,
                  aggregationTypes,
                  usedForGrouping: isGroup,
                }}
              />
            )
          )}
        </Box>
      </Box>

      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="close-mobile-reports-wizard"
        handleConfirm={handleConfirm}
      />
    </Dialog>
  );
};

export default ResultFieldsDialog;
