import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ORGANIZATION_DATA,
  selectContinueBtnName,
  selectOrganizationData,
  selectOrganizationOnboardingSteps,
  selectUserData,
  setContinueBtnName,
  setOrganizationData,
} from "../../store/slices/onboardingSlice";
import { selectUser, setUser } from "../../store/slices/authSlice";
import {
  useOnboardOrganizationMutation,
  useUploadOrganizationImageMutation,
} from "../../store/slices/api/organizationsApiSlice";
import { setErrorMessage, setLanguage } from "../../store/slices/appSlice";
import { useSaveAddressMutation } from "../../store/slices/api/addressesApiSlice";
import {
  testInput,
  transitionDirections,
  userLocalStorageKey,
} from "../../util/utils";
import ErrorHandling from "../../components/common/ErrorHandling";
import Footer from "../../components/Footer";
import MainContent from "../../components/MainContent";
import MobileSteps from "../../components/MobileSteps";
import {
  OnboardingFullContainer,
  OnboardingSteppedContentContainer,
} from "../../components/styles/onboarding/OnboardingStepped.styles";
import { ERROR_MESSAGE, loadUserFromBackend } from "../../util/UserUtil";
import { useUpdateUserOrganizationMutation } from "../../store/slices/api/userManagementSlice";

const OrganizationOnboardingPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const organizationData = useSelector(selectOrganizationData);
  const organizationOnboardingSteps = useSelector(
    selectOrganizationOnboardingSteps
  );
  const continueBtnName = useSelector(selectContinueBtnName);

  // Mutations
  const [
    onboardOrganization,
    {
      isLoading: isLoadingOnboardOrganization,
      isSuccess: isSuccessOnboardingOrganization,
    },
  ] = useOnboardOrganizationMutation(user.id);

  const [
    uploadOrganizationImage,
    { isLoading: isLoadingUploadOrganizationImage },
  ] = useUploadOrganizationImageMutation();

  const [saveAddress] = useSaveAddressMutation();

  const [
    updateUserOrganization,
    { isLoading: isLoadingUpdateUserOrganization },
  ] = useUpdateUserOrganizationMutation();

  // State
  const [activeStep, setActiveStep] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [transitionDirection, setTransitionDirection] = useState(
    transitionDirections.RIGHT_TO_LEFT
  );

  // Handlers
  const submitUploadedOrganizationImage = async (file, organizationId) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await uploadOrganizationImage({
        formData,
        organizationId,
      }).unwrap();

      return resp.logoUri;
    } catch (error) {
      console.error("Failed to upload user profile image", error);
    }
  };

  const handleCurrentOrganizationData = () => {
    const currentUser = user;

    const email = currentUser?.email ?? "";
    const firstName = currentUser?.firstName ?? "";
    const lastName = currentUser?.lastName ?? "";

    dispatch(
      setOrganizationData({
        ...organizationData,
        contactEmail: email,
        contactPerson: firstName + " " + lastName,
      })
    );
  };

  const handleOnboardOrganization = async () => {
    try {
      const data = await onboardOrganization({
        ...organizationData,
      }).unwrap();

      if (data) {
        await updateUserOrganization({
          userId: user.id,
          newOrganizationId: data.id,
        }).unwrap();

        if (organizationData.file) {
          await submitUploadedOrganizationImage(organizationData.file, data.id);
        }

        const fetchedUser = await loadUserFromBackend();

        if (fetchedUser) {
          dispatch(setUser(fetchedUser));
        }

        const address = {
          addressType: "BILLING",
          addressLine1: organizationData.addressLine1,
          city: organizationData.city,
          state: organizationData.state,
          country: organizationData.country,
          postcode: organizationData.postcode,
          district: "District",
        };

        if (Boolean(organizationData.addressLine2)) {
          address.addressLine2 = organizationData.addressLine2;
        }

        await saveAddress({
          address,
          organizationId: data.id,
        }).unwrap();

        dispatch(setLanguage(userData.language));
        dispatch(setOrganizationData(DEFAULT_ORGANIZATION_DATA));

        navigate("/");

        localStorage.setItem(
          userLocalStorageKey("app-initialized"),
          JSON.stringify(true)
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === ERROR_MESSAGE.ERROR_FETCHING_USER) {
          dispatch(setErrorMessage(error.message));
          navigate("/error-page");
        }
      }
    }
  };

  const handleNext = async () => {
    if (activeStep === 3) {
      await handleOnboardOrganization();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setTransitionDirection(transitionDirections.RIGHT_TO_LEFT);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    dispatch(setContinueBtnName("continueButton"));
    setTransitionDirection(transitionDirections.LEFT_TO_RIGHT);
  };

  const handleOrganizationNames = () => {
    const isValidOrganizationName = testInput.ORGANIZATION_NAME(
      organizationData?.organizationName ?? ""
    );

    if (isValidOrganizationName) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleOrganizationDetails = () => {
    const isValidOrganizationAddressLine1 = testInput.ORGANIZATION_NAME(
      organizationData?.addressLine1 ?? ""
    );

    const isValidOrganizationPostcode = testInput.ORGANIZATION_NAME(
      organizationData?.postcode ?? ""
    );

    const isValidOrganizationCity = testInput.ORGANIZATION_NAME(
      organizationData?.city ?? ""
    );

    const isValidOrganizationCountry = Boolean(organizationData?.country);

    const isValidOrganizationPhone = testInput.PHONE_NUMBER(
      organizationData?.contactPhone ?? ""
    );

    if (
      isValidOrganizationAddressLine1 &&
      isValidOrganizationPostcode &&
      isValidOrganizationCity &&
      isValidOrganizationCountry &&
      isValidOrganizationPhone
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleOrganizationDataStorageLocation = () => {
    const isValid = organizationData.dataStorageLocationId;
    setIsButtonDisabled(!isValid);
  };

  const handleOrganizationSubscription = () => {
    const isValid = Boolean(organizationData.subscription);
    setIsButtonDisabled(!isValid);
  };

  // Effects
  useEffect(() => {
    handleCurrentOrganizationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      activeStep === 0 ||
      activeStep === 1 ||
      activeStep === 2 ||
      activeStep === 3
    ) {
      dispatch(setContinueBtnName("continueButton"));
    } else {
      if (organizationData?.subscription?.toLowerCase() === "free") {
        dispatch(setContinueBtnName("ACTIVATE_FREE_TIER"));
      } else {
        dispatch(setContinueBtnName("SUBSCRIBE_FOR"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, organizationData.subscription]);

  useEffect(() => {
    if (activeStep === 0) {
      handleOrganizationNames();
    } else if (activeStep === 1) {
      handleOrganizationDetails();
    } else if (activeStep === 2) {
      handleOrganizationDataStorageLocation();
    } else {
      setIsButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationData.organizationName,
    organizationData.nickname,
    organizationData.contactPhone,
    organizationData.contactEmail,
    organizationData.contactPerson,
    organizationData.addressLine1,
    organizationData.addressLine2,
    organizationData.postcode,
    organizationData.city,
    organizationData.state,
    organizationData.country,
    organizationData.dataStorageLocationId,
    organizationData.subscription,
    activeStep,
  ]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingOnboardOrganization ||
        isSuccessOnboardingOrganization ||
        isLoadingUploadOrganizationImage ||
        isLoadingUpdateUserOrganization
      }
      isError={false}
    >
      <OnboardingFullContainer>
        <MobileSteps
          stepQuantity={organizationOnboardingSteps}
          activeStep={activeStep}
          handleBack={handleBack}
        />

        <OnboardingSteppedContentContainer>
          <MainContent
            activeStep={activeStep}
            transitionDirection={transitionDirection}
            type="organization-onboarding"
          />

          <Footer
            activeStep={activeStep}
            isButtonDisabled={isButtonDisabled}
            handleNext={handleNext}
            continueBtnName={continueBtnName}
          />
        </OnboardingSteppedContentContainer>
      </OnboardingFullContainer>
    </ErrorHandling>
  );
};

export default OrganizationOnboardingPage;
