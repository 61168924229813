import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import { transitionDirections, getTranslation } from "../../util/utils";
import { useDispatch } from "react-redux";
import {
  addResourceAttachment,
  DEFAULT_RESOURCE_ATTACHMENT,
} from "../../store/slices/resourceAttachmentSlice";
import ResourceAttachmentForm from "../../components/assets/asset-form/ResourceAttachmentForm";
import AddAttachmentHeader from "../../navigation/header/assets/AddAttachmentHeader";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const DuplicateAddLocalResourceAttachmentPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // State
  const [resourceAttachmentInput, setResourceAttachmentInput] = useState(
    DEFAULT_RESOURCE_ATTACHMENT
  );

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");

  // Other variables
  const direction = searchParams.get("direction");

  // Handlers
  const cancelHandler = () => {
    navigate(
      `/resources/${resourceId}/duplicate?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=add-local-attachment`
    );
  };

  // Other variables
  const isValid =
    (resourceAttachmentInput.file || resourceAttachmentInput.selectedFile) &&
    name;

  const isDisabled = !isValid;

  const handleSubmit = useCallback(async () => {
    if (!resourceAttachmentInput.selectedFile) {
      messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
      return;
    }

    const input = {
      ...resourceAttachmentInput,
      name: name + extension,
    };

    messageSuccess(
      getTranslation("RESOURCE_ATTACHMENT_CREATED_SUCCESSFULLY", t, i18n)
    );
    dispatch(addResourceAttachment(input));
    cancelHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentInput, name]);

  return (
    <AppAccess>
      <Layer2Access>
        <PageTransition direction={direction}>
          <AddAttachmentHeader
            handleAction={handleSubmit}
            handleCancelAction={cancelHandler}
            isDisabled={isDisabled}
          />

          <HomePagePadding>
            <ResourceAttachmentForm
              resourceAttachmentInput={resourceAttachmentInput}
              setResourceAttachmentInput={setResourceAttachmentInput}
              name={name}
              setName={setName}
              setExtension={setExtension}
            />
          </HomePagePadding>
        </PageTransition>
      </Layer2Access>
    </AppAccess>
  );
};

export default DuplicateAddLocalResourceAttachmentPage;
