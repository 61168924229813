import {
  Box,
  FormControl,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

export const StickySearchContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  zIndex: 50,
  top: 20,
  marginTop: "8px",
  backgroundColor: theme.palette.primary.contrastText,
}));

export const StickySearchFormControl = styled(FormControl)(({ theme }) =>
  theme.unstable_sx({
    minWidth: "100%",
  })
);

export const StickySearchListOptions = styled(List)({
  width: "100%",
});

export const StickySearchStyledDialogListItemButton = styled(ListItemButton)(
  ({ theme }) => ({
    color: theme.palette.secondary.contrastText,
  })
);

export const StickySearchListItemText = styled(ListItemText)({
  display: "flex",
  justifyContent: "center",
});

export const SortingOptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const StickySearchTextField = styled(TextField)({
  marginTop: "-8px", // evaluated of margin-top of the TextField
});

export const SortingOptionContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const SearchFieldContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
