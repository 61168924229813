import { useTheme } from "@mui/material";
import { RouletteSpinner } from "react-spinner-overlay";
import { LoadingSpinnerContainer } from "../styles/common/LoadingSpinner.styles";

const LoadingSpinner = () => {
  // General hooks
  const theme = useTheme();

  return (
    <LoadingSpinnerContainer id="loading-spinner">
      <RouletteSpinner
        loading={true}
        color={theme.palette.secondary.contrastText}
      />
    </LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;
