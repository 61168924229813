import React, { useMemo, useRef, useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetAllCharacteristicsQuery,
  useGetCharacteristicSetTemplatesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import {
  groupCharacteristics,
  mergeCharacteristics,
} from "../../../util/asset-utils";
import CharacteristicItem from "./CharacteristicItem";
import { getTranslation } from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import AssetDetailCharacteristicGroupAccordionColumnVersion from "./AssetDetailCharacteristicGroupAccordionColumnVersion";
import AssetDetailLocationColumnVersion from "./AssetDetailLocationColumnVersion";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";

const AssetDetailCharacteristicGroupColumnVersion = ({
  resourceCharacteristics,
  typeCharacteristics,
  functionId,
  resourceParentId,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // States
  const [borderWidth, setBorderWidth] = useState("25%");

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  // Queries
  const {
    data: characteristicSetTemplatesData,
    isLoading: isLoadingCharacteristicSetTemplates,
    isError: isErrorCharacteristicSetTemplates,
  } = useGetCharacteristicSetTemplatesQuery({
    resourceFunctionId: functionId,
    organizationId,
  });
  const {
    data: characteristicDefinitionsData,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetAllCharacteristicsQuery({ organizationId });

  const characteristics = useMemo(
    () =>
      mergeCharacteristics(
        resourceCharacteristics,
        typeCharacteristics,
        characteristicDefinitionsData
      ),
    [
      resourceCharacteristics,
      typeCharacteristics,
      characteristicDefinitionsData,
    ]
  );

  const { setTemplates, others } = useMemo(
    () => groupCharacteristics(characteristics, characteristicSetTemplatesData),
    [characteristics, characteristicSetTemplatesData]
  );

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <ErrorHandling
      isLoading={
        isLoadingCharacteristicSetTemplates ||
        isLoadingCharacteristicDefinitions
      }
      isError={
        isErrorCharacteristicSetTemplates || isErrorCharacteristicDefinitions
      }
    >
      <div>
        {setTemplates
          .filter(
            (setTemplate) =>
              setTemplate?.characteristics?.filter((char) =>
                Boolean(char.value)
              )?.length > 0
          )
          .sort((a, b) => {
            if (a.orderPriority > b.orderPriority) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((setTemplate) => (
            <AssetDetailCharacteristicGroupAccordionColumnVersion
              setTemplate={setTemplate}
              key={setTemplate.name}
            />
          ))}

        <StyledAccordion currentTheme={currentTheme} defaultExpanded>
          <StyledAccordionSummary
            value={borderWidth}
            expandIcon={
              <div
                style={{ marginTop: "5px", marginRight: "5px" }}
                ref={iconRef}
              >
                {getSvgIcon(
                  "ARROW_RIGHT",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle variant="body2" istablet={1} ref={textRef}>
              {getTranslation("OTHER", t, i18n)}
            </SectionTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <Grid container columnSpacing={2}>
              {others
                ?.filter((char) => Boolean(char.value))
                ?.slice()
                ?.sort((a, b) => {
                  if (a.id > b.id) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                ?.map((row) => (
                  <Grid key={row.id} item xs={12}>
                    <CharacteristicItem
                      characteristic={row}
                      region={user?.region}
                    />
                  </Grid>
                ))}
            </Grid>

            <AssetDetailLocationColumnVersion
              parentId={resourceParentId}
              resourceFunctionId={functionId}
            />
          </StyledAccordionDetails>
        </StyledAccordion>
      </div>
    </ErrorHandling>
  );
};

export default AssetDetailCharacteristicGroupColumnVersion;
