import { useSelector } from "react-redux";
import { useUserRolePermissionsQuery } from "../store/slices/api/userManagementSlice";
import { selectUser } from "../store/slices/authSlice";
import {
  checkOrganizationRestricted,
  getPermissionsFromUserRoles,
  hasAccess,
  permissions,
} from "../util/utils";
import { useGetCurrentSubscriptionQuery } from "../store/slices/api/subscriptionsApiSlice";

const useCheckOrganizationRestricted = (
  organization,
  isLoadingUpdateUserOrganization
) => {
  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const invalidUser = !user?.firstName || !user?.lastName;
  const hasOrganizations = user?.organizations?.length > 0;

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isFetching: isFetchingUserRoles,
  } = useUserRolePermissionsQuery(
    {
      organizationId: organization?.id,
      userId: user?.id,
    },
    { skip: invalidUser || !hasOrganizations || !organization?.id }
  );

  // Other variables
  const canFetchCurrentSubscription =
    !isLoadingUserRoles &&
    !isFetchingUserRoles &&
    !isLoadingUpdateUserOrganization &&
    userRoles &&
    userRoles.length > 0 &&
    organization?.subscription
      ? hasAccess(
          "all",
          [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
          getPermissionsFromUserRoles(userRoles)
        ) && !organization?.restricted
      : false;

  // Queries
  const {
    error: currentSubscriptionError,
    isLoading: isLoadingCurrentSubscription,
  } = useGetCurrentSubscriptionQuery(
    {
      organizationId: organization?.id,
    },
    {
      skip: !canFetchCurrentSubscription,
    }
  );

  // Other variables
  const isRestricted = checkOrganizationRestricted(
    organization,
    currentSubscriptionError
  );

  return {
    isLoading: isLoadingUserRoles || isLoadingCurrentSubscription,
    isRestricted,
  };
};

export default useCheckOrganizationRestricted;
