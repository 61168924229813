import { useDispatch } from "react-redux";
import { setShouldExecute } from "../../../store/slices/reportsSlice";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { AddGroupButton } from "../../styles/reports/Reports.styles";
import { getSvgIcon } from "../../../util/icons";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const CustomAddRuleButton = ({ handleOnClick, path }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleClick = () => {
    handleOnClick();
    dispatch(setShouldExecute(false));
  };

  return (
    <AddGroupButton id="add-condition" variant="text" onClick={handleClick}>
      <div style={{ marginTop: "5px", marginRight: "5px" }}>
        {getSvgIcon(
          "CREATE_NEW",
          iconSize,
          iconSize,
          theme.palette.secondary.contrastText
        )}
      </div>

      {getTranslation("ADD_CONDITION", t, i18n)}
    </AddGroupButton>
  );
};

export default CustomAddRuleButton;
