import React, { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Stack,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ViewButton } from "../../../../components/styles/assets/DesktopHeader.styles";
import {
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectPageView,
  selectSearchTerm,
  selectSortingOption,
  setIsSearchOpen,
  setPageView,
  setSearchTerm,
  setShouldSearch,
  setSortingOption,
  SORTING_OPTIONS,
} from "../../../../store/slices/appSlice";
import { getTranslation, transitionDirections } from "../../../../util/utils";
import { useTranslation } from "react-i18next";
import { messageError } from "../../../../util/notification";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setParentId,
  setTrail,
} from "../../../../store/slices/columnViewSlice";
import {
  HeaderContainer,
  NavigationOptionsContainer,
  SearchTextField,
} from "../../../../components/styles/header/assets/desktop/AssetListDesktopHeader.styles";
import { SortingOptionsMenu } from "../../../../components/styles/types/type-list/DesktopTypeStickySearch.styles";
import { DynamicVisibilityItemIcon } from "../../../../components/styles/types/type-list/DesktopTypeStickySearch.styles";
import { getSvgIcon } from "../../../../util/icons";

const SearchAssetsDesktopHeader = ({
  resourceData,
  goBackHandler,
  tagName,
  from,
  tagId,
  queryResourceId,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const sortingOption = useSelector(selectSortingOption);
  const pageView = useSelector(selectPageView);
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const searchTerm = useSelector(selectSearchTerm);

  // States
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const open = Boolean(anchorEl);
  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleOpenSortingOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortingOptions = () => {
    setAnchorEl(null);
  };

  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchTerm.length < 3) {
        messageError(getTranslation("SEARCH_TERM_TOO_SHORT", t, i18n));
      } else {
        dispatch(setShouldSearch(true));
        navigate(
          `/search?from=${from}${tagId ? `&tagId=${tagId}` : ""}${
            resourceData ? `&resourceId=${resourceData.id}` : ""
          }${tagName ? `&tagName=${tagName}` : ""}`
        );

        dispatch(setIsSearchOpen(false));
      }
    } else {
      dispatch(setIsSearchOpen(true));
    }
  };

  const handleSearch = (e) => {
    dispatch(setTrail([]));

    if (Boolean(e.target.value)) {
      dispatch(setParentId(null));
    }

    dispatch(setSearchTerm(e.target.value));
    dispatch(setShouldSearch(false));
  };

  const handleClearSearch = () => {
    dispatch(setIsSearchOpen(false));
    dispatch(setSearchTerm(""));
    dispatch(setShouldSearch(false));
    dispatch(setParentId(null));

    if (pathname.includes("search")) {
      navigate(`/resources?direction=${transitionDirections.LEFT_TO_RIGHT}`);
    }
  };

  const handleFormatClick = (view) => {
    dispatch(setPageView(view));
  };

  const handleSortingOptionClick = (option) => {
    dispatch(setSortingOption(option));
  };

  const handleOrderClick = (e) => {
    e.stopPropagation();

    dispatch(
      setSortingOption({
        ...sortingOption,
        order: sortingOption.order === "asc" ? "desc" : "asc",
      })
    );
  };

  return (
    <HeaderContainer variant="elevation">
      <NavigationOptionsContainer>
        <IconButton id="back-column-view" onClick={goBackHandler}>
          {getSvgIcon(
            "ARROW_LEFT",
            iconSize,
            iconSize,
            theme.palette.secondary.contrastText
          )}
        </IconButton>

        <IconButton id="forward-column-view" disabled={true}>
          {getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            theme.palette.action.disabled
          )}
        </IconButton>
      </NavigationOptionsContainer>
      <Stack direction="row" spacing={2}>
        <Stack direction="row">
          <ViewButton
            id="grid-view-btn"
            active={pageView === "app"}
            onClick={() => handleFormatClick("app")}
          >
            {getSvgIcon(
              "TILE_VIEW",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </ViewButton>
          <Divider orientation="vertical" />
          <ViewButton
            id="list-view-btn"
            active={pageView === "inline"}
            onClick={() => handleFormatClick("inline")}
          >
            {getSvgIcon(
              "LIST_VIEW",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </ViewButton>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Button onClick={handleOpenSortingOptions}>
            {getSvgIcon(
              "SORTING",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
            {getSvgIcon(
              "EXPAND",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </Button>
          <SortingOptionsMenu
            id="options-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseSortingOptions}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {SORTING_OPTIONS.filter((option) => option.value !== "Z-A").map(
              (option) => {
                const { value } = option;
                return (
                  <MenuItem
                    onClick={() => handleSortingOptionClick(option)}
                    key={value}
                  >
                    <DynamicVisibilityItemIcon
                      isvisible={sortingOption.value === value}
                    >
                      {getSvgIcon(
                        "CHECK",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.light
                      )}
                    </DynamicVisibilityItemIcon>
                    <ListItemText>
                      {value === "A-Z"
                        ? getTranslation("NAME", t, i18n)
                        : getTranslation(value, t, i18n)}
                    </ListItemText>
                    <DynamicVisibilityItemIcon
                      isvisible={sortingOption.value === value}
                      onClick={handleOrderClick}
                    >
                      {sortingOption.order === "asc"
                        ? getSvgIcon(
                            "COLLAPSE",
                            iconSize,
                            iconSize,
                            theme.palette.secondary.light
                          )
                        : getSvgIcon(
                            "EXPAND",
                            iconSize,
                            iconSize,
                            theme.palette.secondary.light
                          )}
                    </DynamicVisibilityItemIcon>
                  </MenuItem>
                );
              }
            )}
          </SortingOptionsMenu>
        </Stack>

        <Stack direction="row">
          {isSearchOpen && (
            <SearchTextField
              inputProps={{
                "data-testid": "search-field",
                style: {
                  padding: 0,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton id="search-btn" onClick={handleSearchClick}>
                      {getSvgIcon(
                        "SEARCH",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.contrastText
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      {getSvgIcon(
                        "CLEAR",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.contrastText
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="search-field"
              variant="outlined"
              placeholder={getTranslation("SEARCH_TERM", t, i18n)}
              value={searchTerm}
              onChange={handleSearch}
            />
          )}
          {!isSearchOpen && (
            <IconButton id="search-btn" onClick={handleSearchClick}>
              {getSvgIcon(
                "SEARCH",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </IconButton>
          )}
        </Stack>
      </Stack>
    </HeaderContainer>
  );
};

export default SearchAssetsDesktopHeader;
