import { IconButton, Grid, Stack, Box, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import {
  useGetOrganizationLogoQuery,
  useLeaveOrganizationMutation,
} from "../../store/slices/api/organizationsApiSlice";
import { StyledAvatar } from "../styles/header/Header.styles";
import {
  useUpdateUserOrganizationMutation,
  useUserRolePermissionsQuery,
} from "../../store/slices/api/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { refreshUser, selectUser, setUser } from "../../store/slices/authSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import { useTranslation } from "react-i18next";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import { messageError, messageSuccess } from "../../util/notification";
import { PrimaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { useNavigate } from "react-router-dom";
import {
  ChangeActiveOrganizationButton,
  ChangeActiveOrganizationButtonBoxContainer,
  EditOrganizationButton,
  OrganizationDesktopCardActionsBoxContainer,
  OrganizationDesktopCardBoldTypography,
  OrganizationDesktopCardDetailsGrid,
  OrganizationDesktopCardHeaderBoxContainer,
  OrganizationDesktopCardHoverOverlay,
  OrganizationDesktopCardInfoGridContainer,
  OrganizationDesktopCardLogoBoxContainer,
  OrganizationDesktopCardWrapper,
  OrganizationDesktopWrapper,
} from "../styles/profile/ProfileDesktop.styles";
import ErrorHandling from "../common/ErrorHandling";
import OrganizationDesktopCardMembers from "./OrganizationDesktopCardMembers";
import { fetchCurrentUser } from "../../services/UserService";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";

const OrganizationDesktopCard = ({ organization, isActive }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const activeOrganization = user?.organizations?.find((o) => o.default);
  const iconSize = globalFontSize * 1.5;

  // Mutations
  const [leaveOrganization, { isLoading: isLoadingLeaveOrganization }] =
    useLeaveOrganizationMutation();

  const [
    updateUserOrganization,
    { isLoading: isLoadingUpdateUserOrganization },
  ] = useUpdateUserOrganizationMutation();

  // Custom hooks
  const { isLoading, isRestricted } = useCheckOrganizationRestricted(
    organization,
    isLoadingUpdateUserOrganization
  );

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery(
    {
      organizationId: organization?.id,
      userId: user?.id,
    },
    { skip: !organization?.id }
  );

  const { data: organizationLogo } = useGetOrganizationLogoQuery(
    {
      logoUri: organization?.logoUri,
    },
    {
      skip: !Boolean(organization?.logoUri),
    }
  );

  // State
  const [initialFile, setInitialFile] = useState(organizationLogo);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // Other variables
  const orgDisabled = !organization.owner && isRestricted;

  const canEdit =
    !orgDisabled &&
    hasAccess(
      "all",
      [permissions.ORG_MANAGEMENT_EDIT],
      getPermissionsFromUserRoles(userRoles)
    );

  const canLeave =
    organization &&
    Object.keys(organization).length > 0 &&
    !organization.owner &&
    !isRestricted;

  // Handlers
  const handleLeaveOrganization = async () => {
    try {
      await leaveOrganization(organization?.id).unwrap();
      const response = await fetchCurrentUser();
      dispatch(refreshUser(response.data));
      messageSuccess(getTranslation("successfulLeaveOrganization", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedLeaveOrganization", t, i18n));
    }
  };

  const handleChangeOrganization = async (organizationId) => {
    const oldOrganizationId = activeOrganization.id;
    const oldOrganizations = user.organizations;

    const newOrganizationId = organizationId;

    const newOrganizations = user?.organizations?.map((org) => {
      return org.id === newOrganizationId
        ? { ...org, default: true }
        : { ...org, default: false };
    });

    dispatch(
      setUser({
        ...user,
        defaultOrganizationId: newOrganizationId,
        organizations: newOrganizations,
      })
    );

    try {
      await updateUserOrganization({
        userId: user.id,
        newOrganizationId: newOrganizationId,
      }).unwrap();

      messageSuccess(
        getTranslation("successfulUpdateUserOrganization", t, i18n)
      );
    } catch (error) {
      dispatch(
        setUser({
          ...user,
          defaultOrganizationId: oldOrganizationId,
          organizations: oldOrganizations,
        })
      );

      messageError(getTranslation("failedUpdateUserOrganization", t, i18n));
    }
  };

  // Effects
  useEffect(() => {
    if (organizationLogo) {
      setInitialFile(organizationLogo);
    }
  }, [organizationLogo]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingUserRoles ||
        isLoadingLeaveOrganization ||
        isLoadingUpdateUserOrganization ||
        isLoading
      }
      isError={isErrorUserRoles}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <OrganizationDesktopCardWrapper
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <ConfirmAlert
            isOpen={openConfirm}
            setIsOpen={setOpenConfirm}
            alert={{
              content:
                getTranslation("organizationLeaveMessage", t, i18n) +
                  " " +
                  organization?.nickname ||
                organization?.name +
                  " " +
                  getTranslation("organization", t, i18n).toLowerCase(),
              confirmTitle: getTranslation("organizationConfirmTitle", t, i18n),
              closeTitle: getTranslation("CANCEL", t, i18n),
              showConfirm: true,
            }}
            label="leave-organization"
            handleConfirm={handleLeaveOrganization}
          />

          <OrganizationDesktopCardHeaderBoxContainer>
            <Grid container>
              <Grid display="flex" justifyContent="center" item>
                <OrganizationDesktopCardLogoBoxContainer>
                  <StyledAvatar
                    id="avatar"
                    src={
                      organization?.logoUri && initialFile ? initialFile : null
                    }
                  >
                    {organization?.nickname
                      ? organization?.nickname?.substring(0, 2)?.toUpperCase()
                      : organization?.name?.substring(0, 2)?.toUpperCase()}
                  </StyledAvatar>
                </OrganizationDesktopCardLogoBoxContainer>
              </Grid>

              <OrganizationDesktopCardDetailsGrid item xs>
                <OrganizationDesktopWrapper>
                  <PrimaryText>
                    {organization?.nickname || organization?.name}
                  </PrimaryText>

                  {isRestricted && (
                    <PrimaryText fontWeight="bold">(Restricted)</PrimaryText>
                  )}

                  <OrganizationDesktopCardBoldTypography variant="caption">
                    Role:{" "}
                    {getTranslation(
                      userRoles ? userRoles[0]?.name : "",
                      t,
                      i18n
                    )}
                  </OrganizationDesktopCardBoldTypography>
                </OrganizationDesktopWrapper>
              </OrganizationDesktopCardDetailsGrid>

              <Grid item flexShrink={0}>
                <Stack direction="row">
                  {canEdit && (
                    <OrganizationDesktopCardActionsBoxContainer>
                      <EditOrganizationButton
                        id={
                          isActive
                            ? "edit-desktop-active"
                            : "edit-desktop-other"
                        }
                        onClick={() =>
                          navigate(
                            `/profile/organization/${organization.id}/edit`
                          )
                        }
                      >
                        {getSvgIcon(
                          "EDIT",
                          iconSize,
                          iconSize,
                          theme.palette.secondary.contrastText
                        )}
                      </EditOrganizationButton>
                    </OrganizationDesktopCardActionsBoxContainer>
                  )}
                  {canLeave && (
                    <OrganizationDesktopCardActionsBoxContainer>
                      <IconButton
                        id={
                          isActive
                            ? "leave-desktop-active"
                            : "leave-desktop-other"
                        }
                        onClick={() => setOpenConfirm(true)}
                        color="error"
                      >
                        {getSvgIcon(
                          "LOG_OUT",
                          iconSize,
                          iconSize,
                          theme.palette.error.main
                        )}
                      </IconButton>
                    </OrganizationDesktopCardActionsBoxContainer>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </OrganizationDesktopCardHeaderBoxContainer>

          <OrganizationDesktopCardInfoGridContainer container>
            <OrganizationDesktopCardMembers
              organization={organization}
              userRoles={userRoles}
            />
          </OrganizationDesktopCardInfoGridContainer>

          {!isRestricted &&
            isHovered &&
            activeOrganization?.id !== organization?.id && (
              <>
                <ChangeActiveOrganizationButtonBoxContainer>
                  <ChangeActiveOrganizationButton
                    onClick={() => {
                      handleChangeOrganization(organization.id);
                    }}
                    variant="text"
                  >
                    {getTranslation("MAKE_ACTIVE", t, i18n)}
                  </ChangeActiveOrganizationButton>
                </ChangeActiveOrganizationButtonBoxContainer>

                <OrganizationDesktopCardHoverOverlay />
              </>
            )}
        </OrganizationDesktopCardWrapper>
      </Box>
    </ErrorHandling>
  );
};

export default OrganizationDesktopCard;
