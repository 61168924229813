import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";
import AssetDetailList from "./AssetDetailList";
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
} from "../../../store/slices/api/tagsSlice";
import {
  setGlobalTags,
  setInitialTags,
  setTags,
} from "../../../store/slices/tagsSlice";
import {
  AssetDetailCardMediaHeaderContainer,
  AssetDetailCardMediaHeaderTextContainer,
} from "../../styles/assets/asset-detail/AssetDetailCard.styles";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import {
  RESOURCE_CATEGORIES,
  getPermissionsFromUserRoles,
  hasAccess,
  permissions,
} from "../../../util/utils";
import { IconButton, useTheme } from "@mui/material";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import EditAsset from "../asset-form/EditAsset";
import { getSvgIcon } from "../../../util/icons";
import CarouselSlider from "./CarouselSlider";
import useResourceCombinedImages from "../../../hooks/useResourceCombinedImages";

const AssetDetails = ({ resourceId }) => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [openEdit, setOpenEdit] = useState(false);

  // Other variables
  const organizationId = user.organizations.find((o) => o.default).id;
  const iconSize = globalFontSize * 1.5;

  // Mutations
  const [addFavorite] = useAddFavoriteMutation();
  const [deleteFavorite] = useDeleteFavoriteMutation();

  // Queries
  const { data: userRoles } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError: isErrorResource,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    {
      skip: !Boolean(resourceId),
    }
  );

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isError: isErrorResourceTags,
  } = useGetResourceTagsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    {
      skip: !Boolean(resourceId),
    }
  );

  // Other variables
  const typeId = resourceData?.type?.id || resourceData?.typeId;

  // Custom hooks
  const { resourceImages, isLoading: isLoadingImages } =
    useResourceCombinedImages({ resourceId, typeId });

  // Other variables
  const resourceFunction = allFunctionsData?.find(
    (f) => f.id === resourceData?.functionId
  );

  // Handlers
  const handleAddFavorite = async (e) => {
    e.stopPropagation();

    try {
      await addFavorite({
        resourceid: resourceId,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();
    } catch (error) {
      console.error("Failed to add favorite", error);
    }
  };

  const handleDeleteFavorite = async (e) => {
    e.stopPropagation();

    try {
      await deleteFavorite({
        resourceid: resourceId,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();
    } catch (error) {
      console.error("Failed to delete favorite", error);
    }
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  // Effects
  // Here, we will fetch the tags and put them into the Redux store (similar to resource images and attachments)
  useEffect(() => {
    if (tags) {
      const mappedTags = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingResource ||
        isLoadingResourceTags ||
        isLoadingTags ||
        isLoadingImages
      }
      isError={isErrorResource || isErrorResourceTags || isErrorTags}
    >
      {openEdit && (
        <EditAsset
          resourceId={resourceId}
          open={openEdit}
          setOpen={setOpenEdit}
        />
      )}

      {resourceImages && resourceImages.length > 0 ? (
        <CarouselSlider resourceImages={resourceImages} />
      ) : (
        getSvgIcon(
          resourceFunction?.name?.toUpperCase(),
          iconSize,
          iconSize,
          theme.palette.secondary.light,
          { marginTop: "8px" }
        )
      )}
      <AssetDetailCardMediaHeaderContainer>
        <AssetDetailCardMediaHeaderTextContainer>
          <SecondaryText variant="h5" id="asset-name">
            {resourceData?.name}
          </SecondaryText>
        </AssetDetailCardMediaHeaderTextContainer>
        {hasAccess(
          "all",
          [
            permissions[
              `ASSET_MANAGEMENT_${
                resourceFunction?.category ===
                RESOURCE_CATEGORIES.HARDWARE_ASSET
                  ? "HARDWARE"
                  : resourceFunction?.category
              }_EDIT`
            ],
          ],
          getPermissionsFromUserRoles(userRoles)
        ) && (
          <IconButton onClick={handleOpenEdit}>
            {getSvgIcon(
              "EDIT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </IconButton>
        )}

        {resourceData?.isFavorite ? (
          <IconButton onClick={handleDeleteFavorite}>
            {getSvgIcon(
              "STAR_FILLED",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </IconButton>
        ) : (
          <IconButton onClick={handleAddFavorite}>
            {getSvgIcon(
              "STAR",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </IconButton>
        )}
      </AssetDetailCardMediaHeaderContainer>

      <AssetDetailList resourceData={resourceData} />
    </ErrorHandling>
  );
};

export default AssetDetails;
