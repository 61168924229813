import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import {
  EmptyCell,
  ListTable,
  SecondaryContrastColorCell,
} from "../../styles/assets/asset-list/AssetListTable.styles";
import AssetListTableRow from "./AssetListTableRow";

const AssetListTable = ({ rows }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    rows &&
    rows.length && (
      <TableContainer component={Paper}>
        <ListTable sx={{ minWidth: "0px" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <EmptyCell></EmptyCell>

              <SecondaryContrastColorCell>
                {getTranslation("NAME", t, i18n)}
              </SecondaryContrastColorCell>

              <SecondaryContrastColorCell>
                {getTranslation("CATEGORY", t, i18n)}
              </SecondaryContrastColorCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const resource = row;

              if (!resource) {
                return <></>;
              }

              const { displayId, name } = resource;
              const combinationDisplayIdAndName = displayId || name;

              return (
                <AssetListTableRow
                  key={combinationDisplayIdAndName}
                  resource={resource}
                />
              );
            })}
          </TableBody>
        </ListTable>
      </TableContainer>
    )
  );
};

export default AssetListTable;
