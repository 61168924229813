import { styled } from "@mui/system";
import { Box, Stack, Typography } from "@mui/material";
import { ItemName } from "../../assets/ListInlineView.styles";

export const IndicatorsContainer = styled(Stack)({
  paddingBottom: "8px",
});

export const Indicator = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

export const IndicatorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const PagingText = styled(ItemName)(({ theme }) => ({
  marginBottom: 1,
  color: theme.palette.secondary.main,
}));
