import { Box } from "@mui/material";
import { defaultValidator, QueryBuilder } from "react-querybuilder";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import "react-querybuilder/dist/query-builder.css";
import { QueryBuilderMaterial } from "@react-querybuilder/material";
import CustomAddGroupButton from "./CustomAddGroupButton";
import CustomAddRuleButton from "./CustomAddRuleButton";
import CustomRemoveGroupButton from "./CustomRemoveGroupButton";
import CustomRemoveRuleButton from "./CustomRemoveRuleButton";
import "./AdvancedSearchQueryBuilder.css";
import CustomFieldSelector from "./CustomFieldSelector";
import CustomOperatorSelector from "./CustomOperatorSelector";
import CustomValueEditor from "./CustomValueEditor";
import CustomCombinatorSelector from "./CustomCombinatorSelector";
import {
  COMBINATORS,
  OPERATORS,
  REPORT_FIELDS,
} from "../../../util/reports-utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectQuery,
  selectReportingFields,
  setQuery,
} from "../../../store/slices/reportsSlice";
import { selectTheme } from "../../../store/slices/appSlice";
import CustomDragHandle from "./CustomDragHandle";

const AdvancedSearchQueryBuilder = () => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const query = useSelector(selectQuery);
  const reportingFields = useSelector(selectReportingFields);
  const currentTheme = useSelector(selectTheme);

  // Other variables
  const dnd = { ...ReactDnD, ...ReactDndHtml5Backend };

  const controlElements = {
    addGroupAction: CustomAddGroupButton,
    addRuleAction: CustomAddRuleButton,
    removeGroupAction: CustomRemoveGroupButton,
    removeRuleAction: CustomRemoveRuleButton,
    fieldSelector: CustomFieldSelector,
    operatorSelector: CustomOperatorSelector,
    valueEditor: CustomValueEditor,
    combinatorSelector: CustomCombinatorSelector,
    dragHandle: CustomDragHandle,
  };

  const fields = reportingFields.filter(
    (field) =>
      !(
        field.name === REPORT_FIELDS["ID"] ||
        field.name === REPORT_FIELDS["TAG_ID"] ||
        field.name === REPORT_FIELDS["P_ID"] ||
        field.name === REPORT_FIELDS["P_TAG_ID"] ||
        field.name === REPORT_FIELDS["CREATED_BY"] ||
        field.name === REPORT_FIELDS["RESOURCE_PARENT_CREATED_BY"]
      )
  );

  // Handlers
  const handleChangeQuery = (newQuery) => {
    dispatch(setQuery(newQuery));
  };

  return (
    <Box>
      <QueryBuilderMaterial>
        <QueryBuilderDnD dnd={dnd}>
          <QueryBuilder
            fields={fields}
            combinators={COMBINATORS}
            operators={OPERATORS}
            query={query}
            controlElements={controlElements}
            showCombinatorsBetweenRules={false}
            resetOnFieldChange={true}
            resetOnOperatorChange={true}
            onQueryChange={(newQuery) => handleChangeQuery(newQuery)}
            validator={defaultValidator}
            autoSelectField={false}
            autoSelectOperator={false}
          />
        </QueryBuilderDnD>
      </QueryBuilderMaterial>
    </Box>
  );
};

export default AdvancedSearchQueryBuilder;
