import { Slide, useTheme } from "@mui/material";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../store/slices/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { getTranslation } from "../util/utils";
import { OrganizationDropDownName } from "./styles/home/Home.styles";
import {
  DropDownButton,
  OrganizationNameContainer,
} from "./styles/profile/OrganizationsMenu.styles";
import {
  CancelButton,
  OrganizationsMenuContainer,
  OrganizationsMenuList,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "./styles/general/General.styles";
import ErrorHandling from "./common/ErrorHandling";
import OrganizationsMenuListItem from "./OrganizationsMenuListItem";
import { getSvgIcon } from "../util/icons";
import { selectGlobalFontSize } from "../store/slices/appSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrganizationsMenu = () => {
  // General Hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [open, setOpen] = useState(false);

  // Other variables
  const iconSize = globalFontSize * 2;

  // Handlers
  const handleNavigateToActiveOrganization = () =>
    navigate(
      `profile/organization/${
        user?.organizations?.find((o) => o.default)?.id
      }?quick_access=true`,
      {
        state: { from: location.pathname + location.search },
      }
    );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ErrorHandling isLoading={false} isError={false}>
      <OrganizationsMenuContainer>
        <StyledDialog
          fullWidth
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <StyledDialogContent>
            <StyledDialogTitle>
              {getTranslation("CHANGE_DEFAULT_ORGANIZATION", t, i18n)}
            </StyledDialogTitle>
            <OrganizationsMenuList id="sorting-options-list">
              {user?.organizations?.map((option) => (
                <OrganizationsMenuListItem
                  key={option.id}
                  organization={option}
                  setOpen={setOpen}
                />
              ))}
            </OrganizationsMenuList>
          </StyledDialogContent>

          <CancelButton id={`cancel-button`} onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
        </StyledDialog>

        <OrganizationNameContainer>
          <div>
            <OrganizationDropDownName
              id="default-organization-name"
              onClick={handleNavigateToActiveOrganization}
            >
              {user?.organizations?.find((o) => o.default)?.nickname ||
                user?.organizations?.find((o) => o.default)?.name}
            </OrganizationDropDownName>
          </div>
          <div>
            {user?.organizations?.length > 1 && (
              <DropDownButton
                id="organization-select-button"
                onClick={handleClickOpen}
              >
                {getSvgIcon(
                  "EXPAND",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </DropDownButton>
            )}
          </div>
        </OrganizationNameContainer>
      </OrganizationsMenuContainer>
    </ErrorHandling>
  );
};

export default OrganizationsMenu;
