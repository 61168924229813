import packageJson from "./../package.json";

//Define default backend
let DEFAULT_BASE_BACKEND_URL = "http://localhost:8080/api/v1";

/*
If user gives a docker parameter as It will be set to env-config.js file in docker imge!!! and this data will be override process.env.REACT_APP_BASE_BACKEND_URL file
and env-config.js data will be added in index.html as script
*/
// Configure back end url parameter
let BACKEND_URL_AS_PARAMETER;

if (window._env_ && window._env_.BACKEND_URL) {
  BACKEND_URL_AS_PARAMETER = window._env_.BACKEND_URL;
} else if (process.env.REACT_APP_BACKEND_URL) {
  BACKEND_URL_AS_PARAMETER = process.env.REACT_APP_BACKEND_URL;
}

// Configure application version
let TMP_APPLICATION_VERSION = "v1.0.0_";

if (window._env_ && window._env_.APPLICATION_VERSION) {
  TMP_APPLICATION_VERSION = window._env_.APPLICATION_VERSION;
} else if (packageJson.version) {
  TMP_APPLICATION_VERSION = packageJson.version;
}

export const APPLICATION_VERSION = TMP_APPLICATION_VERSION;

// Configure disable dev tools
let TMP_DISABLE_DEV_TOOLS = "false";

if (window._env_ && window._env_.REACT_APP_DISABLE_DEV_TOOLS) {
  TMP_DISABLE_DEV_TOOLS = window._env_.REACT_APP_DISABLE_DEV_TOOLS;
} else if (process.env.REACT_APP_DISABLE_DEV_TOOLS) {
  TMP_DISABLE_DEV_TOOLS = process.env.REACT_APP_DISABLE_DEV_TOOLS;
}

export const DISABLE_DEV_TOOLS = TMP_DISABLE_DEV_TOOLS;

// Configure enable debugger protection
let TMP_ENABLE_DEBUGGER_PROTECTION = "false";

if (window._env_ && window._env_.REACT_APP_ENABLE_DEBUGGER_PROTECTION) {
  TMP_ENABLE_DEBUGGER_PROTECTION =
    window._env_.REACT_APP_ENABLE_DEBUGGER_PROTECTION;
} else if (process.env.REACT_APP_ENABLE_DEBUGGER_PROTECTION) {
  TMP_ENABLE_DEBUGGER_PROTECTION =
    process.env.REACT_APP_ENABLE_DEBUGGER_PROTECTION;
}

export const ENABLE_DEBUGGER_PROTECTION = TMP_ENABLE_DEBUGGER_PROTECTION;

// Configure enable redirect protection
let TMP_ENABLE_REDIRECT_PROTECTION = "false";

if (window._env_ && window._env_.REACT_APP_ENABLE_REDIRECT_PROTECTION) {
  TMP_ENABLE_REDIRECT_PROTECTION =
    window._env_.REACT_APP_ENABLE_REDIRECT_PROTECTION;
} else if (process.env.REACT_APP_ENABLE_REDIRECT_PROTECTION) {
  TMP_ENABLE_REDIRECT_PROTECTION =
    process.env.REACT_APP_ENABLE_REDIRECT_PROTECTION;
}

export const ENABLE_REDIRECT_PROTECTION = TMP_ENABLE_REDIRECT_PROTECTION;

// Configure redirect protection url
let TMP_REDIRECT_PROTECTION_URL = "https://www.google.com";

if (window._env_ && window._env_.REACT_APP_REDIRECT_PROTECTION_URL) {
  TMP_REDIRECT_PROTECTION_URL = window._env_.REACT_APP_REDIRECT_PROTECTION_URL;
} else if (process.env.REACT_APP_REDIRECT_PROTECTION_URL) {
  TMP_REDIRECT_PROTECTION_URL = process.env.REACT_APP_REDIRECT_PROTECTION_URL;
}

export const REDIRECT_PROTECTION_URL = TMP_REDIRECT_PROTECTION_URL;

// Configure airfocus url
let TMP_AIRFOCUS_URL =
  "https://twinalize.airfocus.com/share/forms/48a2705475fba61ab6d6c82617d256d2";

if (window._env_ && window._env_.REACT_APP_AIRFOCUS_URL) {
  TMP_AIRFOCUS_URL = window._env_.REACT_APP_AIRFOCUS_URL;
} else if (process.env.REACT_APP_AIRFOCUS_URL) {
  TMP_AIRFOCUS_URL = process.env.REACT_APP_AIRFOCUS_URL;
}

export const AIRFOCUS_URL = TMP_AIRFOCUS_URL;

// let TMP_VOLUME_APPROACH_PERCENTAGE = "0.8";

// if (window._env_ && window._env_.REACT_APP_ALERT_APPROACH_PERCENTAGE) {
//   TMP_VOLUME_APPROACH_PERCENTAGE =
//     window._env_.REACT_APP_VOLUME_APPROACH_PERCENTAGE;
// } else if (process.env.REACT_APP_REDIRECT_PROTECTION_URL) {
//   TMP_VOLUME_APPROACH_PERCENTAGE =
//     process.env.REACT_APP_VOLUME_APPROACH_PERCENTAGE;
// }

// export const ALERT_APPROACH_PERCENTAGE = TMP_VOLUME_APPROACH_PERCENTAGE;

export const BACKEND_URL = BACKEND_URL_AS_PARAMETER
  ? BACKEND_URL_AS_PARAMETER
  : DEFAULT_BASE_BACKEND_URL;

export const account_url = BACKEND_URL + "/accounts";
export const get_account_url = account_url + "/:accountId";
export const create_account_for_me = account_url + "/forme";

export const sub_accounts = account_url + "/:accountId/subaccounts";
export const confirm_account_url = get_account_url + "/confirm";

export const sites_url = BACKEND_URL + "/sites";
export const delete_site_url = BACKEND_URL + "/sites/:siteId";

export const get_app_info = BACKEND_URL + "/info";

export const get_resources_summary =
  BACKEND_URL + "/organizations/:organizationId/resources/summary";
export const get_full_resources_children =
  BACKEND_URL + "/organizations/:organizationId/resources/partial/:resourceId";
export const get_resource_parents =
  BACKEND_URL + "/organizations/:organizationId/resources";
export const get_resource_tree = get_resource_parents + "/parents";
export const get_resource_children =
  BACKEND_URL +
  "/organizations/:organizationId/resources/:resourceid/subresources";
export const get_resource_parent = get_resource_parents + "/:resourceid/parent";
export const get_resources_by_id_list =
  BACKEND_URL + "/organizations/:organizationId/resources/by-id-list";
export const get_resource_details =
  BACKEND_URL + "/organizations/:organizationId/resources/:resourceid";
export const get_resource_path = `${BACKEND_URL}/organizations/:organizationId/resources/:resourceId/parent`;
export const get_all_functions =
  BACKEND_URL + "/organizations/:organizationId/functions";
export const get_characteristic_set_templates =
  get_all_functions +
  "/:resourceFunctionId/characteristic-set-templates/details";
export const get_all_characteristics =
  BACKEND_URL + "/organizations/:organizationId/characteristics";
export const get_characteristic_by_id =
  BACKEND_URL +
  "/organizations/:organizationId/characteristics/:characteristicId";

export const update_single_characteristic =
  BACKEND_URL +
  "/organizations/:organizationId/resources/:resourceCategory/:resourceid/characteristics/:characteristicId";

export const get_types = BACKEND_URL + "/organizations/:organizationId/types";
export const get_types_with_pagination = get_types + "/search";
export const get_type_details = get_types + "/:typeId";
export const get_type_images = get_type_details + "/images";
export const get_type_images_partial = get_types + "/images/partial";
export const get_type_image =
  BACKEND_URL + "/organizations/:organizationId/:imageUri";
export const get_type_characteristics = get_types + "/:typeId/characteristics";
export const get_type_favorites =
  BACKEND_URL + "/organizations/:organizationId/favorites/types";
export const add_and_delete_type_favorite =
  BACKEND_URL + "/organizations/:organizationId/favorites/types/:typeId";
export const get_type_recents =
  BACKEND_URL + "/organizations/:organizationId/recents/types";
export const add_type_recent =
  BACKEND_URL + "/organizations/:organizationId/recents/types/:typeId";
export const create_type = get_types;
export const delete_type = get_types + "/:typeId";
export const update_type = get_types + "/:typeId";

export const category_and_id =
  "/organizations/:organizationId/resources/:resourceCategorys/:resourceid";
export const create_resource =
  BACKEND_URL + "/organizations/:organizationId/resources/:resourceCategorys";
export const delete_resource = BACKEND_URL + category_and_id;
export const update_resource = BACKEND_URL + category_and_id;

export const get_favorites_url =
  BACKEND_URL + "/organizations/:organizationId/favorites";
export const add_and_delete_favorite_url =
  BACKEND_URL + "/organizations/:organizationId/favorites/:resourceid";

export const get_recents_url =
  BACKEND_URL + "/organizations/:organizationId/recents";
export const add_recent_url = get_recents_url + "/:resourceid";

export const resource_images_url =
  BACKEND_URL + "/organizations/:organizationId/resources/:resourceid/images";
export const resource_images_partial =
  BACKEND_URL + "/organizations/:organizationId/resources/images/partial";
export const resource_images_update_and_delete_url =
  resource_images_url + "/:resourceImageId";
export const checkName_url =
  BACKEND_URL + "/organizations/:organizationId/resources/check-name";

export const resource_attachments_url =
  BACKEND_URL +
  "/organizations/:organizationId/resources/:resourceid/attachments";
export const resource_attachments_download_and_delete_url =
  resource_attachments_url + "/:resourceAttachmentId";

// Reporting
export const get_reports =
  BACKEND_URL + "/organizations/:organizationId/reports";

export const reporting_base_url =
  BACKEND_URL + "/organizations/:organizationId/reporting";

//  Report definitions
export const report_definition_url = reporting_base_url + "/definitions";

export const get_report_definition_by_id_url =
  report_definition_url + "/:reportDefinitionId";

//  Report definition filters
export const report_definition_filter_url =
  get_report_definition_by_id_url + "/filters";

export const get_report_definition_filter_url =
  report_definition_filter_url + "/:reportDefinitionFilterId";

//  Report definition fields
export const report_definition_fields_url =
  get_report_definition_by_id_url + "/fields";

export const reports_execution_base_url = reporting_base_url + "/execute";

export const reports_execution_url =
  reports_execution_base_url + "/:reportDefinitionId";

export const reporting_fields_by_type_url =
  reporting_base_url + "/types/:typeId/fields";

//  Report definition aggregations
export const report_definition_aggregations_url =
  get_report_definition_by_id_url + "/aggregations";

export const get_report_widget = get_reports + "/widgets";
export const get_report_locations_by_category = get_reports + "/locations";

export const AGGREGATION_COUNT_KEY = "resource.id.aggregation-count";

export const HARDWARE_ASSET_WIDGET_REPORT_DEFINITION_ID =
  "c27c064b-9d3f-4eda-ab8e-34a6591ec890";

export const RACK_WIDGET_REPORT_DEFINITION_ID =
  "04afc73a-7de0-402b-9d57-b36e4abcce92";

export const ZONE_WIDGET_REPORT_DEFINITION_ID =
  "8e5a1d21-cb24-4d51-97d2-5fc14cb4d0d2";

export const ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID =
  "c6527918-a842-40e0-8783-51a555e7c283";

export const RACK_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID =
  "26ae4599-c376-4b82-a82b-a28ef0fd200d";

export const ASSET_COUNT_PER_RACK_WIDGET_REPORT_DEFINITION_ID =
  "64a032ea-422b-43d2-bc1f-a65b4babf60e";

export const HARDWARE_ASSETS_BY_FUNCTION_WIDGET_REPORT_DEFINITION_ID =
  "3d4d185c-9448-4f8e-8d2d-e4cb7a09cc35";

export const HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID =
  "c5f18bb3-1340-4f19-909b-180c07220a07";

export const ZONE_LIST_REPORT_DEFINITION_ID =
  "7105e2be-74fa-42bf-ad9b-ac7fe108d2d6";

export const ASSET_LIST_PER_LOCATION_REPORT_DEFINITION_ID =
  "32a032ea-422b-43d2-bc1f-a65b4babf60e";

export const ASSET_LIST_PER_RACK_REPORT_DEFINITION_ID =
  "4a1d2796-0c77-424b-b557-1f438e2f26d0";

// ------------------------------------------End

export const search_url =
  BACKEND_URL + "/organizations/:organizationId/resources/filter";

export const tags_url = BACKEND_URL + "/organizations/:organizationId/tags";
export const resource_tags_url =
  BACKEND_URL + "/organizations/:organizationId/resources/:resourceid/tags";
export const patch_resource_tags_url =
  BACKEND_URL +
  "/organizations/:organizationId/resources/:resourceCategory/:resourceid/tags";

export const create_graphical_object_url =
  BACKEND_URL + "/organizations/:organizationId/graphical-objects";
export const get_graphical_objects_url =
  BACKEND_URL +
  "/organizations/:organizationId/resources/:resourceId/graphical-representations";
export const get_single_graphical_object_url =
  create_graphical_object_url + "/:graphicalObjectId";

export const invitations_url =
  BACKEND_URL + "/organizations/:organizationId/invitations";
export const invitation_clearance_url =
  BACKEND_URL +
  "/organizations/:organizationId/invitations/:invitationId/clearances";
export const invitation_removal_url =
  BACKEND_URL + "/organizations/:organizationId/invitations/:id";

export const referrals_url = BACKEND_URL + "/referrals";
export const referrals_remove_url = BACKEND_URL + "/referrals/:id";

// Users
export const get_current_user_url = BACKEND_URL + "/users/me";
export const change_user_language = BACKEND_URL + "/users/language?language=";
export const update_user_data = BACKEND_URL + "/users/:userId";

export const user_languages = BACKEND_URL + "/localization/languages";
export const user_regions = BACKEND_URL + "/localization/regions";
export const user_timezones = BACKEND_URL + "/localization/timezones";
export const user_data_storage_location =
  BACKEND_URL + "/geo-distribution/instances/users/:userId";
export const user_data_locations = BACKEND_URL + "/geo-distribution/instances";
export const user_onboarding = BACKEND_URL + "/users";
export const user_onboarding_update = BACKEND_URL + "/users/:userId";
export const user_profile_image_url = BACKEND_URL + ":avatar-uri";
export const organization_onboarding_url = BACKEND_URL + "/organizations";
export const user_invitations = BACKEND_URL + "/users/invitations";
export const user_invitations_check = user_invitations + "/check";
export const respond_user_invitation = user_invitations + "/:invitationId";
export const users_notifications_subscribe_url =
  BACKEND_URL + "/users/notifications/subscribe";
export const users_notifications_send_url =
  BACKEND_URL + "/users/notifications/send";

// Subscriptions
export const subscriptions_base_url =
  BACKEND_URL + "/organizations/:organizationId/subscriptions";

export const get_current_subscription_url = subscriptions_base_url + "/current";

export const get_current_subscription_actual_usage_url =
  subscriptions_base_url + "/actual-usage";

// Addresses
export const address_base_url =
  BACKEND_URL + "/organizations/:organizationId/addresses";
export const address_by_id_url = address_base_url + "/:addressId";

export const user_role_permissions_url =
  BACKEND_URL + "/organizations/:organizationId/users/:userId/roles";
export const activate_user_role_url = user_role_permissions_url + "/activate";
export const user_role_upload_profile_picture_url =
  BACKEND_URL + "/users/:userId/profile-image";

// Organizations
export const organization_users_url =
  BACKEND_URL + "/organizations/:organizationId/users";
export const organization_upload_profile_picture_url =
  BACKEND_URL + "/organizations/:organizationId/logo";
export const organization_roles =
  BACKEND_URL + "/organizations/:organizationId/roles";
export const organization_data_storage_location =
  BACKEND_URL + "/geo-distribution/instances/organizations/:organization-id";
export const organization_import =
  BACKEND_URL + "/organizations/:organizationId/import";
export const organization_export =
  BACKEND_URL + "/organizations/:organizationId/export";
export const organization_update_url =
  BACKEND_URL + "/organizations/:organizationId";
export const organization_leave_url =
  BACKEND_URL + "/organizations/:organizationId/users/leave";
export const remove_user_from_organization_url =
  BACKEND_URL + "/organizations/:organizationId/users/:userId";

export const community_portal_sso_token_url =
  BACKEND_URL + "/users/community-sso-token";
