import { Backdrop, TableCell, useMediaQuery, useTheme } from "@mui/material";
import {
  AssetListTableFlexContainer,
  CategoryTableCell,
  ChildrenCountCellText,
  FlexBox,
  NameTableCell,
  SelectedTableRow,
  TableCellText,
} from "../../styles/assets/asset-list/AssetListTable.styles";
import TableMedia from "./TableMedia";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectGlobalFontSize,
  selectTheme,
  setIsSearchOpen,
} from "../../../store/slices/appSlice";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { clearHistory } from "../../../store/slices/assetListSlice";
import LongPressAssetsItemMenu from "./LongPressAssetsItemMenu";
import { useCallback, useRef, useState } from "react";
import { LongPressEventType, useLongPress } from "use-long-press";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getSvgIcon } from "../../../util/icons";
import FavoriteStarComponent from "./FavoriteStarComponent";

const AssetListTableRow = ({
  resource,
  handleClickItem,
  currentResourceId,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);
  const [rightClicked, setRightClicked] = useState(false);

  // Refs
  const elementRef = useRef(null);

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(tabletMatches ? callback : null, {
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 1000,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: false,
    detect: LongPressEventType.Pointer,
  });

  // Other variables
  const tagName = searchParams.get("tagName");
  const from = searchParams.get("from");
  const tagId = searchParams.get("tagId");
  const resourceId = searchParams.get("resourceId");
  const iconSize = globalFontSize * 1.5;

  const handlers = bind("longpress context");
  const open = longPressed || rightClicked;

  const isFavoritesOrRecents =
    pathname?.includes("favorites") || pathname?.includes("recents");

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  // Handlers
  const handleNavigate = (id) => {
    if (isFavoritesOrRecents) {
      handleClickItem(resource);
    } else {
      navigate(
        `/resources/${id}?direction=${
          transitionDirections.RIGHT_TO_LEFT
        }&pathname=${pathname}${Boolean(tagName) ? `&tagName=${tagName}` : ""}${
          Boolean(from) ? `&from=${from}` : ""
        }${Boolean(tagId) ? `&tagId=${tagId}` : ""}${
          Boolean(resourceId) ? `&resourceId=${resourceId}` : ""
        }`
      );

      dispatch(setIsSearchOpen(false));
      dispatch(clearHistory());
    }
  };

  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
    setRightClicked(false);
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    if (desktopMatches) {
      setAnchorEl(elementRef?.current);
      setRightClicked(true);
    }
  };

  // Other variables
  const {
    id,
    displayId,
    name,
    functionId,
    childrenCount,
    thumbnailUri,
    isFavorite,
    parentId,
  } = resource;

  const combinationDisplayIdAndName = displayId || name;
  const resourceFunction = getFunction(functionId);

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressAssetsItemMenu
        resourceFunction={resourceFunction}
        anchorEl={anchorEl}
        handleClose={handleClose}
        resourceId={id}
        parentId={parentId}
      />

      <SelectedTableRow
        ref={elementRef}
        currentTheme={currentTheme}
        longPressed={open}
        isselected={currentResourceId === id}
        onClick={() => handleNavigate(id)}
        onContextMenu={handleRightClick}
        {...handlers}
      >
        <TableCell>
          <FlexBox>
            <TableMedia
              functionName={resourceFunction?.name}
              thumbnailUri={thumbnailUri}
            />

            {isFavorite && <FavoriteStarComponent />}
          </FlexBox>
        </TableCell>

        <NameTableCell>
          <TableCellText>{combinationDisplayIdAndName}</TableCellText>
        </NameTableCell>

        <CategoryTableCell>
          <TableCellText>
            {getTranslation(resourceFunction?.category, t, i18n)}
          </TableCellText>
        </CategoryTableCell>

        <TableCell>
          <AssetListTableFlexContainer>
            {childrenCount > 0 && (
              <ChildrenCountCellText>{childrenCount}</ChildrenCountCellText>
            )}
            {getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
          </AssetListTableFlexContainer>
        </TableCell>
      </SelectedTableRow>
    </ErrorHandling>
  );
};

export default AssetListTableRow;
