import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SnackbarContent,
  useTheme,
} from "@mui/material";
import {
  InvitationSectionTitle,
  InviteButton,
  StyledTextField,
  InvitationEmail,
  InvitationInputFormControl,
} from "./styles/invitation/InvitationForm.styles";
import {
  useCreateReferralMutation,
  useGetInvitedUsersQuery,
  useInviteUserMutation,
} from "../store/slices/api/userManagementSlice";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  selectGlobalFontSize,
  setIsLoadingAction,
} from "../store/slices/appSlice";
import {
  addInvitation,
  clearInvitations,
  deleteInvitation,
  editInvitation,
  selectInvitations,
} from "../store/slices/invitationsSlice";
import { selectUser } from "../store/slices/authSlice";
import { HomePagePadding } from "./styles/general/General.styles";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../util/notification";
import PageTransition from "./PageTransition";
import {
  transitionDirections,
  getTranslation,
  testInput,
  calculateActiveDuration,
  showValidationError,
} from "../util/utils";
import { useCallback } from "react";
import { setIsActionButtonDisabled } from "../store/slices/appSlice";
import {
  InvitationFormContainer,
  InvitationListContainer,
  InvitationsContainer,
  StyledDivider,
} from "./styles/profile/InvitationForm.styles";
import InviteHeader from "../navigation/header/organization/InviteHeader";
import {
  useGetOrganizationRolesQuery,
  useGetOrganizationUsersQuery,
} from "../store/slices/api/organizationsApiSlice";
import ErrorHandling from "./common/ErrorHandling";
import { useGetCurrentSubscriptionActualUsageQuery } from "../store/slices/api/subscriptionsApiSlice";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import AppAccess from "./common/AppAccess";
import { getSvgIcon } from "../util/icons";

export const INVITATION_STATUS = {
  SENT: "SENT",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  INVALIDATED: "INVALIDATED",
};

const InvitationForm = (props) => {
  // General Hooks
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  // Selectors
  const invitations = useSelector(selectInvitations);
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const orgId = location.pathname.includes("organization")
    ? organizationId
    : props.organizationId;

  const organization = user?.organizations?.find((o) => o.id === orgId);

  // Queries
  const {
    data: organizationRoles,
    isLoading: isLoadingOrganizationRoles,
    isError: isErrorOrganizationRoles,
  } = useGetOrganizationRolesQuery(orgId, {
    skip: !location.pathname.includes("organization"),
  });

  const {
    data: invitedUsers,
    isLoading: isLoadingInvitedUser,
    isError: isErrorInvitedUsers,
  } = useGetInvitedUsersQuery(orgId);

  const {
    data: organizationUsers,
    isLoading: isLoadingOrganizationUser,
    isError: isErrorOrganizationUser,
  } = useGetOrganizationUsersQuery(orgId);

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  const { data: actualUsage } = useGetCurrentSubscriptionActualUsageQuery(
    {
      organizationId,
    },
    { skip: !Boolean(organizationId) || isRestricted }
  );

  // Mutations
  const [inviteUser, { isLoading: isLoadingInviteUser }] =
    useInviteUserMutation();
  const [createReferral, { isLoading: isLoadingCreateReferral }] =
    useCreateReferralMutation();

  // State
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const isEmailExistingTempInvitations = invitations?.some(
    (i) => i.email === email
  );
  const isEmailExistingOrganization = organizationUsers?.some(
    (iu) => iu.email === email
  );
  const isEmailExistingPendingInvitations = invitedUsers?.some(
    (iu) => iu.email === email && iu.status === INVITATION_STATUS.SENT
  );

  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const sentInvitationsCount =
    invitedUsers?.filter((iu) => iu.status === INVITATION_STATUS.SENT)
      ?.length ?? 0;
  const organizationUsersCount = organizationUsers?.length ?? 0;
  const usersCurrentValue = sentInvitationsCount + organizationUsersCount;
  const totalInvitesCount = usersCurrentValue + invitations?.length;
  const isSendInviteDisabled =
    invitations.length <= 0 || totalInvitesCount > usersSubscriptionValue;

  const iconSize = globalFontSize * 2;
  const clearIconSize = globalFontSize;

  // Handlers
  const handleEmailChange = (e) => {
    const isValid = testInput.USER_EMAIL(e.target.value);
    setIsEmailValid(isValid);
    setEmail(e.target.value);
  };

  const onCancelHandler = () => {
    if (location.pathname.includes("organization")) {
      navigate(
        `/profile/organization/${organizationId}/user-management?direction=${transitionDirections.TOP_TO_BOTTOM}`
      );
    } else {
      navigate(`/profile`);
    }

    dispatch(clearInvitations());
  };

  const handleAddInvitation = () => {
    const newInvitation = {
      email,
      role: organizationRoles?.find((r) => r.name === "COMMENTER"),
    };

    dispatch(addInvitation(newInvitation));

    // reset form inputs
    setEmail("");
    setIsEmailValid(false);
  };

  const handleEditInvitation = (e, invitation) =>
    dispatch(
      editInvitation({
        ...invitation,
        role: { ...invitation.role, id: e.target.value },
      })
    );

  const handleDeleteInvitation = (email) => dispatch(deleteInvitation(email));

  const handleSubmit = useCallback(async () => {
    const invitationData = [];

    invitations.forEach((invitation) => {
      const { email, role } = invitation;

      const userData = {
        email,
        roles: [role?.id],
      };

      const referral = {
        email,
      };

      const data = location.pathname.includes("/organization")
        ? userData
        : referral;

      invitationData.push({
        ...data,
        activeDuration: calculateActiveDuration(
          process.env.REACT_APP_ACTIVE_DURATION
        ), // calculates the activeDuration env variable in seconds
      });
    });

    try {
      if (location.pathname.includes("organization")) {
        await inviteUser({
          invitationData,
          organizationId: location.pathname.includes("organization")
            ? organizationId
            : props.organizationId,
        }).unwrap();
      } else {
        await createReferral({
          referralData: invitationData,
        }).unwrap();
      }

      messageSuccess(getTranslation("successfulSendInvitation", t, i18n));
      onCancelHandler();

      dispatch(setIsLoadingAction(false));
    } catch (error) {
      if (location.pathname.includes("referral")) {
        dispatch(setIsLoadingAction(false));
        messageError("User was already invited");
      } else {
        dispatch(setIsLoadingAction(false));
        showValidationError(error, t, i18n);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations, location.pathname, organizationId, props.organizationId]);

  // Effects
  useEffect(() => {
    if (invitations && invitations.length) {
      dispatch(setIsActionButtonDisabled(false));
    } else {
      dispatch(setIsActionButtonDisabled(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations?.length]);

  useEffect(() => {
    if (isLoadingCreateReferral || isLoadingInviteUser) {
      dispatch(setIsLoadingAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCreateReferral, isLoadingInviteUser]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingCreateReferral ||
          isLoadingInviteUser ||
          isLoadingInvitedUser ||
          isLoadingOrganizationRoles ||
          isLoadingOrganizationUser
        }
        isError={
          isErrorInvitedUsers ||
          isErrorOrganizationRoles ||
          isErrorOrganizationUser
        }
      >
        <>
          <HomePagePadding>
            <PageTransition direction={transitionDirection}>
              <InviteHeader
                handleAction={handleSubmit}
                handleCancelAction={onCancelHandler}
                disabled={isSendInviteDisabled}
              />

              <InvitationFormContainer>
                {/* Email input */}
                <StyledTextField
                  variant="outlined"
                  label={getTranslation("emailAddressPlaceHolder", t, i18n)}
                  type="email"
                  placeholder={getTranslation(
                    "emailAddressPlaceHolder",
                    t,
                    i18n
                  )}
                  value={email}
                  onChange={handleEmailChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <InviteButton
                          id="invite-button"
                          onClick={handleAddInvitation}
                          disabled={
                            !isEmailValid ||
                            user.email === email ||
                            isEmailExistingTempInvitations ||
                            isEmailExistingOrganization ||
                            isEmailExistingPendingInvitations
                          }
                        >
                          {getTranslation("ADD", t, i18n)}
                        </InviteButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </InvitationFormContainer>

              {invitations?.length > 0 && (
                <InvitationsContainer>
                  {totalInvitesCount > usersSubscriptionValue && (
                    <SnackbarContent
                      sx={{
                        backgroundColor: "#f44336",
                        boxShadow: "none",
                        paddingBlock: 0,
                        marginBlock: "10px",
                      }}
                      message="Users limit exceeded"
                    />
                  )}

                  <InvitationSectionTitle variant="h6">
                    {location.pathname.includes("referral")
                      ? getTranslation("SENDREFERRAL", t, i18n) + "..."
                      : getTranslation("INVITE", t, i18n)}
                  </InvitationSectionTitle>
                  {invitations.map((invitation) => (
                    <InvitationListContainer key={invitation.email}>
                      <StyledDivider />
                      <MenuItem dense id={`menu-item-${invitation.email}`}>
                        {/* Preference name */}
                        <InvitationEmail
                          variant="body1"
                          id={`label-${invitation.email}`}
                        >
                          {invitation.email}
                        </InvitationEmail>
                        {location.pathname.includes("/organization") && (
                          <InvitationInputFormControl variant="standard">
                            <Select
                              disableUnderline
                              labelId={`role-select-standard-label-${invitation.email}`}
                              id={`role-select-standard-${invitation.email}`}
                              value={invitation.role?.id}
                              onChange={(e) =>
                                handleEditInvitation(e, invitation)
                              }
                              label={getTranslation("role", t, i18n)}
                              IconComponent={() =>
                                getSvgIcon(
                                  "ARROW_DOWN_SMALL",
                                  iconSize,
                                  iconSize,
                                  theme.palette.primary.main
                                )
                              }
                            >
                              {organizationRoles
                                ?.filter((r) => r.name !== "OWNER")
                                ?.map((role) => (
                                  <MenuItem key={role.id} value={role.id}>
                                    {getTranslation(role.name, t, i18n)}
                                  </MenuItem>
                                ))}
                            </Select>
                          </InvitationInputFormControl>
                        )}
                        <IconButton
                          id={`remove-icon-${invitation.email}`}
                          data-testid={`remove-icon-${invitation.email}`}
                          onClick={() =>
                            handleDeleteInvitation(invitation.email)
                          }
                        >
                          {getSvgIcon(
                            "CLEAR",
                            clearIconSize,
                            clearIconSize,
                            theme.palette.primary.main
                          )}
                        </IconButton>
                      </MenuItem>
                    </InvitationListContainer>
                  ))}
                </InvitationsContainer>
              )}
            </PageTransition>
          </HomePagePadding>
        </>
      </ErrorHandling>
    </AppAccess>
  );
};

export default InvitationForm;
