import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ConfigProvider, TreeSelect } from "antd";
import {
  useGetAllFunctionsQuery,
  useGetAllResourcesQuery,
  useGetResourcePathQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  setParent,
  setParentId,
} from "../../../store/slices/resourceInputSlice";
import { Box, useTheme } from "@mui/material";
import {
  ALLOWED_PARENT_CATEGORIES,
  LOCATION_DETAILS,
} from "../../../util/asset-utils";
import "./ParentInput.css";
import { ParentLabel } from "../../styles/assets/asset-form/ParentInput.styles";
import ErrorHandling from "../../common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";
import { RESOURCE_CATEGORIES, getTranslation } from "../../../util/utils";
import useTreeData from "../../../hooks/useTreeData";
import LoadingSpinner from "../../common/LoadingSpinner";
import LoadingSpinnerOverlay from "../../common/LoadingSpinnerOverlay";

const ParentTreeInput = ({ resourceId, parentId, category }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [focus, setFocus] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [initialFetchPath, setInitialFetchPath] = useState(true);

  // Queries
  const { data: allResourcesData, isLoading: isLoadingResources } =
    useGetAllResourcesQuery(
      {
        organizationId,
      },
      {
        skip: !focus,
      }
    );

  const {
    data: allFunctionsData,
    isLoading: isLoadingAllFunctions,
    isError: isErrorAllFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: resourcePathData,
    isLoading: isLoadingResourcePath,
    isError: isErrorResourcePath,
  } = useGetResourcePathQuery(
    {
      organizationId,
      resourceId: parentId,
    },
    { skip: !parentId || !initialFetchPath }
  );

  // Handlers

  const onChange = (newValue) => {
    const parent = allResourcesData?.find((r) => r.id === newValue);
    dispatch(setParent(parent));
    dispatch(setParentId(newValue));
  };

  const handleFocus = (e) => setFocus(true);

  const handleBlur = (e) => setFocus(false);

  // Other variables
  const themeClassName =
    theme.palette.mode === "light" ? "light-tree-select" : "dark-tree-select";

  const isError = false;

  const errorClassName = isError ? "custom-tree-select-error" : "";
  const emptyClassName = "non-empty";
  const parentLabelClassName =
    theme.palette.mode === "light" ? "light-label" : "dark-label";
  const parentLabelTextClassName =
    theme.palette.mode === "light" ? "light-text" : "dark-text";

  const iconSize = globalFontSize * 1.2;

  const allowedCategories = ALLOWED_PARENT_CATEGORIES[category ?? ""];

  // Custom Hooks
  const treeData = useTreeData(
    allResourcesData,
    allowedCategories,
    allFunctionsData,
    resourceId
  );

  // Other variables
  const parentOption = {
    value: resourcePathData?.id,
    title: resourcePathData?.displayId || resourcePathData?.name,
    children: [],
  };

  const finalTreeData = [
    {
      value: "",
      title: "(Root)",
      children:
        treeData.length > 0 ? treeData : resourcePathData ? [parentOption] : [],
      disabled: category !== RESOURCE_CATEGORIES.LOCATION,
    },
  ];

  useEffect(() => {
    if (resourcePathData) {
      setInitialFetchPath(false);
      dispatch(setParent(resourcePathData));
    }

    const parentPathIds = [];
    let current = resourcePathData;

    while (current) {
      parentPathIds.push(current.id);
      current = current.parent;
    }

    parentPathIds.push("");
    setExpandedKeys(parentPathIds);
  }, [resourcePathData]);

  return (
    <ErrorHandling
      isLoading={isLoadingAllFunctions || isLoadingResourcePath}
      isError={isErrorAllFunctions || isErrorResourcePath}
    >
      <Box sx={{ marginTop: "16px", position: "relative" }}>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "inherit",
              colorBgElevated: theme.palette.primary.contrastText,
              colorText: theme.palette.primary.main,
              controlItemBgActive: theme.palette.secondary.dark,
              colorTextDisabled: "#6A6A6A",
              borderRadius: "8px",
              colorBorder: isError
                ? "#f44336"
                : theme.palette.mode === "light"
                ? "#DFDFDF"
                : theme.palette.secondary.dark,
              controlHeight: 56,
              colorPrimary: theme.palette.primary.main,
              fontSize: globalFontSize,
            },
          }}
        >
          <div className="parent-tree-select-container">
            <TreeSelect
              id="parent-tree-select"
              className={`parent-tree-select custom-tree-select ${themeClassName} ${errorClassName} ${emptyClassName}`}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
                zIndex: "5000",
              }}
              style={{ width: "100%" }}
              virtual={false}
              bordered
              value={parentId ?? ""}
              onChange={onChange}
              treeData={finalTreeData}
              treeExpandedKeys={[...expandedKeys]}
              onTreeExpand={(expanded) => {
                setExpandedKeys([...expanded]);
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              showSearch
              treeNodeFilterProp="title"
              suffixIcon={getSvgIcon(
                "EXPAND",
                iconSize,
                iconSize,
                theme.palette.primary.main
              )}
            />
            <ParentLabel
              fontSize={globalFontSize}
              className={`parent-tree-select-label ${parentLabelClassName}`}
              htmlFor="parent-tree-select"
              id="parent-tree-select-label"
            >
              <div
                className={`parent-tree-select-label-text ${parentLabelTextClassName}`}
              >
                {getTranslation(
                  LOCATION_DETAILS[category ?? LOCATION_DETAILS.DEFAULT],
                  t,
                  i18n
                )}
              </div>
            </ParentLabel>
          </div>
        </ConfigProvider>
      </Box>
    </ErrorHandling>
  );
};

export default memo(ParentTreeInput);
