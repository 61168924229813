import ResourceImageForm from "../../components/assets/asset-form/ResourceImageForm";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import {
  addResourceImage,
  DEFAULT_RESOURCE_IMAGE,
} from "../../store/slices/resourceImageSlice";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import { transitionDirections, getTranslation } from "../../util/utils";
import ChooseAssetImageHeader from "../../navigation/header/assets/ChooseAssetImageHeader";
import { useDispatch } from "react-redux";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const DuplicateAddLocalResourceImagePage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // State
  const [resourceImageInput, setResourceImageInput] = useState(
    DEFAULT_RESOURCE_IMAGE
  );

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");

  // Other variables
  const imageCategory = searchParams.get("imageCategory") ?? "FRONT";
  const direction = searchParams.get("direction");

  // Handlers
  const cancelHandler = () => {
    navigate(
      `/resources/${resourceId}/duplicate?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=add-local-image`
    );
  };

  // Other variables
  const isValid =
    (resourceImageInput.file || resourceImageInput.selectedFile) && name;

  const isDisabled = !isValid;

  const handleSubmit = useCallback(async () => {
    if (!resourceImageInput.selectedFile) {
      messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
      return;
    }

    const finalImageCategory =
      imageCategory === "OTHER" ? "MISC" : imageCategory;

    const input = {
      ...resourceImageInput,
      name: name + extension,
      imageCategory: finalImageCategory,
    };

    messageSuccess(
      getTranslation("RESOURCE_IMAGE_CREATED_SUCCESSFULLY", t, i18n)
    );
    dispatch(addResourceImage(input));
    cancelHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImageInput, name]);

  return (
    <AppAccess>
      <Layer2Access>
        <PageTransition direction={direction}>
          <ChooseAssetImageHeader
            handleAction={handleSubmit}
            handleCancelAction={cancelHandler}
            isDisabled={isDisabled}
          />

          <HomePagePadding>
            <ResourceImageForm
              resourceImageInput={resourceImageInput}
              setResourceImageInput={setResourceImageInput}
              name={name}
              setName={setName}
              setExtension={setExtension}
            />
          </HomePagePadding>
        </PageTransition>
      </Layer2Access>
    </AppAccess>
  );
};

export default DuplicateAddLocalResourceImagePage;
