import React from "react";
import {
  BackInfoContainer,
  HeaderGridContainer,
  HeaderWrapper,
} from "../../components/styles/header/Header.styles";
import { getTranslation } from "../../util/utils";
import { useTranslation } from "react-i18next";

const RecentsHeader = () => {
  // General hooks
  const { t, i18n } = useTranslation();

  return (
    <HeaderWrapper>
      <HeaderGridContainer>
        <BackInfoContainer variant="h4" id="title">
          {getTranslation("LAST_USED", t, i18n)}
        </BackInfoContainer>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default RecentsHeader;
