import { styled } from "@mui/system";
import { OnboardingUserCredentialContainer } from "./OnboardingUserCredential.styles";
import { Typography } from "@mui/material";

export const OnboardingOrganizationPageContainer = styled(
  OnboardingUserCredentialContainer
)({
  alignItems: "unset",
});

export const LogoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: 10,
}));
