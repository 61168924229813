import { styled } from "@mui/system";
import { Typography, Box, Stack } from "@mui/material";

export const AssetImage = styled("img")({
  width: "100%",
  height: "auto",
});

export const AssetInfoDetailsContainer = styled(Stack)({
  marginLeft: "16px",
});

export const AssetActions = styled(Box)({
  marginLeft: "10px",
  display: "flex",
  alignItems: "center",
});

export const AssetName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const AssetCategory = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));
