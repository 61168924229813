import React from "react";
import { useTranslation } from "react-i18next";
import {
  PreferenceItemName,
  PreferenceItemNameSecondary,
  PreferenceItemNameSecondaryWrapper,
  PreferenceItemNameWrapper,
  PreferenceMenuItem,
} from "./styles/profile/ProfilePreferenceItem.styles";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
} from "../util/utils";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import { StyledListItemIcon } from "./styles/general/General.styles";
import { selectUser } from "../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../store/slices/api/userManagementSlice";
import ErrorHandling from "./common/ErrorHandling";
import { useTheme } from "@mui/material";
import { getSvgIcon } from "../util/icons";

const ProfilePreferenceMenuItem = ({
  handleClick,
  label,
  menuId,
  labelId,
  icon,
  value,
  rightIcon,
  permissions,
  checker,
  userRoles,
  showrighticon,
  organizationId,
  disabled,
}) => {
  // General Hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: userRolesData,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery(
    {
      organizationId,
      userId: user?.id,
    },
    { skip: !Boolean(organizationId) }
  );

  // Other variables
  const iconSize = globalFontSize * 1.2;

  return (
    <ErrorHandling isLoading={isLoadingUserRoles} isError={isErrorUserRoles}>
      <PreferenceMenuItem
        disabled={disabled}
        onClick={
          permissions
            ? hasAccess(
                checker,
                permissions,
                getPermissionsFromUserRoles(userRoles)
              )
              ? handleClick
              : null
            : handleClick
        }
        id={menuId}
        data-testid={menuId}
      >
        {icon && (
          <StyledListItemIcon
            globalFontSize={globalFontSize}
            data-testid="icon"
          >
            {icon}
          </StyledListItemIcon>
        )}
        {/* Preference name */}
        <PreferenceItemNameWrapper
          hasvalue={+Boolean(organizationId) || +Boolean(value)}
        >
          <PreferenceItemName id={labelId}>
            {getTranslation(label, t, i18n)}
          </PreferenceItemName>
        </PreferenceItemNameWrapper>

        {!value && organizationId && (
          <PreferenceItemNameSecondaryWrapper>
            <PreferenceItemNameSecondary
              data-testid="preference-value"
              variant="body1"
              id={labelId}
            >
              {getTranslation(
                userRolesData ? userRolesData[0]?.name : "",
                t,
                i18n
              )}
            </PreferenceItemNameSecondary>
          </PreferenceItemNameSecondaryWrapper>
        )}

        {!organizationId && value && (
          <PreferenceItemNameSecondaryWrapper>
            <PreferenceItemNameSecondary
              data-testid="preference-value"
              variant="body1"
              id={labelId}
            >
              {value}
            </PreferenceItemNameSecondary>
          </PreferenceItemNameSecondaryWrapper>
        )}
        {/* Check custom right icon provided, if not use default one. */}
        {/* To be visible or not the default right icon*/}
        {rightIcon
          ? rightIcon
          : permissions
          ? hasAccess(
              checker,
              permissions,
              getPermissionsFromUserRoles(userRoles)
            )
            ? getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.primary.main,
                { visibility: "visible" }
              )
            : getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.primary.main,
                { visibility: "hidden" }
              )
          : showrighticon &&
            getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
      </PreferenceMenuItem>
    </ErrorHandling>
  );
};

export default ProfilePreferenceMenuItem;
