import { Grid, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
  useGetResourcePathQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { getTranslation, userLocalStorageKey } from "../../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import ErrorHandling from "../../common/ErrorHandling";
import { ListItemContainer } from "../../styles/assets/asset-detail/AssetDetail.styles";
import {
  PrimaryText,
  SecondaryText,
} from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";

const LOCATION_DETAILS = {
  RACK: "LOCATION",
  HARDWARE_ASSET: "LOCATION",
  LOCATION: "PARENT",
  DEFAULT: "LOCATION",
};

const AssetDetailLocation = ({ parentId, resourceFunctionId }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Other variables
  const isTablet = tabletMatches || desktopMatches ? 1 : 0;

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResourceDetails,
    isError: isErrorResourceDetails,
  } = useGetResourceDetailsQuery(
    {
      resourceid: parentId,
      organizationId,
    },
    {
      skip: !Boolean(parentId),
    }
  );

  const { data: resourcePath, isLoading: isLoadingResourcePath } =
    useGetResourcePathQuery(
      {
        resourceId: parentId,
        organizationId,
      },
      {
        skip: !parentId,
      }
    );

  const {
    data: allFunctionsData,
    isLoading: isLoadingAllFunctions,
    isError: isErrorAllFunctions,
  } = useGetAllFunctionsQuery({ organizationId });

  // Handlers
  const getFunction = (functionId) => {
    return allFunctionsData?.find((f) => f.id === functionId);
  };

  const getLocations = (obj) => {
    const result = [];

    if (!obj) {
      return result;
    }
    const { name, displayId } = obj;
    const combinedDisplayIdAndName = displayId || name;
    result.push(combinedDisplayIdAndName);

    if (obj.parent) {
      const parent = getLocations(obj.parent);
      result.push(...parent);
    }

    return result;
  };

  // Other variables
  const resourceFunction = getFunction(resourceFunctionId);
  const locations = getLocations(resourcePath);

  // Effects
  return (
    <ErrorHandling
      isLoading={
        isLoadingResourceDetails ||
        isLoadingAllFunctions ||
        isLoadingResourcePath
      }
      isError={isErrorResourceDetails || isErrorAllFunctions}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <ListItemContainer istablet={isTablet}>
            <SecondaryText variant="body2" id="asset-location-header">
              {getTranslation(
                LOCATION_DETAILS[
                  resourceFunction?.category ?? LOCATION_DETAILS.DEFAULT
                ],
                t,
                i18n
              )}
            </SecondaryText>
            <PrimaryText id="asset-location-path">
              {locations.length > 0 ? (
                locations.reverse().join(" - ")
              ) : (
                <SecondaryText component="span" id="asset-location-header">
                  {getTranslation(
                    `NO_${
                      LOCATION_DETAILS[
                        resourceFunction?.category ?? LOCATION_DETAILS.DEFAULT
                      ]
                    }`,
                    t,
                    i18n
                  )}
                </SecondaryText>
              )}
            </PrimaryText>
          </ListItemContainer>
        </Grid>
      </Grid>
    </ErrorHandling>
  );
};

export default AssetDetailLocation;
