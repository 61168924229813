import { Typography, Box, IconButton } from "@mui/material";
import { styled } from "@mui/system";

export const ResourceImagesText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: "20px",
}));

export const AddImageButtonBackgroundCover = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  backgroundColor: theme.palette.secondary.dark,
}));

export const AddImageButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  height: "100%",
});

export const AddImageButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontWeight: "bold",
}));
