import { MobileStepper } from "@mui/material";
import { styled } from "@mui/system";

export const StyledMobileStepper = styled(MobileStepper)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "unset",
    "& .MuiMobileStepper-dot": {
      width: "12px",
      height: "12px",
      margin: "0 5px",
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: theme.palette.secondary.contrastText,
    },
    width: "100%",
  })
);

export const NextButtonContainer = styled("div")({
  visibility: "hidden",
});
