import { Box, useTheme } from "@mui/material";
import ResultFieldsListItem from "./ResultFieldsListItem";
import { useDispatch, useSelector } from "react-redux";
import {
  addResultField,
  selectFurtherResultFields,
  setShouldExecute,
} from "../../../store/slices/reportsSlice";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import {
  AddFieldButton,
  FieldsHeaderTypography,
} from "../../styles/reports/Reports.styles";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const ResultFieldsList = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const resultFields = useSelector(selectFurtherResultFields);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleAddResultField = () => {
    dispatch(addResultField());
    dispatch(setShouldExecute(false));
  };

  return (
    <>
      <Droppable droppableId="result-fields">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Box>
              <FieldsHeaderTypography>
                {getTranslation("REPORT_COLUMNS", t, i18n)}
              </FieldsHeaderTypography>

              {resultFields.map((field, index) => (
                <ResultFieldsListItem
                  key={field.uniqueId}
                  resultField={field}
                  index={index}
                  isAggregationField={true}
                />
              ))}
            </Box>

            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <AddFieldButton
        id="add-column"
        onClick={handleAddResultField}
        variant="text"
      >
        <div style={{ marginTop: "5px", marginRight: "5px" }}>
          {getSvgIcon(
            "CREATE_NEW",
            iconSize,
            iconSize,
            theme.palette.secondary.contrastText
          )}
        </div>

        {getTranslation("ADD_COLUMN", t, i18n)}
      </AddFieldButton>
    </>
  );
};

export default ResultFieldsList;
