import {
  Box,
  Breadcrumbs,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { styled, alpha } from "@mui/system";
import { ProfileAvatar } from "./ProfileCardItm.styles";
import { StyledTextField } from "../invitation/InvitationForm.styles";
import { StyledAvatar } from "../header/Header.styles";
import { SecondaryText } from "../assets/ListInlineView.styles";
import { PrimaryText } from "../assets/asset-detail/AssetDetailBasicInfo.styles";

export const ContrastContainedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.contrastText,
  borderRadius: "5px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}));

export const LightOutlinedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderRadius: "5px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
}));

export const DesktopProfileAvatar = styled(ProfileAvatar)({
  width: "50px",
  height: "50px",
});

export const ProfileAvatarContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  columnGap: "30px",
});

export const DesktopProfileAvatarContainer = styled(Box)({
  position: "relative",
  display: "inline-block",
});

export const DesktopProfileAvatarOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color and opacity as needed
  zIndex: 1,
  borderRadius: "50%",
});

export const MoreIconGridContainer = styled(Grid)({
  display: "flex",
  justifyContent: "end",
});

export const OrganizationDesktopWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const OrganizationDesktopBoxContainer = styled(Box)({
  padding: "20px 30px",
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const ActiveOrganizationHeading = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

export const OtherOrganizationsHeading = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginTop: "40px",
  color: theme.palette.primary.main,
}));

export const OrganizationDesktopCardStackContainer = styled(Stack)({
  marginTop: "20px",
});

export const OrganizationDesktopCardWrapper = styled(Box)(({ theme }) => ({
  minHeight: "210px",
  width: "320px",
  backgroundColor: theme.palette.secondary.dark,
  padding: "15px",
  position: "relative",
  overflow: "hidden",
}));

export const OrganizationDesktopCardHeaderBoxContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const OrganizationDesktopCardLogoBoxContainer = styled(Box)({
  marginRight: "20px",
});

export const OrganizationDesktopInvitationsContainer = styled(Stack)({
  maxWidth: "350px",
  width: "100%",
});

export const OrganizationDesktopCardBoldTypography = styled(PrimaryText)({
  lineHeight: "normal",
});

export const OrganizationDesktopCardMembersCountTypography = styled(
  OrganizationDesktopCardBoldTypography
)({
  marginLeft: "5px",
});

export const OrganizationDesktopCardActionsBoxContainer = styled(Box)({
  display: "flex",
  position: "relative",
  zIndex: 2,
});

export const EditOrganizationButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const OrganizationDesktopCardInfoGridContainer = styled(Grid)({
  marginTop: "15px",
});

export const SubscriptionUsageBoxContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

export const FlexBoxContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const MembersBoxContainer = styled(Box)({
  marginTop: "15px",
  overflow: "hidden",
  width: "100%",
});

export const OverflowedWrapper = styled(Grid)({
  overflow: "hidden",
});

export const ChangeActiveOrganizationButtonBoxContainer = styled(Box)({
  position: "absolute",
  zIndex: 1,
  inset: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ChangeActiveOrganizationButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  "&:hover": {
    background: theme.palette.secondary.contrastText,
  },
  background: theme.palette.secondary.contrastText,
  padding: theme.spacing(1),
  width: "calc(100% - 36px)",
  margin: "auto",
  borderRadius: "5px",
}));

export const OrganizationDesktopCardHoverOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  inset: 0,
  backgroundColor: alpha(theme.palette.primary.contrastText, 0.7),
}));

export const OrganizationDesktopBreadcrumbs = styled(Breadcrumbs)({
  marginBottom: "30px",
});

export const LastLinkTypography = styled(Typography)({
  "&:hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

export const ActiveLinkTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const DesktopAvatarContainer = styled(Box)({
  marginLeft: "-30px",
  marginBottom: "30px",
});

export const TextFieldsGridContainer = styled(Grid)({
  marginBottom: "30px",
});

export const OrganizationUsersListBoxContainer = styled(Box)({});

export const SectionTitleInvited = styled(SecondaryText)({
  marginTop: "8px",
});

export const InviteMemberStyledTextField = styled(StyledTextField)({
  marginTop: "30px",
  marginBottom: "30px",
});

export const FlexGrowContainer = styled(Box)({
  marginBottom: "30px",
  flexGrow: 1,
});

export const OrganizationEditDesktopActions = styled(Box)({
  display: "flex",
  justifyContent: "end",
  marginTop: "20px",
  marginBottom: "15px",
  marginRight: "20px",
});

export const OrganizationEditSaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.contrastText,
  color: theme.palette.primary.contrastText,
  marginRight: "10px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.secondary.contrastText,
  },
  borderRadius: "5px",
}));

export const OrganizationEditDiscardButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.secondary.contrastText,
  color: theme.palette.secondary.contrastText,
  boxShadow: "none",
  "&:hover": {
    borderColor: theme.palette.secondary.contrastText,
  },
  borderRadius: "5px",
}));

export const KeepMeUpdatedLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: "10px",
  ".MuiTypography-root": {
    color: theme.palette.secondary.main,
  },
}));

export const ChangePasswordButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  marginBottom: "30px",
}));

export const ManageOrganizationsMenuItem = styled(MenuItem)({
  marginTop: "10px",
});

export const MenuItemText = styled(Typography)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const ActiveOrganizationMenuItem = styled(ManageOrganizationsMenuItem)({
  marginRight: "15px",
});

export const ManageOrganizationsButtonBoxContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  margin: "auto",
});

export const ManageOrganizationsButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  "&:hover": {
    background: "transparent",
  },
}));

export const ProfileMenuItemStyledAvatar = styled(StyledAvatar)({
  color: "#000",
  fontWeight: "bold",
});

export const ProfileDetailsGridContainer = styled(Grid)({
  overflow: "hidden",
});

export const OrganizationNameBoxContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  overflow: "hidden",
});

export const ProfilePreferencesMenuItem = styled(MenuItem)({
  marginTop: "5px",
});

export const RemoveImageButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const OrganizationsMenuItemAvatarContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100%",
});

export const OrganizationsMenuItemTypographyBoxContainer = styled(Box)({
  marginLeft: "10px",
  maxWidth: "225px",
  flexGrow: 1,
});

export const OrganizationDesktopCardTabletGridContainer = styled(Grid)({
  marginTop: "15px",
});

export const OrganizationEditDesktopStickyFooter = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: 0,
  bottom: 0,
  backgroundColor: theme.palette.primary.contrastText,
  zIndex: 1000,
  width: "100%",
}));

export const OrganizationDesktopCardDetailsGrid = styled(Grid)({
  overflow: "hidden",
});

export const InviteSectionHeaderBoxContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const ActiveOrganizationHeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const OrganizationAvatarTopSpaceContainer = styled(Box)({
  marginTop: "30px",
});

export const LocalInvitationRow = styled(Grid)({
  padding: "16px",
  display: "flex",
  alignItems: "center",
});

export const OverflowEllipseSecondaryText = styled(SecondaryText)({
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "block",
});

export const LeftSpacedSecondaryText = styled(OverflowEllipseSecondaryText)({
  marginLeft: "8px",
});
