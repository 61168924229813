import {
  AcceptButton,
  ActionsContainer,
  DeclineButton,
  OrganizationInvitationCardContainer,
  OrganizationName,
  OrganizationNameContainer,
} from "./styles/invitation/OrganizationInvitationCard.styles";
import { useRespondUserInvitationMutation } from "../store/slices/api/userManagementSlice";
import { useTranslation } from "react-i18next";
import { setUser } from "../store/slices/authSlice";
import { messageError, messageSuccess } from "../util/notification";
import { getTranslation } from "../util/utils";
import ErrorHandling from "./common/ErrorHandling";
import { ERROR_MESSAGE, loadUserFromBackend } from "../util/UserUtil";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setErrorMessage } from "../store/slices/appSlice";

const OrganizationInvitationCard = ({
  acceptHandler,
  declineHandler,
  invitation,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Mutations
  const [respondInvitation, { isLoading: isLoadingRespondInvitation }] =
    useRespondUserInvitationMutation();

  // Handlers
  const handleAccept = async (validationCode, invitationId) => {
    try {
      await respondInvitation({
        invitationCode: validationCode,
        status: "ACCEPT",
        invitationId,
      }).unwrap();

      const fetchedUser = await loadUserFromBackend();

      if (fetchedUser) {
        dispatch(setUser(fetchedUser));
      }

      messageSuccess(
        getTranslation("successfulAcceptOrganizationInvitation", t, i18n)
      );
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === ERROR_MESSAGE.ERROR_FETCHING_USER) {
          dispatch(setErrorMessage(error.message));
          navigate("/error-page");
        }
      }

      messageError(
        getTranslation("failedAcceptOrganizationInvitation", t, i18n)
      );
    }
  };

  const handleDecline = async (validationCode, invitationId) => {
    try {
      await respondInvitation({
        invitationCode: validationCode,
        status: "DECLINE",
        invitationId,
      }).unwrap();
      messageSuccess(
        getTranslation("successfulDeclineOrganizationInvitation", t, i18n)
      );
    } catch (error) {
      if (error.message === ERROR_MESSAGE.ERROR_FETCHING_USER) {
        navigate("/error-message");
      }
      messageError(
        getTranslation("failedDeclineOrganizationInvitation", t, i18n)
      );
    }
  };

  return (
    <ErrorHandling isLoading={isLoadingRespondInvitation} isError={false}>
      <OrganizationInvitationCardContainer id={"invitation-card"}>
        <OrganizationNameContainer>
          <OrganizationName>{invitation.organization.name}</OrganizationName>
        </OrganizationNameContainer>
        <ActionsContainer>
          <DeclineButton
            id="btn-invitation-decline"
            onClick={() => {
              if (declineHandler) {
                declineHandler();
              }

              handleDecline(
                invitation.organization.validationCode,
                invitation.invitationId
              );
            }}
          >
            {getTranslation("decline", t, i18n)}
          </DeclineButton>
          <AcceptButton
            id="btn-invitation-accept"
            onClick={() => {
              if (acceptHandler) {
                acceptHandler();
              }

              handleAccept(
                invitation.organization.validationCode,
                invitation.invitationId
              );
            }}
          >
            {getTranslation("accept", t, i18n)}
          </AcceptButton>
        </ActionsContainer>
      </OrganizationInvitationCardContainer>
    </ErrorHandling>
  );
};

export default OrganizationInvitationCard;
