import React, { useCallback, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Grid,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Item,
  ItemContainer,
  ItemName,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import {
  TypeItemContainer,
  TypeNameGridContainer,
} from "../../styles/types/type-list/TypeItem.styles";
import { getSvgIcon } from "../../../util/icons";
import { LongPressEventType, useLongPress } from "use-long-press";
import LongPressTypesItemMenu from "./LongPressTypesItemMenu";
import FavoriteStarComponent from "../../assets/asset-list/FavoriteStarComponent";

const getIconCreationType = (organizationId, globalFontSize, theme) => {
  const iconSize = globalFontSize * 1.25;

  return organizationId
    ? getSvgIcon("PROFILE", iconSize, iconSize, theme.palette.primary.main)
    : getSvgIcon(
        "MODEL_LIBRARY",
        iconSize,
        iconSize,
        theme.palette.primary.main
      );
};

const getIconCreationTypeDesktop = (organizationId, globalFontSize, theme) => {
  const iconSize = globalFontSize * 2;

  return organizationId
    ? getSvgIcon("PROFILE", iconSize, iconSize, theme.palette.primary.main)
    : getSvgIcon(
        "MODEL_LIBRARY",
        iconSize,
        iconSize,
        theme.palette.primary.main
      );
};

const TypeItem = ({ type, lastIndex, currentTypeId, currentTypeHandler }) => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);
  const iconSize = globalFontSize * 1.2;

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);
  const [rightClicked, setRightClicked] = useState(false);

  // Refs
  const elementRef = useRef();

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(
    (mobileMatches || tabletMatches) && type?.organizationId ? callback : null,
    {
      filterEvents: (event) => true, // All events can potentially trigger long press
      threshold: 1000,
      captureEvent: true,
      cancelOnMovement: false,
      cancelOutsideElement: false,
      detect: LongPressEventType.Pointer,
    }
  );

  // Other variables
  const { id, name, organizationId, isFavorite } = type;

  const creationType = organizationId
    ? getTranslation("USER_CREATED_TYPE", t, i18n)
    : getTranslation("SYSTEM_CREATED_TYPE", t, i18n);

  const handlers = bind("longpress context");
  const open = longPressed || rightClicked;

  // Handlers
  const handleClick = () => {
    if (desktopMatches) {
      currentTypeHandler(id);
    } else {
      navigate(
        `/library/${id}/details?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    }
  };

  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
    setRightClicked(false);
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    if (desktopMatches && type?.organizationId) {
      setAnchorEl(elementRef?.current);
      setRightClicked(true);
    }
  };

  return (
    <>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressTypesItemMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        typeId={type?.id}
      />

      {desktopMatches ? (
        <TypeItemContainer
          ref={elementRef}
          lastindex={lastIndex}
          selected={currentTypeId === id}
          longPressed={open}
          currentTheme={currentTheme}
          onClick={handleClick}
          onContextMenu={handleRightClick}
        >
          <Grid py={1} container gap={1} alignItems="center">
            <Grid item alignItems="center" display="flex" position="relative">
              {getIconCreationTypeDesktop(
                organizationId,
                globalFontSize,
                theme
              )}

              {isFavorite && <FavoriteStarComponent right="-5px" />}
            </Grid>
            <TypeNameGridContainer item xs={7}>
              <ItemName isSelected={currentTypeId === id}>{name}</ItemName>
            </TypeNameGridContainer>
            <Grid item xs>
              <SecondaryText>{creationType}</SecondaryText>
            </Grid>
            <Grid item display="flex" alignItems="center">
              {getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.primary.main
              )}
            </Grid>
          </Grid>
        </TypeItemContainer>
      ) : (
        <ItemContainer longPressed={open} currentTheme={currentTheme}>
          <Item
            key={`resource-${id}`}
            ref={elementRef}
            onClick={handleClick}
            {...handlers}
          >
            <Box sx={{ position: "relative" }}>
              <ListItemIcon>
                {getIconCreationType(organizationId, globalFontSize, theme)}
              </ListItemIcon>

              {isFavorite && (
                <FavoriteStarComponent
                  bottom="-8px"
                  right="8px"
                  multiplier={1.2}
                />
              )}
            </Box>

            <ItemName>{name}</ItemName>
            {getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
          </Item>
        </ItemContainer>
      )}
    </>
  );
};

export default TypeItem;
