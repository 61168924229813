import { useState, forwardRef } from "react";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  MemberItemMobileName,
  MemberItemName,
  PreferenceItemNameSecondary,
  TopSpacedMenuItem,
} from "../components/styles/profile/ProfilePreferenceItem.styles";
import {
  useUpdateUserRolePermissionsMutation,
  useUserRolePermissionsQuery,
} from "../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  showValidationError,
} from "../util/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import {
  CancelButton,
  ChangeRoleButton,
  DynamicContainer,
  HandoverOwnershipTitle,
  RemoveFromOrganizationButton,
  StaticContainer,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../components/styles/general/General.styles";
import { messageError, messageSuccess } from "../util/notification";
import { StyledDivider } from "../components/styles/profile/Profile.styles";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import {
  useActivateUserMutation,
  useGetOrganizationUsersQuery,
  useRemoveUserFromOrganizationMutation,
} from "../store/slices/api/organizationsApiSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../util/viewport-utils";
import { MoreIconGridContainer } from "./styles/profile/ProfileDesktop.styles";
import ConfirmAlert from "../store/confirm/ConfirmAlert";
import ErrorHandling from "./common/ErrorHandling";
import { useGetCurrentSubscriptionActualUsageQuery } from "../store/slices/api/subscriptionsApiSlice";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import { getSvgIcon } from "../util/icons";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrganizationUsersListCard = ({
  user,
  roles,
  currentUserRoles,
  organizationId,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const currentUser = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openOwnership, setOpenOwnership] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Queries
  const {
    data: userRoles,
    isLoading,
    isError,
  } = useUserRolePermissionsQuery(
    {
      organizationId,
      userId: user.id,
    },
    { skip: !organizationId }
  );

  // Other variables
  const organization = currentUser?.organizations?.find(
    (o) => o.id === organizationId
  );
  const iconSize = globalFontSize * 1.2;

  // Custom hooks
  const { isLoading: isLoadingCheckOrgRestricted, isRestricted } =
    useCheckOrganizationRestricted(organization);

  // Queries
  const { data: organizationUsers, isLoading: isLoadingOrganizationUsers } =
    useGetOrganizationUsersQuery(organizationId, {
      skip:
        isRestricted ||
        !hasAccess(
          "all",
          [permissions.USER_MANAGEMENT_VIEW],
          getPermissionsFromUserRoles(currentUserRoles)
        ),
    });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: organization?.id,
      },
      {
        skip: isRestricted,
      }
    );

  // Mutations
  const [
    updateUserRolePermissions,
    { isLoading: isLoadingUpdateUserRolePermissions },
  ] = useUpdateUserRolePermissionsMutation();

  const [
    removeUserFromOrganization,
    { isLoading: isLoadingRemoveUserFromOrganization },
  ] = useRemoveUserFromOrganizationMutation();

  const [activateUser, { isLoading: isLoadingActivateUser }] =
    useActivateUserMutation();

  // Handlers
  const handleOpenConfirm = () => {
    handleCloseOptionsMenu();
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirm = () => {
    handleUpdateUserRolePermissions("OWNER");
    handleCloseConfirm();
  };

  const handleOpenOptionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenOptionsDialog = () => {
    setOpen(true);
  };

  const handleCloseOptionsDialog = () => {
    setOpen(false);
  };

  const handleClickOpenOwnership = () => {
    setOpen(false);
    setOpenOwnership(true);
  };

  const handleCloseOwnership = () => {
    setOpenOwnership(false);
  };

  const handleUpdateUserRolePermissions = async (roleName) => {
    const role = roles?.find((r) => r.name === roleName);

    try {
      await updateUserRolePermissions({
        userId: user.id,
        add: [role.id],
        organizationId,
        remove: userRoles?.filter((r) => r.id !== role.id)?.map((r) => r.id),
      }).unwrap();

      if (roleName === "OWNER") {
        const newOrganizations = currentUser?.organizations?.map((org) => {
          return org.id === organizationId ? { ...org, owner: false } : org;
        });

        dispatch(
          setUser({
            ...currentUser,
            organizations: newOrganizations,
          })
        );
      }

      messageSuccess(
        getTranslation("successfulUpdateUserRolePermissions", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateUserRolePermissions", t, i18n));
    }
  };

  const handleRemoveUserFromOrganization = async () => {
    try {
      await removeUserFromOrganization({
        organizationId,
        userId: user.id,
      }).unwrap();

      messageSuccess(
        getTranslation("successfulRemoveUserFromOrganization", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedRemoveUserFromOrganization", t, i18n));
    }
  };

  const handleActivateUser = async () => {
    try {
      await activateUser({
        organizationId,
        userId: user.id,
      }).unwrap();

      messageSuccess(getTranslation("USER_ACTIVATED", t, i18n));
    } catch (error) {
      showValidationError(error, t, i18n);
    }
  };

  // Other variables
  const openMenu = Boolean(anchorEl);

  const alert = {
    content: getTranslation("CHANGE_OWNERSHIP", t, i18n),
    confirmTitle: getTranslation("HAND_OVER_OWNERSHIP", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const currentUserHasOwnerRole = currentUserRoles?.some(
    (r) => r.name === "OWNER"
  );

  const leftRoles = roles?.filter(
    (r) => !userRoles?.some((ur) => ur.name === r.name)
  );

  const leftRolesExceptOwner = roles?.filter(
    (r) => !userRoles?.some((ur) => ur.name === r.name) && r.name !== "OWNER"
  );

  const userRoleName = userRoles ? userRoles[0].name : "";
  const userHasOwnerRole = userRoles?.some((r) => r.name === "OWNER");
  const canEditMembers = Boolean(organization?.subscription);

  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const usersCurrentValue = organizationUsers?.length ?? 0;

  return (
    <ErrorHandling
      isLoading={
        isLoading ||
        isLoadingRemoveUserFromOrganization ||
        isLoadingUpdateUserRolePermissions ||
        isLoadingActivateUser ||
        isLoadingOrganizationUsers ||
        isLoadingCheckOrgRestricted ||
        isLoadingActualUsage
      }
      isError={isError}
    >
      <>
        <ConfirmAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={alert}
          label="delete-image"
          handleConfirm={handleConfirm}
        />

        <StyledDialog
          fullWidth
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseOptionsDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <StyledDialogContent>
            <StyledDialogTitle>
              {`${user.firstName} ${user.lastName}`}
            </StyledDialogTitle>

            {usersCurrentValue <= usersSubscriptionValue && (
              <>
                {currentUserRoles?.some((r) => r.name === "OWNER")
                  ? leftRoles.map(
                      (r) =>
                        usersCurrentValue <= usersSubscriptionValue && (
                          <div key={r.id}>
                            <StyledDivider />
                            <ChangeRoleButton
                              id={`change-role`}
                              onClick={
                                r.name === "OWNER"
                                  ? handleClickOpenOwnership
                                  : () => {
                                      handleUpdateUserRolePermissions(r.name);
                                      handleCloseOptionsDialog();
                                    }
                              }
                            >
                              {getTranslation("CHANGETO" + r.name, t, i18n)}
                            </ChangeRoleButton>
                          </div>
                        )
                    )
                  : leftRolesExceptOwner.map((r) => (
                      <div key={r.id}>
                        <StyledDivider />
                        <ChangeRoleButton
                          id={`change-role`}
                          onClick={() => {
                            handleCloseOptionsDialog();
                            handleUpdateUserRolePermissions(r.name);
                          }}
                        >
                          {getTranslation("CHANGETO" + r.name, t, i18n)}
                        </ChangeRoleButton>
                      </div>
                    ))}
              </>
            )}

            {usersCurrentValue <= usersSubscriptionValue &&
              user.restricted &&
              hasAccess(
                "all",
                [permissions.USER_MANAGEMENT_EDIT],
                getPermissionsFromUserRoles(currentUserRoles)
              ) && (
                <>
                  <StyledDivider />
                  <RemoveFromOrganizationButton
                    id={`activate-member`}
                    color="error"
                    onClick={() => {
                      handleCloseOptionsDialog();
                      handleActivateUser();
                    }}
                  >
                    {getTranslation("ACTIVATE", t, i18n)}
                  </RemoveFromOrganizationButton>
                </>
              )}

            {usersCurrentValue <= usersSubscriptionValue &&
              hasAccess(
                "all",
                [permissions.USER_MANAGEMENT_DELETE],
                getPermissionsFromUserRoles(currentUserRoles)
              ) && (
                <>
                  <StyledDivider />
                  <RemoveFromOrganizationButton
                    id={`remove-member`}
                    color="error"
                    onClick={() => {
                      handleCloseOptionsDialog();
                      handleRemoveUserFromOrganization();
                    }}
                  >
                    {getTranslation("REMOVEFROMORGANIZATION", t, i18n)}
                  </RemoveFromOrganizationButton>
                </>
              )}
          </StyledDialogContent>

          <CancelButton id={`cancel-button`} onClick={handleCloseOptionsDialog}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
        </StyledDialog>

        <StyledDialog
          fullWidth
          open={openOwnership}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseOwnership}
          aria-describedby="alert-dialog-slide-description"
        >
          <StyledDialogContent>
            <HandoverOwnershipTitle>
              {getTranslation("CHANGE_OWNERSHIP", t, i18n)}
            </HandoverOwnershipTitle>
            <StyledDivider />
            <RemoveFromOrganizationButton
              id={`remove-member`}
              color="error"
              onClick={() => {
                handleUpdateUserRolePermissions("OWNER");
                handleCloseOwnership();
              }}
            >
              {getTranslation("HAND_OVER_OWNERSHIP", t, i18n)}
            </RemoveFromOrganizationButton>
          </StyledDialogContent>
          <CancelButton id={`cancel-button`} onClick={handleCloseOwnership}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
        </StyledDialog>

        <Menu
          slotProps={{
            paper: {
              elevation: 1,
              sx: {
                borderRadius: "8px",
              },
            },
          }}
          aria-labelledby="more-icon"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          id="menu-options"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseOptionsMenu}
        >
          {usersCurrentValue <= usersSubscriptionValue && (
            <>
              {currentUserHasOwnerRole
                ? leftRoles.map((r) => {
                    const isOwnerRole = r.name === "OWNER";

                    return (
                      <div key={r.id}>
                        <MenuItem
                          id="change-role"
                          onClick={
                            isOwnerRole
                              ? handleOpenConfirm
                              : () => {
                                  handleUpdateUserRolePermissions(r.name);
                                  handleCloseOptionsMenu();
                                }
                          }
                        >
                          {getTranslation("CHANGETO" + r.name, t, i18n)}
                        </MenuItem>
                      </div>
                    );
                  })
                : leftRolesExceptOwner.map((r) => (
                    <div key={r.id}>
                      <MenuItem
                        id="change-role"
                        onClick={() => {
                          handleCloseOptionsMenu();
                          handleUpdateUserRolePermissions(r.name);
                        }}
                      >
                        {getTranslation("CHANGETO" + r.name, t, i18n)}
                      </MenuItem>
                    </div>
                  ))}
            </>
          )}

          {usersCurrentValue <= usersSubscriptionValue &&
            user.restricted &&
            hasAccess(
              "all",
              [permissions.USER_MANAGEMENT_EDIT],
              getPermissionsFromUserRoles(currentUserRoles)
            ) && (
              <MenuItem
                id="activate-member"
                onClick={() => {
                  handleCloseOptionsMenu();
                  handleActivateUser();
                }}
              >
                <Typography color="red">
                  {getTranslation("ACTIVATE", t, i18n)}
                </Typography>
              </MenuItem>
            )}

          {hasAccess(
            "all",
            [permissions.USER_MANAGEMENT_DELETE],
            getPermissionsFromUserRoles(currentUserRoles)
          ) && (
            <MenuItem
              id="remove-member"
              onClick={() => {
                handleCloseOptionsMenu();
                handleRemoveUserFromOrganization();
              }}
            >
              <Typography color="red">
                {getTranslation("REMOVEFROMORGANIZATION", t, i18n)}
              </Typography>
            </MenuItem>
          )}
        </Menu>

        <TopSpacedMenuItem
          onClick={() => {
            if (
              userHasOwnerRole &&
              currentUser.id !== user.id &&
              hasAccess(
                "all",
                [permissions.USER_MANAGEMENT_ADD],
                getPermissionsFromUserRoles(currentUserRoles)
              ) &&
              mobileMatches
            ) {
              handleOpenOptionsDialog();
            }
          }}
          dense
          id={`menu-item`}
          desktopMatches={desktopMatches}
        >
          {mobileMatches ? (
            <>
              <DynamicContainer>
                <MemberItemMobileName variant="body1" id={`label-${user.id}`}>
                  {`${user.firstName} ${user.lastName}${
                    user.restricted ? " (Restricted)" : ""
                  }`}
                </MemberItemMobileName>
              </DynamicContainer>

              <StaticContainer>
                <PreferenceItemNameSecondary
                  data-testid="preference-value"
                  variant="body1"
                  id={`label-secondary-${user.id}`}
                >
                  {!userHasOwnerRole
                    ? getTranslation(userRoleName, t, i18n)
                    : getTranslation("OWNER", t, i18n)}
                </PreferenceItemNameSecondary>
              </StaticContainer>

              {canEditMembers &&
                hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_ADD],
                  getPermissionsFromUserRoles(currentUserRoles)
                ) && (
                  <IconButton
                    sx={{ padding: 0 }}
                    data-testid={`arrow-icon-${user.id}`}
                    onClick={handleOpenOptionsDialog}
                    id={
                      !userHasOwnerRole
                        ? getTranslation(userRoleName, t, i18n)
                        : getTranslation("OWNER", t, i18n)
                    }
                  >
                    {getSvgIcon(
                      "ARROW_RIGHT",
                      iconSize,
                      iconSize,
                      theme.palette.primary.main,
                      {
                        visibility:
                          userHasOwnerRole || currentUser.id === user.id
                            ? "hidden"
                            : "visible",
                      }
                    )}
                  </IconButton>
                )}
            </>
          ) : (
            <Grid container>
              <Grid item xs={9}>
                <MemberItemName id={`label-${user.id}`}>
                  {`${user.firstName} ${user.lastName}${
                    user.restricted ? " (Restricted)" : ""
                  }`}
                </MemberItemName>
              </Grid>
              <Grid item xs={2}>
                <PreferenceItemNameSecondary
                  data-testid="preference-value"
                  variant="body1"
                  id={`label-secondary-${user.id}`}
                >
                  {!userHasOwnerRole
                    ? getTranslation(userRoleName, t, i18n)
                    : getTranslation("OWNER", t, i18n)}
                </PreferenceItemNameSecondary>
              </Grid>
              <MoreIconGridContainer item xs={1}>
                {canEditMembers &&
                  hasAccess(
                    "all",
                    [permissions.USER_MANAGEMENT_ADD],
                    getPermissionsFromUserRoles(currentUserRoles)
                  ) &&
                  !userHasOwnerRole &&
                  currentUser.id !== user.id && (
                    <IconButton
                      onClick={handleOpenOptionsMenu}
                      id={
                        !userHasOwnerRole
                          ? getTranslation(userRoleName, t, i18n)
                          : getTranslation("OWNER", t, i18n)
                      }
                      data-testid={`more-icon-${user.id}`}
                    >
                      {getSvgIcon(
                        "MORE",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.contrastText
                      )}
                    </IconButton>
                  )}
              </MoreIconGridContainer>
            </Grid>
          )}
        </TopSpacedMenuItem>
        <StyledDivider />
      </>
    </ErrorHandling>
  );
};

export default OrganizationUsersListCard;
