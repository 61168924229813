import React from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import {
  Box,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import {
  EmptyCell,
  SecondaryContrastColorCell,
} from "../styles/assets/asset-list/AssetListTable.styles";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { getSvgIcon } from "../../util/icons";

const EnhancedTableHead = (props) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const { headCells, order, orderBy, onRequestSort, hideEmptyCell } = props;
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!hideEmptyCell && <EmptyCell></EmptyCell>}

        {headCells.map((headCell) => (
          <SecondaryContrastColorCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(props) => {
                return orderBy === headCell.id ? (
                  <div style={{ marginLeft: "5px", marginTop: "8px" }}>
                    {order === "asc"
                      ? getSvgIcon(
                          "COLLAPSE",
                          iconSize,
                          iconSize,
                          theme.palette.secondary.contrastText
                        )
                      : getSvgIcon(
                          "EXPAND",
                          iconSize,
                          iconSize,
                          theme.palette.secondary.contrastText
                        )}
                  </div>
                ) : (
                  <></>
                );
              }}
            >
              {getTranslation(headCell.label, t, i18n)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </SecondaryContrastColorCell>
        ))}
        {!hideEmptyCell && <EmptyCell></EmptyCell>}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
