import { useSelector } from "react-redux";
import { selectUser } from "../store/slices/authSlice";

const useCheckCurrentSubscription = () => {
  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const defaultOrganization = user?.organizations?.find((o) => o.default);

  if (defaultOrganization?.owner) {
    if (!defaultOrganization?.subscription) {
      return false;
    }
  }

  return true;
};

export default useCheckCurrentSubscription;
