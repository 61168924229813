import {
  Backdrop,
  TableCell,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ReportName,
  ReportTableListBodyRow,
  ReportTableListDescriptionCell,
} from "../styles/reports/Reports.styles";
import { getTranslation } from "../../util/utils";
import { useTranslation } from "react-i18next";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize, selectTheme } from "../../store/slices/appSlice";
import { useCallback, useRef, useState } from "react";
import { LongPressEventType, useLongPress } from "use-long-press";
import LongPressReportsItemMenu from "./LongPressReportsItemMenu";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import { getSvgIcon } from "../../util/icons";

const ReportTableListRow = ({ report, reportId, handleSelect }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);
  const [rightClicked, setRightClicked] = useState(false);

  // Refs
  const elementRef = useRef();

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(
    tabletMatches && report?.organizationId ? callback : null,
    {
      filterEvents: (event) => true, // All events can potentially trigger long press
      threshold: 1000,
      captureEvent: true,
      cancelOnMovement: false,
      cancelOutsideElement: false,
      detect: LongPressEventType.Pointer,
    }
  );

  // Other variables
  const handlers = bind("longpress context");
  const open = longPressed || rightClicked;
  const { id, name, description } = report;
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
    setRightClicked(false);
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    if (desktopMatches && report?.organizationId) {
      setAnchorEl(elementRef?.current);
      setRightClicked(true);
    }
  };

  return (
    <>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressReportsItemMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        reportId={report?.id}
      />

      <ReportTableListBodyRow
        id="report-row-item"
        ref={elementRef}
        hover
        selected={reportId === id}
        longPressed={open}
        currentTheme={currentTheme}
        onClick={() => handleSelect(id)}
        onContextMenu={handleRightClick}
        {...handlers}
      >
        <TableCell component="th" scope="row">
          <ReportName active={reportId === id}>
            {getTranslation(name, t, i18n)}
          </ReportName>
        </TableCell>
        <ReportTableListDescriptionCell>
          <Tooltip title={description}>
            <SecondaryText
              variant="body2"
              sx={{
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {description}
            </SecondaryText>
          </Tooltip>
        </ReportTableListDescriptionCell>
        <TableCell align="right">
          {getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            theme.palette.primary.main
          )}
        </TableCell>
      </ReportTableListBodyRow>
    </>
  );
};

export default ReportTableListRow;
