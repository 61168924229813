import { useLocation } from "react-router-dom";
import { ListGridViewContainer } from "../../styles/assets/ListGridView.styles";
import InfiniteScroll from "react-infinite-scroll-component";
import GridViewCard from "./GridViewCard";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import { Grid, useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { NoDataTextContainer } from "../../styles/general/General.styles";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";

const ListGridView = ({
  list,
  index,
  fetchMoreData,
  resourcesData,
  pageSize,
}) => {
  // General Hooks
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Other variables
  const nonAssetRouteCheck =
    !pathname.includes("resources") &&
    !pathname.includes("favorites") &&
    !pathname.includes("recents") &&
    !pathname.includes("tags-filter") &&
    !pathname.includes("search");

  return nonAssetRouteCheck ? (
    <ListGridViewContainer
      id="assets-tile-list"
      container
      spacing={2}
      columns={!mobileMatches ? 18 : 12}
    >
      {list?.map((row) => (
        <GridViewCard row={row} key={`asset-image-grid-item-${row.id}`} />
      ))}
    </ListGridViewContainer>
  ) : (
    <InfiniteScroll
      dataLength={list?.length}
      next={fetchMoreData}
      hasMore={index < Math.ceil(resourcesData?.totalRecord / pageSize)}
      loader={
        list?.length > 0 ? (
          <InfiniteScrollLoader />
        ) : (
          <NoDataTextContainer>
            <SecondaryText align="center">
              {getTranslation("NO_RESULTS_FOUND", t, i18n)}
            </SecondaryText>
          </NoDataTextContainer>
        )
      }
    >
      <ListGridViewContainer id="assets-tile-list" container>
        <Grid item xs={12} lg={10}>
          <Grid container spacing={2}>
            {list?.map((row) => (
              <GridViewCard
                row={row}
                key={`asset-image-grid-item-${row?.id}`}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={0} lg={2}></Grid>
      </ListGridViewContainer>
      <div id="end"></div>
    </InfiniteScroll>
  );
};

export default ListGridView;
