import React from "react";
import { Grid } from "@mui/material";
import {
  BenefitCardContainer,
  BenefitDescription,
  BenefitName,
} from "./styles/onboarding/BenefitCard.styles";

const BenefitCard = ({ icon, title, description }) => {
  return (
    <BenefitCardContainer container columnSpacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item xs>
        <BenefitName variant="body1">{title}</BenefitName>
        <BenefitDescription variant="body1">{description}</BenefitDescription>
      </Grid>
    </BenefitCardContainer>
  );
};

export default BenefitCard;
