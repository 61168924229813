import { Fragment, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, MenuList, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../store/slices/authSlice";
import OrganizationInvitationCard from "../../components/OrganizationInvitationCard";
import {
  useGetUserInvitationsQuery,
  useUserRolePermissionsQuery,
} from "../../store/slices/api/userManagementSlice";
import {
  BaseDivider,
  StyledDivider,
} from "../../components/styles/profile/Profile.styles";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  hasAccess,
  permissions,
  getPermissionsFromUserRoles,
} from "../../util/utils";
import {
  OrganizationContainer,
  SectionTitle,
} from "../../components/styles/profile/Organization.styles";
import OrganizationHeader from "../../navigation/header/organization/OrganizationHeader";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import OrganizationDesktop from "../../components/profile/OrganizationDesktop";
import ErrorHandling from "../../components/common/ErrorHandling";
import ProfileDesktopHeader from "../../navigation/header/profile/desktop/ProfileDesktopHeader";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import OrganizationPageMenuItem from "../../components/OrganizationPageMenuItem";
import Layer2Alerts from "../../components/Layer2Alerts";
import AppAccess from "../../components/common/AppAccess";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import { messageError } from "../../util/notification";
import { useGetOrganizationUsersQuery } from "../../store/slices/api/organizationsApiSlice";

const Organization = () => {
  //General hooks
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const activeOrganization = user?.organizations?.find((o) => o.default);

  // Custom hooks
  const { isLoading, isRestricted } =
    useCheckOrganizationRestricted(activeOrganization);

  // Queries
  const {
    data: organizationInvitationsData,
    isLoading: isLoadingOrganizationInvitations,
    isError: isErrorOrganizationInvitations,
  } = useGetUserInvitationsQuery(null, { skip: isRestricted });

  const { data: userRoles } = useUserRolePermissionsQuery(
    {
      organizationId: activeOrganization?.id,
      userId: user?.id,
    },
    { skip: !activeOrganization || !activeOrganization?.id }
  );

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: activeOrganization?.id,
      },
      { skip: !activeOrganization || isRestricted }
    );

  const { data: organizationUsers, isLoading: isLoadingOrganizationUsers } =
    useGetOrganizationUsersQuery(activeOrganization?.id, {
      skip:
        isRestricted ||
        !hasAccess(
          "all",
          [permissions.USER_MANAGEMENT_VIEW],
          getPermissionsFromUserRoles(userRoles)
        ),
    });

  // Other variables
  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue ?? 0);
  const usersCurrentValue = organizationUsers?.length ?? 0;

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const showHeader = mobileMatches;

  // Handlers
  const actionAddFunction = () => {
    navigate("/organization-onboarding");
  };

  const goBackHandler = () =>
    navigate(`/profile?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  // Effects
  useEffect(() => {
    const valuesLoaded = !(isLoadingOrganizationUsers || isLoadingActualUsage);
    const isLimitExceeded = usersCurrentValue > usersSubscriptionValue;
    const showAlarmMessage = !isRestricted && valuesLoaded && isLimitExceeded;

    if (showAlarmMessage) {
      messageError(getTranslation("LIMIT_EXCEEDED_USER", t, i18n));
    }
  }, [
    usersCurrentValue,
    usersSubscriptionValue,
    isLoadingOrganizationUsers,
    isLoadingActualUsage,
    isRestricted,
  ]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={isLoadingOrganizationInvitations || isLoading}
        isError={isErrorOrganizationInvitations}
      >
        <PageTransition direction={transitionDirection}>
          {!mobileMatches ? (
            <>
              <ProfileDesktopHeader />
              <OrganizationDesktop />
            </>
          ) : (
            <Box>
              {showHeader && (
                <OrganizationHeader
                  goBackHandler={goBackHandler}
                  ownerOrg={user.organizations?.find((o) => o.owner === true)}
                  actionAddFunction={actionAddFunction}
                />
              )}

              <OrganizationContainer>
                <HomePagePadding>
                  <Box sx={{ marginBottom: "10px" }}>
                    <Layer2Alerts organization={activeOrganization} />
                  </Box>

                  <BaseDivider />

                  <MenuList dense>
                    {user.organizations?.map((org, index) => (
                      <OrganizationPageMenuItem key={org.id} org={org} />
                    ))}
                  </MenuList>
                  <>
                    {organizationInvitationsData?.length > 0 && (
                      <>
                        <SectionTitle variant="h6">
                          {getTranslation(
                            "organization-invitations-title",
                            t,
                            i18n
                          )}
                          ...
                        </SectionTitle>
                        <StyledDivider />
                      </>
                    )}

                    {organizationInvitationsData?.map((invitation) => {
                      return (
                        <Fragment key={invitation.organization.id}>
                          <OrganizationInvitationCard invitation={invitation} />
                          <StyledDivider />
                        </Fragment>
                      );
                    })}
                  </>
                </HomePagePadding>
              </OrganizationContainer>
            </Box>
          )}
        </PageTransition>
      </ErrorHandling>
    </AppAccess>
  );
};

export default Organization;
