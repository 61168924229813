import { useDispatch } from "react-redux";
import { resetWizard } from "../../../store/slices/reportsSlice";
import {
  CloseReportsDialogButton,
  ReportDetailsWizardDialog,
} from "../../styles/reports/Reports.styles";
import { DialogContent, useTheme } from "@mui/material";
import UpdateReportsWizard from "./UpdateReportsWizard";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useGetReportDefinitionAggregationsQuery,
  useGetReportDefinitionByIdQuery,
  useGetReportDefinitionFieldsQuery,
} from "../../../store/slices/api/reportsApiSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const UpdateReportDialog = ({ reportId, open, setOpen }) => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const { data: reportDefinitionData, isLoading: isLoadingReportDefinition } =
    useGetReportDefinitionByIdQuery(
      { organizationId, reportDefinitionId: reportId },
      {
        skip: !reportId,
      }
    );

  const { data: reportDefinitionFieldsData, isLoading: isLoadingReportFields } =
    useGetReportDefinitionFieldsQuery(
      { organizationId, reportDefinitionId: reportId },
      { skip: !reportId }
    );

  const {
    data: reportDefinitionAggregationsData,
    isLoading: isLoadingReportAggregations,
  } = useGetReportDefinitionAggregationsQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    { skip: !reportId }
  );

  // Handlers
  const handleClose = () => {
    dispatch(resetWizard());
    setOpen(false);
  };

  // Other variables
  const iconSize = globalFontSize;

  return (
    <ErrorHandling
      isLoading={
        isLoadingReportDefinition ||
        isLoadingReportFields ||
        isLoadingReportAggregations
      }
      isError={false}
    >
      <ReportDetailsWizardDialog
        fullWidth={true}
        maxWidth={false}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseReportsDialogButton
          id="close-reports-dialog"
          aria-label="close"
          onClick={handleClose}
        >
          {getSvgIcon(
            "CLEAR",
            iconSize,
            iconSize,
            theme.palette.secondary.light
          )}
        </CloseReportsDialogButton>

        <DialogContent>
          <UpdateReportsWizard
            handleClose={handleClose}
            reportDefinitionData={reportDefinitionData}
            reportDefinitionFields={reportDefinitionFieldsData}
            reportDefinitionAggregations={reportDefinitionAggregationsData}
          />
        </DialogContent>
      </ReportDetailsWizardDialog>
    </ErrorHandling>
  );
};

export default UpdateReportDialog;
