import { Backdrop, Box, Grid, ListItemIcon, useTheme } from "@mui/material";
import {
  Item,
  ItemContainer,
  ItemName,
} from "../../styles/assets/ListInlineView.styles";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { useCallback, useRef, useState } from "react";
import LongPressAssetsItemMenu from "./LongPressAssetsItemMenu";
import { LongPressEventType, useLongPress } from "use-long-press";
import { PrimaryText } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { getSvgIcon } from "../../../util/icons";
import FavoriteStarComponent from "./FavoriteStarComponent";

const ListInlineViewItem = ({ row, handleNavigate }) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);
  const currentTheme = useSelector(selectTheme);

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);

  // Refs
  const elementRef = useRef(null);

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(callback, {
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 1000,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: false,
    detect: LongPressEventType.Pointer,
  });

  // Other variables
  const iconSize = globalFontSize * 1.2;
  const {
    id,
    displayId,
    name,
    functionId,
    childrenCount,
    isFavorite,
    parentId,
  } = row;

  const combinationDisplayIdAndName = displayId || name;
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const handlers = bind("longpress context");

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery(
    {
      organizationId,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  // Other variables
  const resourceFunction = allFunctionsData?.find((f) => f.id === functionId);

  // Handlers
  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {longPressed && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={longPressed}
          onClick={handleClose}
        />
      )}

      <LongPressAssetsItemMenu
        resourceFunction={resourceFunction}
        anchorEl={anchorEl}
        handleClose={handleClose}
        resourceId={id}
        parentId={parentId}
      />

      <Grid item xs={0} sm={1}></Grid>

      <ItemContainer
        item
        xs={36}
        sm={10}
        longPressed={longPressed}
        currentTheme={currentTheme}
      >
        <Item
          data-testid={`item-${combinationDisplayIdAndName}`}
          {...handlers}
          onClick={() => handleNavigate(row)}
          ref={elementRef}
        >
          <Box sx={{ position: "relative" }}>
            <ListItemIcon>
              {getSvgIcon(
                resourceFunction?.name?.toUpperCase(),
                iconSize,
                iconSize,
                theme.palette.secondary.light
              )}
            </ListItemIcon>

            {isFavorite && (
              <FavoriteStarComponent
                bottom="-8px"
                right="8px"
                multiplier={1.2}
              />
            )}
          </Box>

          <ItemName>{combinationDisplayIdAndName}</ItemName>

          {childrenCount > 0 && <PrimaryText>{childrenCount}</PrimaryText>}
          {getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            theme.palette.primary.main
          )}
        </Item>
      </ItemContainer>

      <Grid item xs={0} sm={1}></Grid>
    </ErrorHandling>
  );
};

export default ListInlineViewItem;
