import { Box, useTheme } from "@mui/material";
import { getSvgIcon } from "../../../util/icons";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const FavoriteStarComponent = ({
  bottom = "-14px",
  right = "-11px",
  multiplier = 1.5,
}) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * multiplier;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom,
        right,
        zIndex: 1,
      }}
    >
      {getSvgIcon(
        "STAR_FILLED",
        iconSize,
        iconSize,
        theme.palette.secondary.contrastText
      )}
    </Box>
  );
};

export default FavoriteStarComponent;
