import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_RESOURCE_INPUT = {
  name: "",
  description: "",
  typeId: null,
  displayId: "",
  functionId: null,
  category: "",
  version: "",
  characteristics: [],
  parentId: null,
  parent: null,
  hasTypeChanged: true,
};

// Initial state
const initialState = {
  resourceInput: DEFAULT_RESOURCE_INPUT,
  originalInput: DEFAULT_RESOURCE_INPUT,
  error: {},
  isFirstSubmitted: false,
  children: [],
};

// Slice
const resourceInputSlice = createSlice({
  name: "resourceInput",
  initialState,
  reducers: {
    setResourceInput: (state, action) => {
      const newResourceInput = action.payload;
      state.resourceInput = newResourceInput;
    },
    setParentId: (state, action) => {
      const newParentId = action.payload;
      state.resourceInput.parentId = newParentId;
    },
    setParent: (state, action) => {
      const newParent = action.payload;
      state.resourceInput.parent = newParent;
    },
    setOriginalInput: (state, action) => {
      const newOriginalInput = action.payload;
      state.originalInput = newOriginalInput;
    },
    setError: (state, action) => {
      const newError = action.payload;
      state.error = newError;
    },
    setIsFirstSubmitted: (state, action) => {
      const newIsFirstSubmitted = action.payload;
      state.isFirstSubmitted = newIsFirstSubmitted;
    },
    setCharacteristics: (state, action) => {
      const newCharacteristics = action.payload;
      state.resourceInput.characteristics = newCharacteristics;
    },
    addChild: (state, action) => {
      const newChild = action.payload;

      if (!state.children.some((child) => child.id === newChild.id)) {
        state.children.push(newChild);
      }
    },
    clearChildrenList: (state) => {
      state.children = [];
    },
    resetResourceInput: (state) => {
      state.resourceInput = DEFAULT_RESOURCE_INPUT;
      state.error = {};
      state.isFirstSubmitted = false;
    },
  },
});

// Export slice and its corresponding actions
export default resourceInputSlice.reducer;
export const {
  setResourceInput,
  setParentId,
  setParent,
  setCharacteristics,
  setOriginalInput,
  setError,
  setIsFirstSubmitted,
  addChild,
  clearChildrenList,
  resetResourceInput,
} = resourceInputSlice.actions;

// selectors
export const selectResourceInput = (state) => state.resourceInput.resourceInput;
export const selectOriginalInput = (state) => state.resourceInput.originalInput;
export const selectError = (state) => state.resourceInput.error;
export const selectIsFirstSubmitted = (state) =>
  state.resourceInput.isFirstSubmitted;
export const selectChildren = (state) => state.resourceInput.children;
