import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import {
  BackInfoContainer,
  BackPageTitle,
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
} from "../../../components/styles/header/Header.styles";
import {
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectSearchTerm,
} from "../../../store/slices/appSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../../util/utils";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetAllFunctionsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import CategoryMenu from "../../../components/assets/asset-form/CategoryMenu";
import { getSvgIcon } from "../../../util/icons";

const AssetListHeader = ({
  userRoles,
  actionAddFunction,
  currentResourceData,
  resourceid,
  queryPathname,
  tagName,
  from,
  tagId,
  queryResourceId,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const quickAccess = searchParams.get("quick_access");
  const theme = useTheme();

  // States
  const [anchorEl, setAnchorEl] = useState(null);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const open = Boolean(anchorEl);
  const currentResourceFunction = allFunctionsData?.find(
    (f) => f.id === currentResourceData?.functionId
  );
  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleGetBackInfo = () => {
    if (
      Boolean(queryPathname) &&
      (queryPathname.includes("tags-filter") ||
        queryPathname.includes("search"))
    ) {
      const back =
        queryPathname +
        `?direction=${transitionDirections.LEFT_TO_RIGHT}${
          Boolean(tagName) ? `&tagName=${tagName}` : ""
        }${Boolean(from) ? `&from=${from}` : ""}${
          Boolean(tagId) ? `&tagId=${tagId}` : ""
        }${Boolean(queryResourceId) ? `&resourceId=${queryResourceId}` : ""}`;

      const backName = getTranslation("BACK_TO_SEARCH", t, i18n);

      return {
        back,
        backName,
      };
    } else {
      if (Boolean(currentResourceData) && Boolean(resourceid)) {
        const { parent } = currentResourceData;
        const backName =
          parent && parent.id
            ? parent.displayId || parent.name
            : quickAccess
            ? getTranslation("REPORTS", t, i18n)
            : getTranslation("ASSETS", t, i18n);

        const back = `/resources${
          parent && parent.id
            ? `/${parent.id}?direction=${transitionDirections.LEFT_TO_RIGHT}`
            : `?direction=${transitionDirections.LEFT_TO_RIGHT}`
        }`;

        return {
          back,
          backName,
        };
      } else {
        return null;
      }
    }
  };

  const onBackToClickHandler = (back) => {
    if (quickAccess) {
      navigate(
        state?.from.replace(
          "direction=rtl",
          "direction=" + transitionDirections.LEFT_TO_RIGHT
        )
      );
    } else {
      navigate(back);
    }
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer>
        <BackInfoContainer>
          {Boolean(handleGetBackInfo()) ? (
            <LeftActionButton
              id="back-button"
              onClick={() => onBackToClickHandler(handleGetBackInfo().back)}
            >
              {getSvgIcon(
                "ARROW_LEFT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
              <BackTitle component="span" id="previous_page-title">
                {handleGetBackInfo().backName}
              </BackTitle>
            </LeftActionButton>
          ) : (
            <BackPageTitle variant="h4" id="title">
              {getTranslation("assets", t, i18n)}
            </BackPageTitle>
          )}
        </BackInfoContainer>
        {hasAccess(
          "some",
          [
            permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
            permissions.ASSET_MANAGEMENT_LOCATION_ADD,
            permissions.ASSET_MANAGEMENT_RACK_ADD,
          ],
          getPermissionsFromUserRoles(userRoles)
        ) && (
          <Box>
            <div onClick={handleOpenMenu}>
              {getSvgIcon(
                "CREATE_NEW",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>
            <CategoryMenu
              open={open}
              anchorEl={anchorEl}
              handleClose={handleCloseMenu}
              parentCategory={currentResourceFunction?.category}
              categoryActionHandler={actionAddFunction}
            />
          </Box>
        )}
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default AssetListHeader;
