import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_COLOR,
  removeTags,
  resetTags,
  selectAllTags,
  selectHaveChanges,
  selectInputValue,
  setAutocompleteOpen,
  setCurrentColor,
  setError,
  setGlobalTags,
  setInitialTags,
  setInputValue,
  setIsInDialog,
  setMarkedTag,
  setTags,
} from "../../../store/slices/tagsSlice";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
  usePatchResourceTagsMutation,
} from "../../../store/slices/api/tagsSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { messageSuccess } from "../../../util/notification";
import {
  getTranslation,
  RESOURCE_CATEGORIES,
  showValidationError,
} from "../../../util/utils";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import TagsInput from "../tags/TagsInput";
import { StaticHeightDialogContent } from "../../styles/assets/ChooseAssetImagePage.styles";
import ErrorHandling from "../../common/ErrorHandling";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import { useParams } from "react-router-dom";
import { selectCurrentResourceId } from "../../../store/slices/longPressSlice";
import { selectIsInDuplicate } from "../../../store/slices/assetListSlice";

const EditTagsDialog = ({ open, setOpen, id }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { resourceid } = useParams();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const tags = useSelector(selectAllTags);
  const inputValue = useSelector(selectInputValue);
  const haveChanges = useSelector(selectHaveChanges);
  const parentId = useSelector(selectParentId);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const isInDuplicate = useSelector(selectIsInDuplicate);

  // Other variables
  const calculatedResourceId =
    currentResourceId || resourceid || parentId || id;
  const resourceId = calculatedResourceId;

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError: isErrorResource,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    {
      skip: !Boolean(resourceId) || resourceId === "undefined",
    }
  );

  const {
    data: tagsData,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isError: isErrorResourceTags,
  } = useGetResourceTagsQuery({
    resourceid: resourceId,
    organizationId,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [patchResourceTags] = usePatchResourceTagsMutation();

  // Other variables
  const resourceFunction = allFunctionsData?.find(
    (f) => f.id === resourceData?.functionId
  );

  const alert = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),

    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // State
  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    dispatch(setInputValue(""));
    dispatch(setError(""));
    dispatch(setAutocompleteOpen(false));
    dispatch(setCurrentColor(DEFAULT_COLOR));
    dispatch(setMarkedTag(null));
  };

  const handleCancel = () => {
    if (haveChanges) {
      setOpenConfirm(true);
      dispatch(setAutocompleteOpen(false));
    } else {
      handleReset();
      handleClose();
    }
  };

  const handleConfirmAlert = () => {
    handleReset();
    dispatch(resetTags());
    handleClose();
  };

  const handleDone = () => {
    handleReset();
    dispatch(removeTags());
    handleClose();
    messageSuccess(getTranslation("TAGS_UPDATED", t, i18n));
  };

  const handleSubmit = async () => {
    try {
      if (isInDuplicate) {
        handleReset();
        handleClose();
      } else {
        // For add, send tags where 'new' = true AND 'deleted' = false
        const tagsToBeAdded = tags
          .filter((t) => t.new && !t.deleted)
          .map((t) => {
            return {
              displayName: t.name,
              color: t.color,
            };
          });

        // For edit, send tags where 'new' = false AND 'deleted' = false
        const tagsToBeEdited = tags
          .filter((t) => !t.new && !t.deleted)
          .map((t) => {
            return {
              id: t.id,
              displayName: t.name,
              color: t.color,
            };
          });

        // For delete, send tags where 'new' = false AND 'deleted' = true
        // const tagsToBeDeleted = tags.filter((t) => !t.new && t.deleted);

        const resourceCategory = `${
          resourceFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
            ? "hardware-asset"
            : resourceFunction?.category.toLowerCase()
        }s`;

        const tagsPayload = [...tagsToBeAdded, ...tagsToBeEdited];

        await patchResourceTags({
          resourceid: resourceId,
          organizationId: user?.organizations?.find((o) => o.default).id,
          resourceCategory,
          tags: tagsPayload,
        }).unwrap();

        handleDone();
      }
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to patch resource tags", error);
    }
  };

  // Effects
  // Here, we will fetch the tags and put them into the Redux store (similar to resource images and attachments)
  useEffect(() => {
    if (tagsData) {
      const mappedTags = tagsData.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsData]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags]);

  useEffect(() => {
    dispatch(setIsInDialog(true));

    return () => dispatch(setIsInDialog(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandling
      isLoading={
        isLoadingFunctions ||
        isLoadingResource ||
        isLoadingResourceTags ||
        isLoadingTags
      }
      isError={
        isErrorFunctions ||
        isErrorResource ||
        isErrorResourceTags ||
        isErrorTags
      }
    >
      <>
        <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
          <DialogTitle>{getTranslation("EDIT_TAGS", t, i18n)}</DialogTitle>
          <StaticHeightDialogContent>
            <ConfirmAlert
              isOpen={openConfirm}
              setIsOpen={setOpenConfirm}
              alert={alert}
              handleConfirm={handleConfirmAlert}
            />
            <TagsInput id={resourceId} shouldFocus={true} />
          </StaticHeightDialogContent>
          <DialogActions>
            <CancelButton variant="outlined" onClick={handleCancel}>
              {getTranslation("CANCEL", t, i18n)}
            </CancelButton>
            <CreateButton
              disabled={Boolean(inputValue) || !haveChanges}
              onClick={handleSubmit}
            >
              {getTranslation("DONE", t, i18n)}
            </CreateButton>
          </DialogActions>
        </Dialog>
      </>
    </ErrorHandling>
  );
};

export default EditTagsDialog;
