import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ViewButton } from "../../../../components/styles/assets/DesktopHeader.styles";
import {
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectPageView,
  selectSearchTerm,
  selectSortingOption,
  setIsSearchOpen,
  setPageView,
  setSearchTerm,
  setShouldSearch,
  setSortingOption,
  SORTING_OPTIONS,
} from "../../../../store/slices/appSlice";
import {
  constructColumns,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../../../util/utils";
import { useTranslation } from "react-i18next";
import { messageError } from "../../../../util/notification";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  goBack,
  goForward,
  selectColumnViewHistory,
  selectParentId,
  selectTrail,
  setColumns,
  setParentId,
  setTrail,
} from "../../../../store/slices/columnViewSlice";
import { baseApiSlice } from "../../../../store/slices/api/baseApiSlice";
import {
  addHistoryItem,
  removeHistoryItem,
  selectAssetListHistory,
  setTabletTabValue,
} from "../../../../store/slices/assetListSlice";
import DesktopAssetHeading from "../../../../components/assets/asset-list/DesktopAssetHeading";
import { selectFitScreen } from "../../../../store/slices/floorplanSlice";
import {
  HeaderContainer,
  NavigationOptionsContainer,
  SearchTextField,
  SortingMenuOptions,
} from "../../../../components/styles/header/assets/desktop/AssetListDesktopHeader.styles";
import { DynamicVisibilityItemIcon } from "../../../../components/styles/types/type-list/DesktopTypeStickySearch.styles";
import OptionsMenu from "../../../options-menu/OptionsMenu";
import { VIEWPORT_MEDIA_QUERIES } from "../../../../util/viewport-utils";
import AssetsImportExport from "../../../../components/assets/asset-list/AssetsImportExport";
import { useUserRolePermissionsQuery } from "../../../../store/slices/api/userManagementSlice";
import { selectUser } from "../../../../store/slices/authSlice";
import { getSvgIcon } from "../../../../util/icons";
import { useGetResourceParentQuery } from "../../../../store/slices/api/assetManagementSlice";

const AssetListDesktopHeader = ({
  resourceData,
  paramResourceId,
  favoritesData,
  queryPathname,
  tagName,
  from,
  tagId,
  queryResourceId,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [searchParams] = useSearchParams();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const sortingOption = useSelector(selectSortingOption);
  const pageView = useSelector(selectPageView);
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const searchTerm = useSelector(selectSearchTerm);
  const trail = useSelector(selectTrail);
  const columnViewHistory = useSelector(selectColumnViewHistory);
  const assetListHistory = useSelector(selectAssetListHistory);
  const fitScreen = useSelector(selectFitScreen);
  const parentId = useSelector(selectParentId);
  const user = useSelector(selectUser);

  // Other variables
  const organizations = user?.organizations;
  const activeOrganization = organizations?.find((o) => o.default);

  // States
  const [anchorEl, setAnchorEl] = useState(null);

  // Queries
  const { data: userRoles } = useUserRolePermissionsQuery({
    userId: user?.id,
    organizationId: activeOrganization?.id,
  });

  const { data: resourceWithParent } = useGetResourceParentQuery(
    { resourceid: resourceData?.id, organizationId: activeOrganization?.id },
    { skip: pageView === "column" || !resourceData?.id }
  );

  // Other variables
  const open = Boolean(anchorEl);
  const quickAccess = searchParams.get("quick_access");
  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleOpenSortingOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortingOptions = () => {
    setAnchorEl(null);
  };

  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchTerm.length < 3) {
        messageError(getTranslation("SEARCH_TERM_TOO_SHORT", t, i18n));
      } else {
        dispatch(setShouldSearch(true));
        navigate(
          `/search?from=${from}${tagId ? `&tagId=${tagId}` : ""}${
            resourceData ? `&resourceId=${resourceData.id}` : ""
          }${tagName ? `&tagName=${tagName}` : ""}`
        );

        dispatch(setIsSearchOpen(false));
      }
    } else {
      dispatch(setIsSearchOpen(true));
    }
  };

  const handleSearch = (e) => {
    dispatch(setTrail([]));

    if (Boolean(e.target.value)) {
      dispatch(setParentId(null));
    }

    dispatch(setSearchTerm(e.target.value));
    dispatch(setShouldSearch(false));
  };

  const handleClearSearch = () => {
    dispatch(setIsSearchOpen(false));
    dispatch(setSearchTerm(""));
    dispatch(setShouldSearch(false));
    dispatch(setParentId(null));

    if (pathname.includes("search")) {
      navigate(`/resources?direction=${transitionDirections.LEFT_TO_RIGHT}`);
    }
  };

  const handleFormatClick = (view) => {
    dispatch(setPageView(view));

    if (pageView === "column") {
      navigate(
        `/resources${parentId ? `/${parentId}` : ""}?direction=${
          transitionDirections.LEFT_TO_RIGHT
        }`
      );
    }
  };

  const handleSortingOptionClick = (option) => {
    dispatch(setSortingOption(option));
  };

  const handleOrderClick = (e) => {
    e.stopPropagation();

    dispatch(
      setSortingOption({
        ...sortingOption,
        order: sortingOption.order === "asc" ? "desc" : "asc",
      })
    );
  };

  const handleGetBack = () => {
    if (pageView === "column") {
      return {
        back: "",
        isDisabled: trail.filter((t) => t !== null).length <= 1,
      };
    } else {
      if (
        Boolean(queryPathname) &&
        (queryPathname.includes("tags-filter") ||
          queryPathname.includes("search"))
      ) {
        const back =
          queryPathname +
          `?direction=${transitionDirections.LEFT_TO_RIGHT}${
            Boolean(tagName) ? `&tagName=${tagName}` : ""
          }${Boolean(from) ? `&from=${from}` : ""}${
            Boolean(tagId) ? `&tagId=${tagId}` : ""
          }${Boolean(queryResourceId) ? `&resourceId=${queryResourceId}` : ""}`;

        return {
          back,
          isDisabled: false,
        };
      } else {
        if (Boolean(resourceData) && Boolean(paramResourceId)) {
          const { parent } = resourceData;
          const back = `/resources${
            parent && parent.id
              ? `/${parent.id}?direction=${transitionDirections.LEFT_TO_RIGHT}`
              : `?direction=${transitionDirections.LEFT_TO_RIGHT}`
          }`;

          return {
            back,
            isDisabled: false,
          };
        } else {
          return {
            back: "/resources",
            isDisabled: true,
          };
        }
      }
    }
  };

  const handleGetForward = () => {
    if (pageView === "column") {
      return {
        isDisabled: columnViewHistory.length === 0,
      };
    } else {
      return {
        isDisabled: assetListHistory.length === 0,
      };
    }
  };

  const onForwardClickHandler = () => {
    if (pageView === "column") {
      dispatch(baseApiSlice.util.resetApiState());
      dispatch(goForward());
    } else {
      const historyItem = assetListHistory[assetListHistory.length - 1];
      dispatch(removeHistoryItem());
      navigate(
        `/resources/${historyItem}?direction=${transitionDirections.LEFT_TO_RIGHT}`
      );
    }
  };

  const onBackToClickHandler = (back) => {
    if (pageView === "column") {
      dispatch(goBack());
    } else {
      if (quickAccess) {
        navigate(
          state?.from.replace(
            "direction=rtl",
            "direction=" + transitionDirections.LEFT_TO_RIGHT
          )
        );
      } else {
        dispatch(setTabletTabValue(0));
        navigate(back);
        dispatch(addHistoryItem(resourceData.id));
      }
    }
  };

  useEffect(() => {
    const fetchColumnsInfoAsync = async () => {
      const columns = await constructColumns(
        resourceWithParent,
        activeOrganization?.id
      );

      dispatch(setParentId(resourceWithParent?.id));

      dispatch(
        setColumns({
          columns,
          sortBy: sortingOption.value,
          order: sortingOption.order,
          favorites: favoritesData,
        })
      );
    };

    if (pageView !== "column" && resourceWithParent && favoritesData) {
      fetchColumnsInfoAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resourceWithParent,
    activeOrganization?.id,
    sortingOption.value,
    sortingOption.order,
    favoritesData,
    pageView,
  ]);

  return (
    <HeaderContainer fitscreen={fitScreen} variant="elevation">
      <NavigationOptionsContainer>
        <IconButton
          id="back-column-view"
          disabled={handleGetBack().isDisabled}
          onClick={() => onBackToClickHandler(handleGetBack().back)}
        >
          {getSvgIcon(
            "ARROW_LEFT",
            iconSize,
            iconSize,
            handleGetBack().isDisabled
              ? theme.palette.action.disabled
              : theme.palette.secondary.contrastText
          )}
        </IconButton>

        <IconButton
          id="forward-column-view"
          disabled={handleGetForward().isDisabled}
          onClick={onForwardClickHandler}
        >
          {getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            handleGetForward().isDisabled
              ? theme.palette.action.disabled
              : theme.palette.secondary.contrastText
          )}
        </IconButton>

        <DesktopAssetHeading
          resourceId={paramResourceId}
          favorites={favoritesData}
        />
      </NavigationOptionsContainer>
      <Stack direction="row" spacing={2}>
        {tabletMatches ? (
          <OptionsMenu />
        ) : (
          <>
            <Stack direction="row">
              <Tooltip title={getTranslation("TILE_VIEW", t, i18n)}>
                <ViewButton
                  id="tile-view-btn"
                  active={pageView === "app"}
                  onClick={() => handleFormatClick("app")}
                >
                  {getSvgIcon(
                    "TILE_VIEW",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </ViewButton>
              </Tooltip>

              <Divider orientation="vertical" />

              <Tooltip title={getTranslation("LIST_VIEW", t, i18n)}>
                <ViewButton
                  id="list-view-btn"
                  active={pageView === "inline"}
                  onClick={() => handleFormatClick("inline")}
                >
                  {getSvgIcon(
                    "LIST_VIEW",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </ViewButton>
              </Tooltip>

              <Divider orientation="vertical" />

              <Tooltip title={getTranslation("COLUMN_VIEW", t, i18n)}>
                <ViewButton
                  active={pageView === "column"}
                  onClick={() => {
                    handleFormatClick("column");
                    navigate("/resources");
                  }}
                >
                  {getSvgIcon(
                    "COLUMN_VIEW",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </ViewButton>
              </Tooltip>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Button id="sorting-btn" onClick={handleOpenSortingOptions}>
                {getSvgIcon(
                  "SORTING",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}

                {getSvgIcon(
                  "EXPAND",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </Button>

              <SortingMenuOptions
                id="options-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseSortingOptions}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {SORTING_OPTIONS.filter((option) => option.value !== "Z-A").map(
                  (option) => {
                    const { value } = option;
                    return (
                      <MenuItem
                        onClick={() => handleSortingOptionClick(option)}
                        key={value}
                      >
                        <DynamicVisibilityItemIcon
                          isvisible={sortingOption.value === value}
                        >
                          {getSvgIcon(
                            "CHECK",
                            iconSize,
                            iconSize,
                            theme.palette.secondary.light
                          )}
                        </DynamicVisibilityItemIcon>
                        <ListItemText>
                          {value === "A-Z"
                            ? getTranslation("NAME", t, i18n)
                            : getTranslation(value, t, i18n)}
                        </ListItemText>
                        <DynamicVisibilityItemIcon
                          isvisible={sortingOption.value === value}
                          onClick={handleOrderClick}
                        >
                          {sortingOption.order === "asc"
                            ? getSvgIcon(
                                "COLLAPSE",
                                iconSize,
                                iconSize,
                                theme.palette.secondary.light
                              )
                            : getSvgIcon(
                                "EXPAND",
                                iconSize,
                                iconSize,
                                theme.palette.secondary.light
                              )}
                        </DynamicVisibilityItemIcon>
                      </MenuItem>
                    );
                  }
                )}
              </SortingMenuOptions>
            </Stack>
          </>
        )}

        <Stack direction="row">
          {hasAccess(
            "oneOf",
            [
              permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
              permissions.ASSET_MANAGEMENT_RACK_ADD,
              permissions.ASSET_MANAGEMENT_LOCATION_ADD,
            ],
            getPermissionsFromUserRoles(userRoles)
          ) && <AssetsImportExport />}
          {isSearchOpen && (
            <SearchTextField
              inputProps={{
                "data-testid": "search-field",
                style: {
                  padding: 0,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleSearchClick}>
                      {getSvgIcon(
                        "SEARCH",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.contrastText
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      {getSvgIcon(
                        "CLEAR",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.contrastText
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="search-field"
              variant="outlined"
              placeholder={getTranslation("SEARCH_TERM", t, i18n)}
              value={searchTerm}
              onChange={handleSearch}
            />
          )}
          {!isSearchOpen && (
            <IconButton onClick={handleSearchClick}>
              {getSvgIcon(
                "SEARCH",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </IconButton>
          )}
        </Stack>
      </Stack>
    </HeaderContainer>
  );
};

export default AssetListDesktopHeader;
