import { fetchCurrentUser } from "../services/UserService";

/**
 *
 * @returns Fetch user data and create credential. Set it to localSession and first read from session
 */
export const loadUserFromBackend = async () => {
  //If tehre is no session data retrive it
  let response = await fetchCurrentUser();

  if (!response.ok) {
    throw new Error(ERROR_MESSAGE.ERROR_FETCHING_USER);
  }

  //Map user to front-end user
  let user = {};
  if (response) {
    user = mapUser(response.data);
  }

  return user;
};

/**
 * Map given user to front-end user
 * @param {*} fetchedUser
 * @returns
 */
export const mapUser = (fetchedUser) => {
  //define short name
  let shortUsername = "P";
  if (fetchedUser?.firstName) {
    shortUsername = fetchedUser?.firstName?.charAt(0).toUpperCase();
  } else {
    shortUsername = fetchedUser?.email?.charAt(0).toUpperCase();
  }

  //evaluate onboarding status
  //create
  return {
    id: fetchedUser?.id,
    refreshTime: new Date().getTime(),
    loadCompleted: true,
    email: fetchedUser?.email,
    firstName: fetchedUser?.firstName,
    lastName: fetchedUser?.lastName,
    language: fetchedUser?.language,
    region: fetchedUser?.region,
    dataStorageLocation: fetchedUser?.dataStorageLocation,
    avatarUri: fetchedUser?.avatarUri,
    shortUsername,
    organizations: fetchedUser?.organizations,
    defaultOrganizationId: fetchedUser?.defaultOrganizationId,
    timeZone: fetchedUser?.timeZone,
    nickname: fetchedUser?.nickname,
  };
};

export const ERROR_MESSAGE = {
  ERROR_FETCHING_USER: "ERROR_FETCHING_USER",
};
