import { createSlice } from "@reduxjs/toolkit";
import { getAssetDisplay } from "../../util/graphical-rack-view-utils";
import { userLocalStorageKey } from "../../util/utils";

// Default values for initial state
const DEFAULT_THEME = "LIGHT";
export const DEFAULT_LANGUAGE = "en";
const DEFAULT_PAGE_INFO = {
  title: "HOME",
  showCancelButton: true,
  showProfile: true,
  showBackButton: false,
  showLogout: false,
  actionButton: "",
  cancelButton: "",
  back: "/",
  backName: "",
  hideHeader: false,
  isActionButtonDisabled: false,
  isLoadingAction: false,
};
const DEFAULT_PAGE_VIEW = "column";

export const SORTING_OPTIONS = [
  {
    label: "From A to Z",
    value: "A-Z",
    order: "asc",
    type: "displayIdAndNameCombination",
  },
  {
    label: "From Z to A",
    value: "Z-A",
    order: "desc",
    type: "displayIdAndNameCombination",
  },
  {
    label: "Favoured",
    value: "FAVOURED",
    order: "desc",
    type: "favoured",
  },
  {
    label: "Category",
    value: "CATEGORY",
    order: "asc",
    type: "displayIdAndNameCombination",
  },
];
export const DEFAULT_SORTING_OPTION = SORTING_OPTIONS[0];
export const DEFAULT_INDEX = 1;
export const DEFAULT_FONT_SIZE = 14;

// Initial state
const initialState = {
  theme: DEFAULT_THEME,
  userTheme: DEFAULT_THEME,
  language: DEFAULT_LANGUAGE,
  pageInfo: DEFAULT_PAGE_INFO,
  pageView: DEFAULT_PAGE_VIEW,
  sortingOption: DEFAULT_SORTING_OPTION,
  searchTerm: "",
  isSearchOpen: false,
  shouldSearch: false,
  isSearching: false,
  index: DEFAULT_INDEX,
  isDrawerOpen: false,
  globalFontSize: DEFAULT_FONT_SIZE,
  currentresourceid: "",
  activeLink: "account-settings",
  organizationId: null,
  drawerMenuIndex: 0,
  isSubscribed: false,
  showNotifications: true,
  graphicalViewAssetDisplay: getAssetDisplay(),
  graphicalViewFitMode: "height",
  columnViewTrigger: 0,
  stage: null,
  errorMessage: null,
};

// Slice
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      const newTheme = action.payload;
      state.theme = newTheme;
      // localStorage.setItem(userLocalStorageKey("theme"), newTheme);
    },
    setUserTheme: (state, action) => {
      const newTheme = action.payload;
      state.userTheme = newTheme;
    },
    setLanguage: (state, action) => {
      const newLanguage = action.payload;
      state.language = newLanguage;
      localStorage.setItem(userLocalStorageKey("language"), newLanguage);
    },
    setPageInfo: (state, action) => {
      const {
        title,
        showProfile,
        showBackButton,
        back,
        backName,
        showLogout,
        actionButton,
        cancelButton,
        hideHeader,
        isActionButtonDisabled,
      } = action.payload;

      const newPageInfo = {
        title,
        showProfile: showProfile !== undefined ? showProfile : true,
        showBackButton: showBackButton !== undefined ? showBackButton : false,
        back,
        backName,
        showLogout: showLogout !== undefined ? showLogout : false,
        actionButton: actionButton !== undefined ? actionButton : "",
        cancelButton: cancelButton !== undefined ? cancelButton : "",
        hideHeader: hideHeader !== undefined ? hideHeader : false,
        isActionButtonDisabled:
          isActionButtonDisabled !== undefined ? isActionButtonDisabled : false,
      };

      state.pageInfo = newPageInfo;
    },
    setActionButton: (state, action) => {
      const newActionButton = action.payload;
      state.pageInfo.actionButton = newActionButton;
    },
    setIsActionButtonDisabled: (state, action) => {
      const value = action.payload;
      state.pageInfo.isActionButtonDisabled = value;
    },
    setIsLoadingAction: (state, action) => {
      const value = action.payload;
      state.pageInfo.isLoadingAction = value;
    },
    setPageView: (state, action) => {
      const newPageView = action.payload;
      state.pageView = newPageView;
    },
    setSortingOption: (state, action) => {
      const newSortingOption = action.payload;
      state.sortingOption = newSortingOption;
    },
    setSearchTerm: (state, action) => {
      const newSearchTerm = action.payload;
      state.searchTerm = newSearchTerm;
    },
    setIsSearchOpen: (state, action) => {
      const newValue = action.payload;
      state.isSearchOpen = newValue;
    },
    setShouldSearch: (state, action) => {
      const newValue = action.payload;
      state.shouldSearch = newValue;
    },
    setIsSearching: (state, action) => {
      const newValue = action.payload;
      state.isSearching = newValue;
    },
    setIndex: (state, action) => {
      const newIndex = action.payload;
      state.index = newIndex;
    },
    setIsDrawerOpen: (state, action) => {
      const value = action.payload;
      state.isDrawerOpen = value;
    },
    setGlobalFontSize: (state, action) => {
      const newGlobalFontSize = action.payload;
      state.globalFontSize = newGlobalFontSize;
    },
    setCurrentresourceid: (state, action) => {
      const newresourceid = action.payload;
      state.currentresourceid = newresourceid;
    },
    setActiveLink: (state, action) => {
      const newActiveLink = action.payload;
      state.activeLink = newActiveLink;
    },
    setOrganizationId: (state, action) => {
      const newOrganizationId = action.payload;
      state.organizationId = newOrganizationId;
    },
    setDrawerMenuIndex: (state, action) => {
      const newDrawerMenuIndex = action.payload;
      state.drawerMenuIndex = newDrawerMenuIndex;
    },
    setIsSubscribed: (state, action) => {
      const newValue = action.payload;
      state.isSubscribed = newValue;
    },
    setShowNotifications: (state, action) => {
      const newValue = action.payload;
      state.showNotifications = newValue;
    },
    setGraphicalViewAssetDisplay: (state, action) => {
      const newAssetDisplay = action.payload;

      state.graphicalViewAssetDisplay = newAssetDisplay;

      localStorage.setItem("assetDisplay", JSON.stringify(newAssetDisplay));
    },
    setGraphicalViewFitMode: (state, action) => {
      const newFitMode = action.payload;
      state.graphicalViewFitMode = newFitMode;
    },
    setColumnViewTrigger: (state, action) => {
      const newTrigger = action.payload;
      state.columnViewTrigger = newTrigger;
    },
    setStage: (state, action) => {
      const newStage = action.payload;
      state.stage = newStage;
    },
    setErrorMessage: (state, action) => {
      const newErrorMessage = action.payload;
      state.errorMessage = newErrorMessage;
    },
  },
});

// Export slice and its corresponding actions
export default appSlice.reducer;
export const {
  setTheme,
  setUserTheme,
  setLanguage,
  setPageInfo,
  setPageView,
  setSortingOption,
  setSearchTerm,
  setIsSearchOpen,
  setShouldSearch,
  setIsSearching,
  setIndex,
  setActionButton,
  setIsActionButtonDisabled,
  setIsDrawerOpen,
  setIsLoadingAction,
  setGlobalFontSize,
  setCurrentresourceid,
  setActiveLink,
  setOrganizationId,
  setDrawerMenuIndex,
  setIsSubscribed,
  setShowNotifications,
  setGraphicalViewAssetDisplay,
  setGraphicalViewFitMode,
  setColumnViewTrigger,
  setStage,
  setErrorMessage,
} = appSlice.actions;

// Selectors
export const selectTheme = (state) => state.app.theme;
export const selectUserTheme = (state) => state.app.userTheme;
export const selectLanguage = (state) => state.app.language;
export const selectPageInfo = (state) => state.app.pageInfo;
export const selectPageView = (state) => state.app.pageView;
export const selectSortingOption = (state) => state.app.sortingOption;
export const selectSearchTerm = (state) => state.app.searchTerm;
export const selectIsSearchOpen = (state) => state.app.isSearchOpen;
export const selectShouldSearch = (state) => state.app.shouldSearch;
export const selectIsSearching = (state) => state.app.isSearching;
export const selectIndex = (state) => state.app.index;
export const selectIsDrawerOpen = (state) => state.app.isDrawerOpen;
export const selectGlobalFontSize = (state) => state.app.globalFontSize;
export const selectCurrentresourceid = (state) => state.app.currentresourceid;
export const selectActiveLink = (state) => state.app.activeLink;
export const selectOrganizationId = (state) => state.app.organizationId;
export const selectDrawerMenuIndex = (state) => state.app.drawerMenuIndex;
export const selectIsSubscribed = (state) => state.app.isSubscribed;
export const selectShowNotifications = (state) => state.app.showNotifications;
export const selectGraphicalViewAssetDisplay = (state) =>
  state.app.graphicalViewAssetDisplay;
export const selectGraphicalViewFitMode = (state) =>
  state.app.graphicalViewFitMode;
export const selectColumnViewTrigger = (state) => state.app.columnViewTrigger;
export const selectStage = (state) => state.app.stage;
export const selectErrorMessage = (state) => state.app.errorMessage;
