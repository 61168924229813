import React, { memo } from "react";
import { Layer, Rect } from "react-konva";
import { haveIntersection } from "../../../util/graphical-rack-view-utils";

const FiltersList = ({
  images = [],
  slots,
  rows,
  screenWidth,
  rectangleHeight,
}) => {
  // Handlers
  const getFilters = () => {
    const topRow = { x: 0, y: 0, width: screenWidth, height: rectangleHeight };
    const bottomRow = {
      x: 0,
      y: rows * rectangleHeight,
      width: screenWidth,
      height: rectangleHeight,
    };

    // Adding red filter if there are overlapping assets
    let nonTextObjects = images.map((i) => {
      if (i.type === "Group") {
        const rect = i.props.children[0];
        return rect;
      }

      return i;
    });

    const calculateHardwareAssets = nonTextObjects
      .map((hardwareAsset) => {
        const { x, width, startPosition } = hardwareAsset;
        const height = slots * rectangleHeight;
        const y = (startPosition - slots + 1) * rectangleHeight;
        return {
          x: x,
          y,
          width: width,
          height,
        };
      })
      .concat([topRow, bottomRow]);

    let filters = [];
    for (let i = 0; i < calculateHardwareAssets.length; i++) {
      const shape1 = calculateHardwareAssets[i];
      for (let j = i + 1; j < calculateHardwareAssets.length; j++) {
        const shape2 = calculateHardwareAssets[j];

        if (haveIntersection(shape1, shape2)) {
          const intersection = {
            x: Math.max(shape1.x, shape2.x),
            y: Math.max(shape1.y, shape2.y),
            width:
              Math.min(shape1.x + shape1.width, shape2.x + shape2.width) -
              Math.max(shape1.x, shape2.x),
            height:
              Math.min(shape1.y + shape1.height, shape2.y + shape2.height) -
              Math.max(shape1.y, shape2.y),
          };

          filters.push(intersection);
        }
      }
    }

    return filters;
  };

  // Other variables
  const filters = getFilters();

  return (
    <Layer>
      {filters.map((filter, i) => {
        const { x, y, width, height } = filter;
        return (
          <Rect
            key={"intersection-" + i}
            x={x}
            y={y}
            width={width}
            height={height}
            fill="rgba(250, 128, 114, 0.3)"
          />
        );
      })}
    </Layer>
  );
};

export default memo(FiltersList);
