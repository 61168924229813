import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NamesGroup from "../../components/inputs/NamesGroup";
import { OnboardingUserCredentialContainer } from "../../components/styles/onboarding/OnboardingUserCredential.styles";
import { selectUser } from "../../store/slices/authSlice";
import { selectUserData } from "../../store/slices/onboardingSlice";
import { setUserData } from "../../store/slices/onboardingSlice";
import { useGetUserProfileImageQuery } from "../../store/slices/api/userManagementSlice";
import UploadImage from "../../components/UploadImage";
import PageTransition from "../../components/PageTransition";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import { PrimaryText } from "../../components/styles/onboarding/Onboarding.styles";

const OnboardingUserCredential = ({ transitionDirection }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);

  // Queries
  const { data: profileImage } = useGetUserProfileImageQuery(
    {
      avatarUri: user?.avatarUri,
    },
    {
      skip: !Boolean(user?.avatarUri),
    }
  );

  useEffect(() => {
    if (profileImage) {
      dispatch(
        setUserData({
          ...userData,
          selectedFile: profileImage,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage]);

  return (
    <PageTransition direction={transitionDirection}>
      <PrimaryText sx={{ marginBottom: "10px" }} variant="h2" align="start">
        {getTranslation("PERSONAL_DATA", t, i18n)}
      </PrimaryText>

      <PrimaryText sx={{ marginBottom: "20px" }} variant="body2">
        {getTranslation("PERSONAL_DATA_CAPTION", t, i18n)}
      </PrimaryText>

      <OnboardingUserCredentialContainer>
        {/* Add image icon */}
        <UploadImage
          initialFile={userData.selectedFile}
          isUserOnboarding={true}
          uploadTitle={userData.selectedFile ? "EDIT_IMAGE" : "ADD_IMAGE"}
        />

        {/* First and last name inputs */}
        <NamesGroup />
      </OnboardingUserCredentialContainer>
    </PageTransition>
  );
};

export default OnboardingUserCredential;
