import { get_resource_parents } from "../Constants";
import HttpService from "./HttpService";

export const getResources = async (
  organizationId,
  parentId,
  index,
  size,
  sortBy,
  order
) => {
  let url =
    get_resource_parents.replace(":organizationId", organizationId) +
    `${
      index
        ? `?index=${index}&size=${size}&sort_by=${sortBy}&order=${order}${
            parentId ? `&resource_id=${parentId}` : ""
          }`
        : sortBy
        ? `?sort_by=${sortBy}&order=${order}${
            parentId ? `&resource_id=${parentId}` : ""
          }`
        : `${parentId ? `?resource_id=${parentId}` : ""}`
    } `;

  var config = { method: "GET", url };
  let result = await HttpService.sendRequest(config);

  return result;
};
