import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_INDEX } from "./appSlice";

const initialState = {
  typeId: null,
  items: [],
  index: DEFAULT_INDEX,
};

const typeSlice = createSlice({
  name: "type",
  initialState: initialState,
  reducers: {
    setTypeId(state, action) {
      const newTypeId = action.payload;
      state.typeId = newTypeId;
    },
    setItems: (state, action) => {
      const newItems = action.payload;
      state.items = newItems;
    },
    setIndex: (state, action) => {
      const newIndex = action.payload;
      state.index = newIndex;
    },
    editItem: (state, action) => {
      const { id, newData } = action.payload;

      state.items = state.items.map((item) =>
        item.id === id ? { ...item, ...newData } : item
      );
    },
    deleteItem: (state, action) => {
      const id = action.payload;
      state.items = state.items.filter((i) => i.id !== id);
    },
  },
});

export default typeSlice.reducer;

export const { setTypeId, setItems, setIndex, editItem, deleteItem } =
  typeSlice.actions;

export const selectTypeId = (state) => state.type.typeId;
export const selectItems = (state) => state.type.items;
export const selectIndex = (state) => state.type.index;
