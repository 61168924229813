import { Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";

export const BenefitCardContainer = styled(Grid)({
  gap: "8px",
  marginTop: 15,
  maxWidth: "460px",
  alignItems: "center",
});

export const BenefitName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

export const BenefitDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 300,
}));
