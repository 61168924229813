import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { SUBSCRIPTION_ERRORS } from "../../../util/utils";

const baseQuery = retry(fetchBaseQuery({}), { maxRetries: 0 });

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 403) {
      const isRestricted =
        result.error.data?.error ===
          SUBSCRIPTION_ERRORS["SUBSCRIPTION_NOT_FOUND"] ||
        result.error.data?.error ===
          SUBSCRIPTION_ERRORS["CURRENT_SUBSCRIPTION_NOT_FOUND"] ||
        result.error.data?.error ===
          SUBSCRIPTION_ERRORS["LIMIT_EXCEEDED_USER"] ||
        result.error.data?.error ===
          SUBSCRIPTION_ERRORS["RESTRICTED_DUE_TO_OVERLIMIT_USER"];

      // if (isRestricted) {
      //   window.location.reload();
      // }
    }
  }

  return result;
};

export const baseApiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: [
    "Invitations",
    "Roles",
    "UserImage",
    "OrganizationImage",
    "OrganizationUsers",
    "Referrals",
    "Favorites",
    "ResourceImage",
    "ResourceAttachment",
    "ResourcesByType",
    "Recents",
    "Reports",
    "LIST",
    "DETAILS",
    "SET_TEMPLATES",
    "FUNCTIONS",
    "TYPES",
    "TYPE",
    "TYPE_CHARACTERISTICS",
    "REPORT_WIDGET",
    "REPORT_LOCATIONS",
    "Tags",
    "Search",
    "GraphicalObjects",
    "FAVORITE_TYPES",
    "RECENT_TYPES",
    "DETAILS",
    "ReportDefinitionExecute",
    "ReportDefinition",
    "ReportDefinitionField",
    "ReportDefinitionFilter",
    "ReportDefinitionAggregations",
    "ADDRESS",
    "ORGANIZATION",
    "Subscription",
  ],
  refetchOnFocus: true,
  endpoints: () => ({}),
});
