import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_REPORT_RESULTS_PAGE_SIZE,
  getComparator,
  REPORT_FIELDS,
  REPORT_RESULTS_PAGE_OPTIONS,
  stableSort,
} from "../../../util/reports-utils";
import { getTranslation, localizeDate, THEME } from "../../../util/utils";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import {
  selectQueryDefinitionTablePage,
  selectShouldExecute,
  setQueryDefinitionTablePage,
} from "../../../store/slices/reportsSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import {
  EmptyRowTable,
  FormatResultPreviewTableWrapper,
  QueryDefinitionPreviewTableBodyRow,
  QueryDefinitionPreviewTableHeaderCell,
} from "../../styles/reports/Reports.styles";
import { selectTheme } from "../../../store/slices/appSlice";

const TABLE_CELL_HEIGHT = 33;

const QueryDefinitionPreviewTable = ({ rows }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const shouldExecute = useSelector(selectShouldExecute);
  const page = useSelector(selectQueryDefinitionTablePage);
  const currentTheme = useSelector(selectTheme);

  // States
  const [rowsPerPage, setRowsPerPage] = useState(
    DEFAULT_REPORT_RESULTS_PAGE_SIZE
  );
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  // Other variables
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const disabledColor =
    currentTheme === THEME.LIGHT ? "rgba(224, 224, 224, 1)" : "#6C757D";

  const activeColor =
    currentTheme === THEME.LIGHT
      ? "rgba(0, 0, 0, 0.87)"
      : "rgba(224, 224, 224, 1)";

  const color = !shouldExecute ? disabledColor : activeColor;

  // Handlers
  const handleChangePage = (event, newPage) => {
    dispatch(setQueryDefinitionTablePage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(setQueryDefinitionTablePage(0));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <FormatResultPreviewTableWrapper>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <QueryDefinitionPreviewTableHeaderCell color={color}>
              <TableSortLabel
                onClick={createSortHandler(REPORT_FIELDS["DISPLAY_ID"])}
              >
                {getTranslation(REPORT_FIELDS["DISPLAY_ID"], t, i18n)}

                {orderBy === REPORT_FIELDS["DISPLAY_ID"] ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </QueryDefinitionPreviewTableHeaderCell>

            <QueryDefinitionPreviewTableHeaderCell color={color}>
              <TableSortLabel
                onClick={createSortHandler(REPORT_FIELDS["NAME"])}
              >
                {getTranslation(REPORT_FIELDS["NAME"], t, i18n)}

                {orderBy === REPORT_FIELDS["NAME"] ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </QueryDefinitionPreviewTableHeaderCell>

            <QueryDefinitionPreviewTableHeaderCell color={color}>
              <TableSortLabel
                onClick={createSortHandler(REPORT_FIELDS["FUNCTION_NAME"])}
              >
                {getTranslation(REPORT_FIELDS["FUNCTION_NAME"], t, i18n)}

                {orderBy === REPORT_FIELDS["FUNCTION_NAME"] ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </QueryDefinitionPreviewTableHeaderCell>

            <QueryDefinitionPreviewTableHeaderCell color={color}>
              <TableSortLabel
                onClick={createSortHandler(REPORT_FIELDS["TYPE_NAME"])}
              >
                {getTranslation(REPORT_FIELDS["TYPE_NAME"], t, i18n)}

                {orderBy === REPORT_FIELDS["TYPE_NAME"] ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </QueryDefinitionPreviewTableHeaderCell>

            <QueryDefinitionPreviewTableHeaderCell color={color}>
              <TableSortLabel
                onClick={createSortHandler(REPORT_FIELDS["CREATED_AT"])}
              >
                {getTranslation(REPORT_FIELDS["CREATED_AT"], t, i18n)}

                {orderBy === REPORT_FIELDS["CREATED_AT"] ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </QueryDefinitionPreviewTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows.map((row) => (
            <QueryDefinitionPreviewTableBodyRow key={row[REPORT_FIELDS["ID"]]}>
              <QueryDefinitionPreviewTableHeaderCell
                color={color}
                component="th"
                scope="row"
              >
                {row[REPORT_FIELDS["DISPLAY_ID"]]}
              </QueryDefinitionPreviewTableHeaderCell>

              <QueryDefinitionPreviewTableHeaderCell color={color}>
                {row[REPORT_FIELDS["NAME"]]}
              </QueryDefinitionPreviewTableHeaderCell>

              <QueryDefinitionPreviewTableHeaderCell color={color}>
                {row[REPORT_FIELDS["FUNCTION_NAME"]]}
              </QueryDefinitionPreviewTableHeaderCell>

              <QueryDefinitionPreviewTableHeaderCell color={color}>
                {row[REPORT_FIELDS["TYPE_NAME"]]}
              </QueryDefinitionPreviewTableHeaderCell>

              <QueryDefinitionPreviewTableHeaderCell color={color}>
                {localizeDate(row[REPORT_FIELDS["CREATED_AT"]], user?.region)}
              </QueryDefinitionPreviewTableHeaderCell>
            </QueryDefinitionPreviewTableBodyRow>
          ))}

          {emptyRows > 0 && (
            <EmptyRowTable rowHeight={TABLE_CELL_HEIGHT * emptyRows}>
              <TableCell colSpan={6} />
            </EmptyRowTable>
          )}
        </TableBody>
      </Table>

      <TablePagination
        sx={{ "& .MuiTablePagination-input": { fontSize: "16px" } }}
        rowsPerPageOptions={REPORT_RESULTS_PAGE_OPTIONS}
        component="div"
        count={rows.length}
        labelRowsPerPage={getTranslation("ROWS_PER_PAGE", t, i18n)}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </FormatResultPreviewTableWrapper>
  );
};

export default QueryDefinitionPreviewTable;
