import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  currentResourceId: null,
};

// Slice
const longPressSlice = createSlice({
  name: "longPress",
  initialState,
  reducers: {
    setCurrentResourceId: (state, action) => {
      const newCurrentResourceId = action.payload;
      state.currentResourceId = newCurrentResourceId;
    },
  },
});

// Export slice and its corresponding actions
export default longPressSlice.reducer;
export const { setCurrentResourceId } = longPressSlice.actions;

// selectors
export const selectCurrentResourceId = (state) =>
  state.longPress.currentResourceId;
