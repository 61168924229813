import { useDispatch } from "react-redux";
import {
  FUNCTION_CATEGORIES,
  LIFECYCLE_STATUSES,
  REPORT_CONDITION_OPERATORS,
  REPORT_FIELDS,
} from "../../../../util/reports-utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/slices/authSlice";
import { useGetAllFunctionsQuery } from "../../../../store/slices/api/assetManagementSlice";
import { setShouldExecute } from "../../../../store/slices/reportsSlice";
import {
  CustomValueEditorAutocomplete,
  CustomValueEditorDatePicker,
  CustomValueEditorTextField,
  QueryCheckboxMobile,
} from "../../../styles/reports/Reports.styles";
import { datePickerFormat, getTranslation } from "../../../../util/utils";
import { TextField, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getSvgIcon } from "../../../../util/icons";
import { selectGlobalFontSize } from "../../../../store/slices/appSlice";

const RuleValueEditor = ({ field, operator, value, handleOnChange }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const { data: functionsData } = useGetAllFunctionsQuery({
    organizationId: user?.organizations?.find((o) => o.default)?.id,
  });

  // Other variables
  const isNumberField =
    field === REPORT_FIELDS["EXT_HIERARCHY_LEVEL"] ||
    field === REPORT_FIELDS["EXT_FIRST_LEVEL_CHILD_COUNT"] ||
    field === REPORT_FIELDS["EXT_TOTAL_CHILD_COUNT"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_POWER_CONSUMPTION"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_WIDTH"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_HEIGHT"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_LENGTH"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_WEIGHT"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_RACK_UNIT_CAPACITY"] ||
    field === REPORT_FIELDS["RESOURCE_LOCATION_HARDWARE_ASSET_COUNT"] ||
    field === REPORT_FIELDS["RESOURCE_LOCATION_RACK_COUNT"] ||
    field === REPORT_FIELDS["RESOURCE_RACK_HARDWARE_ASSET_COUNT"] ||
    field === REPORT_FIELDS["PARENT_CHILDREN_COUNT"] ||
    field === REPORT_FIELDS["CHARACTERISTIC_HEAT_EMISSION"];
  const iconSize = globalFontSize;

  // Handlers
  const handleTextFieldChange = (e) => {
    dispatch(setShouldExecute(false));
    handleOnChange(e.target.value);
  };

  const handleDatePickerChange = (value) => {
    dispatch(setShouldExecute(false));
    handleOnChange(value.format());
  };

  const handleAutocompleteChange = (event, newValue) => {
    dispatch(setShouldExecute(false));
    handleOnChange(newValue.join(","));
  };

  const handleIsDateField = () =>
    field === REPORT_FIELDS["CREATED_AT"] ||
    field === REPORT_FIELDS["LIFECYCLE_DATE"] ||
    field === REPORT_FIELDS["RESOURCE_TAG_MATCH_DATE"] ||
    field === REPORT_FIELDS["RESOURCE_PARENT_LIFECYCLE_DATE"] ||
    field === REPORT_FIELDS["RESOURCE_PARENT_CREATED_AT"] ||
    field === REPORT_FIELDS["P_RESOURCE_TAG_MATCH_DATE"];

  const handleIsAutocompleteOperator = () =>
    operator === REPORT_CONDITION_OPERATORS["IN"] ||
    operator === REPORT_CONDITION_OPERATORS["NOT_IN"];

  // Renderers
  const renderCustomValueEditor = () => {
    if (handleIsAutocompleteOperator()) {
      let options = [];

      const functionCategoriesSelector =
        field === REPORT_FIELDS["FUNCTION_CATEGORY"] ||
        field === REPORT_FIELDS["P_FUNCTION_CATEGORY"] ||
        field === REPORT_FIELDS["TYPE_FUNCTION_CATEGORY"] ||
        field === REPORT_FIELDS["P_TYPE_FUNCTION_CATEGORY"];

      const functionNamesSelector =
        (field === REPORT_FIELDS["FUNCTION_NAME"] ||
          field === REPORT_FIELDS["P_FUNCTION_NAME"] ||
          field === REPORT_FIELDS["TYPE_FUNCTION_NAME"] ||
          field === REPORT_FIELDS["P_TYPE_FUNCTION_NAME"]) &&
        functionsData;

      const lifecycleStatusesSelector =
        field === REPORT_FIELDS["LIFECYCLE_STATUS"];

      if (functionCategoriesSelector) {
        options = FUNCTION_CATEGORIES;
      }

      if (functionNamesSelector) {
        options = functionsData.map((functionData) => functionData.name);
      }

      if (lifecycleStatusesSelector) {
        options = LIFECYCLE_STATUSES;
      }

      const autocompleteValue = value ? value.split(",") : [];

      return (
        <CustomValueEditorAutocomplete
          fullWidth
          multiple
          id="value"
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) =>
            functionCategoriesSelector || lifecycleStatusesSelector
              ? getTranslation(option, t, i18n)
              : option
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <QueryCheckboxMobile
                icon={getSvgIcon(
                  "CHECKBOX",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}
                checkedIcon={getSvgIcon(
                  "CHECKBOX_DONE",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}
                checked={selected}
              />

              {functionCategoriesSelector || lifecycleStatusesSelector
                ? getTranslation(option, t, i18n)
                : option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={getTranslation("CUSTOM_VALUE_EDITOR_LABEL", t, i18n)}
            />
          )}
          value={autocompleteValue}
          onChange={handleAutocompleteChange}
        />
      );
    }

    if (handleIsDateField()) {
      return (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={datePickerFormat(user.region)}
        >
          <CustomValueEditorDatePicker
            id="value"
            data-testid="value"
            label={getTranslation("CUSTOM_VALUE_EDITOR_LABEL", t, i18n)}
            value={dayjs(new Date(value))}
            onChange={handleDatePickerChange}
            fullWidth
          />
        </LocalizationProvider>
      );
    }

    return (
      <CustomValueEditorTextField
        id="value"
        name="value"
        label={getTranslation("CUSTOM_VALUE_EDITOR_LABEL", t, i18n)}
        type={isNumberField ? "number" : "text"}
        fullWidth
        value={value}
        onChange={handleTextFieldChange}
        error={!Boolean(value)}
      />
    );
  };

  return <>{renderCustomValueEditor()}</>;
};

export default RuleValueEditor;
