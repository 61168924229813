import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { ErrorModalContentContainer } from "./styles/general/General.styles";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../util/utils";
import { Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectErrorMessage,
  selectGlobalFontSize,
  setErrorMessage,
} from "../store/slices/appSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSvgIcon } from "../util/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ErrorModal() {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  // Selectors
  const errorMessage = useSelector(selectErrorMessage);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Handlers
  const handleNavigateHome = () => {
    navigate("/");
    window.location.reload();
  };

  // Other variables
  const iconSize = globalFontSize * 3;

  // Effects
  useEffect(() => {
    return () => {
      dispatch(setErrorMessage(null));
    };
  }, []);

  return (
    <div>
      <Modal
        hideBackdrop={false}
        open={true}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorModalContentContainer spacing={2}>
            {getSvgIcon(
              "WARNING",
              iconSize,
              iconSize,
              theme.palette.error.main,
              {
                color: theme.palette.primary.contrastText,
              }
            )}

            <Typography data-testid="error-message" variant="body1">
              {getTranslation("ERROR_OCCURRED", t, i18n)}
            </Typography>
            {errorMessage && (
              <Typography
                data-testid="error-message"
                variant="caption"
                color="error"
              >
                {`Description: ${getTranslation(errorMessage, t, i18n)}`}
              </Typography>
            )}
            <Button variant="contained" onClick={handleNavigateHome}>
              {getTranslation("HOME", t, i18n)}
            </Button>
          </ErrorModalContentContainer>
        </Box>
      </Modal>
    </div>
  );
}
