import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { ListItemContainer } from "../../styles/assets/asset-detail/AssetDetail.styles";
import {
  PrimaryText,
  SecondaryText,
  SectionTitle,
} from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { useRef, useState, useEffect } from "react";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { getSvgIcon } from "../../../util/icons";

const AssetDetailTypeInfo = ({ resourceData }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // States
  const [borderWidth, setBorderWidth] = useState("25%");

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  const {
    type: { name, displayId, description },
  } = resourceData;

  const isTablet = tabletMatches || desktopMatches ? 1 : 0;

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <div id="type-details">
      <StyledAccordion currentTheme={currentTheme} defaultExpanded>
        <StyledAccordionSummary
          value={borderWidth}
          expandIcon={
            <div style={{ marginTop: "5px", marginRight: "5px" }} ref={iconRef}>
              {getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <SectionTitle variant="body2" istablet={1} ref={textRef}>
            {getTranslation("TYPE", t, i18n)}
          </SectionTitle>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <ListItemContainer istablet={isTablet}>
                <SecondaryText variant="body2" id="type-name-label">
                  {getTranslation("NAME", t, i18n)}
                </SecondaryText>
                <PrimaryText id="type-name">{name}</PrimaryText>
              </ListItemContainer>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ListItemContainer istablet={isTablet}>
                <SecondaryText variant="body2" id="type-display-id-label">
                  {getTranslation("DISPLAY_ID", t, i18n)}
                </SecondaryText>
                <PrimaryText id="type-display-id">{displayId}</PrimaryText>
              </ListItemContainer>
            </Grid>

            {description && (
              <Grid item xs={12} sm={6}>
                <ListItemContainer istablet={isTablet}>
                  <SecondaryText variant="body2" id="type-description-label">
                    {getTranslation("NOTES", t, i18n)}
                  </SecondaryText>
                  <PrimaryText id="type-description">{description}</PrimaryText>
                </ListItemContainer>
              </Grid>
            )}
          </Grid>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default AssetDetailTypeInfo;
