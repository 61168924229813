import { createSlice } from "@reduxjs/toolkit";

// Default values for initial state
export const DEFAULT_USER_DATA = {
  firstName: "",
  lastName: "",
  language: "en",
  region: "us",
  timeZone: null,
  dataStorageLocationId: "1",
  selectedFile: null,
  file: null,
  nickname: "",
};

export const DEFAULT_ORGANIZATION_DATA = {
  organizationName: "",
  contactPhone: "",
  subscription: "",
  subscriptionFee: "",
  dataStorageLocationId: "1",
  billingAddress: "",
  vatNumber: "",
  contactEmail: "",
  contactPerson: "",
  nickname: "",
  addressLine1: "",
  addressLine2: "",
  postcode: "",
  city: "",
  state: "",
  country: "",
  selectedFile: null,
  file: null,
  checked: false,
};

export const DEFAULT_USER_ONBOARDING_STEPS = 2; // Total steps + 1
export const DEFAULT_ORGANIZATION_ONBOARDING_STEPS = 3; // Total steps + 1

const DEFAULT_STEP_QUANTITY = 4;
const DEFAULT_CONTINUE_BTN_NAME = "continueButton";

// Initial state
const initialState = {
  userData: DEFAULT_USER_DATA,
  organizationData: DEFAULT_ORGANIZATION_DATA,
  stepQuantity: DEFAULT_STEP_QUANTITY,
  continueBtnName: DEFAULT_CONTINUE_BTN_NAME,
  loading: true,
  userOnboardingSteps: DEFAULT_USER_ONBOARDING_STEPS,
  organizationOnboardingSteps: DEFAULT_ORGANIZATION_ONBOARDING_STEPS,
};

// Slice
const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const newUserData = action.payload;
      state.userData = newUserData;
    },
    setOrganizationData: (state, action) => {
      const newOrganizationData = action.payload;
      state.organizationData = newOrganizationData;
    },
    setOrganizationDataCountry: (state, action) => {
      const newCountry = action.payload;
      state.organizationData.country = newCountry;
    },
    setStepQuantity: (state, action) => {
      const newStepQuantity = action.payload;
      state.stepQuantity = newStepQuantity;
    },
    setContinueBtnName: (state, action) => {
      const newContinueBtnName = action.payload;
      state.continueBtnName = newContinueBtnName;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserOnboardingSteps: (state, action) => {
      const newSteps = action.payload;
      state.userOnboardingSteps = newSteps;
    },
    setOrganizationOnboardingSteps: (state, action) => {
      const newSteps = action.payload;
      state.organizationOnboardingSteps = newSteps;
    },
  },
});

// Export slice and its corresponding actions
export default onboardingSlice.reducer;

export const {
  setUserData,
  setOrganizationData,
  setOrganizationDataCountry,
  setStepQuantity,
  setContinueBtnName,
  setLoading,
  setUserOnboardingSteps,
  setOrganizationOnboardingSteps,
} = onboardingSlice.actions;

// Selectors
export const selectUserData = (state) => state.onboarding.userData;
export const selectOrganizationData = (state) =>
  state.onboarding.organizationData;
export const selectStepQuantity = (state) => state.onboarding.stepQuantity;
export const selectContinueBtnName = (state) =>
  state.onboarding.continueBtnName;
export const selectLoading = (state) => state.onboarding.loading;
export const selectUserOnboardingSteps = (state) =>
  state.onboarding.userOnboardingSteps;
export const selectOrganizationOnboardingSteps = (state) =>
  state.onboarding.organizationOnboardingSteps;
