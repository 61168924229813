import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import {
  ALLOWED_EXTENSIONS,
  DEFAULT_IMAGE_SIZE,
  getNormalizedFile,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
} from "../util/utils";
import {
  UploadButton,
  UploadImageContainer,
} from "./styles/profile/UploadImage.styles";
import CropEasy from "./CropEasy";
import { messageError } from "../util/notification";
import fileTypeChecker from "file-type-checker";
import { useCallback } from "react";
import { StyledProfileAvatar } from "./styles/general/General.styles";
import { useLocation } from "react-router-dom";
import ConfirmAlert from "../store/confirm/ConfirmAlert";
import ErrorHandling from "./common/ErrorHandling";
import { getSvgIcon } from "../util/icons";
import { useTheme } from "@mui/material";
import { selectGlobalFontSize } from "../store/slices/appSlice";

const UploadImage = ({
  initialFile,
  submitUploadedImage,
  isUserOnboarding,
  isOrganizationOnboarding,
  uploadTitle,
  checker,
  permissions,
  userRoles,
  isLoadingImage,
  deleteImage,
  disabled,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [selectedFile, setSelectedFile] = useState(
    initialFile ? initialFile : null
  );
  const [photoURL, setPhotoURL] = useState(initialFile ? initialFile : null);
  const [openCrop, setOpenCrop] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const readFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        getNormalizedFile(file)
          .then((normalizedFile) => reader.readAsDataURL(normalizedFile))
          .catch((error) => reject(error));
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;

    if (file) {
      if (file.size > DEFAULT_IMAGE_SIZE / 2) {
        messageError(getTranslation("TOO_LARGE_FILE_VALIDATION", t, i18n));
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const buffer = reader.result;
        const uintArray = new Uint8Array(buffer);

        const isImage = fileTypeChecker.validateFileType(
          uintArray,
          ALLOWED_EXTENSIONS
        );

        if (!isImage) {
          messageError(getTranslation("NOT_ALLOWED_FILE_TYPE", t, i18n));
          setPhotoURL(null);
          setOpenCrop(false);
        }
      };

      reader.readAsArrayBuffer(file);

      const url = await readFile(file);
      setPhotoURL(url);
      setOpenCrop(true);
    }
  };

  const handleAvatarClick = () => {
    if (pathname.includes("profile") && Boolean(selectedFile)) {
      setOpenConfirm(true);
    }
  };

  const handleConfirm = () => {
    deleteImage();
  };

  // Other variables
  const iconSize = globalFontSize * 2;

  // Effects
  useEffect(() => {
    setSelectedFile(initialFile);
  }, [initialFile]);

  useEffect(() => {
    setPhotoURL(initialFile);
  }, [initialFile]);

  return (
    <ErrorHandling isLoading={isLoadingImage} isError={false}>
      <UploadImageContainer>
        <ConfirmAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={{
            content: getTranslation("ASSET_IMAGE_DELETE_MESSAGE", t, i18n),
            confirmTitle: getTranslation("DELETE_ASSET_IMAGE", t, i18n),
            closeTitle: getTranslation("CANCEL", t, i18n),
            showConfirm: true,
          }}
          label="delete-image"
          handleConfirm={handleConfirm}
        />

        <CropEasy
          openCrop={openCrop}
          setOpenCrop={setOpenCrop}
          photoURL={photoURL}
          setPhotoURL={setPhotoURL}
          setSelectedFile={setSelectedFile}
          submitUploadedImage={submitUploadedImage}
          isUserOnboarding={isUserOnboarding}
          isOrganizationOnboarding={isOrganizationOnboarding}
        />

        <div>
          <StyledProfileAvatar
            onClick={handleAvatarClick}
            data-testid="profile-avatar"
            id="profile-avatar"
            alt={user.shortUsername}
            src={selectedFile}
          >
            {isUserOnboarding
              ? getSvgIcon(
                  "PROFILE",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.light
                )
              : getSvgIcon(
                  "IMAGE",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.light
                )}
          </StyledProfileAvatar>
        </div>

        {(permissions
          ? hasAccess(
              checker,
              permissions,
              getPermissionsFromUserRoles(userRoles)
            )
          : true) && (
          <UploadButton
            id="upload-image-button"
            data-testid="upload-image-button"
            aria-label="upload picture"
            component="label"
            src={selectedFile}
            disabled={disabled}
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              data-testid="upload-image-input"
            />
            {getTranslation(uploadTitle, t, i18n)}
          </UploadButton>
        )}
      </UploadImageContainer>
    </ErrorHandling>
  );
};

export default UploadImage;
