import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useGetResourceDetailsQuery,
  useGetFavoritesQuery,
  useAddRecentMutation,
  useGetAllFunctionsQuery,
} from "../../store/slices/api/assetManagementSlice";
import {
  DEFAULT_INDEX,
  selectGlobalFontSize,
  selectPageView,
  selectSortingOption,
  setCurrentresourceid,
  setIndex,
  setIsSearchOpen,
  setPageView,
} from "../../store/slices/appSlice";
import StickySearch from "../../components/assets/asset-list/StickySearch";
import { selectUser } from "../../store/slices/authSlice";
import { SecondaryText } from "../../components/styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import PageTransition from "../../components/PageTransition";
import { transitionDirections } from "../../util/utils";
import {
  CreateAssetButton,
  NoAssetsContainer,
} from "../../components/styles/assets/AssetList.styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ListColumnView from "../../components/assets/asset-list/ListColumnView";
import AssetListRenderer from "../../components/assets/asset-list/AssetListRenderer";
import MobileHeader from "../../components/assets/asset-list/MobileHeader";
import { selectItems } from "../../store/slices/assetListSlice";
import {
  setGlobalTags,
  setInitialTags,
  setTags,
} from "../../store/slices/tagsSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
} from "../../store/slices/api/tagsSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import AssetListDesktopHeader from "../../navigation/header/assets//desktop/AssetListDesktopHeader";
import CreateAsset from "../../components/assets/asset-form/CreateAsset";
import AssetListTabs from "../../components/assets/asset-list/AssetListTabs";
import CreateNewButton from "../../components/buttons/CreateNewButton";
import { DynamicPagePadding } from "../../components/styles/assets/asset-list/AssetList.styles";
import ErrorHandling from "../../components/common/ErrorHandling";
import CategoryMenu from "../../components/assets/asset-form/CategoryMenu";
import AssetListHeader from "../../navigation/header/assets/AssetListHeader";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";
import { getSvgIcon } from "../../util/icons";
import {
  selectDeletedResourceId,
  selectParentId,
} from "../../store/slices/columnViewSlice";

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const a11yAssetListTabProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const AssetList = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const { resourceid: paramResourceId } = useParams();
  const theme = useTheme();

  // States
  const [anchorEl, setAnchorEl] = useState(null);
  const [initializedCategory, setInitializedCategory] = useState(""); // Used state for desktop

  // Selectors
  const currentpageview = useSelector(selectPageView);
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const sortingOption = useSelector(selectSortingOption);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const items = useSelector(selectItems);
  const parentId = useSelector(selectParentId);
  const deletedResourceId = useSelector(selectDeletedResourceId);

  // Queries
  const {
    data: currentResourceData,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
    error: currentResourceDataError,
  } = useGetResourceDetailsQuery(
    {
      resourceid: paramResourceId,
      organizationId,
    },
    {
      skip: !Boolean(paramResourceId),
    }
  );

  const {
    data: favoritesData,
    isLoading: isLoadingFavorites,
    isError: isErrorFavorites,
  } = useGetFavoritesQuery({
    organizationId,
  });

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  // Other variables
  const calculatedResourceId =
    currentpageview === "column" ? parentId : paramResourceId;

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isError: isErrorResourceTags,
  } = useGetResourceTagsQuery(
    {
      resourceid: calculatedResourceId,
      organizationId,
    },
    {
      skip:
        !Boolean(calculatedResourceId) ||
        calculatedResourceId === deletedResourceId,
    }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [addRecent] = useAddRecentMutation();

  // State
  const [openDialog, setOpenDialog] = useState(false);

  // Other variables
  const direction = searchParams.get("direction");
  const queryPathname = searchParams.get("pathname");
  const tagName = searchParams.get("tagName");
  const tagId = searchParams.get("tagId");
  const queryResourceId = searchParams.get("resourceId");

  const from =
    currentResourceData && paramResourceId ? "resource-child" : "resources";

  const openCategoryMenu = Boolean(anchorEl);

  const currentResourceFunction = allFunctionsData?.find(
    (f) => f.id === currentResourceData?.functionId
  );

  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleOpenDialog = (category) => {
    setInitializedCategory(category);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const formatClickHandler = (value) => {
    dispatch(setPageView(value));
  };

  const handleNavigateToCreateAsset = (category) => {
    if (currentResourceData) {
      const { id } = currentResourceData;
      navigate(
        `/resources/add-new?resourceid=${id}&category=${category}&direction=${transitionDirections.BOTTOM_TO_TOP}`
      );
    } else {
      navigate(
        `/resources/add-new?category=${category}&direction=${transitionDirections.BOTTOM_TO_TOP}`
      );
    }
  };

  const handleAddToRecents = async () => {
    try {
      await addRecent({
        resourceid: paramResourceId,
        organizationId: user?.organizations?.find((o) => o.default).id,
      }).unwrap();
    } catch (error) {
      console.error("Failed to add to recents", error);
    }
  };

  const handleCreateAsset = (category) => {
    if (desktopMatches) {
      handleOpenDialog(category);
    } else {
      handleNavigateToCreateAsset(category);
    }
  };

  const handleOpenCategoryMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseCategoryMenu = () => {
    setAnchorEl(null);
  };

  // Effects
  useEffect(() => {
    dispatch(setIndex(DEFAULT_INDEX));
    dispatch(setIsSearchOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setIndex(DEFAULT_INDEX));
    dispatch(setCurrentresourceid(paramResourceId));

    if (paramResourceId) {
      handleAddToRecents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramResourceId]);

  useEffect(() => {
    if (currentpageview === "column" && pathname !== "/resources") {
      dispatch(setPageView("inline"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mobileMatches && currentpageview === "column") {
      dispatch(setPageView("inline"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileMatches, currentpageview]);

  useEffect(() => {
    dispatch(setIndex(DEFAULT_INDEX));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOption.value]);

  // Here, we will fetch the tags and put them into the Redux store (similar to resource images and attachments)
  useEffect(() => {
    if (tags) {
      const mappedTags = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags]);

  if (currentResourceDataError?.status === 404) {
    return <Navigate to="*" />;
  }

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={
            isLoadingCurrent ||
            isLoadingFavorites ||
            isLoadingResourceTags ||
            isLoadingFunctions ||
            isLoadingTags ||
            isLoadingUserRoles
          }
          isError={
            isErrorCurrent ||
            isErrorFavorites ||
            isErrorFunctions ||
            isErrorResourceTags ||
            isErrorTags ||
            isErrorUserRoles
          }
        >
          <PageTransition direction={direction}>
            <CategoryMenu
              open={openCategoryMenu}
              parentCategory={currentResourceFunction?.category}
              handleClose={handleCloseCategoryMenu}
              categoryActionHandler={handleCreateAsset}
              anchorEl={anchorEl}
            />
            {mobileMatches ? (
              <AssetListHeader
                actionAddFunction={handleNavigateToCreateAsset}
                userRoles={userRoles}
                currentResourceData={currentResourceData}
                resourceid={paramResourceId}
                favorites={favoritesData}
                queryPathname={queryPathname}
                tagName={tagName}
                from={from}
                tagId={tagId}
                queryResourceId={queryResourceId}
              />
            ) : (
              <AssetListDesktopHeader
                resourceData={currentResourceData}
                paramResourceId={paramResourceId}
                queryPathname={queryPathname}
                tagId={tagId}
                tagName={tagName}
                from={from}
                favoritesData={favoritesData?.data}
                queryResourceId={queryResourceId}
              />
            )}
            <DynamicPagePadding
              iscolumn={currentpageview === "column"}
              mobileMatches={mobileMatches}
            >
              {currentResourceData && paramResourceId ? (
                <>
                  {mobileMatches ? (
                    <MobileHeader resourceid={paramResourceId} />
                  ) : (
                    <AssetListTabs
                      resourceId={paramResourceId}
                      currentResourceData={currentResourceData}
                    />
                  )}
                </>
              ) : (
                <>
                  {mobileMatches && (
                    <StickySearch
                      currentpageview={currentpageview}
                      formatClickHandler={formatClickHandler}
                      from="resources"
                    />
                  )}

                  {currentpageview === "column" ? (
                    <ListColumnView favorites={favoritesData?.data} />
                  ) : (
                    <>
                      <AssetListRenderer resourceid={paramResourceId} />

                      {items?.length > 0 ? (
                        !mobileMatches && (
                          <CreateNewButton
                            handler={handleOpenCategoryMenu}
                            userRoles={userRoles}
                            name="CREATE_NEW_ASSET"
                          />
                        )
                      ) : (
                        <NoAssetsContainer resourceid={paramResourceId}>
                          <SecondaryText align="center">
                            {getTranslation("NO_ASSETS", t, i18n)}
                          </SecondaryText>
                          <CreateAssetButton
                            disabled={
                              !hasAccess(
                                "all",
                                [
                                  permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                                  permissions.ASSET_MANAGEMENT_LOCATION_ADD,
                                  permissions.ASSET_MANAGEMENT_RACK_ADD,
                                ],
                                getPermissionsFromUserRoles(userRoles)
                              )
                            }
                            onClick={handleOpenCategoryMenu}
                          >
                            <div
                              style={{ marginRight: "5px", marginTop: "5px" }}
                            >
                              {getSvgIcon(
                                "CREATE_NEW",
                                iconSize,
                                iconSize,
                                theme.palette.primary.contrastText
                              )}
                            </div>

                            {getTranslation("CREATE_FIRST_ASSET", t, i18n)}
                          </CreateAssetButton>
                        </NoAssetsContainer>
                      )}

                      {openDialog && (
                        <CreateAsset
                          initializedParentId={paramResourceId}
                          initializedCategory={initializedCategory}
                          open={openDialog}
                          handleClose={handleCloseDialog}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </DynamicPagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default AssetList;
