import React from "react";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../../store/slices/appSlice";
import { getTranslation } from "../../../../util/utils";
import { useTranslation } from "react-i18next";
import {
  HeaderContainer,
  NavigationOptionsContainer,
  PageTitle,
} from "../../../../components/styles/header/assets/desktop/AssetListDesktopHeader.styles";
import { IconButton, useTheme } from "@mui/material";
import { getSvgIcon } from "../../../../util/icons";

const RecentsDesktopHeader = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.5;

  return (
    <HeaderContainer variant="elevation">
      <NavigationOptionsContainer>
        <IconButton disabled={true} id="back-column-view">
          {getSvgIcon(
            "ARROW_LEFT",
            iconSize,
            iconSize,
            theme.palette.action.disabled
          )}
        </IconButton>

        <IconButton disabled={true} id="forward-column-view">
          {getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            theme.palette.action.disabled
          )}
        </IconButton>
        <PageTitle variant="h5">
          {getTranslation("LAST_USED", t, i18n)}
        </PageTitle>
      </NavigationOptionsContainer>
    </HeaderContainer>
  );
};

export default RecentsDesktopHeader;
