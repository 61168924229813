import { Box, styled } from "@mui/material";

export const OptionsMenuContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  width: "fit-content",
  bgcolor: theme.palette.primary.contrastText,
  color: "text.secondary",
  gap: "8px",
  flexGrow: 1,
}));

export const SearchContainer = styled(Box)({
  marginTop: "16px",
});
