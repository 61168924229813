import React from "react";
import { Grid, List, useMediaQuery, useTheme } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Item,
  ItemContainer,
  ItemName,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  setIsSearchOpen,
} from "../../../store/slices/appSlice";
import AssetListTable from "./AssetListTable";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { clearHistory } from "../../../store/slices/assetListSlice";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { useTranslation } from "react-i18next";
import { NoDataTextContainer } from "../../styles/general/General.styles";
import ListInlineViewItem from "./ListInlineViewItem";
import { getSvgIcon } from "../../../util/icons";

const ListInlineView = ({
  list,
  fetchMoreData,
  resourcesData,
  index,
  isHome,
  pageSize,
}) => {
  // General Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const { data: allFunctionsData, isError } = useGetAllFunctionsQuery(
    {
      organizationId,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  // Other variables
  const tagName = searchParams.get("tagName");
  const from = searchParams.get("from");
  const tagId = searchParams.get("tagId");
  const resourceId = searchParams.get("resourceId");

  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleNavigate = (row) => {
    navigate(
      `/resources/${row?.id}?direction=${
        transitionDirections.RIGHT_TO_LEFT
      }&pathname=${pathname}${Boolean(tagName) ? `&tagName=${tagName}` : ""}${
        Boolean(from) ? `&from=${from}` : ""
      }${Boolean(tagId) ? `&tagId=${tagId}` : ""}${
        Boolean(resourceId) ? `&resourceId=${resourceId}` : ""
      }`
    );

    dispatch(setIsSearchOpen(false));
    dispatch(clearHistory());
  };

  return (
    <ErrorHandling isLoading={false} isError={isError}>
      <List id="assets-list">
        {isHome ? (
          <Grid
            container
            display="flex"
            justifyContent="start"
            rowGap={tabletMatches ? 2 : 0}
            columns={36}
          >
            {list?.map((row) => {
              const { id, functionId, displayId, name } = row;
              const combinationDisplayIdAndName = displayId || name;
              const resourceFunction = allFunctionsData?.find(
                (f) => f.id === functionId
              );

              return (
                <React.Fragment key={`resource-${id}`}>
                  <Grid item xs={0} sm={1}></Grid>
                  <ItemContainer item xs={36} sm={10}>
                    <Item
                      onClick={() => {
                        navigate(
                          `/resources/${id}?direction=${transitionDirections.RIGHT_TO_LEFT}`
                        );
                      }}
                    >
                      <ListItemIcon>
                        {getSvgIcon(
                          resourceFunction?.name?.toUpperCase(),
                          iconSize,
                          iconSize,
                          theme.palette.secondary.light
                        )}
                      </ListItemIcon>
                      <ItemName>{combinationDisplayIdAndName}</ItemName>
                      {getSvgIcon(
                        "ARROW_RIGHT",
                        iconSize,
                        iconSize,
                        theme.palette.primary.main
                      )}
                    </Item>
                  </ItemContainer>
                  <Grid item xs={0} sm={1}></Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        ) : (
          list?.length > 0 && (
            <InfiniteScroll
              dataLength={list?.length}
              next={fetchMoreData}
              hasMore={index < Math.ceil(resourcesData?.totalRecord / pageSize)}
              loader={
                list?.length > 0 ? (
                  <InfiniteScrollLoader />
                ) : (
                  <NoDataTextContainer>
                    <SecondaryText align="center">
                      {getTranslation("NO_RESULTS_FOUND", t, i18n)}
                    </SecondaryText>
                  </NoDataTextContainer>
                )
              }
            >
              {!mobileMatches ? (
                <AssetListTable rows={list} />
              ) : (
                <Grid
                  container
                  display="flex"
                  justifyContent="start"
                  columns={36}
                >
                  {list?.map((row) => (
                    <ListInlineViewItem
                      key={`resource-${row?.id}`}
                      row={row}
                      handleNavigate={handleNavigate}
                    />
                  ))}
                </Grid>
              )}
              <div id="end"></div>
            </InfiniteScroll>
          )
        )}
      </List>
    </ErrorHandling>
  );
};

export default ListInlineView;
