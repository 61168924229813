import {
  BackTitle,
  HeaderWrapper,
  LeftActionButton,
  PageTitle,
} from "../../../components/styles/header/Header.styles";
import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../../util/utils";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useDispatch } from "react-redux";
import { setMobileDialogOpen } from "../../../store/slices/reportsSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import ErrorHandling from "../../../components/common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";

const ReportsHeader = ({ goBackHandler }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.5;

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId: user?.organizations?.find((o) => o.default)?.id,
    });

  // Handlers
  const handleCreateReport = () => {
    dispatch(setMobileDialogOpen(true));
  };

  return (
    <ErrorHandling isLoading={isLoadingUserRoles} isError={false}>
      <HeaderWrapper id="sticky-header">
        <Grid
          container
          sx={{
            display: "flex",
            padding: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={3}>
            <LeftActionButton id="back-button" onClick={goBackHandler}>
              {getSvgIcon(
                "ARROW_LEFT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
              <BackTitle component="span" id="previous_page-title">
                {getTranslation("MORE", t, i18n)}
              </BackTitle>
            </LeftActionButton>
          </Grid>

          <Grid item xs={6}>
            <PageTitle variant="h6" id="title">
              {getTranslation("REPORTS", t, i18n)}
            </PageTitle>
          </Grid>

          <Grid display="flex" justifyContent="end" item xs={3}>
            {hasAccess(
              "all",
              [permissions["REPORTING_USER_DEFINED_CREATE"]],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <div onClick={handleCreateReport}>
                {getSvgIcon(
                  "CREATE_NEW",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </HeaderWrapper>
    </ErrorHandling>
  );
};

export default ReportsHeader;
