import { styled } from "@mui/system";
import { alpha } from "@mui/material";
import { Item } from "../../assets/ListInlineView.styles";
import { Grid } from "@mui/material";
import { THEME } from "../../../../util/utils";

export const TypeItemContainer = styled(Item)(
  ({ theme, selected, lastindex, longPressed, currentTheme }) => ({
    position: longPressed ? "relative" : "static",
    zIndex: longPressed ? 1200 : "auto",
    backgroundColor: longPressed
      ? currentTheme === THEME.LIGHT
        ? "#FFF"
        : "#222222"
      : selected
      ? alpha("#0076bc", 0.1)
      : "",
    ":hover": {
      backgroundColor: selected ? alpha("#0076bc", 0.2) : alpha("#000", 0.04),
    },
    borderBottom: !lastindex ? `1px solid ${theme.palette.divider}` : "",
  })
);

export const TypeNameGridContainer = styled(Grid)({
  overflow: "hidden",
});
