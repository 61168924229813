import {
  Box,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchFieldContainer,
  StickySearchTextField,
} from "../../../components/styles/assets/StickySearch.styles";
import {
  BackInfoContainer,
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
} from "../../../components/styles/header/Header.styles";
import {
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectSearchTerm,
  setIsSearchOpen,
  setSearchTerm,
  setShouldSearch,
} from "../../../store/slices/appSlice";
import { getTranslation, transitionDirections } from "../../../util/utils";
import OptionsMenu from "../../options-menu/OptionsMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { messageError } from "../../../util/notification";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { OptionsMenuContainer } from "../../../components/styles/header/assets/AssetListHeader.styles";
import { getSvgIcon } from "../../../util/icons";

const TagsFilterHeader = ({ resourceData, tagId, tagName }) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const searchTerm = useSelector(selectSearchTerm);
  const isSearchOpen = useSelector(selectIsSearchOpen);

  // Handlers
  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchTerm?.length < 3) {
        messageError(getTranslation("SEARCH_TERM_TOO_SHORT", t, i18n));
      } else {
        dispatch(setShouldSearch(true));

        navigate(
          `/search?from=tags-filter&tagId=${tagId}${
            tagName ? `&tagName=${tagName}` : ""
          }&resourceId=${resourceData.id}&direction=${
            transitionDirections.LEFT_TO_RIGHT
          }`
        );

        dispatch(setIsSearchOpen(false));
      }
    } else {
      dispatch(setIsSearchOpen(true));
    }
  };

  const handleClearSearch = () => {
    dispatch(setIsSearchOpen(false));
    dispatch(setSearchTerm(""));
    dispatch(setShouldSearch(false));

    if (pathname.includes("search")) {
      navigate(
        `/tags-filter/${resourceData?.id}/${tagId}?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(setSearchTerm(e.target.value));
    dispatch(setShouldSearch(false));
  };

  const onBackToClickHandler = (back) => {
    navigate(back);
  };

  const handleGetBackInfo = () => {
    if (Boolean(resourceData)) {
      const { id, name, displayId } = resourceData;
      const backName = displayId || name;

      const back = `/resources/${id}/details?direction=${transitionDirections.LEFT_TO_RIGHT}`;

      return {
        back,
        backName,
      };
    } else {
      return null;
    }
  };

  // Other variables
  const iconSize = globalFontSize * 1.5;

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer>
        <BackInfoContainer>
          <LeftActionButton
            id="back-button"
            onClick={() => onBackToClickHandler(handleGetBackInfo().back)}
          >
            {getSvgIcon(
              "ARROW_LEFT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
            <BackTitle component="span" id="previous_page-title">
              {handleGetBackInfo().backName}
            </BackTitle>
          </LeftActionButton>
        </BackInfoContainer>
        <Box>
          {tabletMatches && (
            <OptionsMenuContainer>
              <OptionsMenu />
              {isSearchOpen ? (
                <SearchFieldContainer>
                  <StickySearchTextField
                    inputProps={{ "data-testid": "search-field" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton id="search" onClick={handleSearchClick}>
                            {getSvgIcon(
                              "SEARCH",
                              iconSize,
                              iconSize,
                              theme.palette.secondary.contrastText
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch} id="clear">
                            {getSvgIcon(
                              "CLEAR",
                              iconSize,
                              iconSize,
                              theme.palette.secondary.contrastText
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="search-field"
                    variant="outlined"
                    placeholder={getTranslation("SEARCH_TERM", t, i18n)}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </SearchFieldContainer>
              ) : (
                <IconButton id="search" onClick={handleSearchClick}>
                  {getSvgIcon(
                    "SEARCH",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </IconButton>
              )}
            </OptionsMenuContainer>
          )}
        </Box>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default TagsFilterHeader;
