import { forwardRef } from "react";
import { useTheme } from "@mui/material";
import { getSvgIcon } from "../../../util/icons";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const CustomDragHandle = forwardRef((props, ref) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize;

  return (
    <span ref={ref} {...props}>
      {getSvgIcon("DRAG_POINT", iconSize, iconSize, theme.palette.primary.main)}
    </span>
  );
});

export default CustomDragHandle;
