import React from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_CATEGORIES, getTranslation } from "../../../util/utils";
import { Menu } from "@mui/material";
import { SecondaryContrastMenuItem } from "../../styles/assets/asset-form/CategoryMenu.styles";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import { ALLOWED_CHILDREN_CATEGORIES } from "../../../util/asset-utils";

const CategoryMenu = ({
  open,
  anchorEl,
  handleClose,
  parentCategory = "",
  categoryActionHandler,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Handlers
  const handleOption = (category) => {
    handleClose();
    categoryActionHandler(category);
  };

  // Other variables
  const allowedChildrenCategories = ALLOWED_CHILDREN_CATEGORIES[parentCategory];

  return (
    <Menu
      slotProps={{
        paper: {
          elevation: 1,
          sx: {
            borderRadius: "8px",
          },
        },
      }}
      id="menu-category-options"
      aria-labelledby="more-icon"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <SecondaryContrastMenuItem
        id="hardware-asset-item"
        disabled={
          !allowedChildrenCategories.some(
            (c) => c === RESOURCE_CATEGORIES.HARDWARE_ASSET
          )
        }
        onClick={() => handleOption(RESOURCE_CATEGORIES.HARDWARE_ASSET)}
      >
        {getTranslation("CREATE_HARDWARE_ASSET", t, i18n)}
      </SecondaryContrastMenuItem>
      <SecondaryContrastMenuItem
        id="location-item"
        disabled={
          !allowedChildrenCategories.some(
            (c) => c === RESOURCE_CATEGORIES.LOCATION
          )
        }
        onClick={() => handleOption(RESOURCE_CATEGORIES.LOCATION)}
      >
        {getTranslation("CREATE_LOCATION", t, i18n)}
      </SecondaryContrastMenuItem>
      <SecondaryContrastMenuItem
        id="rack-item"
        disabled={
          !allowedChildrenCategories.some((c) => c === RESOURCE_CATEGORIES.RACK)
        }
        onClick={() => handleOption(RESOURCE_CATEGORIES.RACK)}
      >
        {getTranslation("CREATE_RACK", t, i18n)}
      </SecondaryContrastMenuItem>
    </Menu>
  );
};

export default CategoryMenu;
