import { styled } from "@mui/system";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Switch,
  Typography,
  alpha,
  FormControlLabel,
  DialogActions,
  List,
  ListItemIcon,
} from "@mui/material";
import { StyledDivider } from "../profile/Profile.styles";
import { SecondaryText } from "../assets/asset-detail/AssetDetailBasicInfo.styles";
import { AssetDetailCardMedia } from "../assets/asset-detail/AssetDetailCard.styles";
import { ProfileAvatar } from "../profile/ProfileCardItm.styles";

export const HomePagePadding = styled(Box)({
  padding: "0 10px",
});

export const OnboardingPagePadding = styled(Box)({
  paddingRight: "32px",
  paddingLeft: "32px",
});

export const SectionSpacer = styled(Box)({
  marginBottom: "50px",
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    alignItems: "flex-end",
  },
  "& .MuiDialog-paper": {
    background: "transparent",
    boxShadow: "unset",
    marginInline: "7px",
    borderRadius: "15px",
  },
  "& .MuiDialogContent-root": {
    padding: "10px 0",
  },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  background: theme.palette.secondary.dark,
  borderRadius: "15px",
  overflowX: "hidden",
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: "center",
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: "bold",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const HandoverOwnershipTitle = styled(StyledDialogTitle)({
  textWrap: "wrap",
});

export const ResendInvitationButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  margin: "auto",
  marginBlock: "10px",
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

export const ChangeRoleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: "10px auto",
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

export const RemoveFromOrganizationButton = styled(Button)({
  margin: "10px auto",
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const CancelButton = styled(Button)(({ theme }) => ({
  margin: "auto",
  height: "50px",
  marginTop: "7px",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  borderRadius: "10px",
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.dark,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export const CloseIconButton = styled(IconButton)({
  outline: "none",
  border: "none",
  padding: 0,
  cursor: "pointer",
  opacity: "0.7",
  transition: " 0.3s ease",
  alignSelf: "center",
});

export const DarkDivider = styled(StyledDivider)(({ theme }) => ({
  borderColor: "#000000",
}));

export const DynamicContainer = styled(Box)({
  flex: "1 1 auto",
  overflow: "hidden",
});

export const StaticContainer = styled(Box)({
  flexShrink: 0,
});

export const CenteredErrorText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.primary.main,
}));

export const ErrorModalContentContainer = styled(Stack)({
  mx: 2,
  alignItems: "center",
});

export const CenteredSecondaryText = styled(SecondaryText)({
  textAlign: "center",
});
export const InfiniteScrollLoaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  margin: "auto",
});

export const GroupByResourceCategoryContainer = styled(Box)({
  marginTop: "20px",
});

export const ListGridViewContainer = styled(Box)({
  marginTop: "10px",
});

export const NoDataTextContainer = styled(Box)({
  marginTop: "150px",
});

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  "&.Mui-checked": {
    color: theme.palette.secondary.contrastText,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "24px !important",
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, disabled }) => ({
    "& .MuiCheckbox-root": {
      color: theme.palette.secondary.contrastText,
    },
    "& .MuiCheckbox-root.Mui-checked": {
      color: theme.palette.secondary.contrastText,
    },
    "& .MuiCheckbox-root.Mui-disabled": {
      color: alpha(theme.palette.secondary.contrastText, 0.5),
    },
    "& .MuiFormControlLabel-label": {
      color: theme.palette.secondary.main,
    },
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: alpha(theme.palette.secondary.main, 0.5),
    },
    "& .MuiSvgIcon-root": {
      fontSize: "24px !important",
    },
  })
);

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.secondary.contrastText,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}));

export const StyledRefreshButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const UpdateAlertContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100%",
});

export const UpdateAlertContentContainer = styled(Box)({
  flexGrow: 1,
});

export const UpdateAlertActionsContainer = styled(Box)({
  marginTop: "3px",
});

export const SecondaryContrastButton = styled(Button)(
  ({ theme, disabled, variant }) => ({
    color: disabled ? "grey" : theme.palette.secondary.contrastText,
    borderRadius: "4px",
    borderColor:
      variant === "outlined" ? theme.palette.secondary.contrastText : "",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor:
        variant === "outlined" ? theme.palette.secondary.contrastText : "",
    },
    backgroundColor: "transparent",
  })
);

export const DialogPaperProps = {
  sx: {
    borderRadius: "8px",
  },
};

export const CropperStyledDialogContent = styled(StyledDialogContent)({
  position: "relative",
  height: 400,
  width: "auto",
  minWidth: { sm: 500 },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "none",
});

export const CropperStyledDialogActions = styled(DialogActions)(
  ({ theme }) => ({
    flexDirection: "column",
    overflow: "hidden",
    paddingInline: "16px",

    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
    },
    marginBlock: "8px",
    color: theme.palette.secondary.contrastText,
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.contrastText,
  })
);

export const CropperActionsBoxContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
}));

export const StyledHeaderAssetImage = styled(AssetDetailCardMedia)({
  width: "100%",
});

export const OrganizationsMenuContainer = styled(Box)({
  display: "flex",
  justifyContent: "end",
});

export const OrganizationsMenuList = styled(List)({
  width: "100%",
});

export const StyledListItemIcon = styled(ListItemIcon)(
  ({ globalFontSize }) => ({
    "&.MuiListItemIcon-root svg": {
      fontSize: `${globalFontSize * 1.5}px !important`,
    },
    marginRight: "5px",
  })
);

export const StyledProfileAvatar = styled(ProfileAvatar)(({ theme }) => ({
  width: "75px",
  height: "75px",
  cursor: "pointer",
  backgroundColor: theme.palette.primary.contrastText,
  boxShadow: theme.shadows[3],
}));

export const StyledContainer = styled(Box)(
  ({ theme, leftspace, bottomspace }) => ({
    marginLeft: `${leftspace}px`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingBottom: `calc(8px + env(safe-area-inset-bottom, ${bottomspace}px))`,
    "@supports (padding-bottom: calc(8px + env(safe-area-inset-bottom, ${bottomspace}px)))":
      {
        paddingBottom: `${bottomspace}px`,
      },
  })
);

export const EditButton = styled(SecondaryContrastButton)({
  marginTop: "16px",
});

export const EditButtonWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "end",
});

export const CustomTabPanelContainer = styled(Box)({
  position: "relative",
  height: "100%",
});

export const SecondaryLightText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
}));
