import {
  MenuItemContainer,
  PreferenceItemName,
} from "./styles/profile/PreferenceItem.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import { getSvgIcon } from "../util/icons";
import { useTheme } from "@mui/material";

const PreferenceItem = ({ menuId, label, isChecked, handleClick }) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  return (
    <MenuItemContainer onClick={handleClick} id={menuId} data-testid={menuId}>
      {/* Name of the preference */}
      <PreferenceItemName variant="body1">{label}</PreferenceItemName>
      {isChecked &&
        getSvgIcon("CHECK", iconSize, iconSize, theme.palette.primary.main)}
    </MenuItemContainer>
  );
};

export default PreferenceItem;
