import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Group, Image, Rect, Text } from "react-konva";
import { updatePresent } from "../../../store/slices/undoRedoGraphicalViewSlice";
import { haveIntersection } from "../../../util/graphical-rack-view-utils";
import { closestInteger, getTranslation } from "../../../util/utils";
import {
  setCoordinates,
  setResource,
  setVisible,
} from "../../../store/slices/tooltipSlice";
import { TOOLTIP_WIDTH } from "./Tooltip";
import { setIsGraphicalViewLoading } from "../../../store/slices/assetListSlice";

const HardwareAssetCanvas = ({
  hardwareAsset,
  locked,
  graphicalObjects,
  newImagesInfo,
  patchGraphicalObject,
  organizationId,
  loadedAssets,
  setLoadedAssets,
  setGraphicalView,
  handlePrepareGraphicalRackViewInfo,
  handleDrawGraphicalRackView,
  // Calculated graphical rack view props
  rectangleHeight,
  usableAreaWidth,
  sideFrameWidth,
  rackUnits,
}) => {
  // Props
  const {
    rackUnits: slots,
    image,
    resource,
    width,
    x,
    graphicalView,
    startPosition,
  } = hardwareAsset;

  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Other variables
  const text = `${resource.displayId || resource.name} - ${
    graphicalView === "FRONT"
      ? getTranslation("GRAPHICAL_VIEW_FRONT", t, i18n)
      : getTranslation("REAR", t, i18n)
  }`;
  const height = slots * rectangleHeight;
  const y = (startPosition - slots + 1) * rectangleHeight;
  const graphicalObject = graphicalObjects?.find(
    (go) => go.resourceId === resource.id
  );

  // Handlers
  const haveSpace = (asset, imagesInfo, usableAreaWidth) => {
    let intersectedAssets = [];
    const filteredImagesInfo = imagesInfo.filter(
      (info) => info.resource.id !== asset.resource.id
    );

    const {
      rackUnits: assetRackUnits,
      realStartPosition: assetRealStartPosition,
      width: assetWidth,
    } = asset;

    let assetRange = [];

    for (
      let i = assetRealStartPosition;
      i < assetRealStartPosition + assetRackUnits;
      i++
    ) {
      assetRange.push(i);
    }

    for (const item of filteredImagesInfo) {
      const {
        rackUnits: currentAssetRackUnits,
        realStartPosition: currentAssetRealStartPosition,
      } = item;

      let currentAssetRange = [];

      for (
        let i = currentAssetRealStartPosition;
        i < currentAssetRealStartPosition + currentAssetRackUnits;
        i++
      ) {
        currentAssetRange.push(i);
      }

      const intersection = assetRange.filter(
        (current) => currentAssetRange.indexOf(current) !== -1
      );

      if (Boolean(intersection) && intersection.length > 0) {
        intersectedAssets.push(item);
      }
    }

    let combinedWidth = assetWidth;

    for (const intersectedAsset of intersectedAssets) {
      combinedWidth += intersectedAsset.width;
    }

    if (combinedWidth <= usableAreaWidth) {
      return { result: true };
    } else {
      return { result: false };
    }
  };

  const handleDragStart = (e) => {
    e.target.setAttrs({
      scaleX: 1,
      scaleY: 1,
    });
  };

  const handleDragMove = (e) => {
    handleOnMouseOut();
    const group = e.target;
    let targetRect = group;
    if (Boolean(e.target.children)) {
      targetRect = e.target.children[0];
    }

    const rect = group.getClientRect();
    const layer = group.getLayer();

    layer.children.forEach((element) => {
      let elementTargetRect = element;
      const elementRect = element.getClientRect();

      if (Boolean(element.children)) {
        elementTargetRect = element.children[0];
      }

      if (elementTargetRect === targetRect) {
        return;
      }

      if (haveIntersection(elementRect, rect)) {
        targetRect.stroke("red");
        elementTargetRect.stroke("red");

        targetRect.strokeWidth(10);
        elementTargetRect.strokeWidth(10);
      } else {
        targetRect.stroke("#000");
        elementTargetRect.stroke("#000");

        targetRect.strokeWidth(1);
        elementTargetRect.strokeWidth(1);
      }
    });
  };

  const handleDragEnd = async (
    e,
    assetRackUnits,
    rackUnits,
    graphicalObject,
    x,
    initialY,
    imageInfo,
    imagesInfo,
    usableAreaWidth,
    rectangleHeight
  ) => {
    let group = e.target;
    let targetRect = group;
    if (Boolean(e.target.children)) {
      targetRect = e.target.children[0];
    }

    let rect = group.getClientRect();
    let layer = group.getLayer();

    // Start position calculation
    const roundedY = Math.round(group.y());
    const yClosestInteger = closestInteger(roundedY, rectangleHeight);
    const rackHeight = (rackUnits + 1) * rectangleHeight;

    // Handles if assets goes out of borders
    const y = Math.max(
      rectangleHeight,
      Math.min(yClosestInteger, rackHeight - assetRackUnits * rectangleHeight)
    );

    const invertedRackUnitPosition = y / rectangleHeight + assetRackUnits - 1;
    const newRackUnitPosition = rackUnits - invertedRackUnitPosition + 1;
    const updatedAsset = {
      ...imageInfo,
      realStartPosition: newRackUnitPosition,
      startPosition: invertedRackUnitPosition,
    };

    layer.children.forEach((element) => {
      let elementTargetRect = element;
      if (Boolean(element.children)) {
        elementTargetRect = element.children[0];
      }

      if (elementTargetRect === targetRect) {
        return;
      }

      const elementRect = element.getClientRect();

      if (haveIntersection(elementRect, rect)) {
        targetRect.stroke("#000");
        elementTargetRect.stroke("#000");

        targetRect.strokeWidth(1);
        elementTargetRect.strokeWidth(1);
      }
    });

    const { result } = haveSpace(updatedAsset, imagesInfo, usableAreaWidth);

    if (!result || imageInfo.realStartPosition === newRackUnitPosition) {
      group.setAttrs({
        scaleX: 1,
        scaleY: 1,
      });
      group.x(x);
      group.y(initialY);
      layer.batchDraw();

      return;
    }

    group.x(x);
    group.y(y);
    layer.batchDraw();

    try {
      const graphicalObjectDto = {
        yCoordinate: Number(newRackUnitPosition),
      };

      await patchGraphicalObject({
        organizationId,
        graphicalObjectId: graphicalObject?.id,
        graphicalObjectDto,
      }).unwrap();

      dispatch(
        updatePresent({
          action: "UPDATE",
          payload: [graphicalObject],
        })
      );

      if (
        imageInfo.image &&
        !loadedAssets.some(
          (asset) => asset.resource.id === imageInfo.resource.id
        )
      ) {
        setLoadedAssets([...loadedAssets, imageInfo]);
      }

      let latestImagesInfo = imagesInfo.filter(
        (i) => i.resource.id !== updatedAsset.resource.id
      );
      latestImagesInfo.push({ ...updatedAsset });

      const { newImagesInfo: preparedNewImagesInfo } =
        await handlePrepareGraphicalRackViewInfo(latestImagesInfo);

      const graphicalViewResult = await handleDrawGraphicalRackView({
        newImagesInfo: preparedNewImagesInfo,
      });

      setGraphicalView(graphicalViewResult);

      dispatch(setIsGraphicalViewLoading(true));
    } catch (error) {
      console.error("Failed to update a resource", error);
    }
  };

  const handleClick = (e) => {
    if (!locked) {
      return;
    }

    const stage = e.target.getStage();
    const point = stage.getRelativePointerPosition();
    dispatch(setResource(resource));
    let newX = point.x + 15;
    let newY = point.y;

    dispatch(
      setCoordinates({
        x:
          Math.min(newX + TOOLTIP_WIDTH, usableAreaWidth + sideFrameWidth) -
          TOOLTIP_WIDTH,
        y: newY,
      })
    );
    dispatch(setVisible(true));
  };

  const handleOnMouseEnter = () => {
    if (!locked) {
      return;
    }

    dispatch(setResource(resource));
  };

  const handleOnMouseOut = () => {
    if (!locked) {
      return;
    }

    dispatch(setVisible(false));
  };

  const handleOnMouseMove = (e) => {
    if (!locked) {
      return;
    }

    const stage = e.target.getStage();
    const point = stage.getRelativePointerPosition();

    dispatch(
      setCoordinates({
        x: point.x + 15,
        y: point.y + 15,
      })
    );
    dispatch(setVisible(true));
  };

  return (
    <>
      {image ? (
        <Image
          x={x}
          y={y}
          height={height}
          width={width}
          image={image}
          draggable={!locked}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragEnd={(e) => {
            handleDragEnd(
              e,
              slots,
              rackUnits,
              graphicalObject,
              x,
              y,
              hardwareAsset,
              newImagesInfo,
              usableAreaWidth,
              rectangleHeight,
              sideFrameWidth
            );
          }}
          onClick={handleClick}
          onTap={handleClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseOut={handleOnMouseOut}
          onMouseMove={handleOnMouseMove}
          stroke="#000"
        />
      ) : (
        <Group
          name="non-image-object"
          draggable={!locked}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragEnd={(e) => {
            handleDragEnd(
              e,
              slots,
              rackUnits,
              graphicalObject,
              x,
              y,
              hardwareAsset,
              newImagesInfo,
              usableAreaWidth,
              rectangleHeight,
              sideFrameWidth
            );
          }}
          x={x}
          y={y}
          onClick={handleClick}
          onTap={handleClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseOut={handleOnMouseOut}
          onMouseMove={handleOnMouseMove}
        >
          <Rect
            height={height}
            width={width}
            fillLinearGradientStartPoint={{ x: 0, y: 0 }}
            fillLinearGradientEndPoint={{
              x: width,
              y: slots * rectangleHeight,
            }}
            fillLinearGradientColorStops={[
              0,
              "lightgray",
              0.5,
              "white",
              1,
              "lightgray",
            ]}
            stroke={"#000"}
          />
          <Text
            text={text}
            fontSize={12}
            fill="black"
            wrap="none"
            ellipsis={true}
            width={width}
            height={height}
            verticalAlign="middle"
            align="center"
          />
        </Group>
      )}
    </>
  );
};

export default HardwareAssetCanvas;
