import { useSelector } from "react-redux";
import {
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from "../../components/styles/navigation/drawer/DrawerMenuItem.styles";
import { selectGlobalFontSize, selectTheme } from "../../store/slices/appSlice";
import { useTranslation } from "react-i18next";
import { getTranslation, THEME } from "../../util/utils";
import { Tooltip, useTheme } from "@mui/material";
import { getSvgIcon } from "../../util/icons";

const DrawerMenuItem = ({ handleNavigate, isDrawerOpen, active, menu }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // Other variables
  const { name, icon } = menu;
  const iconSize = globalFontSize * 1.5;
  const nonActiveColor =
    currentTheme === THEME.LIGHT ? "#767676" : "rgba(255, 255, 255, 0.7)";

  return (
    <Tooltip
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      title={getTranslation(name, t, i18n)}
      placement="right"
    >
      <StyledListItem active={active} onClick={handleNavigate} disablePadding>
        <StyledListItemButton isdraweropen={isDrawerOpen}>
          <StyledListItemIcon isdraweropen={isDrawerOpen}>
            {getSvgIcon(
              icon,
              iconSize,
              iconSize,
              active ? theme.palette.secondary.contrastText : nonActiveColor
            )}
          </StyledListItemIcon>
          <StyledListItemText
            active={active}
            isdraweropen={isDrawerOpen}
            primary={getTranslation(name, t, i18n)}
          />
        </StyledListItemButton>
      </StyledListItem>
    </Tooltip>
  );
};

export default DrawerMenuItem;
