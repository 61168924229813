import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useAddTypeRecentMutation,
  useGetTypeDetailsQuery,
} from "../../../store/slices/api/typesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import DesktopTypeCharacteristicGroup from "./DesktopTypeCharacteristicGroup";
import { Box } from "@mui/material";
import DesktopTypeDetailsList from "./DesktopTypeDetailsList";
import DesktopTypeDetailsHeader from "./DesktopTypeDetailsHeader";
import ErrorHandling from "../../common/ErrorHandling";

const DesktopTypeDetails = ({ typeId, isRecent }) => {
  //Selectors
  const user = useSelector(selectUser);
  const userOrganizationId = user?.organizations?.find((o) => o.default)?.id;

  // Mutations
  const [addRecent] = useAddTypeRecentMutation();

  // Queries
  const { data, isLoading, isError } = useGetTypeDetailsQuery(
    {
      typeId,
      organizationId: userOrganizationId,
    },
    { skip: !typeId }
  );

  // Other variables
  const { organizationId, name, functionId, characteristics } = data ?? {};

  const handleAddRecents = async () => {
    try {
      if (typeId) {
        await addRecent({
          organizationId: userOrganizationId,
          typeId,
        }).unwrap();
      }
    } catch (error) {
      console.error("Failed to add to recents", error);
    }
  };

  useEffect(() => {
    if (isRecent) return;

    if (typeId) {
      handleAddRecents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId]);

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <DesktopTypeDetailsHeader
        typeId={typeId}
        name={name}
        organizationId={organizationId}
      />
      <Box p={2}>
        <Box mb={4}>
          <DesktopTypeDetailsList type={data} />
        </Box>
        <DesktopTypeCharacteristicGroup
          typeCharacteristics={characteristics}
          functionId={functionId}
        />
      </Box>
    </ErrorHandling>
  );
};

export default DesktopTypeDetails;
