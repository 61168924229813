import { styled } from "@mui/system";
import { Button, Stack, Typography } from "@mui/material";

const iconStyles = ({ theme }) => ({
  marginRight: 19,
  color: theme.palette.secondary.contrastText,
});

export const PageContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  paddingInline: "32px",
});

export const WelcomeFirstText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginTop: 46,
  textAlign: "center",
}));

export const WelcomeSecondText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: 700,
  textAlign: "center",
}));

export const BenefitsCardContainer = styled(Stack)({
  flexGrow: 0.7,
  alignItems: "center",
});

export const ContinueButton = styled(Button)(({ theme }) => ({
  width: "311px",
  height: "42px",
  fontSize: theme.typography.body1.fontSize,
  margin: "0 auto",
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.secondary.contrastText,
}));
