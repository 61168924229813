import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useDeleteResourceMutation,
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
  useGetResourcesQuery,
  useUpdateResourceMutation,
} from "../../store/slices/api/assetManagementSlice";
import {
  setIsActionButtonDisabled,
  setIsLoadingAction,
} from "../../store/slices/appSlice";
import AssetForm from "../../components/assets/asset-form/AssetForm";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { selectUser } from "../../store/slices/authSlice";
import {
  getTranslation,
  permissions,
  RESOURCE_CATEGORIES,
  showValidationError,
  transitionDirections,
  userLocalStorageKey,
} from "../../util/utils";
import {
  FORM_MODE,
  checkResourceChanged,
  transformResourceInputRequest,
  transformResourceResponse,
  validateResourceForm,
} from "../../util/asset-utils";
import {
  clearChildrenList,
  resetResourceInput,
  selectError,
  selectIsFirstSubmitted,
  selectOriginalInput,
  selectResourceInput,
  setIsFirstSubmitted,
  setOriginalInput,
  setResourceInput,
} from "../../store/slices/resourceInputSlice";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import { clearList, setList } from "../../store/slices/resourceImageSlice";
import {
  clearAttachmentList,
  setAttachmentList,
} from "../../store/slices/resourceAttachmentSlice";
import EditAssetHeader from "../../navigation/header/assets/EditAssetHeader";
import { useGetResourceAttachmentsQuery } from "../../store/slices/api/resourceAttachmentsApiSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  setGlobalTags,
  setInitialTags,
  setTags,
} from "../../store/slices/tagsSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
} from "../../store/slices/api/tagsSlice";
import DeleteAsset from "../../components/assets/asset-form/DeleteAsset";
import EventBus, { EVENT_DISCARD_CHANGES } from "../../util/EventBus";
import Access from "../../components/common/Access";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";
import { useGetResourceImagesQuery } from "../../store/slices/api/resourceImagesApiSlice";

const EditAsset = () => {
  // General Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resourceid } = useParams();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const resourceInput = useSelector(selectResourceInput);
  const originalInput = useSelector(selectOriginalInput);
  const error = useSelector(selectError);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);

  // States
  const [openConfirm, setOpenConfirm] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState("");

  // Mutations
  const [updateResource, { isLoading: isLoadingUpdate }] =
    useUpdateResourceMutation();
  const [
    deleteResource,
    { isSuccess: isSuccessDelete, isLoading: isLoadingDelete },
  ] = useDeleteResourceMutation();

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResourceData,
    isError: isErrorResourceData,
    error: resourceDataError,
  } = useGetResourceDetailsQuery(
    {
      resourceid,
      organizationId,
    },
    { skip: isSuccessDelete || isLoadingDelete }
  );

  const {
    data: resourceAttachments,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery({
    resourceid,
    organizationId,
  });

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery(
    {
      organizationId,
    },
    { skip: isSuccessDelete || isLoadingDelete }
  );

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isError: isErrorResourceTags,
  } = useGetResourceTagsQuery(
    {
      resourceid,
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    { skip: isSuccessDelete || isLoadingDelete }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const { data: resourceImagesData, isLoading: isLoadingResourceImages } =
    useGetResourceImagesQuery(
      { resourceid, organizationId },
      { skip: !resourceid }
    );

  const {
    data: rootResourcesData,
    isLoading: isLoadingRootResources,
    isError: isErrorRootResources,
  } = useGetResourcesQuery(
    {
      organizationId,
    },
    { size: 1, index: 1 }
  );

  // Other variables
  const direction = searchParams.get("direction");
  const previousPage = searchParams.get("previousPage");
  const resourceImages = resourceImagesData ?? [];

  // Handlers
  const cancelHandler = (redirectUrl) => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(redirectRoute);
    }

    // Reset form
    dispatch(resetResourceInput());
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const handleCancelAction = () => {
    const backRoute = `/resources/${resourceid}?direction=${transitionDirections.LEFT_TO_RIGHT}`;

    if (checkResourceChanged(originalInput, resourceInput, user?.region)) {
      setRedirectRoute(backRoute);
      setOpenConfirm(true);
      return;
    }

    cancelHandler(backRoute);
  };

  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));
    const parent = resourceInput.parent;
    const parentFunction = allFunctionsData.find(
      (f) => f.id === parent?.functionId
    );

    const isParentHardwareAsset =
      parentFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET;
    const isParentRack = parentFunction?.category === RESOURCE_CATEGORIES.RACK;
    const hasLocations = rootResourcesData?.totalRecord > 0;

    if (
      resourceInput.category === RESOURCE_CATEGORIES.LOCATION &&
      resourceInput.parentId &&
      (isParentHardwareAsset || isParentRack)
    ) {
      messageError(getTranslation("CHANGE_PARENT_VALIDATION", t, i18n));
      return;
    }

    if (
      resourceInput.category === RESOURCE_CATEGORIES.RACK &&
      resourceInput.parentId &&
      isParentHardwareAsset
    ) {
      messageError(
        getTranslation("CHANGE_HARDWARE_PARENT_VALIDATION", t, i18n)
      );
      return;
    }

    if (
      (resourceInput.category === "HARDWARE_ASSET" ||
        resourceInput.category === "RACK") &&
      !hasLocations
    ) {
      messageError(getTranslation("NO_LOCATIONS_VALIDATION", t, i18n));
      return;
    }

    const { error: evaluatedError, firstError } = validateResourceForm(
      resourceInput,
      t
    );

    const isValid =
      Object.keys(evaluatedError).length === 0 && !Boolean(error.name);
    if (!isValid) {
      if (firstError) {
        messageError(firstError);
      }

      if (error.name) {
        messageError(error.name);
      }
      dispatch(setIsActionButtonDisabled(true));
      return;
    }

    try {
      await updateResource({
        resourceInput: transformResourceInputRequest(
          resourceInput,
          user?.region,
          true
        ),
        resourceid,
        organizationId: user?.organizations?.find((o) => o.default).id,
        category: resourceInput.category.replace("_", "-").toLowerCase(),
      }).unwrap();

      messageSuccess(getTranslation("ASSET_UPDATED_SUCCESSFULLY", t, i18n));
      navigate(`/resources/${resourceid}/details`);
      // Reset Form
      dispatch(resetResourceInput());
      dispatch(setIsLoadingAction(false));
    } catch (error) {
      dispatch(setIsLoadingAction(false));
      showValidationError(error, t, i18n);
      console.error("Failed to update a resource", error);
    }
  };

  // Other variables
  const resourceFunction = getFunction(resourceData?.functionId);

  const permissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_EDIT`;

  // Effects
  useEffect(() => {
    return () => {
      dispatch(clearChildrenList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleNavigation = (data) => {
      if (checkResourceChanged(originalInput, resourceInput, user?.region)) {
        setRedirectRoute(data.route);
        setOpenConfirm(true);
      } else {
        cancelHandler(data.route);
      }
    };

    EventBus.on(EVENT_DISCARD_CHANGES, handleNavigation);
    return () => EventBus.remove(EVENT_DISCARD_CHANGES, handleNavigation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput, originalInput]);

  useEffect(() => {
    if (resourceData) {
      if (
        !previousPage?.includes("add-type") &&
        !previousPage?.includes("edit-type") &&
        !previousPage?.includes("edit-tags") &&
        !previousPage?.includes("add-local-image") &&
        !previousPage?.includes("add-local-attachment") &&
        !previousPage?.includes("edit-local-image") &&
        !previousPage?.includes("edit-local-attachment")
      ) {
        // Reset form and validations
        dispatch(resetResourceInput());
        dispatch(setIsActionButtonDisabled(false));

        const resource = transformResourceResponse({
          ...resourceData,
          function: getFunction(resourceData.functionId),
        });

        //Load data into resource input
        dispatch(setResourceInput(resource));
        dispatch(setOriginalInput(resource));

        dispatch(clearList());
        dispatch(clearAttachmentList());
      }
    }

    return () => {
      localStorage.removeItem(userLocalStorageKey("locations"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceData]);

  useEffect(() => {
    if (resourceImages) {
      let resultList = [];

      resourceImages?.forEach((resourceImage) => {
        const item = {
          imageCategory: resourceImage.imageCategory,
          selectedFile: resourceImage.uri,
          resourceid,
          id: resourceImage.id,
          name: resourceImage.name,
          mimeType: resourceImage.name?.substring(
            resourceImage.name?.lastIndexOf(".")
          ),
          description: resourceImage.description,
          file: null,
          isFromDb: true,
        };

        resultList.push(item);
      });

      dispatch(setList(resultList));
    } else {
      dispatch(clearList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImages]);

  useEffect(() => {
    if (resourceAttachments) {
      let resultList = [];

      resourceAttachments?.forEach((resourceAttachment) => {
        const item = {
          selectedFile: resourceAttachment.uri,
          resourceid,
          id: resourceAttachment.id,
          name: resourceAttachment.name,
          description: resourceAttachment.description,
          mimeType: resourceAttachment.name.substring(
            resourceAttachment.name.lastIndexOf(".")
          ),
          file: null,
          isFromDb: true,
        };

        resultList.push(item);
      });

      dispatch(setAttachmentList(resultList));
    } else {
      dispatch(clearAttachmentList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachments]);

  // Here, we will fetch the tags and put them into the Redux store (similar to resource images and attachments)
  useEffect(() => {
    if (tags) {
      const mappedTags = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags]);

  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateResourceForm(resourceInput, t);
    const isValid = Object.keys(evaluatedError).length === 0 && !error.name;

    if (isValid) {
      dispatch(setIsActionButtonDisabled(false));
    } else {
      dispatch(setIsActionButtonDisabled(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput]);

  useEffect(() => {
    if (isLoadingUpdate) {
      dispatch(setIsLoadingAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUpdate]);

  if (resourceDataError?.status === 404) {
    return <Navigate to="*" />;
  }

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingAttachments ||
          isLoadingDelete ||
          isLoadingFunctions ||
          isLoadingResourceData ||
          isLoadingResourceTags ||
          isLoadingRootResources ||
          isLoadingTags ||
          isLoadingUpdate ||
          isLoadingResourceImages
        }
        isError={
          isErrorAttachments ||
          isErrorFunctions ||
          isErrorResourceData ||
          isErrorResourceTags ||
          isErrorRootResources ||
          isErrorTags
        }
      >
        <Access all={[permissions[permissionsKey]]}>
          <Layer2Access>
            <PageTransition direction={direction}>
              <ConfirmAlert
                isOpen={openConfirm}
                setIsOpen={setOpenConfirm}
                alert={{
                  content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
                  confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
                  closeTitle: getTranslation("CANCEL", t, i18n),
                  showConfirm: true,
                }}
                handleConfirm={cancelHandler}
                label="discard-changes"
              />
              <EditAssetHeader
                handleAction={handleSubmit}
                handleCancelAction={handleCancelAction}
              />
              <HomePagePadding>
                <AssetForm
                  id={resourceid}
                  setOpenConfirm={setOpenConfirm}
                  setRedirectRoute={setRedirectRoute}
                  mode={FORM_MODE.EDIT}
                  allFunctionsData={allFunctionsData}
                />
                {resourceData && (
                  <DeleteAsset
                    resourceid={resourceid}
                    parentId={resourceData.parent?.id}
                    category={resourceInput.category?.toLowerCase()}
                    organizationId={organizationId}
                    deleteResource={deleteResource}
                  />
                )}
              </HomePagePadding>
            </PageTransition>
          </Layer2Access>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default EditAsset;
