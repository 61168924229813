import { Grid, useTheme } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CharacteristicItem from "./CharacteristicItem";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { getTranslation } from "../../../util/utils";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { getSvgIcon } from "../../../util/icons";

const AssetDetailCharacteristicGroupAccordion = ({ setTemplate }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // States
  const [borderWidth, setBorderWidth] = useState("25%");

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <React.Fragment key={setTemplate.name}>
      <StyledAccordion currentTheme={currentTheme} defaultExpanded>
        <StyledAccordionSummary
          value={borderWidth}
          expandIcon={
            <div style={{ marginTop: "5px", marginRight: "5px" }} ref={iconRef}>
              {getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <SectionTitle variant="body2" istablet={1} ref={textRef}>
            {getTranslation(setTemplate.name, t, i18n)}
          </SectionTitle>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <Grid id={setTemplate.name.toLowerCase()} container columnSpacing={2}>
            {setTemplate.characteristics
              ?.filter((char) => Boolean(char.value))
              ?.slice()
              ?.sort((a, b) => {
                if (a.id > b.id) {
                  return 1;
                } else {
                  return -1;
                }
              })
              ?.map((row) => {
                return (
                  <Grid key={row.id} item xs={12} sm={6}>
                    <CharacteristicItem
                      characteristic={row}
                      region={user?.region}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </StyledAccordionDetails>
      </StyledAccordion>
    </React.Fragment>
  );
};

export default AssetDetailCharacteristicGroupAccordion;
