import {
  Paper,
  BottomNavigationAction,
  Icon,
  BottomNavigation,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

export const MenuPaper = styled(Paper)({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
});

export const HomeBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const AssetsBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const ReportsBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const LibraryBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const FavoritesBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const MoreBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme, active }) => ({
    "& .Mui-selected": {
      color: active ? theme.palette.secondary.contrastText : "",
    },
    minWidth: "60px",
  })
);

export const StyledBottomNavigation = styled(BottomNavigation)({
  paddingBottom: "8px",
  "@supports (padding-bottom: max(0px))": {
    paddingBottom: "max(8px, calc(env(safe-area-inset-bottom) * 0.7))",
  },
  paddingTop: "5px",
  minHeight: "56px",
});

export const ContrastText = styled(Typography)(({ theme, active }) => ({
  color: active ? theme.palette.secondary.contrastText : "",
}));
