import {
  BackTitle,
  HeaderGridContainer,
  HeaderWrapper,
  LeftActionButton,
  PageTitle,
} from "../../../components/styles/header/Header.styles";
import {
  Grid,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectIsSearchOpen,
  selectSearchTerm,
  setIsSearchOpen,
  setSearchTerm,
  setShouldSearch,
} from "../../../store/slices/appSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { messageError } from "../../../util/notification";
import { setParentId, setTrail } from "../../../store/slices/columnViewSlice";
import OptionsMenu from "../../options-menu/OptionsMenu";
import { StickySearchTextField } from "../../../components/styles/assets/StickySearch.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import {
  OptionsMenuContainer,
  SearchContainer,
} from "../../../components/styles/header/assets/AssetListHeader.styles";
import { getSvgIcon } from "../../../util/icons";

const SearchAssetsHeader = ({
  goBackHandler,
  back,
  from,
  tagId,
  tagName,
  resourceData,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const searchTerm = useSelector(selectSearchTerm);

  // Handlers
  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchTerm?.length < 3) {
        messageError(getTranslation("SEARCH_TERM_TOO_SHORT", t, i18n));
      } else {
        dispatch(setShouldSearch(true));
        navigate(
          `/search?from=${from}${tagId ? `&tagId=${tagId}` : ""}${
            resourceData ? `&resourceId=${resourceData.id}` : ""
          }${tagName ? `&tagName=${tagName}` : ""}`
        );

        dispatch(setIsSearchOpen(false));
      }
    } else {
      dispatch(setIsSearchOpen(true));
    }
  };

  const handleClearSearch = () => {
    dispatch(setIsSearchOpen(false));
    dispatch(setSearchTerm(""));
    dispatch(setShouldSearch(false));
    dispatch(setParentId(null));

    if (pathname.includes("search")) {
      goBackHandler();
    }
  };

  const handleSearch = (e) => {
    dispatch(setTrail([]));

    if (Boolean(e.target.value)) {
      dispatch(setParentId(null));
    }

    dispatch(setSearchTerm(e.target.value));
    dispatch(setShouldSearch(false));
  };

  // Other variables
  const iconSize = globalFontSize * 1.5;

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs={5}>
          <LeftActionButton id="back-button" onClick={goBackHandler}>
            {getSvgIcon(
              "ARROW_LEFT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
            <BackTitle component="span" id="previous_page-title">
              {getTranslation(back, t, i18n)}
            </BackTitle>
          </LeftActionButton>
        </Grid>
        <Grid item xs={2}>
          <PageTitle variant="h6" id="title">
            {getTranslation("SEARCH", t, i18n)}
          </PageTitle>
        </Grid>
        <Grid display="flex" justifyContent="end" item xs={5}>
          {tabletMatches && (
            <OptionsMenuContainer>
              <OptionsMenu />
              {isSearchOpen ? (
                <SearchContainer>
                  <StickySearchTextField
                    inputProps={{ "data-testid": "search-field" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton id="search" onClick={handleSearchClick}>
                            {getSvgIcon(
                              "SEARCH",
                              iconSize,
                              iconSize,
                              theme.palette.secondary.contrastText
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton id="clear" onClick={handleClearSearch}>
                            {getSvgIcon(
                              "CLEAR",
                              iconSize,
                              iconSize,
                              theme.palette.secondary.contrastText
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="search-field"
                    variant="outlined"
                    placeholder={getTranslation("SEARCH_TERM", t, i18n)}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </SearchContainer>
              ) : (
                <IconButton onClick={handleSearchClick} id="search-icon">
                  {getSvgIcon(
                    "SEARCH",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </IconButton>
              )}
            </OptionsMenuContainer>
          )}
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default SearchAssetsHeader;
