import { IconButton, useTheme } from "@mui/material";
import {
  NextButtonContainer,
  StyledMobileStepper,
} from "./styles/onboarding/MobileSteps.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import { getSvgIcon } from "../util/icons";

const MobileSteps = ({ stepQuantity, activeStep, handleBack }) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.5;

  return (
    <StyledMobileStepper
      id="mobile-stepper"
      color="primary"
      variant="dots"
      steps={stepQuantity + 1}
      position="static"
      activeStep={activeStep}
      backButton={
        <IconButton
          sx={{
            visibility: activeStep
              ? activeStep === 0
                ? "hidden"
                : "visible"
              : "hidden",
          }}
          onClick={handleBack}
          data-testid="back-button"
          aria-label="back"
        >
          {getSvgIcon(
            "ARROW_LEFT",
            iconSize,
            iconSize,
            theme.palette.secondary.contrastText
          )}
        </IconButton>
      }
      nextButton={
        <NextButtonContainer>
          <IconButton aria-label="back">
            {getSvgIcon(
              "ARROW_LEFT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </IconButton>
        </NextButtonContainer>
      }
    />
  );
};

export default MobileSteps;
