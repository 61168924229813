import { createSlice } from "@reduxjs/toolkit";

// Default state
export const DEFAULT_ASSET_LIST_STATE = {
  items: [],
  history: [],
  searchResultItems: [],
  mobileTabValue: 0,
  tabletTabValue: 0,
  carouselIndex: 0,
  isGraphicalViewLoading: true,
  resourceId: null,
  isInDuplicate: false,
};

// Initial state
const initialState = {
  ...DEFAULT_ASSET_LIST_STATE,
};

// Slice
const assetListSlice = createSlice({
  name: "assetList",
  initialState,
  reducers: {
    setItems: (state, action) => {
      const newItems = action.payload;
      state.items = newItems;
    },
    editItem: (state, action) => {
      const { id, newData } = action.payload;

      state.items = state.items.map((item) =>
        item.id === id ? { ...item, ...newData } : item
      );
    },
    deleteItem: (state, action) => {
      const id = action.payload;
      state.items = state.items.filter((i) => i.id !== id);
    },
    setSearchResultItems: (state, action) => {
      const newSearchResultItems = action.payload;
      state.searchResultItems = newSearchResultItems;
    },
    setMobileTabValue: (state, action) => {
      const newValue = action.payload;
      state.mobileTabValue = newValue;
    },
    setTabletTabValue: (state, action) => {
      const newValue = action.payload;
      state.tabletTabValue = newValue;
    },
    setCarouselIndex: (state, action) => {
      const newCarouselIndex = action.payload;
      state.carouselIndex = newCarouselIndex;
    },
    setIsGraphicalViewLoading: (state, action) => {
      const newValue = action.payload;
      state.isGraphicalViewLoading = newValue;
    },
    addHistoryItem: (state, action) => {
      const parentId = action.payload;
      state.history = [...state.history, parentId];
    },
    removeHistoryItem: (state) => {
      const lastIndex = state.history.length - 1;
      state.history = state.history.slice(0, lastIndex);
    },
    clearHistory: (state, action) => {
      state.history = [];
    },
    setResourceId: (state, action) => {
      const newResourceId = action.payload;
      state.resourceId = newResourceId;
    },
    setIsInDuplicate: (state, action) => {
      const newValue = action.payload;
      state.isInDuplicate = newValue;
    },
  },
});

// Export slice and its corresponding actions
export default assetListSlice.reducer;
export const {
  setItems,
  editItem,
  deleteItem,
  setSearchResultItems,
  setMobileTabValue,
  setTabletTabValue,
  setCarouselIndex,
  setIsGraphicalViewLoading,
  addHistoryItem,
  removeHistoryItem,
  clearHistory,
  setResourceId,
  setIsInDuplicate,
} = assetListSlice.actions;

// selectors
export const selectItems = (state) => state.assetList.items;
export const selectSearchResultItems = (state) =>
  state.assetList.searchResultItems;
export const selectMobileTabValue = (state) => state.assetList.mobileTabValue;
export const selectTabletTabValue = (state) => state.assetList.tabletTabValue;
export const selectCarouselIndex = (state) => state.assetList.carouselIndex;
export const selectIsGraphicalViewLoading = (state) =>
  state.assetList.isGraphicalViewLoading;
export const selectAssetListHistory = (state) => state.assetList.history;
export const selectResourceId = (state) => state.assetList.resourceId;
export const selectIsInDuplicate = (state) => state.assetList.isInDuplicate;
