import { useCallback, useEffect } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { useSelector } from "react-redux";
import { useGetUserInvitationsCheckQuery } from "../../store/slices/api/userManagementSlice";
import { useNavigate } from "react-router-dom";

const AppAccess = ({ children }) => {
  // General hooks
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const invalidUser = !user?.firstName || !user?.lastName;

  // Queries
  const { data, error } = useGetUserInvitationsCheckQuery(
    { email: user?.email, status: "ACCEPTED" },
    { skip: invalidUser || user?.organizations?.length > 0 }
  );

  // Handlers
  const handleRedirection = useCallback(() => {
    if (user?.id) {
      if (invalidUser) {
        navigate("/onboarding");
        return;
      }

      const organizations = user?.organizations;

      if (
        (!organizations || organizations.length <= 0) &&
        error?.status === 404
      ) {
        navigate("/organization-onboarding");
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.organizations?.length, data, error?.status]);

  // Effects
  useEffect(() => {
    handleRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRedirection]);

  return <>{children}</>;
};

export default AppAccess;
