import { useMemo } from "react";

// Hook to transform flat data to tree structure
const useTreeData = (
  data = [],
  allowedCategories = [],
  allFunctionsData = [],
  resourceId
) => {
  return useMemo(() => {
    const idMapping = data?.reduce((acc, item) => {
      acc[item?.id] = { ...item, children: [] };
      return acc;
    }, {});

    const tree = [];

    data?.forEach((item) => {
      if (item?.parentId === null) {
        // If parentId is null, it means it's a root item
        tree?.push(idMapping[item?.id]);
      } else {
        // Add item to its parent's children array
        idMapping[item?.parentId]?.children?.push(idMapping[item?.id]);
      }
    });

    // Transform the tree to { value, title, children } format
    const transformTree = (nodes, disableAll = false) => {
      return nodes?.map((node) => {
        const isSameResource = resourceId === node?.id;

        const rFunction = allFunctionsData?.find(
          (f) => f.id === Number(node?.functionId)
        );

        const isAllowed = allowedCategories?.some(
          (c) => c === rFunction?.category
        );

        const disabled = disableAll || isSameResource || !isAllowed;

        return {
          value: node?.id,
          title: node?.displayId || node?.name,
          children:
            node?.children?.length > 0
              ? transformTree(node?.children, disabled)
              : [],
          disabled,
        };
      });
    };

    return transformTree(tree);
  }, [data, allowedCategories, allFunctionsData, resourceId]);
};

export default useTreeData;
