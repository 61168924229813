import { styled } from "@mui/system";
import { Item, ItemContainer, ItemName } from "../ListInlineView.styles";
import { Typography, alpha } from "@mui/material";
import { THEME } from "../../../../util/utils";

export const SelectedResourceItem = styled(ItemContainer)(
  ({ theme, isselected, isactive, longPressed, currentTheme }) => ({
    backgroundColor: longPressed
      ? currentTheme === THEME.LIGHT
        ? "#FFF"
        : "#222222"
      : isactive
      ? alpha("#0076bc", 0.1)
      : isselected
      ? alpha("#000", 0.04)
      : theme.palette.primary.contrastText,
    zIndex: longPressed ? 1200 : "auto",
    position: longPressed ? "relative" : "static",
  })
);

export const ItemWrapper = styled(Item)({
  paddingInline: "8px",
});

export const ChildrenCountText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
}));

export const ResourceItemName = styled(ItemName)(({ isactive }) => ({
  marginLeft: 0,
  fontWeight: isactive ? "bold" : "normal",
}));
