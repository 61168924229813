import { useCallback, useState } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { StaticHeightDialogContent } from "../../styles/assets/ChooseAssetImagePage.styles";
import { selectResourceImages } from "../../../store/slices/resourceImageSlice";
import { useTranslation } from "react-i18next";
import { getTranslation, showValidationError } from "../../../util/utils";
import { messageError, messageSuccess } from "../../../util/notification";
import { selectPageInfo } from "../../../store/slices/appSlice";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { useDispatch } from "react-redux";
import ResourceAttachmentForm from "./ResourceAttachmentForm";
import {
  useGetResourceAttachmentQuery,
  useUpdateResourceAttachmentMutation,
} from "../../../store/slices/api/resourceAttachmentsApiSlice";
import {
  DEFAULT_RESOURCE_ATTACHMENT,
  editResourceAttachment,
  selectResourceAttachmentById,
} from "../../../store/slices/resourceAttachmentSlice";

const EditLocalAttachmentDialog = ({ resourceId, open, setOpen, id }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const { isActionButtonDisabled } = useSelector(selectPageInfo);
  const singleResourceAttachment = useSelector(
    selectResourceAttachmentById(id)
  );

  // Queries
  const {
    data: resourceAttachmentUri,
    isLoading,
    isError,
    error,
  } = useGetResourceAttachmentQuery(
    {
      attachmentUri: singleResourceAttachment
        ? singleResourceAttachment?.selectedFile
        : "",
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !singleResourceAttachment || !singleResourceAttachment?.isFromDb,
    }
  );

  const transformedAttachment = {
    selectedFile:
      resourceAttachmentUri || singleResourceAttachment?.selectedFile,
    resourceid: "",
    id: singleResourceAttachment?.id,
    name: singleResourceAttachment?.name,
    mimeType: singleResourceAttachment?.name?.substring(
      singleResourceAttachment?.name?.lastIndexOf(".")
    ),
    description: singleResourceAttachment?.description,
    file: singleResourceAttachment?.file,
  };

  // States
  const [resourceAttachmentInput, setResourceAttachmentInput] = useState(
    transformedAttachment
  );

  const [name, setName] = useState(
    resourceAttachmentInput?.name?.substring(
      0,
      resourceAttachmentInput?.name?.lastIndexOf(".")
    )
  );

  const [extension, setExtension] = useState(
    resourceAttachmentInput?.name?.substring(
      resourceAttachmentInput?.name?.lastIndexOf(".")
    )
  );

  // Mutations
  const [updateResourceAttachment, { isLoading: isLoadingUpdate }] =
    useUpdateResourceAttachmentMutation();

  // Handlers
  const handleClose = () => {
    setOpen(false);
    setResourceAttachmentInput(DEFAULT_RESOURCE_ATTACHMENT);
  };

  const handleSubmit = useCallback(async () => {
    if (!resourceAttachmentInput.selectedFile) {
      messageError(getTranslation("ATTACHMENT_IS_REQUIRED", t, i18n));
      return;
    }

    try {
      if (resourceId) {
        const formData = new FormData();

        const { file, description } = resourceAttachmentInput;

        formData.append("file", file);
        formData.append("fileName", name + extension);
        formData.append("description", description);

        await updateResourceAttachment({
          formData,
          resourceid: resourceId,
          organizationId,
          updateAttachment: Boolean(file),
          resourceAttachmentId: resourceAttachmentInput?.id,
        }).unwrap();

        messageSuccess(
          getTranslation("RESOURCE_ATTACHMENT_UPDATED_SUCCESSFULLY", t, i18n)
        );
      } else {
        const resourceAttachment = {
          ...resourceAttachmentInput,
          name: name + extension,
        };

        dispatch(
          editResourceAttachment({
            id: resourceAttachment.id,
            resourceAttachment,
            isFromDb: false,
          })
        );
      }
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update resource attachment", error);
    }

    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentInput, name]);

  return (
    <ErrorHandling
      isLoading={isLoadingUpdate || isLoading}
      isError={error?.status !== 404 && isError}
    >
      <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
        <DialogTitle>{getTranslation("CHOOSE_IMAGE", t, i18n)}</DialogTitle>
        <StaticHeightDialogContent>
          <ResourceAttachmentForm
            resourceAttachmentInput={resourceAttachmentInput}
            setResourceAttachmentInput={setResourceAttachmentInput}
            name={name}
            setName={setName}
            setExtension={setExtension}
          />
        </StaticHeightDialogContent>
        <DialogActions>
          <CancelButton variant="outlined" onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
          <CreateButton
            disabled={isActionButtonDisabled || isLoadingUpdate}
            onClick={handleSubmit}
          >
            {getTranslation("SUBMIT", t, i18n)}
          </CreateButton>
        </DialogActions>
      </Dialog>
    </ErrorHandling>
  );
};

export default EditLocalAttachmentDialog;
