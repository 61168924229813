import { useSelector } from "react-redux";
import { useGetResourceImagesQuery } from "../store/slices/api/resourceImagesApiSlice";
import { selectUser } from "../store/slices/authSlice";
import { useGetTypeImagesQuery } from "../store/slices/api/typesApiSlice";

const useResourceCombinedImages = ({ resourceId, typeId }) => {
  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const { data: resourceImagesData, isLoading: isLoadingResourceImages } =
    useGetResourceImagesQuery(
      { resourceid: resourceId, organizationId },
      { skip: !resourceId }
    );

  const { data: typeImagesData, isLoading: isLoadingTypeImages } =
    useGetTypeImagesQuery({ typeId, organizationId }, { skip: !typeId });

  // Other variables
  const typeImages =
    typeImagesData?.map((img) => {
      return {
        ...img,
        uri: `/organizations/${organizationId}${img.uri}`,
      };
    }) ?? [];

  const resourceImages = [...(resourceImagesData ?? []), ...typeImages];

  return {
    resourceImages,
    isLoading: isLoadingResourceImages || isLoadingTypeImages,
  };
};

export default useResourceCombinedImages;
