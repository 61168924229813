import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  showValidationError,
} from "../../util/utils";
import { useDispatch } from "react-redux";
import ErrorHandling from "../../components/common/ErrorHandling";
import { selectUser } from "../../store/slices/authSlice";
import {
  useGetResourceAttachmentQuery,
  useUpdateResourceAttachmentMutation,
} from "../../store/slices/api/resourceAttachmentsApiSlice";
import AddAttachmentHeader from "../../navigation/header/assets/AddAttachmentHeader";
import ResourceAttachmentForm from "../../components/assets/asset-form/ResourceAttachmentForm";
import {
  editResourceAttachment,
  selectResourceAttachmentById,
} from "../../store/slices/resourceAttachmentSlice";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const EditLocalResourceAttachmentPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId } = useParams();

  // Other variables
  const direction = searchParams.get("direction");
  const id = searchParams.get("id");

  // Selectors
  const user = useSelector(selectUser);
  const singleResourceAttachment = useSelector(
    selectResourceAttachmentById(id)
  );

  // Mutations
  const [updateResourceAttachment, { isLoading: isLoadingUpdate }] =
    useUpdateResourceAttachmentMutation();

  // Queries
  const {
    data: resourceAttachmentUri,
    isLoading,
    isError,
    error,
  } = useGetResourceAttachmentQuery(
    {
      imageUri: singleResourceAttachment
        ? singleResourceAttachment?.selectedFile
        : "",
      organizationId: user?.organizations?.find((o) => o.default).id,
    },
    {
      skip: !singleResourceAttachment || !singleResourceAttachment?.isFromDb,
    }
  );

  const transformedAttachment = {
    selectedFile:
      resourceAttachmentUri || singleResourceAttachment?.selectedFile,
    resourceid: "",
    id: singleResourceAttachment?.id,
    name: singleResourceAttachment?.name,
    mimeType: singleResourceAttachment?.name?.substring(
      singleResourceAttachment?.name?.lastIndexOf(".")
    ),
    description: singleResourceAttachment?.description,
    file: singleResourceAttachment?.file,
  };

  // State
  const [resourceAttachmentInput, setResourceAttachmentInput] = useState(
    transformedAttachment
  );

  const [name, setName] = useState(
    resourceAttachmentInput?.name?.substring(
      0,
      resourceAttachmentInput?.name?.lastIndexOf(".")
    )
  );

  const [extension, setExtension] = useState(
    resourceAttachmentInput?.name?.substring(
      resourceAttachmentInput?.name?.lastIndexOf(".")
    )
  );

  // Handlers
  const cancelHandler = () => {
    if (resourceId) {
      navigate(
        `/resources/${resourceId}/edit?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=edit-local-attachment`
      );
    } else {
      navigate(
        `/resources/add-new?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=edit-local-attachment`
      );
    }
  };

  // Other variables
  const isValid =
    (resourceAttachmentInput.file || resourceAttachmentInput.selectedFile) &&
    name;

  const isDisabled = !isValid;

  const handleSubmit = useCallback(async () => {
    if (!resourceAttachmentInput.selectedFile) {
      messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
      return;
    }

    try {
      if (resourceId) {
        const formData = new FormData();

        const { file, description } = resourceAttachmentInput;

        formData.append("file", file);
        formData.append("fileName", name + extension);
        formData.append("description", description);

        await updateResourceAttachment({
          formData,
          resourceid: resourceId,
          organizationId: user?.organizations?.find((o) => o.default)?.id,
          updateAttachment: Boolean(file),
          resourceAttachmentId: transformedAttachment?.id,
        }).unwrap();

        cancelHandler();

        messageSuccess(
          getTranslation("RESOURCE_ATTACHMENT_UPDATED_SUCCESSFULLY", t, i18n)
        );
      } else {
        const resourceAttachment = {
          ...resourceAttachmentInput,
          name: name + extension,
        };

        dispatch(
          editResourceAttachment({
            id: resourceAttachment.id,
            resourceAttachment,
          })
        );
      }

      cancelHandler();
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update resource image", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentInput, name]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={isLoadingUpdate || isLoading}
          isError={error?.status !== 404 && isError}
        >
          <PageTransition direction={direction}>
            <AddAttachmentHeader
              handleAction={handleSubmit}
              handleCancelAction={cancelHandler}
              isDisabled={isDisabled}
            />

            <HomePagePadding>
              <ResourceAttachmentForm
                resourceAttachmentInput={resourceAttachmentInput}
                setResourceAttachmentInput={setResourceAttachmentInput}
                name={name}
                setName={setName}
                setExtension={setExtension}
              />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default EditLocalResourceAttachmentPage;
