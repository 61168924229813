import { createSelector, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const DEFAULT_RESOURCE_ATTACHMENT = {
  selectedFile: null,
  file: null,
  resourceid: "",
  id: "",
  name: "",
  mimeType: "",
  description: "",
  isFromDb: false,
};

// Initial state
const initialState = {
  list: [],
};

// Slice
const resourceAttachmentSlice = createSlice({
  name: "resourceAttachment",
  initialState,
  reducers: {
    addResourceAttachment: (state, action) => {
      const newAttachment = action.payload;
      state.list.push({
        ...newAttachment,
        id: uuidv4(),
      });
    },
    editResourceAttachment: (state, action) => {
      const { id, resourceAttachment } = action.payload;
      state.list = state.list.map((attachment) => {
        if (attachment.id === id) {
          return resourceAttachment;
        }

        return attachment;
      });
    },
    deleteResourceAttachment: (state, action) => {
      const id = action.payload;
      state.list = state.list.filter((attachment) => attachment.id !== id);
    },
    setAttachmentList: (state, action) => {
      const newList = action.payload;
      state.list = newList;
    },
    clearAttachmentList: (state) => {
      state.list = [];
    },
  },
});

// Export slice and its corresponding actions
export default resourceAttachmentSlice.reducer;
export const {
  addResourceAttachment,
  editResourceAttachment,
  deleteResourceAttachment,
  setAttachmentList,
  clearAttachmentList,
} = resourceAttachmentSlice.actions;

// selectors
export const selectResourceAttachments = (state) =>
  state.resourceAttachment.list;

export const selectResourceAttachmentById = (attachmentId) =>
  createSelector(
    [selectResourceAttachments, (attachments) => attachments],
    (attachments) =>
      attachments.find((attachment) => attachment.id === attachmentId)
  );
