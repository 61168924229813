import { useNavigate, useSearchParams } from "react-router-dom";
import KeycloakService from "../../services/KeycloakService";
import { MenuList, useMediaQuery, useTheme } from "@mui/material";
import ProfilePreferenceItem from "../../components/ProfilePreferenceItem";
import ProfileCardItem from "../../components/ProfileCardItem";
import { useSelector } from "react-redux";
import { StyledDivider } from "../../components/styles/profile/Profile.styles";
import PageTransition from "../../components/PageTransition";
import { transitionDirections, userLocalStorageKey } from "../../util/utils";
import { selectUser } from "../../store/slices/authSlice";
import ProfileHeader from "../../navigation/header/profile/ProfileHeader";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import ProfileDesktop from "../../components/profile/ProfileDesktop";
import ProfileDesktopHeader from "../../navigation/header/profile/desktop/ProfileDesktopHeader";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import AppAccess from "../../components/common/AppAccess";
import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";

const Profile = () => {
  // Global hooks
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const onLogoutClickHandler = () => {
    KeycloakService.doLogout();
    localStorage.removeItem("keycloak-initialized");
    localStorage.removeItem(userLocalStorageKey("app-initialized"));
  };

  const onPreferencesClickHandler = () => {
    navigate(
      `/profile/preferences?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const handleOrganizationClick = (organizationId) =>
    navigate(
      `/profile/organization?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );

  const handleGoBack = () =>
    navigate(`/more?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  return (
    <AppAccess>
      <PageTransition direction={transitionDirection}>
        {!mobileMatches ? (
          <>
            <ProfileDesktopHeader />
            <ProfileDesktop />
          </>
        ) : (
          <>
            <ProfileHeader goBackHandler={handleGoBack} />
            <HomePagePadding>
              <ProfileCardItem />
              <MenuList dense>
                <StyledDivider />
                {/* Profile menu item about organization */}
                <ProfilePreferenceItem
                  icon={getSvgIcon(
                    "ORGANIZATION",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.light
                  )}
                  handleClick={handleOrganizationClick}
                  label={"organization"}
                  labelId="btn-user-organization"
                  menuId="btn-menu-item-user-organization"
                  value={
                    user?.organizations?.find((o) => o.default)?.nickname ||
                    user?.organizations?.find((o) => o.default)?.name
                  }
                />
                <StyledDivider />
                {/* Profile preference menu item about preferences */}
                <ProfilePreferenceItem
                  handleClick={onPreferencesClickHandler}
                  icon={getSvgIcon(
                    "PREFERENCES",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.light
                  )}
                  label="PREFERENCES"
                  labelId="btn-profile-settings"
                  menuId="btn-menu-item-profile-settings"
                />
                <StyledDivider />

                <ProfilePreferenceItem
                  handleClick={onLogoutClickHandler}
                  icon={getSvgIcon(
                    "LOG_OUT",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.light
                  )}
                  label="logOut"
                  labelId="btn-profile-logout"
                  menuId="btn-menu-item-profile-logout"
                />

                <StyledDivider />
              </MenuList>
            </HomePagePadding>
          </>
        )}
      </PageTransition>
    </AppAccess>
  );
};

export default Profile;
