import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import { selectUser } from "../../store/slices/authSlice";
import { getPermissionsFromUserRoles } from "../../util/utils";
import ErrorHandling from "./ErrorHandling";

const Access = ({ children, all, oneOf, not, organizationId }) => {
  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const calculatedOrganizationId =
    organizationId || user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError,
  } = useUserRolePermissionsQuery(
    {
      userId: user.id,
      organizationId: calculatedOrganizationId,
    },
    { skip: !calculatedOrganizationId }
  );

  // Other variables
  const permissions = getPermissionsFromUserRoles(userRoles);

  const checkAll = !!all?.every((item) => permissions?.includes(item));
  const checkOneOf = !!oneOf?.some((item) => permissions?.includes(item));

  const hasAll = !!all && checkAll;
  const hasOneOf = !!oneOf && checkOneOf;

  let hasAccess = all && oneOf ? hasAll && hasOneOf : hasAll || hasOneOf;

  if (not) {
    hasAccess = !hasAccess;
  }

  return (
    <ErrorHandling isLoading={isLoadingUserRoles} isError={isError}>
      {hasAccess ? <>{children}</> : <Navigate to="/" />}
    </ErrorHandling>
  );
};

export default Access;
