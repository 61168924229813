import React, { useState } from "react";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";
import ErrorHandling from "../../components/common/ErrorHandling";
import {
  DEFAULT_COLOR,
  resetTags,
  selectAllTags,
  selectHaveChanges,
  setAutocompleteOpen,
  setCurrentColor,
  setError,
  setInputValue,
  setMarkedTag,
  setTags,
} from "../../store/slices/tagsSlice";
import { getTranslation, transitionDirections } from "../../util/utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import EditTagsHeader from "../../navigation/header/assets/EditTagsHeader";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import TagsInput from "../../components/assets/tags/TagsInput";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PageTransition from "../../components/PageTransition";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";

const DuplicateEditTags = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { resourceid } = useParams();

  // Selectors
  const haveChanges = useSelector(selectHaveChanges);
  const tags = useSelector(selectAllTags);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);
  const [originalTags] = useState(tags);

  // Handlers
  const handleSubmit = () => {
    handleReset();
    handleNavigateToResourceDuplicate();
  };

  const handleReset = () => {
    dispatch(setInputValue(""));
    dispatch(setError(""));
    dispatch(setAutocompleteOpen(false));
    dispatch(setCurrentColor(DEFAULT_COLOR));
    dispatch(setMarkedTag(null));
  };

  const handleNavigateToResourceDuplicate = () =>
    navigate(
      `/resources/${resourceid}/duplicate?direction=${transitionDirections.TOP_TO_BOTTOM}&previousPage=edit-tags`
    );

  const handleCancel = () => {
    if (haveChanges) {
      setOpenConfirm(true);
      dispatch(setAutocompleteOpen(false));
    } else {
      handleReset();
      handleNavigateToResourceDuplicate();
    }
  };

  const handleConfirmAlert = () => {
    handleReset();
    dispatch(resetTags());
    dispatch(setTags(originalTags));
    handleNavigateToResourceDuplicate();
  };

  // Other variables
  const direction = searchParams.get("direction");
  const alert = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),

    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling isLoading={false}>
          <PageTransition direction={direction}>
            <ConfirmAlert
              isOpen={openConfirm}
              setIsOpen={setOpenConfirm}
              alert={alert}
              handleConfirm={handleConfirmAlert}
            />
            <EditTagsHeader
              handleAction={handleSubmit}
              handleCancelAction={handleCancel}
            />
            <HomePagePadding>
              <TagsInput id={resourceid} shouldFocus={true} />
            </HomePagePadding>
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default DuplicateEditTags;
