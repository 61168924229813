import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { Grid, IconButton, useTheme } from "@mui/material";
import EditType from "../type-form/EditType";
import {
  CreationTypeGridContainer,
  HeaderContainer,
} from "../../styles/types/type-details/DesktopTypeDetails.styles";
import AddFavoriteType from "./AddFavoriteType";
import { getSvgIcon } from "../../../util/icons";
import {
  getPermissionsFromUserRoles,
  hasAccess,
  permissions,
} from "../../../util/utils";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";

const DesktopTypeDetailsHeader = ({ typeId, name, organizationId }) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const defaultOrganizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [open, setOpen] = useState(false);

  // Queries
  const { data: userRoles } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId: defaultOrganizationId,
  });

  // Other variables
  const iconSize = globalFontSize * 1.2;
  const canEdit =
    organizationId &&
    hasAccess(
      "all",
      [permissions.ASSET_MANAGEMENT_TYPE_EDIT],
      getPermissionsFromUserRoles(userRoles)
    );

  // Handlers
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <HeaderContainer container gap={2}>
      <CreationTypeGridContainer item>
        {organizationId
          ? getSvgIcon(
              "PROFILE",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )
          : getSvgIcon(
              "MODEL_LIBRARY",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
      </CreationTypeGridContainer>
      <Grid item md>
        <SecondaryText fontWeight="bold" variant="h5">
          {name}
        </SecondaryText>
      </Grid>
      <Grid item>
        {canEdit && (
          <>
            <IconButton onClick={handleOpen}>
              {getSvgIcon(
                "EDIT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </IconButton>
            {open && <EditType typeId={typeId} open={open} setOpen={setOpen} />}
          </>
        )}
      </Grid>
      <Grid item>
        <AddFavoriteType typeId={typeId} />
      </Grid>
    </HeaderContainer>
  );
};

export default DesktopTypeDetailsHeader;
