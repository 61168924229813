import i18n from "i18next";
import { fetchCurrentUser } from "./UserService";
import { supportedLanguages, userLocalStorageKey } from "../util/utils";

export const getBrowserLanguage = (options = {}) => {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split("-")[0] : trimmedLocale;
  })[0];
};

export const getFallBackLanguage = (selectedLang) => {
  try {
    let lang = selectedLang;
    if (lang.indexOf("_") !== -1) {
      lang = lang.substring(0, lang.indexOf("_"));
    }
    return lang;
  } catch {
    return "en";
  }
};

const initI18n = async (user) => {
  let _language = "en";

  if (user?.data?.language) {
    _language = user.data.language;
    localStorage.setItem(userLocalStorageKey("language"), _language);
  } else {
    let savedLanguage = localStorage
      .getItem(userLocalStorageKey("language"))
      ?.split("-")[0];

    if (!savedLanguage) {
      const browserLanguage = getBrowserLanguage({ languageCodeOnly: true });
      savedLanguage = browserLanguage;
    }

    _language = savedLanguage;
    localStorage.setItem(userLocalStorageKey("language"), _language);
  }

  i18n.init({
    interpolation: { escapeValue: false }, // React already protects from xss
    lng: _language,
    fallbackLng: getFallBackLanguage(_language),
    resources: supportedLanguages.reduce((acc, curr) => {
      acc[curr.code] = {
        translation: require(`../languages/${curr.code}.json`),
      };
      return acc;
    }, {}),
  });

  i18n.changeLanguage(_language);
};

const LanguageService = {
  initI18n,
};

export default LanguageService;
