import { Stage, Layer } from "react-konva";
import RackBase from "./RackBase";
import {
  TOP_BORDER_HEIGHT,
  TOP_BORDER_THICKNESS,
} from "../../../util/graphical-rack-view-utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGraphicalViewFitMode,
  setStage,
} from "../../../store/slices/appSlice";
import { useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import Tooltip from "./Tooltip";
import { setVisible } from "../../../store/slices/tooltipSlice";
import { useEffect, useRef, useState } from "react";
import { RackStage } from "../../styles/assets/graphical-rack-view/GraphicalRackView.styles";
import FiltersList from "./FiltersList";

const GraphicalRackViewStage = ({
  rackUnits,
  rackHeight,
  screenWidth,
  fitWidthModeEmptySpaceWidth,
  rows,
  rectangleHeight,
  graphicalViewSide,
  allowedMaxScroll,
  images,
  handleDragMoveStage,
  handleDragEndStage,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const graphicalViewFitMode = useSelector(selectGraphicalViewFitMode);

  // Refs
  const stageRef = useRef();

  // State
  const [scrollInterval, setScrollInterval] = useState(null);

  // Other variables
  const rackResizeCheck = !mobileMatches && graphicalViewFitMode === "height";

  // Handlers
  const handleDragStartStage = (e) => {
    const stage = stageRef?.current?.getStage();

    const canScroll = mobileMatches;

    if (canScroll && e.target.attrs.name !== "stage" && stage) {
      const duration = 0.1;
      const initialPos = stage.getPointerPosition();

      const interval = setInterval(() => {
        const pos = stage.getPointerPosition();
        const offset = 100;

        const isNearTop = pos.y < offset;

        if (isNearTop) {
          stage.to({
            y: stage.y() + 20,
            duration,
          });
        }

        const isNearBottom = pos.y > initialPos.y;

        if (isNearBottom) {
          stage.to({
            y: stage.y() - 20,
            duration,
          });
        }

        if (stage.y() > 0) {
          stage.to({
            y: 0,
            duration,
          });
        }

        if (stage.y() <= -allowedMaxScroll) {
          stage.to({
            y: -allowedMaxScroll,
            duration,
          });
        }
      }, duration * 1000);

      setScrollInterval(interval);
    }
  };

  // Effects
  useEffect(() => {
    if (stageRef && stageRef.current) {
      const stage = stageRef.current.getStage();
      dispatch(setStage(stage));
    }

    return () => dispatch(setStage(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RackStage
      ref={stageRef}
      name="stage"
      offsetY={
        rackResizeCheck
          ? -(TOP_BORDER_HEIGHT / 2 + TOP_BORDER_THICKNESS) + rectangleHeight
          : 0
      }
      width={window.innerWidth}
      height={rackHeight}
      draggable={
        mobileMatches ? mobileMatches : graphicalViewFitMode !== "height"
      }
      onMouseMove={(e) => {
        // FOR DEBUGGING
        // console.log(e.evt.clientX, e.evt.clientY);
      }}
      onMouseEnter={(e) => {
        // style stage container:
        if (graphicalViewFitMode !== "height") {
          const container = e.target.getStage().container();
          container.style.cursor = "grab";
        }
      }}
      onMouseLeave={(e) => {
        if (graphicalViewFitMode !== "height") {
          const container = e.target.getStage().container();
          container.style.cursor = "default";
        }
      }}
      onDragStart={handleDragStartStage}
      onDragMove={(e) => handleDragMoveStage(e, allowedMaxScroll)}
      onDragEnd={(e) => {
        handleDragEndStage(e);
        clearInterval(scrollInterval);
      }}
    >
      <Layer onTap={() => dispatch(setVisible(false))}>
        <RackBase
          screenWidth={screenWidth}
          fitWidthModeEmptySpaceWidth={fitWidthModeEmptySpaceWidth}
          rows={rows}
          graphicalViewSide={graphicalViewSide}
          rectangleHeight={rectangleHeight}
        />
      </Layer>

      {/* Objects*/}
      <Layer>
        {images.map((image, index) => image)}
        <Tooltip rackHeight={rackHeight} />
      </Layer>

      {/* Intersection */}
      <FiltersList
        slots={rackUnits}
        hardwareAssetResources={images}
        rows={rows}
        screenWidth={screenWidth}
        rectangleHeight={rectangleHeight}
      />
      {/* <Layer>{filters.map((item, index) => item)}</Layer> */}
    </RackStage>
  );
};

export default GraphicalRackViewStage;
