import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { BACKEND_URL } from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectGraphicalViewAssetDisplay,
  selectGraphicalViewFitMode,
  selectPageView,
} from "../../../store/slices/appSlice";
import { useGetResourcesImagesPartialQuery } from "../../../store/slices/api/resourceImagesApiSlice";
import {
  useGetAllCharacteristicsQuery,
  useGetAllFunctionsQuery,
  useGetFullResourcesChildrenQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  selectIsGraphicalViewLoading,
  setIsGraphicalViewLoading,
} from "../../../store/slices/assetListSlice";
import {
  useGetGraphicalObjectsListQuery,
  usePatchGraphicalObjectMutation,
} from "../../../store/slices/api/graphicalObjectsSlice";
import { reset } from "../../../store/slices/undoRedoGraphicalViewSlice";
import {
  FIT_WIDTH_MODE_EMPTY_SPACE_PERCENTS,
  PIXELS_PER_RACK_UNIT,
  RESOURCE_CATEGORIES,
  SIDE_FRAMES_PERCENTS,
  USABLE_AREA_PERCENTS,
} from "../../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { mergeCharacteristics } from "../../../util/asset-utils";
import { setVisible } from "../../../store/slices/tooltipSlice";
import {
  getCalculatedHeight,
  getView,
  ASSET_DISPLAY_MODES,
  DESKTOP_NAV_BAR_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
  COLUMN_VIEW_TABS_HEIGHT,
  TABLET_NAV_BAR_HEIGHT,
  TOP_BORDER_HEIGHT,
  TABLET_DETAILS_PAGE_NAV_BAR_HEIGHT,
  TABLET_DETAILS_PAGE_TABS_HEIGHT,
} from "../../../util/graphical-rack-view-utils";
import GraphicalRackObjects from "./GraphicalRackObjects";
import TopBorder from "./TopBorder";
import HardwareAssetCanvas from "./HardwareAssetCanvas";
import ErrorHandling from "../../common/ErrorHandling";
import LoadingSpinnerOverlay from "../../common/LoadingSpinnerOverlay";
import GraphicalRackViewStage from "./GraphicalRackViewStage";
import {
  GraphicalRackViewContainer,
  GraphicalRackViewStageContainer,
} from "../../styles/assets/graphical-rack-view/GraphicalRackView.styles";
import { useGetTypesImagesPartialQuery } from "../../../store/slices/api/typesApiSlice";

const GraphicalRackView = ({ currentResourceData }) => {
  const { id: resourceId } = currentResourceData ?? {};

  // General hooks
  const dispatch = useDispatch();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const isGraphicalViewLoading = useSelector(selectIsGraphicalViewLoading);
  const graphicalViewAssetDisplay = useSelector(
    selectGraphicalViewAssetDisplay
  );
  const appView = useSelector(selectPageView);
  const graphicalViewFitMode = useSelector(selectGraphicalViewFitMode);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: resourcesData,
    isLoading: isLoadingResources,
    isError: isErrorResources,
  } = useGetFullResourcesChildrenQuery({
    parentId: resourceId,
    organizationId,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctionsData,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const hardwareAssetResources = useMemo(
    () =>
      resourcesData?.filter((resource) => {
        const resourceFunction = allFunctionsData?.find(
          (f) => f.id === resource.functionId
        );

        return (
          resourceFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
        );
      }) ?? [],
    [resourcesData, allFunctionsData]
  );

  const {
    data: graphicalObjects,
    isLoading: isLoadingGraphicalObjects,
    isError: isErrorGraphicalObjects,
  } = useGetGraphicalObjectsListQuery({ resourceId, organizationId });

  const { data: characteristicDefinitionsData } = useGetAllCharacteristicsQuery(
    { organizationId }
  );

  const {
    data: resourcesImagesData,
    isLoading: isLoadingResourcesImages,
    isErrorResourcesImages,
  } = useGetResourcesImagesPartialQuery(
    { organizationId, resourceIds: hardwareAssetResources?.map((r) => r.id) },
    {
      skip:
        !resourcesData ||
        graphicalViewAssetDisplay !== ASSET_DISPLAY_MODES.IMAGES,
    }
  );

  const {
    data: typesImagesData,
    isLoading: isLoadingTypesImages,
    isError: isErrorTypesImages,
  } = useGetTypesImagesPartialQuery(
    {
      organizationId,
      typeIds: hardwareAssetResources?.map((r) => r.typeId),
    },
    {
      skip:
        !resourcesData ||
        graphicalViewAssetDisplay !== ASSET_DISPLAY_MODES.BITMAPS,
    }
  );

  // Mutations
  const [patchGraphicalObject, { isLoading: isLoadingPatchGraphicalObject }] =
    usePatchGraphicalObjectMutation();

  // Refs
  const graphicalViewHeaderRef = useRef();

  // States
  const [graphicalView, setGraphicalView] = useState(null);
  const [graphicalViewSide, setGraphicalViewSide] = useState("front");
  const [loadedAssets, setLoadedAssets] = useState([]);
  const [locked, setLocked] = useState(true);
  const [openGraphicalObjects, setOpenGraphicalObjects] = useState(false);
  const [graphicalRackWidth, setGraphicalRackWidth] = useState(0);

  // Other variables
  const currentResourceFunction = allFunctionsData?.find(
    (f) => f.id === currentResourceData.functionId
  );

  const resizeRackCheck = !mobileMatches && graphicalViewFitMode === "height";

  // Handlers
  const getRackUnits = () => {
    const rackUnitsCharacteristicId = characteristicDefinitionsData?.find(
      (c) => c.name === "RACK_UNIT_CAPACITY"
    )?.id;

    const rackUnits = currentResourceData.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    )?.value;

    const typeRackUnits = currentResourceData.type?.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    )?.value;

    const units = rackUnits || typeRackUnits;

    return units ? parseInt(units) : 20;
  };

  const getRackWidth = () => {
    const widthCharacteristicId = characteristicDefinitionsData?.find(
      (c) => c.name === "WIDTH"
    )?.id;

    const rackWidth = currentResourceData.characteristics.find(
      (c) => c.id === widthCharacteristicId
    )?.value;

    const typeRackWidth = currentResourceData.type?.characteristics.find(
      (c) => c.id === widthCharacteristicId
    )?.value;

    const width = rackWidth || typeRackWidth;

    return width ? Math.round(parseInt(width) / 10) : 48;
  };

  const getRows = () => {
    const rackUnits = getRackUnits();

    return rackUnits + 1;
  };

  const getGraphicalRackCalculatedProps = useCallback(() => {
    const rackUnits = getRackUnits();
    const rackWidth = getRackWidth();

    let screenWidth = graphicalRackWidth;

    // Left margin
    const fitWidthModeEmptySpaceWidth = Math.round(
      (screenWidth * FIT_WIDTH_MODE_EMPTY_SPACE_PERCENTS) / 100
    );

    if (resizeRackCheck) {
      screenWidth = screenWidth - 2 * fitWidthModeEmptySpaceWidth;
    }

    const sideFrameWidth = Math.round(
      (screenWidth * SIDE_FRAMES_PERCENTS) / 100
    );

    const usableAreaWidth = Math.round(
      (screenWidth * USABLE_AREA_PERCENTS) / 100
    );

    const usableAreaWidthCm = Math.round(usableAreaWidth / rackWidth);

    let availableHeight = window.innerHeight;

    if (desktopMatches) {
      availableHeight -=
        DESKTOP_NAV_BAR_HEIGHT +
        DESKTOP_HEADER_HEIGHT +
        COLUMN_VIEW_TABS_HEIGHT +
        TOP_BORDER_HEIGHT;
    } else if (tabletMatches) {
      if (appView === "column") {
        availableHeight -=
          TABLET_NAV_BAR_HEIGHT + COLUMN_VIEW_TABS_HEIGHT + TOP_BORDER_HEIGHT;
      } else {
        availableHeight -=
          TABLET_DETAILS_PAGE_NAV_BAR_HEIGHT +
          TABLET_DETAILS_PAGE_TABS_HEIGHT +
          COLUMN_VIEW_TABS_HEIGHT +
          TOP_BORDER_HEIGHT;
      }
    }

    const cellHeight = Math.floor(availableHeight / (rackUnits + 1));

    const rows = getRows();

    const rackHeight = resizeRackCheck
      ? (rows + 3) * cellHeight
      : (rows + 3) * PIXELS_PER_RACK_UNIT * 6;

    const rectangleHeight = resizeRackCheck
      ? cellHeight
      : PIXELS_PER_RACK_UNIT * 6;

    const allowedMaxScroll = rackHeight - 4 * rectangleHeight;

    return {
      rackUnits,
      screenWidth,
      sideFrameWidth,
      usableAreaWidth,
      usableAreaWidthCm,
      rows,
      rackHeight,
      rackWidth,
      rectangleHeight,
      fitWidthModeEmptySpaceWidth,
      availableHeight,
      allowedMaxScroll,
    };
  }, [
    characteristicDefinitionsData,
    currentResourceData,
    resizeRackCheck,
    graphicalRackWidth,
    tabletMatches,
    desktopMatches,
    window.innerHeight,
  ]);

  const toggleLock = () => {
    setLocked(!locked);
    dispatch(setVisible(false));
    dispatch(reset());
  };

  const handleOpenGraphicalObjects = () => {
    setOpenGraphicalObjects(true);
  };

  const handleDragMoveStage = (e, allowedMaxScroll) => {
    if (e.target.attrs.name === "stage") {
      e.target.x(0);

      if (e.target.attrs.y > 0) {
        e.target.y(0);
      }

      if (e.target.attrs.y <= -allowedMaxScroll) {
        e.target.y(-allowedMaxScroll);
      }

      if (e.target.attrs.y < -PIXELS_PER_RACK_UNIT * 6) {
        graphicalViewHeaderRef.current.style.zIndex = 100;
      } else {
        graphicalViewHeaderRef.current.style.zIndex = 0;
      }
    }
  };

  const handleDragEndStage = (e) => {
    if (e.target.attrs.name === "stage") {
      e.target.x(0);
    }
  };

  const handleChangeGraphicalRackViewSide = () => {
    if (graphicalViewSide === "front") {
      setGraphicalViewSide("rear");
    } else {
      setGraphicalViewSide("front");
    }

    setLoadedAssets([]);
    setIsGraphicalViewLoading(true);
  };

  const handlePrepareGraphicalRackViewInfo = useCallback(
    async (latestImagesInfo = []) => {
      const {
        rackUnits,
        sideFrameWidth,
        usableAreaWidth,
        usableAreaWidthCm,
        fitWidthModeEmptySpaceWidth,
      } = getGraphicalRackCalculatedProps();

      // Images info
      let imagesInfo = latestImagesInfo;

      // Collection where we keep track of already loaded images
      let newAssets = loadedAssets;

      // Filling out initial images info
      if (
        (!latestImagesInfo || latestImagesInfo.length <= 0) &&
        Boolean(hardwareAssetResources)
      ) {
        for (const hardwareAsset of hardwareAssetResources) {
          const hardwareAssetResourceCharacteristics =
            hardwareAsset.characteristics;
          const hardwareAssetTypeCharacteristics =
            hardwareAsset.type?.characteristics;

          // Hardware asset merge characteristics
          const hardwareAssetCharacteristics = mergeCharacteristics(
            hardwareAssetResourceCharacteristics,
            hardwareAssetTypeCharacteristics,
            characteristicDefinitionsData
          );

          // Graphical object data for resource
          const graphicalObject = graphicalObjects?.find(
            (go) => go.resourceId === hardwareAsset.id
          );

          const hardwareAssetWidth = hardwareAssetCharacteristics?.find(
            (characteristic) => characteristic?.name === "WIDTH"
          );

          const hardwareAssetHeight = hardwareAssetCharacteristics?.find(
            (characteristic) => characteristic?.name === "HEIGHT"
          );

          // Some assets do not possess these characteristics we do not need them
          const isNoDimensionAsset =
            !Boolean(graphicalObject) ||
            !Boolean(graphicalObject?.yCoordinate) ||
            !Boolean(hardwareAssetWidth) ||
            !Boolean(hardwareAssetHeight);

          if (isNoDimensionAsset) {
            continue;
          }

          // Characteristic values for the given asset
          const currentAssetUnitPosition = graphicalObject
            ? parseInt(graphicalObject.yCoordinate)
            : 1;

          // Scaling the width in the app taking into an account characteristic values from the catalogue
          const currentAssetWidth = hardwareAssetWidth
            ? Math.round(parseInt(hardwareAssetWidth.value) / 10) *
              usableAreaWidthCm
            : 48 * usableAreaWidthCm;

          // Scaling the height to rack units
          const currentAssetHeight = getCalculatedHeight(
            hardwareAssetHeight.value
          );

          // Calculating the empty space on each row, so we can later move the asset to the center depending on how much free space we have
          const currentAssetEmptySpace = Math.round(
            (usableAreaWidth - currentAssetWidth) / 2
          );

          // Parsing the current perspective
          const criteria =
            getView(graphicalObject.angleOfRotation, graphicalViewSide) ===
            "front"
              ? "FRONT"
              : "REAR";

          let imageUri = null;

          // Parsing images information into JSON format
          const images =
            resourcesImagesData?.filter(
              (resourceImage) => resourceImage.resourceId === hardwareAsset.id
            ) ?? [];
          // Getting the URI of the front/rear image based on the current perspective
          if (graphicalViewAssetDisplay === ASSET_DISPLAY_MODES.IMAGES) {
            imageUri = images.find(
              (imageData) => imageData.imageCategory === criteria
            )?.uri;
          }

          // Use the type images only when there is no an user-uploaded image
          if (graphicalViewAssetDisplay === ASSET_DISPLAY_MODES.BITMAPS) {
            // Getting type images
            const typeId = hardwareAsset?.typeId;

            const typeImagesData =
              typesImagesData?.filter(
                (typeImage) => typeImage.typeId === typeId
              ) ?? [];

            // Getting the URI of the front/rear type image based on the current perspective
            imageUri = typeImagesData.find(
              (imageData) => imageData.imageCategory === criteria
            )?.uri;
          }

          const leftMargin = resizeRackCheck ? fitWidthModeEmptySpaceWidth : 0;

          // Constructing image info object
          const imageInfo = {
            rackUnits: currentAssetHeight,
            startPosition: rackUnits - currentAssetUnitPosition + 1,
            realStartPosition: currentAssetUnitPosition,
            resource: hardwareAsset,
            width: currentAssetWidth,
            emptySpace: currentAssetEmptySpace,
            graphicalView: criteria,
            x: leftMargin + sideFrameWidth + currentAssetEmptySpace,
          };

          let image = null;
          // Make request only if there is a imageUri present
          if (imageUri) {
            // Download the image
            const downloadResponse = await fetch(
              BACKEND_URL +
                `/organizations/${organizationId}/` +
                imageUri.replace(`/organizations/${organizationId}/`, ""),
              {
                headers: {
                  authorization: `Bearer ${KeycloakService.getToken()}`,
                },
              }
            );

            // Creating an URL for it
            const blob = await downloadResponse.blob();
            const objectURL = URL.createObjectURL(blob);

            // These will be needed by the `Image` canvas object
            const img = new window.Image();
            img.crossOrigin = "Anonymous";
            img.src = objectURL;

            image = img;
            newAssets.push({ ...imageInfo, image: img });
          }

          // Insert all information which is needed to position the image on the correct place
          imagesInfo.push({
            ...imageInfo,
            image,
          });
        }
      }

      let newImagesInfo = imagesInfo;
      setLoadedAssets([
        ...loadedAssets,
        ...newAssets.filter(
          (newAsset) =>
            !loadedAssets.some(
              (loaded) => loaded.resource.id === newAsset.resource.id
            )
        ),
      ]);

      // Updating imagesInfo when they are intersections
      for (let i = 0; i < imagesInfo.length; i++) {
        const {
          realStartPosition: currentAssetRealStartPosition,
          resource: { id: currentAssetresourceid },
          emptySpace: currentAssetEmptySpace,
        } = imagesInfo[i];

        // Here, we will store the range for the current asset, for example [20, 21, 22] being the slots that the current asset occupies
        const currentAssetRange = Array.from(
          { length: rackUnits },
          (_, index) => currentAssetRealStartPosition + index
        );

        // Getting only the assets that have common rows with the current asset, we do not need to perform any actions for the non-intersected assets
        let intersectedAssets = newImagesInfo
          .map((info) => {
            const { realStartPosition: assetRealStartPosition } = info;

            const assetRange = Array.from(
              { length: rackUnits },
              (_, index) => assetRealStartPosition + index
            );

            const intersection = currentAssetRange.filter((current) =>
              assetRange.includes(current)
            );

            return intersection.length > 0 ? { ...info, intersection } : null;
          })
          .filter(Boolean);

        // Catch any non-intersected assets
        let intersectedAssetsToBeRemoved = [];

        for (let j = 0; j < intersectedAssets.length; j++) {
          const { intersection: currentIntersection } = intersectedAssets[j];

          for (let k = j + 1; k < intersectedAssets.length; k++) {
            const { intersection: nextIntersection } = intersectedAssets[k];

            const intersection = currentIntersection.filter((current) =>
              nextIntersection.includes(current)
            );

            const areIntersected =
              Boolean(intersection) && intersection.length > 0;

            if (!areIntersected) {
              intersectedAssetsToBeRemoved.push(intersectedAssets[k]);
            }
          }
        }

        // Remove any non-intersected assets
        intersectedAssets = intersectedAssets
          .filter(
            (intersected) =>
              !intersectedAssetsToBeRemoved.some(
                (remove) => remove.resource.id === intersected.resource.id
              )
          )
          .map((info) => {
            const {
              rackUnits: assetRackUnits,
              realStartPosition: assetRealStartPosition,
            } = info;

            const assetRange = Array.from(
              { length: assetRackUnits },
              (_, index) => assetRealStartPosition + index
            );

            const intersection = currentAssetRange.filter((current) =>
              assetRange.includes(current)
            );

            return {
              ...info,
              intersection,
            };
          });

        // Apply the logic only if we have an intersection between 2 or more assets
        // It is possible to have an array of single object
        // That means the object is intersected by itself which is always true
        if (intersectedAssets.length > 1) {
          // Declaring variable to store the combined width of all intersected assets and getting the leftmost one on the coordinate system (the one that has the lowest `x` coordinate)
          let combinedWidth = 0;
          let leftmostAsset = intersectedAssets.sort((a, b) => a.x - b.x)[0];

          // Filter out the intersected assets
          // We want to add them again when we perform some manipulations over them
          newImagesInfo = newImagesInfo.filter(
            (newImageInfo) =>
              !intersectedAssets.some(
                (intersected) =>
                  intersected.resource.id === newImageInfo.resource.id
              )
          );

          // Calculating the combined width
          for (const intersectedAsset of intersectedAssets) {
            combinedWidth += intersectedAsset.width;

            if (intersectedAsset.x < leftmostAsset.x) {
              leftmostAsset = intersectedAsset;
            }
          }

          // Only if there is enough width, we will place the assets on the same row
          // Otherwise, we will reset the state of the objects which is quite sufficient for the PoC as we do not support validations yet
          if (combinedWidth <= usableAreaWidth) {
            // Calculate the remaining area and the new `x` position of the leftmost asset
            const leftMargin = resizeRackCheck
              ? fitWidthModeEmptySpaceWidth
              : 0;

            const remainingArea = usableAreaWidth - combinedWidth;
            let newX = leftMargin + sideFrameWidth + remainingArea / 2;

            // Updating the `x` of each intersected asset based on the new `x` position of the leftmost asset
            for (const intersectedAsset of intersectedAssets.sort(
              (a, b) => a.x - b.x
            )) {
              const newAsset = { ...intersectedAsset, x: newX };
              newImagesInfo.push(newAsset);
              newX += intersectedAsset.width;
            }
          } else {
            newImagesInfo.push(...intersectedAssets);
          }
        } else {
          const newItem = newImagesInfo.find(
            (info) => info.resource.id === currentAssetresourceid
          );

          let updatedImagesInfo = newImagesInfo.filter(
            (info) => info.resource.id !== currentAssetresourceid
          );

          const leftMargin = resizeRackCheck ? fitWidthModeEmptySpaceWidth : 0;

          newImagesInfo = [
            ...updatedImagesInfo,
            {
              ...newItem,
              x: leftMargin + sideFrameWidth + currentAssetEmptySpace,
            },
          ];
        }
      }

      return {
        newImagesInfo,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hardwareAssetResources,
      resourcesImagesData,
      typesImagesData,
      characteristicDefinitionsData,
      graphicalObjects,
      graphicalViewSide,
      graphicalViewAssetDisplay,
      resizeRackCheck,
      appView,
      graphicalViewFitMode,
      getGraphicalRackCalculatedProps,
    ]
  );

  const handleDrawGraphicalRackView = useCallback(
    async ({ newImagesInfo }) => {
      const {
        rows,
        screenWidth,
        rackUnits,
        rackHeight,
        rectangleHeight,
        sideFrameWidth,
        fitWidthModeEmptySpaceWidth,
        usableAreaWidth,
        allowedMaxScroll,
      } = getGraphicalRackCalculatedProps();

      // Variables where we will store information about the canvas objects
      let images = [];

      // Preparing the images for being used on the canvas
      for (let i = 0; i < newImagesInfo.length; i++) {
        const hardwareAsset = newImagesInfo[i];

        // Adding image in order to later draw it on the canvas
        images.push(
          <HardwareAssetCanvas
            key={"non-image-" + i}
            hardwareAsset={hardwareAsset}
            locked={locked}
            graphicalObjects={graphicalObjects}
            newImagesInfo={newImagesInfo}
            patchGraphicalObject={patchGraphicalObject}
            organizationId={organizationId}
            loadedAssets={loadedAssets}
            setLoadedAssets={setLoadedAssets}
            setGraphicalView={setGraphicalView}
            handlePrepareGraphicalRackViewInfo={
              handlePrepareGraphicalRackViewInfo
            }
            handleDrawGraphicalRackView={handleDrawGraphicalRackView}
            // Calculated graphical rack view props
            rectangleHeight={rectangleHeight}
            usableAreaWidth={usableAreaWidth}
            sideFrameWidth={sideFrameWidth}
            rackUnits={rackUnits}
          />
        );
      }

      // Draw all the objects on the canvas
      return {
        rackHeight,
        screenWidth,
        rows,
        rectangleHeight,
        fitWidthModeEmptySpaceWidth,
        sideFrameWidth,
        usableAreaWidth,
        graphicalViewSide,
        allowedMaxScroll,
        images,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      graphicalViewSide,
      graphicalObjects,
      locked,
      graphicalRackWidth,
      getGraphicalRackCalculatedProps,
    ]
  );

  const handleUpdateGraphicalRackView = useCallback(async () => {
    const { newImagesInfo } = await handlePrepareGraphicalRackViewInfo();

    const result = await handleDrawGraphicalRackView({
      newImagesInfo,
    });

    setGraphicalView(result);
  }, [handlePrepareGraphicalRackViewInfo, handleDrawGraphicalRackView]);

  // Effects
  useEffect(() => {
    if (currentResourceFunction.category === RESOURCE_CATEGORIES.RACK) {
      const renderGraphicalView = async () => {
        await handleUpdateGraphicalRackView();
        dispatch(setIsGraphicalViewLoading(false));
      };

      renderGraphicalView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUpdateGraphicalRackView, graphicalViewSide, graphicalObjects]);

  useEffect(() => {
    if (!Boolean(graphicalViewHeaderRef?.current)) return;

    const resizedObserver = new ResizeObserver(() => {
      if (graphicalViewHeaderRef.current?.offsetWidth !== graphicalRackWidth) {
        setGraphicalRackWidth(graphicalViewHeaderRef.current?.offsetWidth);
      }
    });

    resizedObserver.observe(graphicalViewHeaderRef.current);

    return () => {
      resizedObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphicalViewHeaderRef?.current]);

  useEffect(() => {
    if (mobileMatches) {
      document.body.style.overflowY = "hidden";
    }

    return () => {
      dispatch(reset());
      if (mobileMatches) {
        document.body.style.overflowY = "scroll";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Other variables
  const isLoading =
    isGraphicalViewLoading ||
    isLoadingPatchGraphicalObject ||
    isLoadingResources ||
    isLoadingResourcesImages ||
    isLoadingTypesImages ||
    isLoadingGraphicalObjects ||
    isLoadingFunctionsData;

  return (
    <ErrorHandling
      isLoading={false}
      isError={
        isErrorFunctions ||
        isErrorGraphicalObjects ||
        isErrorResources ||
        isErrorResourcesImages ||
        isErrorTypesImages
      }
    >
      <GraphicalRackViewContainer>
        <TopBorder
          elementRef={graphicalViewHeaderRef}
          graphicalViewSide={graphicalViewSide}
          locked={locked}
          toggleLock={toggleLock}
          handleChangeGraphicalRackViewSide={handleChangeGraphicalRackViewSide}
          handleOpenGraphicalObjects={handleOpenGraphicalObjects}
        />
        {isLoading && <LoadingSpinnerOverlay />}

        {Boolean(graphicalView) && graphicalRackWidth > 0 ? (
          <>
            {openGraphicalObjects && (
              <GraphicalRackObjects
                rows={getRows()}
                resourceChildren={hardwareAssetResources}
                graphicalObjects={graphicalObjects}
                characteristicDefinitions={characteristicDefinitionsData}
                setOpen={setOpenGraphicalObjects}
                open={openGraphicalObjects}
              />
            )}

            <GraphicalRackViewStageContainer>
              <GraphicalRackViewStage
                handleDragMoveStage={handleDragMoveStage}
                handleDragEndStage={handleDragEndStage}
                {...graphicalView}
              />
            </GraphicalRackViewStageContainer>
          </>
        ) : (
          ""
        )}
      </GraphicalRackViewContainer>
    </ErrorHandling>
  );
};

export default GraphicalRackView;
