import {
  Box,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTypeInput,
  selectIsFirstSubmitted,
  setTypeInput,
} from "../../../store/slices/typeInputSlice";
import {
  CATEGORY_SELECT_LIST,
  getTranslation,
  RESOURCE_CATEGORIES,
} from "../../../util/utils";
import SelectInput from "../../SelectInput";
import FunctionInput from "../../assets/asset-form/FunctionInput";
import TypeCharacteristicsInputGroup from "../type-form/TypeCharacteristicsInputGroup";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import {
  BgAccordion,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getSvgIcon } from "../../../util/icons";

const TypeForm = ({ mode }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const typeInput = useSelector(selectTypeInput);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const textRefType = useRef(null);
  const textRefBasicData = useRef(null);
  const iconRefType = useRef(null);
  const iconRefBasicData = useRef(null);

  // State
  const [borderWidthType, setBorderWidthType] = useState("25%");
  const [borderWidthBasicData, setBorderWidthBasicData] = useState("25%");

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleChangeName = (e) => {
    const newName = e.target.value.trimStart();

    dispatch(
      setTypeInput({
        ...typeInput,
        name: newName,
      })
    );
  };

  const handleChangeDisplayId = (e) => {
    const newDisplayId = e.target.value.trimStart();
    dispatch(setTypeInput({ ...typeInput, displayId: newDisplayId }));
  };

  const handleChangeDescription = (e) => {
    const newDescription = e.target.value.trimStart();

    dispatch(
      setTypeInput({
        ...typeInput,
        description: newDescription,
      })
    );
  };

  const handleChangeCategory = (value) => {
    const newCategory = value;
    dispatch(
      setTypeInput({
        ...typeInput,
        category: newCategory,
        functionId: null,
      })
    );
  };

  const handleChangeFunctionId = (value) => {
    const newFunctionId = value;
    dispatch(
      setTypeInput({
        ...typeInput,
        functionId: newFunctionId,
        hasFunctionIdChanged: true,
      })
    );
  };

  // Effects
  useEffect(() => {
    if (textRefType.current && iconRefType.current) {
      const textWidth = textRefType.current.offsetWidth;
      const totalWidth = textRefType.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefType.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthType(`${percentage}%`);
    }

    if (textRefBasicData.current && iconRefBasicData.current) {
      const textWidth = textRefBasicData.current.offsetWidth;
      const totalWidth = textRefBasicData.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefBasicData.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthBasicData(`${percentage}%`);
    }
  }, []);

  return (
    <Stack marginTop={2} spacing={3}>
      {/* Basic data Accordion */}
      {mobileMatches ? (
        <BgAccordion defaultExpanded>
          <StyledAccordionSummary
            value={borderWidthBasicData}
            expandIcon={
              <div
                style={{ marginTop: "5px", marginRight: "5px" }}
                ref={iconRefBasicData}
              >
                {getSvgIcon(
                  "ARROW_RIGHT",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle variant="body2" istablet={1} ref={textRefBasicData}>
              {getTranslation("BASIC_DATA", t, i18n)}
            </SectionTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    data-testid="name"
                    required={true}
                    label={getTranslation("NAME", t, i18n)}
                    value={typeInput.name}
                    onChange={handleChangeName}
                    error={isFirstSubmitted && !typeInput.name}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    data-testid="display-id"
                    required={true}
                    label={getTranslation("DISPLAY_ID", t, i18n)}
                    value={typeInput.displayId ?? ""}
                    onChange={handleChangeDisplayId}
                    error={isFirstSubmitted && !typeInput.displayId}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    data-testid="notes"
                    required={true}
                    label={getTranslation("NOTES", t, i18n)}
                    value={typeInput.description}
                    onChange={handleChangeDescription}
                    error={isFirstSubmitted && !typeInput.description}
                    placeholder={`${getTranslation(
                      "MANUFACTURER",
                      t,
                      i18n
                    )}, ${getTranslation(
                      "DISPLAY_ID",
                      t,
                      i18n
                    )} - ${getTranslation("DETAILS", t, i18n)}`}
                    multiline
                    rows={3}
                  />
                </Box>
              </Grid>
            </Grid>
          </StyledAccordionDetails>
        </BgAccordion>
      ) : (
        <StyledAccordion currentTheme={currentTheme} defaultExpanded>
          <StyledAccordionSummary
            value={borderWidthBasicData}
            expandIcon={
              <div
                style={{ marginTop: "5px", marginRight: "5px" }}
                ref={iconRefBasicData}
              >
                {getSvgIcon(
                  "ARROW_RIGHT",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle variant="body2" istablet={1} ref={textRefBasicData}>
              {getTranslation("BASIC_DATA", t, i18n)}
            </SectionTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    data-testid="name"
                    required={true}
                    label={getTranslation("NAME", t, i18n)}
                    value={typeInput.name}
                    onChange={handleChangeName}
                    error={isFirstSubmitted && !typeInput.name}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    data-testid="display-id"
                    required={true}
                    label={getTranslation("DISPLAY_ID", t, i18n)}
                    value={typeInput.displayId ?? ""}
                    onChange={handleChangeDisplayId}
                    error={isFirstSubmitted && !typeInput.displayId}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    data-testid="notes"
                    required={true}
                    label={getTranslation("NOTES", t, i18n)}
                    value={typeInput.description}
                    onChange={handleChangeDescription}
                    error={isFirstSubmitted && !typeInput.description}
                    placeholder={`${getTranslation(
                      "MANUFACTURER",
                      t,
                      i18n
                    )}, ${getTranslation(
                      "DISPLAY_ID",
                      t,
                      i18n
                    )} - ${getTranslation("DETAILS", t, i18n)}`}
                    multiline
                    rows={3}
                  />
                </Box>
              </Grid>
            </Grid>
          </StyledAccordionDetails>
        </StyledAccordion>
      )}

      {/* Type Accordion */}
      {mobileMatches ? (
        <BgAccordion defaultExpanded>
          <StyledAccordionSummary
            value={borderWidthType}
            expandIcon={
              <div
                style={{ marginTop: "5px", marginRight: "5px" }}
                ref={iconRefType}
              >
                {getSvgIcon(
                  "ARROW_RIGHT",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle variant="body2" istablet={1} ref={textRefType}>
              {getTranslation("TYPE", t, i18n)}
            </SectionTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <SelectInput
                    fullWidth
                    selectLabelId="resourceCategory-label"
                    label={getTranslation("RESOURCE_CATEGORY", t, i18n)}
                    handleChange={handleChangeCategory}
                    data={CATEGORY_SELECT_LIST.filter(
                      (r) => r.value !== RESOURCE_CATEGORIES.LOCATION
                    )}
                    value={typeInput.category}
                    required={true}
                    error={isFirstSubmitted && !typeInput.category}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <FunctionInput
                    changeHandler={handleChangeFunctionId}
                    isFirstSubmitted={isFirstSubmitted}
                    functionId={typeInput.functionId}
                    category={typeInput.category}
                    required={true}
                    onlyWithType={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </StyledAccordionDetails>
        </BgAccordion>
      ) : (
        <StyledAccordion currentTheme={currentTheme} defaultExpanded>
          <StyledAccordionSummary
            value={borderWidthType}
            expandIcon={
              <div
                style={{ marginTop: "5px", marginRight: "5px" }}
                ref={iconRefType}
              >
                {getSvgIcon(
                  "ARROW_RIGHT",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle variant="body2" istablet={1} ref={textRefType}>
              {getTranslation("TYPE", t, i18n)}
            </SectionTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <SelectInput
                    fullWidth
                    selectLabelId="resourceCategory-label"
                    label={getTranslation("RESOURCE_CATEGORY", t, i18n)}
                    handleChange={handleChangeCategory}
                    data={CATEGORY_SELECT_LIST.filter(
                      (r) => r.value !== RESOURCE_CATEGORIES.LOCATION
                    )}
                    value={typeInput.category}
                    required={true}
                    error={isFirstSubmitted && !typeInput.category}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <FunctionInput
                    changeHandler={handleChangeFunctionId}
                    isFirstSubmitted={isFirstSubmitted}
                    functionId={typeInput.functionId}
                    category={typeInput.category}
                    required={true}
                    onlyWithType={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </StyledAccordionDetails>
        </StyledAccordion>
      )}

      <TypeCharacteristicsInputGroup mode={mode} />

      <div id="end"></div>
    </Stack>
  );
};

export default TypeForm;
