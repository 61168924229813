import { memo } from "react";
import {
  SIDE_FRAMES_PERCENTS,
  USABLE_AREA_PERCENTS,
} from "../../../util/utils";
import RackUnitRectangle from "./RackUnitRectangle";

const RackBase = ({
  screenWidth,
  rows,
  rectangleHeight,
  fitWidthModeEmptySpaceWidth,
}) => {
  // Other variables
  const usableAreaWidth = Math.round(
    (screenWidth * USABLE_AREA_PERCENTS) / 100
  );

  const rectangles = new Array(rows).fill({});

  const sideFrameWidth = Math.round((screenWidth * SIDE_FRAMES_PERCENTS) / 100);

  return rectangles.map((r, i) => (
    <RackUnitRectangle
      key={`unit-${i}`}
      i={i}
      usableAreaWidth={usableAreaWidth}
      rectangleHeight={rectangleHeight}
      fitWidthModeEmptySpaceWidth={fitWidthModeEmptySpaceWidth}
      sideFrameWidth={sideFrameWidth}
      rowNumber={`${rows - i}`}
      rows={rows}
    />
  ));
};

export default memo(RackBase);
