import { useEffect, useState } from "react";
import "../Onboarding.css";
import { useTranslation } from "react-i18next";
import SelectInput from "../../../components/SelectInput";
import {
  OnboardingContainer,
  PrimaryText,
  SelectInputsContainer,
} from "../../../components/styles/onboarding/Onboarding.styles";
import { useGetDataStorageLocationsQuery } from "../../../store/slices/api/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectOrganizationData } from "../../../store/slices/onboardingSlice";
import { setOrganizationData } from "../../../store/slices/onboardingSlice";
import { getTranslation } from "../../../util/utils";
import PageTransition from "../../../components/PageTransition";
import ErrorHandling from "../../../components/common/ErrorHandling";
import { Box, useTheme } from "@mui/material";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const OnboardingOrganizationDataLocationPage = ({ transitionDirection }) => {
  // General Hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  // Selectors
  const organizationData = useSelector(selectOrganizationData);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [dirty, setDirty] = useState({});

  // Data storage locations
  const {
    data: dataStorageLocations,
    isLoading,
    isError,
  } = useGetDataStorageLocationsQuery();

  // Handlers
  const transformDataStorageLocationsData = (dataStorageLocations) =>
    dataStorageLocations?.map((instance) => {
      return {
        label: i18n.exists(instance.displayId)
          ? t(instance.displayId)
          : instance.name,
        value: instance.id,
      };
    });

  const handleChangeDataStorageLocation = (value) =>
    dispatch(
      setOrganizationData({
        ...organizationData,
        dataStorageLocationId: value,
      })
    );

  const handleOnBlur = (e) =>
    setDirty({
      ...dirty,
      [e.target.name]: true,
    });

  // Other variables
  const iconSize = globalFontSize;

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <PageTransition direction={transitionDirection}>
        <PrimaryText sx={{ marginBottom: "10px" }} variant="h2" align="start">
          {getTranslation("DATA_LOCATION", t, i18n)}
        </PrimaryText>

        <PrimaryText sx={{ marginBottom: "20px" }} variant="body2">
          {getTranslation("ORGANIZATION_DATA_LOCATION_CAPTION", t, i18n)}
        </PrimaryText>

        <OnboardingContainer>
          {/* Description explaining onboarding step*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              marginBottom: "10px",
              columnGap: "5px",
            }}
          >
            {getSvgIcon("WARNING", iconSize, iconSize, "#FF7634", {
              color: theme.palette.primary.contrastText,
            })}

            <PrimaryText
              sx={{ marginTop: "5px", textWrap: "wrap" }}
              variant="body2"
              align="center"
            >
              {getTranslation("userGeoLocationStorage", t, i18n)}
            </PrimaryText>
          </Box>

          <SelectInputsContainer>
            {/* Storage location for the data select input*/}
            <SelectInput
              fullWidth
              label="dataStorageLocationSelection"
              selectLabelId="data-storage-location-select-box"
              name="dataStorageLocationSelection"
              value={organizationData.dataStorageLocationId}
              error={
                dirty["dataStorageLocationSelection"] &&
                !organizationData.dataStorageLocationId
              }
              onBlur={handleOnBlur}
              handleChange={handleChangeDataStorageLocation}
              data={transformDataStorageLocationsData(dataStorageLocations)}
            />
          </SelectInputsContainer>
        </OnboardingContainer>
      </PageTransition>
    </ErrorHandling>
  );
};

export default OnboardingOrganizationDataLocationPage;
