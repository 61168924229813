import { Box, Grid, Tooltip, useTheme } from "@mui/material";
import { useGetOrganizationUsersQuery } from "../../store/slices/api/organizationsApiSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import ErrorHandling from "../common/ErrorHandling";
import {
  FlexBoxContainer,
  MembersBoxContainer,
  OrganizationDesktopCardMembersCountTypography,
  OverflowedWrapper,
  SubscriptionUsageBoxContainer,
} from "../styles/profile/ProfileDesktop.styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { PrimaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import Layer2Alerts from "../Layer2Alerts";
import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";

const OrganizationDesktopCardMembers = ({ organization, userRoles }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Custom hooks
  const { isLoading, isRestricted } =
    useCheckOrganizationRestricted(organization);

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: organizationUsers,
    isLoading: isLoadingOrganizationUsers,
    isError: isErrorOrganizationUsers,
  } = useGetOrganizationUsersQuery(organization?.id, {
    skip:
      isRestricted ||
      !hasAccess(
        "all",
        [permissions.USER_MANAGEMENT_VIEW],
        getPermissionsFromUserRoles(userRoles)
      ),
  });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: organization?.id,
      },
      {
        skip: isRestricted,
      }
    );

  // Other variables
  const ownersString = organizationUsers
    ?.filter((u) => u.userRoles.some((ur) => ur.name === "OWNER"))
    ?.map(
      (u) =>
        u.firstName + " " + u.lastName + `${u.id === user?.id ? " (You)" : ""}`
    )
    ?.join(", ");

  const adminsString = organizationUsers
    ?.filter((u) => u.userRoles.some((ur) => ur.name === "ADMIN"))
    ?.map(
      (u) =>
        u.firstName + " " + u.lastName + `${u.id === user?.id ? " (You)" : ""}`
    )
    ?.join(", ");

  const editorsString = organizationUsers
    ?.filter((u) => u.userRoles.some((ur) => ur.name === "EDITOR"))
    ?.map(
      (u) =>
        u.firstName + " " + u.lastName + `${u.id === user?.id ? " (You)" : ""}`
    )
    ?.join(", ");

  const commentersString = organizationUsers
    ?.filter((u) => u.userRoles.some((ur) => ur.name === "COMMENTER"))
    ?.map(
      (u) =>
        u.firstName + " " + u.lastName + `${u.id === user?.id ? " (You)" : ""}`
    )
    ?.join(", ");

  const facts = actualUsage?.facts;
  const assetsUsage = facts?.find((f) => f.name === "ASSET_COUNT");
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const assetsSubscriptionValue = assetsUsage?.subscriptionValue
    ? Number(assetsUsage?.subscriptionValue)
    : "-";
  const assetsCurrentValue = assetsUsage?.currentValue
    ? Number(assetsUsage?.currentValue)
    : "-";
  const usersSubscriptionValue = assetsUsage?.currentValue
    ? Number(usersUsage?.subscriptionValue)
    : "-";
  const usersCurrentValue = organizationUsers?.length ?? 0;
  const iconSize = globalFontSize * 1.2;

  return (
    <ErrorHandling
      isLoading={
        isLoadingOrganizationUsers || isLoading || isLoadingActualUsage
      }
      isError={isErrorOrganizationUsers}
      shouldThrowError={false}
    >
      <SubscriptionUsageBoxContainer>
        {!isRestricted &&
          hasAccess(
            "all",
            [permissions.USER_MANAGEMENT_VIEW],
            getPermissionsFromUserRoles(userRoles)
          ) && (
            <Box>
              <Tooltip
                title={`${usersCurrentValue}/${usersSubscriptionValue} users`}
              >
                <FlexBoxContainer>
                  {getSvgIcon(
                    "FORUM",
                    iconSize,
                    iconSize,
                    theme.palette.primary.main
                  )}

                  <OrganizationDesktopCardMembersCountTypography>
                    {usersCurrentValue}/{usersSubscriptionValue}
                  </OrganizationDesktopCardMembersCountTypography>
                </FlexBoxContainer>
              </Tooltip>
            </Box>
          )}

        {!isRestricted && (
          <Box>
            <Tooltip
              title={`${getTranslation(
                organization?.subscription?.tier?.name,
                t,
                i18n
              )} tier`}
            >
              <FlexBoxContainer>
                {getSvgIcon(
                  "MONEY",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.light
                )}
                <OrganizationDesktopCardMembersCountTypography>
                  {getTranslation(
                    organization?.subscription?.tier?.name,
                    t,
                    i18n
                  ) ?? "Subscription not available"}
                </OrganizationDesktopCardMembersCountTypography>
              </FlexBoxContainer>
            </Tooltip>
          </Box>
        )}

        {!isRestricted && (
          <Box>
            <Tooltip
              title={`${assetsCurrentValue}/${assetsSubscriptionValue} assets`}
            >
              <FlexBoxContainer>
                {getSvgIcon(
                  "ASSETS",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}

                <OrganizationDesktopCardMembersCountTypography>
                  {assetsCurrentValue}/{assetsSubscriptionValue} assets
                </OrganizationDesktopCardMembersCountTypography>
              </FlexBoxContainer>
            </Tooltip>
          </Box>
        )}
      </SubscriptionUsageBoxContainer>

      <Layer2Alerts organization={organization} />

      {hasAccess(
        "all",
        [permissions.USER_MANAGEMENT_VIEW],
        getPermissionsFromUserRoles(userRoles)
      ) && (
        <>
          {organizationUsers?.filter((u) =>
            u.userRoles.some((ur) => ur.name === "OWNER")
          )?.length > 0 && (
            <MembersBoxContainer>
              <Tooltip title={ownersString}>
                <Grid container columnSpacing={0.5}>
                  <Grid item>
                    <PrimaryText>
                      {getTranslation("OWNER", t, i18n)}:
                    </PrimaryText>
                  </Grid>
                  <OverflowedWrapper item xs>
                    <PrimaryText> {ownersString}</PrimaryText>
                  </OverflowedWrapper>
                </Grid>
              </Tooltip>
            </MembersBoxContainer>
          )}

          {organizationUsers?.filter((u) =>
            u.userRoles.some((ur) => ur.name === "ADMIN")
          )?.length > 0 && (
            <MembersBoxContainer>
              <Tooltip title={adminsString}>
                <Grid container columnSpacing={0.5}>
                  <Grid item>
                    <PrimaryText>
                      {getTranslation("ADMIN", t, i18n)}:
                    </PrimaryText>
                  </Grid>
                  <OverflowedWrapper item xs>
                    <PrimaryText>{adminsString}</PrimaryText>
                  </OverflowedWrapper>
                </Grid>
              </Tooltip>
            </MembersBoxContainer>
          )}

          {organizationUsers?.filter((u) =>
            u.userRoles.some((ur) => ur.name === "EDITOR")
          )?.length > 0 && (
            <MembersBoxContainer>
              <Tooltip title={editorsString}>
                <Grid container columnSpacing={0.5}>
                  <Grid item>
                    <PrimaryText>
                      {getTranslation("EDITOR", t, i18n)}:
                    </PrimaryText>
                  </Grid>
                  <OverflowedWrapper item xs>
                    <PrimaryText>{editorsString}</PrimaryText>
                  </OverflowedWrapper>
                </Grid>
              </Tooltip>
            </MembersBoxContainer>
          )}

          {organizationUsers?.filter((u) =>
            u.userRoles.some((ur) => ur.name === "COMMENTER")
          )?.length > 0 && (
            <MembersBoxContainer>
              <Tooltip title={commentersString}>
                <Grid container columnSpacing={0.5}>
                  <Grid item>
                    <PrimaryText>
                      {getTranslation("COMMENTER", t, i18n)}:
                    </PrimaryText>
                  </Grid>
                  <OverflowedWrapper item xs>
                    <PrimaryText>{commentersString}</PrimaryText>
                  </OverflowedWrapper>
                </Grid>
              </Tooltip>
            </MembersBoxContainer>
          )}
        </>
      )}
    </ErrorHandling>
  );
};

export default OrganizationDesktopCardMembers;
