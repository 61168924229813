import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectPageView,
} from "../../store/slices/appSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import { SecondaryContrastButton } from "../styles/general/General.styles";
import {
  ButtonContainer,
  StyledBox,
} from "../styles/buttons/CreateNewButton.styles";
import { getSvgIcon } from "../../util/icons";
import { useTheme } from "@mui/material";

const CreateNewButton = ({ handler, userRoles, name }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const view = useSelector(selectPageView);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  return (
    <StyledBox>
      <ButtonContainer view={view}>
        <SecondaryContrastButton
          disabled={
            !hasAccess(
              "all",
              [
                permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                permissions.ASSET_MANAGEMENT_LOCATION_ADD,
                permissions.ASSET_MANAGEMENT_RACK_ADD,
              ],
              getPermissionsFromUserRoles(userRoles)
            )
          }
          onClick={handler}
        >
          <div style={{ marginRight: "5px", marginTop: "5px" }}>
            {getSvgIcon(
              "CREATE_NEW",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </div>

          {getTranslation(name, t, i18n)}
        </SecondaryContrastButton>
      </ButtonContainer>
    </StyledBox>
  );
};

export default CreateNewButton;
