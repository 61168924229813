import { Box, ListItemText, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const TopSpacedMenuItem = styled(MenuItem)(({ desktopMatches }) => ({
  marginTop: "5px",
  cursor: desktopMatches ? "default" : "pointer",
}));

export const PreferenceMenuItem = styled(MenuItem)({
  overflow: "hidden",
  paddingInline: "8px",
});

export const PreferenceItemName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h6.fontSize,
  width: "auto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginRight: "30px",
  maxWidth: "200px",
}));

export const MemberItemMobileName = styled(PreferenceItemName)({
  maxWidth: "250px",
});

export const MemberItemName = styled(PreferenceItemName)({
  fontSize: "16px",
  maxWidth: "250px",
});

export const PreferenceItemNameSecondaryWrapper = styled(Box)({
  flexShrink: 1,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const PreferenceItemNameSecondary = styled(ListItemText)(
  ({ theme }) => ({
    color: theme.palette.text.secondary,
    textAlign: "end",
    "> span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "start",
    },
  })
);

export const PreferenceItemEmail = styled(PreferenceItemName)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: "16px",
}));

export const PreferenceItemNameWrapper = styled(Box)(({ hasvalue }) => ({
  flexGrow: 1,
  flexShrink: 1 ? 0 : 1,
  display: "flex",
  overflow: "hidden",
}));
