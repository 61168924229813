import { Stack, TextField, Box, Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation, testInput } from "../../util/utils";
import {
  InputContainer,
  ValidationText,
} from "../styles/inputs/NamesGroup.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrganizationData,
  setOrganizationData,
  setOrganizationDataCountry,
} from "../../store/slices/onboardingSlice";
import { useState, useEffect } from "react";
import { inputLabelProps } from "./BillingGroup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { selectTheme } from "../../store/slices/appSlice";
import { SelectInputsContainer } from "../styles/onboarding/Onboarding.styles";
import CountriesAutocomplete from "./CountriesAutocomplete";

const COUNTRY_CODE_DEFINITION = {
  BROWSER: "BROWSER",
  MANUAL: "MANUAL",
};

const OrganizationDetailsGroup = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const currentTheme = useSelector(selectTheme);
  const organizationData = useSelector(selectOrganizationData);

  // State
  const [organizationAddressLine1Valid, setOrganizationAddressLine1Valid] =
    useState(true);
  const [organizationPostalCodeValid, setOrganizationPostalCodeValid] =
    useState(true);
  const [organizationCityValid, setOrganizationCityValid] = useState(true);
  const [isOrganizationPhoneValid, setIsOrganizationPhoneValid] =
    useState(true);
  const [countryCode, setCountryCode] = useState({
    definition_type: null,
    value: null,
  });

  // Other variables
  const phoneInputProps = { "data-testid": "phone-input" };

  const phoneInputStyle = {
    marginTop: "15px",
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.secondary,
    width: "100%",
  };

  const phoneInputButtonStyle = { marginTop: "15px" };

  const phoneInputDropdownStyle = {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  };

  // Handlers
  const handleOrganizationAddressLine1Change = (event) => {
    const isValid = testInput.ORGANIZATION_NAME(event.target.value);

    dispatch(
      setOrganizationData({
        ...organizationData,
        addressLine1: event.target.value,
      })
    );

    setOrganizationAddressLine1Valid(isValid);
  };

  const handleOrganizationAddressLine2Change = (event) => {
    dispatch(
      setOrganizationData({
        ...organizationData,
        addressLine2: event.target.value,
      })
    );
  };

  const handleOrganizationPostalCodeChange = (event) => {
    const isValid = testInput.ORGANIZATION_NAME(event.target.value);

    dispatch(
      setOrganizationData({
        ...organizationData,
        postcode: event.target.value,
      })
    );

    setOrganizationPostalCodeValid(isValid);
  };

  const handleOrganizationCityChange = (event) => {
    const isValid = testInput.ORGANIZATION_NAME(event.target.value);

    dispatch(
      setOrganizationData({
        ...organizationData,
        city: event.target.value,
      })
    );

    setOrganizationCityValid(isValid);
  };

  const handleOrganizationStateChange = (event) => {
    dispatch(
      setOrganizationData({
        ...organizationData,
        state: event.target.value,
      })
    );
  };

  const handleOrganizationCountryChange = (value) => {
    dispatch(
      setOrganizationData({
        ...organizationData,
        country: value,
      })
    );
  };

  const handlePhoneChange = (value, country) => {
    const isValid = testInput.PHONE_NUMBER(value);

    dispatch(
      setOrganizationData({
        ...organizationData,
        contactPhone: value,
      })
    );

    if (countryCode.value !== country.countryCode) {
      setCountryCode({
        definition_type: COUNTRY_CODE_DEFINITION.MANUAL,
        value: country?.countryCode,
      });
    }

    setIsOrganizationPhoneValid(isValid);
  };

  useEffect(() => {
    if (!organizationData.contactPhone) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        )
          .then((response) => response.json())
          .then((data) => {
            setCountryCode({
              definition_type: COUNTRY_CODE_DEFINITION.BROWSER,
              value: data.countryCode,
            });
            dispatch(setOrganizationDataCountry(data.countryCode));
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={0}>
      {/* Organization address line 1 input */}
      <InputContainer>
        <TextField
          inputProps={{ "data-testid": "organization-address-line-1-input" }}
          id="organization-address-line-1-input"
          type="text"
          label={getTranslation("ADDRESS_LINE1", t, i18n)}
          required
          placeholder={getTranslation("ADDRESS_LINE1", t, i18n)}
          name="addressLine1"
          value={organizationData.addressLine1}
          onChange={handleOrganizationAddressLine1Change}
          error={!organizationAddressLine1Valid}
          InputLabelProps={inputLabelProps}
        />

        <Box>
          {!organizationAddressLine1Valid && (
            <ValidationText
              data-testid="validation-text-organization-address-line-1"
              variant="body5"
              align="center"
            >
              {getTranslation("ORGANIZATION_ADDRESS_LINE1_VALIDATION", t, i18n)}
            </ValidationText>
          )}
        </Box>
      </InputContainer>

      {/* Organization address line 2 input */}
      <InputContainer>
        <TextField
          inputProps={{ "data-testid": "organization-address-line-2-input" }}
          id="organization-address-line-2-input"
          type="text"
          label={getTranslation("ADDRESS_LINE2", t, i18n)}
          placeholder={getTranslation("ADDRESS_LINE2", t, i18n)}
          name="addressLine2"
          value={organizationData.addressLine2}
          onChange={handleOrganizationAddressLine2Change}
          InputLabelProps={inputLabelProps}
        />
      </InputContainer>

      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          {/* Organization postal code input */}
          <TextField
            inputProps={{ "data-testid": "organization-postal-code-input" }}
            id="organization-postal-code-input"
            type="text"
            label={getTranslation("POSTAL_CODE", t, i18n)}
            required
            placeholder={getTranslation("POSTAL_CODE", t, i18n)}
            name="postalCode"
            value={organizationData.postcode}
            onChange={handleOrganizationPostalCodeChange}
            error={!organizationPostalCodeValid}
            InputLabelProps={inputLabelProps}
          />

          <Box>
            {!organizationPostalCodeValid && (
              <ValidationText
                data-testid="validation-text-organization-postal-code"
                variant="body5"
                align="center"
              >
                {getTranslation("ORGANIZATION_POSTAL_CODE_VALIDATION", t, i18n)}
              </ValidationText>
            )}
          </Box>
        </Grid>

        <Grid item xs={6}>
          {/* Organization city input */}
          <TextField
            inputProps={{ "data-testid": "organization-city-input" }}
            id="organization-city-input"
            type="text"
            label={getTranslation("CITY", t, i18n)}
            required
            placeholder={getTranslation("CITY", t, i18n)}
            name="city"
            value={organizationData.city}
            onChange={handleOrganizationCityChange}
            error={!organizationCityValid}
            InputLabelProps={inputLabelProps}
          />

          <Box>
            {!organizationCityValid && (
              <ValidationText
                data-testid="validation-text-organization-city"
                variant="body5"
                align="center"
              >
                {getTranslation("ORGANIZATION_CITY_VALIDATION", t, i18n)}
              </ValidationText>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Organization state input */}
      <InputContainer>
        <TextField
          inputProps={{ "data-testid": "organization-state-input" }}
          id="organization-state-input"
          type="text"
          label={getTranslation("STATE", t, i18n)}
          placeholder={getTranslation("STATE", t, i18n)}
          name="state"
          value={organizationData.state}
          onChange={handleOrganizationStateChange}
          InputLabelProps={inputLabelProps}
        />
      </InputContainer>

      {/* Organization country input */}
      <SelectInputsContainer>
        <CountriesAutocomplete
          value={organizationData.country}
          handleChange={handleOrganizationCountryChange}
        />
      </SelectInputsContainer>

      {/* Contact phone input */}
      <InputContainer>
        <PhoneInput
          inputProps={phoneInputProps}
          containerClass={currentTheme}
          inputClass={"phone-input"}
          specialLabel={getTranslation("phoneNumber", t, i18n)}
          inputStyle={phoneInputStyle}
          buttonStyle={phoneInputButtonStyle}
          dropdownStyle={phoneInputDropdownStyle}
          id="phone-input"
          placeholder={getTranslation("phoneNumber", t, i18n)}
          value={organizationData.contactPhone}
          country={
            countryCode.definition_type === COUNTRY_CODE_DEFINITION.MANUAL
              ? countryCode.value?.toLowerCase()
              : organizationData.country?.toLowerCase()
          }
          onChange={handlePhoneChange}
          isValid={() => isOrganizationPhoneValid}
          defaultErrorMessage={getTranslation("phoneNumber", t, i18n)}
        />
        <Box>
          {!isOrganizationPhoneValid && (
            <ValidationText
              data-testid="validation-text-phone-number"
              variant="body5"
              align="center"
            >
              {getTranslation("phoneNumberValidation", t, i18n)}
            </ValidationText>
          )}
        </Box>
      </InputContainer>
    </Stack>
  );
};

export default OrganizationDetailsGroup;
