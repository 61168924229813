import { styled } from "@mui/system";
import { Divider, ListItemIcon, ListItemText, Menu } from "@mui/material";

export const StyledOptionsMenu = styled(Menu)({
  marginTop: "15px",
  ".MuiMenu-paper": { borderRadius: "15px", outline: "none" },
  ".MuiMenu-list": {
    width: "240px",
    borderRadius: "15px",
  },
});

export const ColumnViewListItemIcon = styled(ListItemIcon)(
  ({ currentpageview }) => ({
    visibility: currentpageview === "column" ? "visible" : "hidden",
  })
);

export const EllipsisListItemText = styled(ListItemText)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const InlineViewListItemIcon = styled(ListItemIcon)(
  ({ currentpageview }) => ({
    visibility: currentpageview === "inline" ? "visible" : "hidden",
  })
);

export const AppViewListItemIcon = styled(ListItemIcon)(
  ({ currentpageview }) => ({
    visibility: currentpageview === "app" ? "visible" : "hidden",
  })
);

export const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  borderBottomWidth: 3,
}));

export const SortingOptionListItemIcon = styled(ListItemIcon)(
  ({ sortingOption, value }) => ({
    visibility: sortingOption.value === value ? "visible" : "hidden",
  })
);
