import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { StyledDivider } from "../../profile/Profile.styles";
import { RemoveFromOrganizationButton } from "../../general/General.styles";

export const AttachmentItemContainer = styled(Box)(
  ({ theme, mobileMatches }) => ({
    marginTop: "2px",
    backgroundColor: theme.palette.secondary.dark,
    width: mobileMatches ? "100%" : "50%",
  })
);

export const AttachmentNameText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  flexGrow: 1,
  marginLeft: "10px",
}));

export const StyledDividerWithSpace = styled(StyledDivider)({
  marginBottom: "10px",
});

export const DeleteAttachmentButton = styled(RemoveFromOrganizationButton)({
  margin: 0,
});
