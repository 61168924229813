import { useParams } from "react-router-dom";
import OrganizationEditDesktop from "../../../components/profile/OrganizationEditDesktop";
import ProfileDesktopHeader from "../../../navigation/header/profile/desktop/ProfileDesktopHeader";
import AppAccess from "../../../components/common/AppAccess";
import { useEffect } from "react";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../../store/slices/api/subscriptionsApiSlice";
import { messageError } from "../../../util/notification";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import useCheckOrganizationRestricted from "../../../hooks/useCheckOrganizationRestricted";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import { useGetOrganizationUsersQuery } from "../../../store/slices/api/organizationsApiSlice";

const OrganizationEdit = () => {
  // General hooks
  const { organizationId } = useParams();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organization = user?.organizations.find((o) => o.id === organizationId);

  // Queries
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  const { data: organizationUsers, isLoading: isLoadingOrganizationUsers } =
    useGetOrganizationUsersQuery(organizationId, {
      skip: isRestricted,
    });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: organizationId,
      },
      {
        skip: isRestricted,
      }
    );

  // Other variables
  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue ?? 0);
  const usersCurrentValue = organizationUsers?.length ?? 0;

  // Effects
  useEffect(() => {
    const valuesLoaded = !(isLoadingOrganizationUsers || isLoadingActualUsage);
    const isLimitExceeded = usersCurrentValue > usersSubscriptionValue;
    const showAlarmMessage = !isRestricted && valuesLoaded && isLimitExceeded;

    if (showAlarmMessage) {
      messageError(getTranslation("LIMIT_EXCEEDED_USER", t, i18n));
    }
  }, [
    usersCurrentValue,
    usersSubscriptionValue,
    isLoadingOrganizationUsers,
    isLoadingActualUsage,
    isRestricted,
  ]);

  return (
    <AppAccess>
      <ProfileDesktopHeader />
      <OrganizationEditDesktop organizationId={organizationId} />;
    </AppAccess>
  );
};

export default OrganizationEdit;
