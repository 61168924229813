import React, { useEffect } from "react";
import { Box, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import CountWidgetContent from "./categorized-widgets/CountWidgetContent";
import MinMaxAverageWidgetContent from "./categorized-widgets/MinMaxAverageWidgetContent";
import PercentageWidgetContent from "./categorized-widgets/PercentageWidgetContent";
import StatusWidgetContent from "./categorized-widgets/StatusWidgetContent";
import PieChartWidgetContent from "./categorized-widgets/PieChartWidgetContent.jsx";
import DonutChartWidgetContent from "./categorized-widgets/DonutChartWidgetContent.jsx";
import LineChartWidgetContent from "./categorized-widgets/LineChartWidgetContent.jsx";
import BarChartWidgetContent from "./categorized-widgets/BarChartWidgetContent.jsx";
import FunnelChartWidgetContent from "./categorized-widgets/FunnelChartWidgetContent.jsx";
import SumChartWidgetContent from "./categorized-widgets/SumChartWidgetContent.jsx";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice.js";
import {
  WIDGET_CATEGORY,
  selectReportType,
  selectWidgetCategory,
  setWidgetCategory,
} from "../../../store/slices/reportsSlice.js";
import AreaChartWidgetContent from "./categorized-widgets/AreaChartWidgetContent.jsx";
import WidgetForm from "./WidgetForm.jsx";

const WIDGETS = [
  {
    id: 1,
    title: "Link",
    category: WIDGET_CATEGORY.LINK,
    reportTypes: ["SUMMARY", "LIST"],
  },
  {
    id: 2,
    title: "Count",
    category: WIDGET_CATEGORY.COUNT,
    reportTypes: ["LIST"],
  },
  {
    id: 3,
    title: "Min Max Average",
    category: WIDGET_CATEGORY.MIN_MAX_AVERAGE,
    reportTypes: ["LIST"],
  },
  {
    id: 4,
    title: "Percentage",
    category: WIDGET_CATEGORY.PERCENTAGE,
    reportTypes: ["LIST"],
  },
  {
    id: 5,
    title: "Status",
    category: WIDGET_CATEGORY.STATUS,
    reportTypes: ["LIST"],
  },
  {
    id: 6,
    title: "Pie chart",
    category: WIDGET_CATEGORY.PIE_CHART,
    reportTypes: ["SUMMARY"],
  },
  {
    id: 7,
    title: "Donut chart",
    category: WIDGET_CATEGORY.DONUT_CHART,
    reportTypes: ["SUMMARY"],
  },
  {
    id: 8,
    title: "Line chart",
    category: WIDGET_CATEGORY.LINE_CHART,
    reportTypes: ["SUMMARY"],
  },
  {
    id: 9,
    title: "Bar chart",
    category: WIDGET_CATEGORY.BAR_CHART,
    reportTypes: ["SUMMARY"],
  },
  {
    id: 10,
    title: "Funnel chart",
    category: WIDGET_CATEGORY.FUNNEL_CHART,
    reportTypes: ["SUMMARY"],
  },
  {
    id: 11,
    title: "Sum",
    category: WIDGET_CATEGORY.SUM,
    reportTypes: ["LIST"],
  },
  {
    id: 12,
    title: "Area chart",
    category: WIDGET_CATEGORY.AREA_CHART,
    reportTypes: ["SUMMARY"],
  },
];

const PIE_CHART_DATA = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      label: "Test",
      data: [5, 5, 10],
      backgroundColor: ["#0A5EB4", "1A3C66", "#7598D7"],
    },
  ],
};

const DONUT_CHART = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      fill: true,
      data: [5, 5, 10],
      backgroundColor: ["#0A5EB4", "1A3C66", "#7598D7"],
    },
  ],
  updateMode: "normal",
};

const LINE_CHART = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: "#095CAA",
      tension: 0.1,
    },
  ],
};

const BAR_CHART = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: ["#095CAA", "#095CAA", "#095CAA", "#095CAA"],
      borderColor: ["#095CAA", "#095CAA)", "#095CAA", "#095CAA"],
      borderWidth: 1,
    },
  ],
};

const FUNNEL_CHART = [
  {
    value: 100,
    name: "February",
    fill: "#095CAA",
  },
  {
    value: 80,
    name: "March",
    fill: "#095CAA",
  },
  {
    value: 70,
    name: "March",
    fill: "#095CAA",
  },
  {
    value: 60,
    name: "January",
    fill: "#095CAA",
  },
  {
    value: 30,
    name: "April",
    fill: "#095CAA",
  },
];

const AREA_CHART = {
  labels: ["January", "February", "March", "April"],
  datasets: [
    {
      label: "My Second dataset",
      data: [100, 20, 120, 70],
      borderColor: "#0076BC",
      backgroundColor: "#0076BC",
      fill: true,
    },
    {
      label: "My First dataset",
      data: [10, 100, 60, 80],
      borderColor: "#89A9D9",
      backgroundColor: "#89A9D9",
      fill: true,
    },
  ],
};

const AddWidgetWizardStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();

  // Selectors
  const reportType = useSelector(selectReportType);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const widgetCategory = useSelector(selectWidgetCategory);

  // Handlers
  const handleSelect = (category) => {
    dispatch(setWidgetCategory(category));
  };

  const filteredWidgets = WIDGETS.filter((w) =>
    w.reportTypes.some((rt) => rt === reportType)
  );

  useEffect(() => {
    return () => {
      dispatch(setWidgetCategory(WIDGET_CATEGORY.LINK));
    };
  }, []);

  return (
    <Box>
      {/* TODO Add translation*/}
      <Typography
        sx={{ fontWeight: "bold", marginBottom: "20px" }}
        variant="h5"
      >
        Create new asset management report
      </Typography>
      <SecondaryText>
        Do you want to add further widgets to show the report on your
        dashboards?
      </SecondaryText>
      <Grid container columnSpacing={3}>
        <Grid item xs={6}>
          <Grid marginTop="8px" container spacing={3}>
            {filteredWidgets.map((w) => {
              const { id, title, category } = w;
              const isSelected = widgetCategory === category;

              return (
                <Grid key={id} item xs={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      height: "180px",
                      backgroundColor: "#F1F1F1",
                      position: "relative",
                      cursor: "pointer",
                      border: `1px solid ${theme}`,
                    }}
                    onClick={() => {
                      handleSelect(category);
                    }}
                  >
                    <Stack sx={{ height: "100%" }}>
                      <Grid container padding="8px" alignItems="center">
                        <Grid item>
                          {/* {isSelected ? (
                            <NavigationActionIcon
                              active={true}
                              customfontsize={globalFontSize * 1.2}
                            >
                              check_circle
                            </NavigationActionIcon>
                          ) : (
                            <NavigationActionIcon
                              className="material-icons material-icons-outlined"
                              customfontsize={globalFontSize * 1.2}
                            >
                              circle
                            </NavigationActionIcon>
                          )} */}
                        </Grid>
                        <Grid item xs>
                          <SecondaryText variant="body2" textAlign="center">
                            {title}
                          </SecondaryText>
                        </Grid>
                        <Grid item>
                          {/* <NavigationActionIcon
                            sx={{ visibility: "hidden" }}
                            active={true}
                            customfontsize={globalFontSize * 1.2}
                          >
                            check_circle
                          </NavigationActionIcon> */}
                        </Grid>
                      </Grid>
                      {category === WIDGET_CATEGORY.COUNT && (
                        <CountWidgetContent count={144} recentCount={12} />
                      )}
                      {category === WIDGET_CATEGORY.MIN_MAX_AVERAGE && (
                        <MinMaxAverageWidgetContent
                          min={12}
                          max={12}
                          avg={144}
                        />
                      )}
                      {category === WIDGET_CATEGORY.PERCENTAGE && (
                        <PercentageWidgetContent percentage={75} />
                      )}
                      {category === WIDGET_CATEGORY.STATUS && (
                        <StatusWidgetContent
                          dangerCount={1}
                          warningCount={3}
                          successCount={144}
                        />
                      )}
                      {category === WIDGET_CATEGORY.PIE_CHART && (
                        <PieChartWidgetContent data={PIE_CHART_DATA} />
                      )}
                      {category === WIDGET_CATEGORY.DONUT_CHART && (
                        <DonutChartWidgetContent data={DONUT_CHART} />
                      )}
                      {category === WIDGET_CATEGORY.LINE_CHART && (
                        <LineChartWidgetContent data={LINE_CHART} />
                      )}
                      {category === WIDGET_CATEGORY.BAR_CHART && (
                        <BarChartWidgetContent data={BAR_CHART} />
                      )}
                      {category === WIDGET_CATEGORY.FUNNEL_CHART && (
                        <FunnelChartWidgetContent data={FUNNEL_CHART} />
                      )}
                      {category === WIDGET_CATEGORY.SUM && (
                        <SumChartWidgetContent sum={144} />
                      )}
                      {category === WIDGET_CATEGORY.AREA_CHART && (
                        <AreaChartWidgetContent data={AREA_CHART} />
                      )}
                    </Stack>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <WidgetForm category={widgetCategory} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddWidgetWizardStep;
